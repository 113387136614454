import React from "react";
import Grid from "../layout/grid";
import Select from "react-select";

export default (props) => {
	const propsInput = props.input || {};
	const listaOptions = (props.options || []).map((opt) => ({
		value: String(opt.id),
		label: opt.valor,
	}));
	const valueRecebido = props.value || propsInput.value;
	const valueMultiplo = valueRecebido instanceof Array && valueRecebido.length >= 0;
	const valueIndividual = listaOptions.find((opt) => opt.value == valueRecebido) || "";
	const estilo = props.style || {};
    const valueSelecionado = valueMultiplo ? valueRecebido : valueIndividual;

    const onChange = props.onChange || propsInput.onChange;
    const onBlur = () => (propsInput.onBlur ? propsInput.onBlur() : null);
    return (
        <Grid cols={props.cols} style={{ marginBlock: props.gridMarginBlock != null ? props.gridMarginBlock : 3 }}>
            <div className="form-group" style={{ ...estilo }}>
                <label htmlFor={props.name}>{props.label}</label>
                <Select
                    menuPortalTarget={document.body}
                    styles={{
                        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                        control: (provided, state) => ({
                            ...provided,
                            color: state.isDisabled ? "#333" : estilo.color || "#000",
                            backgroundColor: state.isDisabled ? "#f0f0f0" : estilo.backgroundColor || "#fff", // Set the background color for disabled state
                            opacity: state.isDisabled ? 0.5 : 1, // Optional: adjust opacity for disabled state
                            cursor: state.isDisabled ? "not-allowed" : "default", // Change the cursor for disabled state
                        }),
                        menu: (provided) => ({
                            ...provided,
                            backgroundColor: estilo.backgroundColor || "#FFF",
                            color: estilo.color,
                        }),
                        option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? "#EEEEEE" : estilo.backgroundColor || "#FFF", // Estilo para opções dentro do menu
                            color: state.isSelected || state.isFocused ? "#000" : estilo.color, // Cor do texto da opção, azul se estiver selecionada
                        }),
                        singleValue: (provided) => ({
                            ...provided,
                            color: estilo.color, // Cor do texto da opção selecionada
                        }),
                        multiValue: (provided) => ({
                            ...provided,
                            color: estilo.color, // Cor do texto das opções selecionadas quando multi
                        }),
                    }}
                    placeholder={props.placeholder}
                    isDisabled={props.readOnly}
                    value={valueSelecionado}
                    isClearable={props.isClearable == null || props.isClearable}
                    isSearchable={props.isSearchable == null || props.isSearchable}
                    isMulti={props.isMulti}
                    onChange={(item) => onChange(item ? (props.getTarget ? { name: props.name, value: item.value } : props.isMulti ? item : item.value) : null)}
                    onBlur={() => onBlur()}
                    options={listaOptions}
                />
            </div>
        </Grid>
    );
};

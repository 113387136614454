const INITIAL_STATE = {
    registro: {},
    aguardando: false,
    modoTela: "lista",
    lista: [],
    listaTiposIntegracao: [
        { id: "GROWATT", valor: "GROWATT" },
        { id: "SICESSOLAR", valor: "SICESSOLAR" },
        { id: "SOLARVIEW", valor: "SOLARVIEW" },
        { id: "FRONIUS", valor: "FRONIUS" },
        { id: "WEG", valor: "WEG" },
        { id: "SOLIS", valor: "SOLIS" },
        { id: "SOLARMAN", valor: "SOLARMAN" },
        { id: "EMA APP (Renovigi)", valor: "EMA APP (Renovigi)" },
        { id: "RENOCLIENT (Renovigi)", valor: "RENOCLIENT (Renovigi)" },
        { id: "FUSION SOLAR (Huawei)", valor: "FUSION SOLAR (Huawei)" },
        { id: "ISOLARCLOUD (Sungrow)", valor: "ISOLARCLOUD (Sungrow)" },
        { id: "SOLAREDGE", valor: "SOLAREDGE" },
        { id: "BYD (ENERGY CLOUD)", valor: "BYD (ENERGY CLOUD)" },
    ].sort((a, b) => a.valor.localeCompare(b.valor)),
    listaCliente: [],
    listaUnidadeConsumidora: [],
    filtro: {
        id_cliente: null,
        id_unidade_consumidora: null,
    },
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "GERACAO_INTEGRACAO_MODO_TELA":
            return {
                ...state,
                modoTela: action.payload.modo,
                registro: action.payload.registro,
            };

        case "GERACAO_INTEGRACAO_SET_AGUARDANDO":
            return { ...state, aguardando: action.payload };

        case "GERACAO_INTEGRACAO_FILTRO":
            return { ...state, filtro: action.payload };

        case "GERACAO_INTEGRACAO_LISTADO":
            return {
                ...state,
                lista: action.payload.data.map((item) => ({
                    ...item,
                    id_sistema_fotovoltaico:
                        item.tipo === "FRONIUS"
                            ? item.id_sistema_fotovoltaico && "https://www.solarweb.com/PvSystems/PvSystem?pvSystemId=" + item.id_sistema_fotovoltaico
                            : item.tipo === "SOLIS"
                            ? item.id_sistema_fotovoltaico && "https://www.soliscloud.com/#/station/stationDetails/generalSituation/" + item.id_sistema_fotovoltaico
                            : item.tipo === "SOLARVIEW"
                            ? item.id_sistema_fotovoltaico && "https://my.solarview.com.br/monitoramento?und=" + item.id_sistema_fotovoltaico
                            : ["FUSION SOLAR (Huawei)", "SOLARMAN", "WEG", "RENOCLIENT (Renovigi)", "ISOLARCLOUD (Sungrow)", "SOLAREDGE"].includes(item.tipo)
                            ? item.id_sistema_fotovoltaico
                            : "",
                })),
            };

        case "GERACAO_INTEGRACAO_CLIENTE_SELECT_LISTADO":
            return {
                ...state,
                listaCliente: action.payload.data,
            };

        case "GERACAO_INTEGRACAO_UNIDADE_CONSUMIDORA_SELECT_LISTADO":
            return {
                ...state,
                listaUnidadeConsumidora: action.payload.data,
            };

        default:
            return state;
    }
};

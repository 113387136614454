import axios from "axios";
import consts from "../consts";
import { setErroAPI, setSuccess } from "../common/toast/toast";

export function setFiltro(filtro) {
    return {
        type: "DASHBOARD_FILTRO",
        payload: filtro,
    };
}

export function getListaCliente() {
    const request = axios.get(`${consts.API_URL}/cliente/listarSelect`);
    return {
        type: "DASHBOARD_CLIENTE_SELECT_LISTADO",
        payload: request,
    };
}

export function getListaUnidadeConsumidora() {
    const request = axios.get(`${consts.API_URL}/unidadeConsumidora/listarSelect`);
    return {
        type: "DASHBOARD_UNIDADE_CONSUMIDORA_SELECT_LISTADO",
        payload: request,
    };
}

export function getListaDemandaContratoTipo() {
    const request = axios.get(`${consts.API_URL}/demandaContratoTipo/listarselect`);
    return {
        type: "DASHBOARD_CONTRATO_DEMANDA_TIPO_SELECT_LISTADO",
        payload: request,
    };
}

export function getDemanda() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "DASHBOARD_DEMANDA_LISTADO",
                payload: null,
            });

            const resp = await axios.get(
                `${consts.API_URL}/demandaContrato?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().dashboard.filtro.id_cliente || 0
            }&id_tipo_contrato_demanda=${getState().dashboard.filtro.id_tipo_contrato_demanda || 0}&id_unidade_consumidora=${
                    getState().dashboard.filtro.id_unidade_consumidora || 0
                }&encerrado=false`
            );
            dispatch({
                type: "DASHBOARD_DEMANDA_LISTADO",
                payload: {
                    demandas: resp.data,
                    vigente: resp.data[0],
                },
            });
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export async function getDemandaContagem() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "DASHBOARD_DEMANDA_CONTAGEM_LISTADO",
                payload: { data: [] },
            });
            if (!getState().auth.usuarioLogado) return {};
            const resp = await axios.get(`${consts.API_URL}/demandaContrato/listarContagemComAgendamentoDemanda?
			id_cliente=${getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : null}&id_tipo_contrato_demanda=${
                getState().dashboard.filtro.id_tipo_contrato_demanda || 0
            }`);

            //garante que "agendamentos" vai ser um array
            const listaDemandaComAgendamento = (resp.data || []).map((demanda) => {
                return {
                    ...demanda,
                    agendamentos: demanda.agendamentos || [],
                };
            });

            dispatch({
                type: "DASHBOARD_DEMANDA_CONTAGEM_LISTADO",
                payload: { data: listaDemandaComAgendamento },
            });
        } catch (erro) {
            setErroAPI(erro);
        }
    };
}

export function excluirAgendamento(registro) {
    return (dispatch, getState) => {
        if (window.confirm(`Deseja realmente excluir o agendamento de ${registro.competencia_agendamento}?`)) {
            axios
                .delete(`${consts.API_URL}/demandaAgendamento?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(getDemandaContagem());
                })
                .catch((e) => {
                    setErroAPI(e);
                });
        }
    };
}


export function excluirPeriodoTeste(registro) {
    return (dispatch, getState) => {
        if (window.confirm(`Deseja realmente excluir o período de teste?`)) {
            axios
                .delete(`${consts.API_URL}/demandaContrato/periodoTeste/?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(getDemandaContagem());
                })
                .catch((e) => {
                    setErroAPI(e);
                });
        }
    };
}



export function getListaGraficoCustoAnual(callback) {
    return (dispatch, getState) => {
        dispatch({
            type: "DASHBOARD_GRAFICO_CUSTO_ANUAL_LISTADO",
            payload: { data: [] },
        });

        axios
            .get(
                `${consts.API_URL}/fatura/listarGraficoCusto?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().dashboard.filtro.id_cliente || null
            }
			&id_unidade_consumidora=${getState().dashboard.filtro.id_unidade_consumidora || null}`
            )
            .then((resp) => {
                dispatch({
                    type: "DASHBOARD_GRAFICO_CUSTO_ANUAL_LISTADO",
                    payload: resp,
                });
                callback();
            })
            .catch((ex) => {
                callback();
            });
    };
}

export function getListaGraficoGeracaoQuantidadeAnual(callback) {
    return (dispatch, getState) => {
        dispatch({
            type: "DASHBOARD_GRAFICO_GERACAO_QUANTIDADE_ANUAL_LISTADO",
            payload: { data: [] },
        });

        axios
            .get(
                `${consts.API_URL}/fatura/listarGraficoGeracaoQuantidade?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().dashboard.filtro.id_cliente || null
            }
			&id_unidade_consumidora=${getState().dashboard.filtro.id_unidade_consumidora || null}`
            )
            .then((resp) => {
                dispatch({
                    type: "DASHBOARD_GRAFICO_GERACAO_QUANTIDADE_ANUAL_LISTADO",
                    payload: resp,
                });
                callback();
            })
            .catch((ex) => {
                callback();
            });
    };
}

export function getListaGraficoCustoPorUnidadeUltimoMes(callback) {
    return (dispatch, getState) => {
        dispatch({
            type: "DASHBOARD_GRAFICO_CUSTO_UNIDADE_ULTIMO_MES_LISTADO",
            payload: { data: [] },
        });

        let competenciaBase = null;
        if (getState().dashboard.filtro.mes_base == 2) {
            let dataAtual = new Date();
            dataAtual.setMonth(dataAtual.getMonth() - 2);
            competenciaBase = `${dataAtual.getFullYear()}${dataAtual.getMonth() < 9 ? "0" : ""}${
                dataAtual.getMonth() + 1
            }`;
        } else {
            let dataAtual = new Date();
            dataAtual.setMonth(dataAtual.getMonth() - 1);
            competenciaBase = `${dataAtual.getFullYear()}${dataAtual.getMonth() < 9 ? "0" : ""}${
                dataAtual.getMonth() + 1
            }`;
        }
            const idCliente = getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().dashboard.filtro.id_cliente || 0;
            axios
                .get(
                    `${consts.API_URL}/fatura/listarGraficoCustoPorUnidadeUltimoMes?
			id_cliente=${idCliente}
			${competenciaBase ? `&competencia_base=${competenciaBase}` : ""}`
                )
                .then((resp) => {
                    dispatch({
                        type: "DASHBOARD_GRAFICO_CUSTO_UNIDADE_ULTIMO_MES_LISTADO",
                        payload: resp,
                    });
                    callback();
                })
                .catch((ex) => {
                    callback();
                });
    };
}

export function getListaGraficoCustoPorUnidadeAcumuladoAno(callback) {
    return (dispatch, getState) => {
        dispatch({
            type: "DASHBOARD_GRAFICO_CUSTO_UNIDADE_ACUMULADO_ANO_LISTADO",
            payload: { data: [] },
        });

        const idCliente = getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente ? getState().auth.usuarioLogado.id_cliente : getState().dashboard.filtro.id_cliente || 0;
        axios
            .get(
                `${consts.API_URL}/fatura/listarGraficoCustoPorUnidadeAcumuladoAno?
			id_cliente=${idCliente}`
            )
            .then((resp) => {
                dispatch({
                    type: "DASHBOARD_GRAFICO_CUSTO_UNIDADE_ACUMULADO_ANO_LISTADO",
                    payload: resp,
                });
                callback();
            })
            .catch((ex) => {
                callback();
            });
    };
}

export function getListaConsumoPercentualPorHorarioUltimoMes(callback) {
    return (dispatch, getState) => {
        dispatch({
            type: "DASHBOARD_GRAFICO_CONSUMO_PERCENTUAL_POR_HORARIO_MES_LISTADO",
            payload: { data: [] },
        });

        let competenciaBase = null;
        if (getState().dashboard.filtro.mes_base == 2) {
            let dataAtual = new Date();
            dataAtual.setMonth(dataAtual.getMonth() - 2);
            competenciaBase = `${dataAtual.getFullYear()}${dataAtual.getMonth() < 9 ? "0" : ""}${
                dataAtual.getMonth() + 1
            }`;
        } else {
            let dataAtual = new Date();
            dataAtual.setMonth(dataAtual.getMonth() - 1);
            competenciaBase = `${dataAtual.getFullYear()}${dataAtual.getMonth() < 9 ? "0" : ""}${
                dataAtual.getMonth() + 1
            }`;
        }

        axios
            .get(
                `${consts.API_URL}/fatura/listarConsumoPercentualPorHorarioUltimoMes?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().dashboard.filtro.id_cliente || 0
            }
			${competenciaBase ? `&competencia_base=${competenciaBase}` : ""}`
            )
            .then((resp) => {
                dispatch({
                    type: "DASHBOARD_GRAFICO_CONSUMO_PERCENTUAL_POR_HORARIO_MES_LISTADO",
                    payload: resp,
                });
                callback();
            })
            .catch((ex) => {
                callback();
            });
    };
}

export function getListaGraficoGeracaoAvaliacaoProducao(callback) {
    return (dispatch, getState) => {
        dispatch({
            type: "DASHBOARD_GRAFICO_GERACAO_AVALIACAO_PRODUCAO_LISTADO",
            payload: { data: [] },
        });

        axios
            .get(
                `${consts.API_URL}/fatura/listarGraficoGeracaoAvaliacaoProducao?
			id_cliente=${
                getState().auth.usuarioLogado && getState().auth.usuarioLogado.acesso_cliente
                    ? getState().auth.usuarioLogado.id_cliente
                    : getState().dashboard.filtro.id_cliente || null
            }
			&id_unidade_consumidora=${getState().dashboard.filtro.id_unidade_consumidora || null}`
            )
            .then((resp) => {
                dispatch({
                    type: "DASHBOARD_GRAFICO_GERACAO_AVALIACAO_PRODUCAO_LISTADO",
                    payload: resp,
                });
                callback();
            })
            .catch((ex) => {
                callback();
            });
    };
}

/* eslint-disable default-case */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, getFormValues } from "redux-form";

import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import ContentCardFooter from "../common/template/contentCardFooter";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import Form from "../common/form/form";
import LabelAndInput from "../common/form/labelAndInput";
import LabelAndInputNumber from "../common/form/labelAndInputNumber";
import LabelAndInputMask from "../common/form/labelAndInputMask";
import Select from "../common/form/select";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import NewTr from "../common/table/newTr";
import Tr from "../common/table/tr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import FormatUtils from "../common/formatUtils/FormatUtils";
import DateFormat from "../common/dateFormat/DateFormat";

import {
    setModoTela,
    initForm,
    getLista,
    setFiltro,
    atualizarValoresFormulario,
    getListaDemandaCompetencia,
    adicionarHistorico,
    alterarHistorico,
    setListaDemandaCompetenciaAlteracao,
    getListaHistorico,
    getListaAgendamento,
    initFormAgendamento,
} from "./demandaContratoActions";

class DemandaContratoForm extends Component {
    state = {
        //se altera conforme cliques nos botões adicionar ou remover período de teste
        exibirCamposPeriodoTeste: (this.props.formularioValues.periodo_teste || []).length,
        //não se altera ao longo das iterações
        existiaPeriodoTesteCadastrado: (this.props.formularioValues.periodo_teste || []).length,
    };

    componentWillMount() {
        //se estiver filtrado mostra UCs que já possuem demanda no Adicionar,
        //sendo assim, é necessário resetar o filtro e carregar a lista completa novamente
        this.props.setFiltro({
            ...this.props.filtro,
            id_cliente: null,
            id_unidade_consumidora: null,
        });
        this.props.getLista();
        //--------------------------------------------------------------------------------
        this.props.getListaDemandaCompetencia(this.props.formularioValues.id);
        this.props.getListaAgendamento(this.props.formularioValues.id);
        if (this.props.formularioValues.competencia_inicio_periodo_teste && this.props.formularioValues.competencia_fim_periodo_teste) {
            this.setState({ ...this.state, existiaPeriodoTesteCadastrado: true });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.filtro.id_tipo_contrato_demanda != this.props.filtro.id_tipo_contrato_demanda) {
            this.props.getListaDemandaCompetencia(this.props.formularioValues.id);
            this.props.getListaAgendamento(this.props.formularioValues.id);
        }
    }

    render() {
        const usuarioLogado = this.props.usuarioLogado;
        let readOnly = this.props.excluir || this.props.visualizar ? "readOnly" : "";

        let mes = parseInt(this.props.formularioValues.mes_inicio);

        let listaCicloRural = [];
        if (mes) {
            const predicado = (item) => item.id == mes;
            for (let i = 0; i < 12; i++) {
                const objetoMes = this.props.listaMeses.find(predicado);
                listaCicloRural.push(`${objetoMes.valor}`);
                mes = mes + 1 == 13 ? 1 : mes + 1;
            }
        }

        const listaUCsSelect = this.props.listaUnidadeConsumidora.filter((item) => {
            const implantacaoLogado = usuarioLogado.usuario_implantacao;
            const existeDemandaParaUC = this.props.lista.find((demanda) => demanda.id_unidade_consumidora == item.id && !demanda.encerrado);
            const condicaoImplantacao = !implantacaoLogado || item.id_usuario_implantacao == usuarioLogado.id;
            const condicaoGrupoA = item.id_grupo_faturamento == 1;
            const condicaoExisteDemanda = !!this.props.formularioValues.id || !existeDemandaParaUC;
            const condicaoUcDoCliente = item.id_cliente == this.props.formularioValues.id_cliente;
            return condicaoImplantacao && condicaoGrupoA && condicaoExisteDemanda && condicaoUcDoCliente;
        });
        const ucSelecionada = listaUCsSelect.find((uc) => this.props.formularioValues.id_unidade_consumidora == uc.id);
        const msgUcSemModalidadeTarifaria = ucSelecionada && !ucSelecionada.id_modalidade_tarifaria ? "(Verifique a modalidade tarifária no cadastro da UC)" : "";
        return (
            <>
                <Form event={this.props.handleSubmit}>
                    <ContentCard>
                        <ContentCardBody>
                            {this.props.formularioValues && this.props.formularioValues.id ? (
                                <>
                                    <Row>
                                        <Grid cols="12 12 4 4">
                                            <Button
                                                text={this.props.formularioValues.arquivo_cusd ? "Contrato CUSD Importado (Substituir)" : "Importar Contrato CUSD"}
                                                type={"primary"}
                                                icon={"fa fa-file-import"}
                                                disabled={readOnly ? true : false}
                                                event={() => {
                                                    this.props.setModoTela("importarCusd", this.props.formularioValues);
                                                }}
                                            />
                                        </Grid>
                                        <Grid cols="12 12 4 4">
                                            <Button
                                                text={this.props.formularioValues.arquivo_ccer ? "Contrato CCER Importado (Substituir)" : "Importar Contrato CCER"}
                                                type={"primary"}
                                                icon={"fa fa-file-import"}
                                                disabled={readOnly ? true : false}
                                                event={() => {
                                                    this.props.setModoTela("importarCcer", this.props.formularioValues);
                                                }}
                                            />
                                        </Grid>
                                        <Grid cols="12 12 4 4">
                                            <Button
                                                text={this.props.formularioValues.arquivo_ciclo_rural ? "Ciclo Sazonal Importado (Substituir)" : "Importar Ciclo Sazonal"}
                                                type={"primary"}
                                                icon={"fa fa-file-import"}
                                                disabled={readOnly ? true : false}
                                                event={() => {
                                                    this.props.setModoTela("importarCicloRural", this.props.formularioValues);
                                                }}
                                            />
                                        </Grid>
                                    </Row>
                                </>
                            ) : null}
                            <Row
                                style={{
                                    paddingTop: this.state.exibirCamposPeriodoTeste ? 10 : null,
                                }}
                            >
                                {usuarioLogado.acesso_periodo_teste_demanda_adicionar && !this.state.exibirCamposPeriodoTeste ? (
                                    <Grid cols="12 4 4 4">
                                        <Button
                                            text={"Ativar Período de Testes"}
                                            type={"info"}
                                            icon={"fa fa-stopwatch"}
                                            disabled={readOnly ? true : false}
                                            event={() => {
                                                this.setState({
                                                    ...this.state,
                                                    exibirCamposPeriodoTeste: !this.state.exibirCamposPeriodoTeste,
                                                });
                                                this.props.initForm({
                                                    ...this.props.formularioValues,
                                                    competencia_inicio_periodo_teste: null,
                                                    competencia_fim_periodo_teste: null,
                                                });
                                            }}
                                        />
                                    </Grid>
                                ) : null}

                                {usuarioLogado.acesso_periodo_teste_demanda_excluir && this.state.exibirCamposPeriodoTeste ? (
                                    <Grid cols="12 4 4 4">
                                        <Button
                                            style={{
                                                paddingBlock: 25,
                                            }}
                                            text={"Remover período de testes"}
                                            type={"danger"}
                                            icon={"fa fa-times"}
                                            disabled={readOnly ? true : false}
                                            event={() => {
                                                this.setState({
                                                    ...this.state,
                                                    exibirCamposPeriodoTeste: !this.state.exibirCamposPeriodoTeste,
                                                });
                                                this.props.initForm({
                                                    ...this.props.formularioValues,
                                                    competencia_inicio_periodo_teste: null,
                                                    competencia_fim_periodo_teste: null,
                                                });
                                            }}
                                        />
                                    </Grid>
                                ) : null}
                                {(usuarioLogado.acesso_periodo_teste_demanda_visualizar ||
                                    usuarioLogado.acesso_periodo_teste_demanda_adicionar ||
                                    usuarioLogado.acesso_periodo_teste_demanda_editar ||
                                    usuarioLogado.acesso_periodo_teste_demanda_excluir) &&
                                this.state.exibirCamposPeriodoTeste ? (
                                    <Grid cols="12 8 8 8">
                                        <Row>
                                            <Field
                                                name="competencia_inicio_periodo_teste"
                                                component={LabelAndInputMask}
                                                label="Início do Período de Testes"
                                                placeholder="Informe a competência inicial"
                                                cols="12 4 4 4"
                                                mask="99/9999"
                                                readOnly={
                                                    readOnly ||
                                                    ((usuarioLogado.acesso_periodo_teste_demanda_visualizar || usuarioLogado.acesso_periodo_teste_demanda_excluir) &&
                                                        !usuarioLogado.acesso_periodo_teste_demanda_adicionar &&
                                                        !usuarioLogado.acesso_periodo_teste_demanda_editar) ||
                                                    (!usuarioLogado.acesso_periodo_teste_demanda_editar && this.state.existiaPeriodoTesteCadastrado)
                                                }
                                            />
                                            <Field
                                                name="competencia_fim_periodo_teste"
                                                component={LabelAndInputMask}
                                                label="Fim do Período de Testes"
                                                placeholder="Informe a competência final"
                                                cols="12 4 4 4"
                                                mask="99/9999"
                                                readOnly={
                                                    readOnly ||
                                                    ((usuarioLogado.acesso_periodo_teste_demanda_visualizar || usuarioLogado.acesso_periodo_teste_demanda_excluir) &&
                                                        !usuarioLogado.acesso_periodo_teste_demanda_adicionar &&
                                                        !usuarioLogado.acesso_periodo_teste_demanda_editar) ||
                                                    (!usuarioLogado.acesso_periodo_teste_demanda_editar && this.state.existiaPeriodoTesteCadastrado)
                                                }
                                            />
                                        </Row>
                                    </Grid>
                                ) : null}
                            </Row>
                            <br />
                            <Row>
                                <Field
                                    name="id_cliente"
                                    component={Select}
                                    //se usuario implantacao estiver logado
                                    //filtra para poder incluir apenas itens na UC
                                    //que ele é o implantador
                                    options={this.props.listaCliente.filter(
                                        (cliente) => !usuarioLogado.usuario_implantacao || cliente.itens.find((uc) => uc.id_usuario_implantacao == usuarioLogado.id)
                                    )}
                                    label="Cliente"
                                    cols="12 12 12 12"
                                    placeholder="Selecione o cliente"
                                    readOnly={this.props.formularioValues.id ? "readOnly" : ""}
                                />
                            </Row>
                            <Row>
                                <Field
                                    name="id_unidade_consumidora"
                                    component={Select}
                                    //se usuario implantacao estiver logado
                                    //filtra para poder incluir apenas itens na UC
                                    //que ele é o implantador
                                    options={listaUCsSelect}
                                    label={
                                        <>
                                            Unidade Consumidora <span style={{ color: "red" }}>{msgUcSemModalidadeTarifaria}</span>
                                        </>
                                    }
                                    cols="12 12 12 12"
                                    placeholder="Selecione a unidade consumidora"
                                    readOnly={this.props.formularioValues.id ? "readOnly" : ""}
                                />
                            </Row>
                            {this.props.formularioValues.sazonal ? (
                                <>
                                    <Row>
                                        <Select
                                            name="mes_inicio"
                                            options={this.props.listaMeses}
                                            label="Início do Ciclo Sazonal"
                                            cols="12 12 12 12"
                                            placeholder="Selecione o mês de início do ciclo sazonal"
                                            readOnly={this.props.formularioValues.id ? "readOnly" : ""}
                                            value={this.props.formularioValues.mes_inicio}
                                            onChange={(valor) => {
                                                this.props.initForm({
                                                    ...this.props.formularioValues,
                                                    mes_inicio: valor,
                                                    mes_fim: valor == 1 ? parseInt(valor) + 11 : parseInt(valor) - 1,
                                                });
                                            }}
                                        />
                                    </Row>
                                    <Row>
                                        <Field
                                            name="mes_fim"
                                            component={Select}
                                            options={this.props.listaMeses.filter((mes) => mes.id == this.props.formularioValues.mes_fim)}
                                            label="Fim do Ciclo Sazonal"
                                            cols="12 12 12 12"
                                            placeholder="Fim do ciclo sazonal (Definido após selecionar o mês de início do ciclo sazonal)"
                                            readOnly={this.props.formularioValues.id ? "readOnly" : ""}
                                        />
                                    </Row>
                                </>
                            ) : (
                                <Row>
                                    <Field
                                        name="competencia_base_sazonalidade"
                                        component={LabelAndInputMask}
                                        label="Competência Início da Não Sazonalidade"
                                        placeholder="Informe a competência"
                                        cols="12 12 12 12"
                                        mask="99/9999"
                                        readOnly={readOnly}
                                        autoFocus={true}
                                    />
                                </Row>
                            )}
                            <Row>
                                <Field name="observacao" component={LabelAndInput} label="Observação" placeholder="Informe a observação" cols="12 12 12 12" readOnly={readOnly} />
                            </Row>
                        </ContentCardBody>
                    </ContentCard>
                    <Row style={{ paddingInline: 7 }}>
                        {this.props.listaDemandaContratoTipo.map((tipo) => {
                            const abas = listarAbasVisiveis(listaUCsSelect, this.props.formularioValues.id_unidade_consumidora);
                            if (!abas.includes(tipo.id)) return null;
                            const tipoSelecionado = this.props.filtro.id_tipo_contrato_demanda;
                            return (
                                <Grid
                                    cols="4 4 4 4"
                                    style={{
                                        padding: "15px 0px 5px 10px",
                                        backgroundColor: tipoSelecionado == tipo.id ? "#FFF" : "#EEE",
                                        color: tipoSelecionado == tipo.id ? "#000" : "#333",
                                        marginBottom: 0,
                                        zIndex: tipoSelecionado == tipo.id ? 1 : 0,
                                        borderRadius: "10px 10px 0 0",
                                        boxShadow: "#ddd 1px -7px 8px 1px",
                                        border: "solid 1px #ddd",
                                        borderBottom: "none",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        this.props.setFiltro({
                                            ...this.props.filtro,
                                            id_tipo_contrato_demanda: tipo.id,
                                        });
                                        this.props.atualizarValoresFormulario(this.props.formularioValues);
                                    }}
                                >
                                    <h6>{tipo.valor}</h6>
                                </Grid>
                            );
                        })}
                    </Row>
                    <ContentCard>
                        <ContentCardBody>
                            <Row style={{ padding: 9 }}>
                                <label>Contrato Atual</label>
                            </Row>
                            <Row style={{ paddingInline: 5 }}>
                                {listaCicloRural.map((cicloRural, i) => {
                                    let sufixoTipoContrato = "";
                                    switch (this.props.filtro.id_tipo_contrato_demanda) {
                                        case 1:
                                            sufixoTipoContrato = "";
                                            break;
                                        case 2:
                                            sufixoTipoContrato = "_ponta";
                                            break;
                                        case 3:
                                            sufixoTipoContrato = "_geracao";
                                            break;
                                    }
                                    return (
                                        <Field
                                            key={i}
                                            name={`valor_${i + 1}${sufixoTipoContrato}`}
                                            component={LabelAndInputNumber}
                                            label={cicloRural}
                                            placeholder="Informe a demanda"
                                            cols="12 6 4 3"
                                            casas={2}
                                            readOnly={readOnly}
                                        />
                                    );
                                })}
                            </Row>

                            <Row alignCenter style={{ padding: "10px 10px 30px 0px" }}>
                                <Grid cols="12 4 4 4">
                                    <Button
                                        text={"Agendar"}
                                        type={"primary"}
                                        icon={"fa fa-calendar-alt"}
                                        disabled={readOnly}
                                        event={() => {
                                            const valores1ao12 = retornaValores1ao12(this.props);
                                            const registro = {
                                                id_tipo_contrato_demanda: this.props.filtro.id_tipo_contrato_demanda,
                                                id_demanda_contrato: this.props.formularioValues.id,
                                                id_cliente: this.props.formularioValues.id_cliente,
                                                id_unidade_consumidora: this.props.formularioValues.id_unidade_consumidora,
                                                mes_inicio: this.props.formularioValues.mes_inicio,
                                                ...valores1ao12,
                                            };
                                            this.props.setModoTela("cadastroAgendamento", registro);
                                            this.props.initFormAgendamento(registro);
                                        }}
                                    />
                                </Grid>
                                <Grid cols="12 4 4 4">
                                    {this.props.excluir ? (
                                        <Button text="Excluir" submit type={"danger"} icon={"fa fa-trash"} />
                                    ) : (
                                        <Button
                                            text={this.props.formularioValues.id ? "Atualizar Contrato Atual" : "Salvar"}
                                            submit
                                            type={"success"}
                                            icon={"fa fa-check"}
                                            disabled={this.props.visualizar}
                                        />
                                    )}
                                </Grid>
                                <Grid cols="12 4 4 4">
                                    <Button
                                        text="Voltar"
                                        type={"warning"}
                                        icon={"fa fa-chevron-left"}
                                        event={() => {
                                            this.props.setModoTela("lista");
                                            this.props.getLista();
                                        }}
                                    />
                                </Grid>
                            </Row>
                            {this.props.modoTelaDemandaCompetencia == "lista" ? this.listaItens(listaCicloRural, readOnly) : this.alterarDemandaCompetencia(listaCicloRural)}
                        </ContentCardBody>
                    </ContentCard>
                </Form>
            </>
        );
    }

    renderizarLinhasDemandaCompetencia(listaCiclo, readOnly) {
        return listaCiclo.map((item) => {
            const listaDemandaCompetencia = [];
            for (let i = 1; i <= 12; i++) {
                listaDemandaCompetencia.push(`demandaCompetencia${i}`);
            }
            let mesAtual = parseInt(`${new Date().getFullYear()}${new Date().getMonth() < 9 ? "0" : ""}${new Date().getMonth() + 1}`);

            return (
                <NewTr
                    key={item.ano}
                    backgroundColorTr={mesAtual >= item.demandaCompetencia1.competencia && mesAtual <= item.demandaCompetencia12.competencia ? "#3f8" : ""}
                    botoes={[
                        {
                            type: "warning",
                            icon: "fas fa-pencil-alt",
                            disabled: readOnly ? true : false,
                            event: () => {
                                this.props.setListaDemandaCompetenciaAlteracao(
                                    "alteracao",
                                    [
                                        item.demandaCompetencia1,
                                        item.demandaCompetencia2,
                                        item.demandaCompetencia3,
                                        item.demandaCompetencia4,
                                        item.demandaCompetencia5,
                                        item.demandaCompetencia6,
                                        item.demandaCompetencia7,
                                        item.demandaCompetencia8,
                                        item.demandaCompetencia9,
                                        item.demandaCompetencia10,
                                        item.demandaCompetencia11,
                                        item.demandaCompetencia12,
                                    ].map((demandaCompetencia) => ({
                                        ...demandaCompetencia,
                                        quantidade: FormatUtils.formatarValorTela(demandaCompetencia.quantidade, 2),
                                    }))
                                );

                                this.props.getListaHistorico(this.props.formularioValues.id, item.demandaCompetencia1.competencia, item.demandaCompetencia12.competencia);
                            },
                        },
                    ]}
                >
                    <Td>{item.ano}</Td>
                    {listaDemandaCompetencia.map((demanda) => (
                        <Td alignCenter>{FormatUtils.formatarValorTela(item[demanda].quantidade, 2)}</Td>
                    ))}
                </NewTr>
            );
        });
    }

    renderizarLinhasDemandaAgendamento(readOnly) {
        return this.props.listaAgendamento.map((item) => {
            const listaValores = [];
            for (let i = 1; i <= 12; i++) {
                listaValores.push(`valor_${i}`);
            }

            const valores = {};
            listaValores.forEach((valor) => (valores[valor] = FormatUtils.formatarValorTela(item[valor], 2)));
            const registro = {
                ...item,
                mes_inicio: this.props.formularioValues.mes_inicio,
                competencia_inicio_vigencia: `${String(item.competencia_inicio_vigencia).substring(4, 6)}/${String(item.competencia_inicio_vigencia).substring(0, 4)}`,
                ...valores,
            };

            return (
                <NewTr
                    key={item.id}
                    backgroundColorTr={item.executado ? "#5df" : "#fe4"}
                    botoes={[
                        {
                            type: "warning",
                            icon: "fas fa-pencil-alt",
                            disabled: readOnly,
                            event: () => {
                                this.props.setModoTela("cadastroAgendamento", registro);
                                this.props.initFormAgendamento(registro);
                            },
                        },
                        {
                            type: "danger",
                            icon: "fas fa-trash-alt",
                            disabled: readOnly,
                            event: () => {
                                this.props.setModoTela("exclusaoAgendamento", registro);
                                this.props.initFormAgendamento(registro);
                            },
                        },
                    ]}
                >
                    <Td minWidth={200}>
                        {item.executado ? "Agendamento Executado " : "Agendado "}
                        {String(item.competencia_inicio_vigencia).substring(4, 6)}/{String(item.competencia_inicio_vigencia).substring(0, 4)}
                    </Td>
                    {listaValores.map((valor) => (
                        <Td alignCenter>{FormatUtils.formatarValorTela(item[valor], 2)}</Td>
                    ))}
                </NewTr>
            );
        });
    }

    listaItens(listaCicloRural, readOnly) {
        let listaCiclo = [];
        for (let i = 0; i < this.props.listaDemandaCompetencia.length; i += 12) {
            let anoInicial = this.props.listaDemandaCompetencia[i] ? parseInt(this.props.listaDemandaCompetencia[i].competencia / 100) : null;
            let anoFinal = this.props.listaDemandaCompetencia[i + 11] ? parseInt(this.props.listaDemandaCompetencia[i + 11].competencia / 100) : null;
            if (anoInicial && anoFinal) {
                listaCiclo.push({
                    ano: anoInicial == anoFinal ? anoInicial : `${anoInicial} até ${anoFinal}`,
                    demandaCompetencia1: this.props.listaDemandaCompetencia[i],
                    demandaCompetencia2: this.props.listaDemandaCompetencia[i + 1],
                    demandaCompetencia3: this.props.listaDemandaCompetencia[i + 2],
                    demandaCompetencia4: this.props.listaDemandaCompetencia[i + 3],
                    demandaCompetencia5: this.props.listaDemandaCompetencia[i + 4],
                    demandaCompetencia6: this.props.listaDemandaCompetencia[i + 5],
                    demandaCompetencia7: this.props.listaDemandaCompetencia[i + 6],
                    demandaCompetencia8: this.props.listaDemandaCompetencia[i + 7],
                    demandaCompetencia9: this.props.listaDemandaCompetencia[i + 8],
                    demandaCompetencia10: this.props.listaDemandaCompetencia[i + 9],
                    demandaCompetencia11: this.props.listaDemandaCompetencia[i + 10],
                    demandaCompetencia12: this.props.listaDemandaCompetencia[i + 11],
                });
            }
        }

        let anoHistoricoInicial = this.props.listaDemandaCompetencia[0] ? parseInt(this.props.listaDemandaCompetencia[0].competencia / 100) : null;
        let anoHistoricoFinal = this.props.listaDemandaCompetencia[11] ? parseInt(this.props.listaDemandaCompetencia[11].competencia / 100) : null;

        return (
            <ContentCard
                style={{
                    paddingTop: 20,
                    marginTop: 20,
                    boxShadow: "0px -2px 5px 1px #ccc",
                }}
            >
                <ContentCardHeader>
                    {this.props.formularioValues.id && listaCiclo.length > 0 && anoHistoricoInicial && anoHistoricoFinal ? (
                        <Row>
                            <Grid cols="12 12 4 4">
                                <Button
                                    text={`Adicionar Histórico para ${
                                        anoHistoricoInicial == anoHistoricoFinal ? anoHistoricoInicial - 1 : `${anoHistoricoInicial - 1} até ${anoHistoricoFinal - 1}`
                                    }`}
                                    type={"success"}
                                    icon={"fa fa-plus"}
                                    disabled={readOnly ? true : false}
                                    event={() => {
                                        this.props.adicionarHistorico(
                                            this.props.formularioValues.id,
                                            this.props.listaDemandaCompetencia.slice(0, 12).map((item) => ({
                                                ...item,
                                                id: null,
                                                competencia: item.competencia - 100,
                                            }))
                                        );
                                    }}
                                />
                            </Grid>
                        </Row>
                    ) : null}
                </ContentCardHeader>

                <ContentCardBody>
                    <Table responsive>
                        <THead>
                            <Tr>
                                <Th alignLeft width={200}>
                                    Ano
                                </Th>
                                {listaCicloRural.map((cicloRural) => (
                                    <Th key={cicloRural} alignCenter width={200}>
                                        {cicloRural}
                                    </Th>
                                ))}
                                <Th width={50}></Th>
                            </Tr>
                        </THead>
                        <TBody>
                            {this.renderizarLinhasDemandaCompetencia(listaCiclo, readOnly)}
                            {this.renderizarLinhasDemandaAgendamento(readOnly)}
                        </TBody>
                    </Table>
                </ContentCardBody>
            </ContentCard>
        );
    }

    alterarDemandaCompetencia() {
        return (
            <>
                <ContentCard>
                    <ContentCardHeader>
                        <label>Alteração (só é possível alterar os históricos)</label>
                    </ContentCardHeader>

                    <ContentCardBody>
                        <Form>
                            <Row>
                                {this.props.listaAlteracaoDemandaCompetencia.map((item, i) => (
                                    <LabelAndInputNumber
                                        name="competencia"
                                        label={`${this.props.listaMeses.filter((mes) => mes.id == parseInt(String(item.competencia).substring(4, 6)))[0].valor} de ${parseInt(
                                            item.competencia / 100
                                        )}`}
                                        placeholder="Informe a demanda"
                                        cols="12 6 4 3"
                                        casas={2}
                                        value={item.quantidade}
                                        readOnly={
                                            item.competencia >= `${new Date().getFullYear()}${new Date().getMonth() < 9 ? "0" : ""}${new Date().getMonth() + 1}` ? "readOnly" : ""
                                        }
                                        onChange={(data) => {
                                            this.props.setListaDemandaCompetenciaAlteracao(
                                                "alteracao",
                                                this.props.listaAlteracaoDemandaCompetencia.map((demandaCompetencia) => {
                                                    return {
                                                        ...demandaCompetencia,
                                                        quantidade: demandaCompetencia.id == item.id ? data.target.value : demandaCompetencia.quantidade,
                                                    };
                                                })
                                            );
                                        }}
                                    />
                                ))}
                            </Row>
                        </Form>
                    </ContentCardBody>

                    <ContentCardFooter>
                        <Row alignCenter>
                            <Grid cols="6 6 4 3">
                                <Button
                                    text={"Salvar"}
                                    type={"success"}
                                    icon={"fa fa-check"}
                                    event={() => {
                                        this.props.alterarHistorico(this.props.formularioValues.id, this.props.listaAlteracaoDemandaCompetencia);
                                    }}
                                />
                            </Grid>
                            <Grid cols="6 6 4 3">
                                <Button text="Voltar" type={"warning"} icon={"fa fa-chevron-left"} event={() => this.props.setListaDemandaCompetenciaAlteracao("lista", [])} />
                            </Grid>
                        </Row>
                    </ContentCardFooter>
                </ContentCard>

                <ContentCard>
                    <ContentCardBody>
                        <Row>
                            <Grid cols="12 12 12 12">
                                <Table responsive>
                                    <THead>
                                        <Tr>
                                            <Th colSpan={2}>Histórico</Th>
                                        </Tr>
                                    </THead>
                                    <TBody>
                                        {this.props.listaHistorico.map((item) => (
                                            <Tr>
                                                <Td width={150}>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_alteracao)}</Td>
                                                <Td>{item.descricao}</Td>
                                            </Tr>
                                        ))}
                                    </TBody>
                                </Table>
                            </Grid>
                        </Row>
                    </ContentCardBody>
                </ContentCard>
            </>
        );
    }
}

DemandaContratoForm = reduxForm({
    form: "demandaContratoForm",
    destroyOnUnmount: false,
})(DemandaContratoForm);
const mapStateToProps = (state) => ({
    sessao: state.auth.usuario,
    usuarioLogado: state.auth.usuarioLogado,
    formularioValues: getFormValues("demandaContratoForm")(state),
    registro: state.demandaContrato.registro,
    lista: state.demandaContrato.lista,
    filtro: state.demandaContrato.filtro,
    listaCliente: state.demandaContrato.listaCliente,
    listaUnidadeConsumidora: state.demandaContrato.listaUnidadeConsumidora,
    listaDemandaContratoTipo: state.demandaContrato.listaDemandaContratoTipo,
    listaMeses: state.demandaContrato.listaMeses,
    listaDemandaCompetencia: state.demandaContrato.listaDemandaCompetencia,
    modoTelaDemandaCompetencia: state.demandaContrato.modoTelaDemandaCompetencia,
    listaAlteracaoDemandaCompetencia: state.demandaContrato.listaAlteracaoDemandaCompetencia,
    listaHistorico: state.demandaContrato.listaHistorico,
    listaAgendamento: state.demandaContrato.listaAgendamento,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setModoTela,
            initForm,
            getLista,
            atualizarValoresFormulario,
            setFiltro,
            getListaDemandaCompetencia,
            adicionarHistorico,
            alterarHistorico,
            setListaDemandaCompetenciaAlteracao,
            getListaHistorico,
            getListaAgendamento,
            initFormAgendamento,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(DemandaContratoForm);

function retornaValores1ao12(props) {
    let sufixoTipoContrato = "";
    switch (props.filtro.id_tipo_contrato_demanda) {
        case 1:
            sufixoTipoContrato = "";
            break;
        case 2:
            sufixoTipoContrato = "_ponta";
            break;
        case 3:
            sufixoTipoContrato = "_geracao";
            break;
    }
    const valores = {};
    for (let i = 1; i <= 12; i++) {
        valores[`valor_${i}`] = props.formularioValues[`valor_${i}${sufixoTipoContrato}`];
    }
    return valores;
}

function listarAbasVisiveis(listaUCs, idUnidadeConsumidora) {
    const ucSelecionada = listaUCs.find((uc) => idUnidadeConsumidora == uc.id);
    if (!ucSelecionada) return [];

    // Regras para ilustrar as abas NO CADASTRO DE DEMANDA:

    if (
        ucSelecionada.id_grupo_faturamento == 1 && // UNIDADE Grupo A
        ucSelecionada.id_modalidade_tarifaria == 1 && //Horossazonal Verde
        !ucSelecionada.geracao //Sem geração
    ) {
        return [1]; //ilustra apenas a aba FORA PONTA
    }
    if (
        ucSelecionada.id_grupo_faturamento == 1 && // UNIDADE Grupo A
        ucSelecionada.id_modalidade_tarifaria == 1 && //Horossazonal Verde
        ucSelecionada.geracao //COM geração
    ) {
        return [1, 3]; //ilustra apenas a aba FORA PONTA E DEMANDA G
    }

    if (
        ucSelecionada.id_grupo_faturamento == 1 && // UNIDADE Grupo A
        (ucSelecionada.id_modalidade_tarifaria == 2 || !ucSelecionada.id_modalidade_tarifaria) && //Horossazonal AZUL ou SEM Horossazonal
        !ucSelecionada.geracao //Sem geração
    ) {
        return [1, 2]; //ilustra apenas a aba FORA PONTA E PONTA
    }

    if (
        ucSelecionada.id_grupo_faturamento == 1 && // UNIDADE Grupo A
        (ucSelecionada.id_modalidade_tarifaria == 2 || !ucSelecionada.id_modalidade_tarifaria) && //Horossazonal AZUL ou SEM Horossazonal
        ucSelecionada.geracao //COM geração
    ) {
        return [1, 2, 3]; //ilustra a aba FORA PONTA E PONTA E DEMANDA G
    }
}

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import locale from "@amcharts/amcharts4/lang/pt_BR.js";

function montarGraficoPieAnual(state, component, nome, dados = [], titulo, prefixo, sufixo, ocultarLegenda = false, tamanhoPizza, cores, sufixoAdicionalPorTipo = {}) {
    if (component) {
        component.dispose();
    }

    let chart = am4core.create(nome, am4charts.PieChart3D);
    chart.colors.step = 1;

    chart.language.locale = locale;

    let title = chart.titles.create();
    title.text = titulo;
    title.fontSize = 16;
    title.marginBottom = 30;

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.filePrefix = "Gráfico";
    chart.exporting.menu.align = "right";
    chart.exporting.menu.verticalAlign = "top";
    chart.exporting.menu.items = [
        {
            label: "...",
            menu: [
                {
                    type: "png",
                    label: "PNG",
                },
            ],
        },
    ];

    // chart.legend = new am4charts.Legend()
    // chart.legend.position = 'top'
    // chart.legend.paddingBottom = 20
    // chart.legend.labels.template.maxWidth = 95
    chart.language.locale["_decimalSeparator"] = ",";
    chart.language.locale["_thousandSeparator"] = ".";
    chart.numberFormatter.numberFormat = "###,###,###,###.00";
    chart.hiddenState.properties.opacity = 0;

    if (!ocultarLegenda) {
        chart.legend = new am4charts.Legend();
    }

    let series = chart.series.push(new am4charts.PieSeries3D());
    series.dataFields.value = "value";
    series.dataFields.category = "category";
    series.colors.list = state.cores;

    series.slices.template.tooltipText = `{category}: [bold]${prefixo} {value.formatNumber("###,###,###,###.00")} ${sufixo}[/]`;

    series.slices.template.adapter.add("tooltipText", (tooltipText, target) => {
        const categoria = target.dataItem.category;

        // Verifica se a categoria existe no objeto sufixoAdicionalPorTipo (usado para exibir o cálculo realizado geralmente)
        if (sufixoAdicionalPorTipo[categoria]) {
            // Adiciona o sufixo extra, que pode ser uma string dinâmica do objeto
            return `{category}: [bold]${prefixo} {value.formatNumber("###,###,###,###.00")} ${sufixo}[/] ${sufixoAdicionalPorTipo[categoria]}`;
        }
        // Caso contrário, mantém o tooltip padrão
        return tooltipText;
    });

    // Quebra de linha automática para descrições longas nas fatias
    series.labels.template.maxWidth = 220; // Define uma largura máxima, ajuste conforme necessário
    series.labels.template.wrap = true; // Ativa a quebra de linha
    series.labels.template.truncate = false; // Evita truncamento, deixando o texto quebrar em várias linhas

    if (cores) {
        // Mapa de cores fixas para categorias
        // Aplicando cores fixas para cada categoria com base no mapa de cores
        series.slices.template.adapter.add("fill", (fill, target) => {
            return cores[target.dataItem.category] || fill; // Se a categoria tiver uma cor definida, usa essa cor
        });
    }
    // let interfaceColors = new am4core.InterfaceColorSet();

    // Definindo o raio do gráfico
    // series.slices.template.radius = am4core.percent(raio); // define tamanho do círculo
    // chart.width = am4core.percent(raio); // Define a largura do gráfico como 70% do container
    if (tamanhoPizza) {
        chart.height = am4core.percent(tamanhoPizza); // Define a altura do gráfico como 70% do container
    }

    let data = [];
    dados.forEach((item) => {
        item.tipos.forEach((itemTipo) => {
            if (!data.find((serie) => serie.category == itemTipo.tipo)) {
                data.push({
                    category: String(itemTipo.tipo),
                    value: 0,
                });
            }
            data = data.map((serie) => {
                if (serie.category == itemTipo.tipo) {
                    return {
                        ...serie,
                        value: serie.value + (itemTipo.valor == "NaN" ? 0 : itemTipo.valor),
                    };
                } else {
                    return serie;
                }
            });
        });
    });

    chart.data = data;

    return chart;
}

export { montarGraficoPieAnual };

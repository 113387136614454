import axios from "axios";
import consts from "../consts";
import { setErroAPI, setSuccess } from "../common/toast/toast";

export function setModoTela(modo, registro = {}) {
    return {
        type: "MONITORAMENTO_CONSUMO_MODO_TELA",
        payload: {
            modo: modo,
            registro: registro,
        },
    };
}

export function setAguardando(aguardando) {
    return {
        type: "MONITORAMENTO_CONSUMO_SET_AGUARDANDO",
        payload: aguardando,
    };
}

export function setFiltro(filtro) {
    return {
        type: "MONITORAMENTO_CONSUMO_FILTRO",
        payload: filtro,
    };
}
export function setDataGrafico(data) {
    return {
        type: "MONITORAMENTO_CONSUMO_SET_DATA_GRAFICO",
        payload: data,
    };
}

// export function getLista() {
// 	return async (dispatch, getState) => {
// 		//evita buscar 2 vezes enquanto jáesta buscando a primeira vez
// 		if (getState().geracaoLeitura.aguardando) return {};
// 		dispatch({
// 			type: "MONITORAMENTO_CONSUMO_SET_AGUARDANDO",
// 			payload: true,
// 		});
// 		//ALTERADO PRA CARREGAR A LISTA INTEIRA UMA VEZ E DEPOIS FILTRAR O QUE JÁ ESTA CARREGADO NO FRONT
// 		try {
// 			const { id_cliente, id_unidade_consumidora, id_agrupador, agrupar_por_uc } = getState().geracaoLeitura.filtro;
// 			const resp = await axios.get(
// 				`${consts.API_URL}/geracaoLeitura?id_cliente=${id_cliente}&id_unidade_consumidora=${id_unidade_consumidora}&id_agrupador=${
// 					//pode ser id_geracao_integracao (modo individual) ou id da UC (modo agrupado por UC)
// 					id_agrupador
// 				}&agrupar_por_uc=${agrupar_por_uc}`
// 			);
// 			dispatch({
// 				type: "MONITORAMENTO_CONSUMO_LISTADO",
// 				payload: resp,
// 			});
// 			dispatch({
// 				type: "MONITORAMENTO_CONSUMO_SET_AGUARDANDO",
// 				payload: false,
// 			});
// 		} catch (erro) {
// 			setErroAPI(erro);
// 		}
// 	};
// }

export async function getDemandaContagem() {
    return async (dispatch, getState) => {
        try {
            if (getState().monitoramentoConsumo.aguardando) return {};
            const usuarioLogado = getState().auth.usuarioLogado;
            dispatch(
                {
                    type: "MONITORAMENTO_CONSUMO_DEMANDA_CONTAGEM_LISTADO",
                    payload: { data: [] },
                },
                setAguardando(true)
            );
            if (!usuarioLogado) return {};
            const filtro = getState().monitoramentoConsumo.filtro;
            const idCliente = usuarioLogado.acesso_cliente ? usuarioLogado.id_cliente : filtro.id_cliente;
            const tipoContratoDemanda = filtro.id_tipo_contrato_demanda || 0;
            const resp = await axios.get(`${consts.API_URL}/demandaContrato/listarContagemComAgendamentoDemanda?
			id_cliente=${idCliente}&id_tipo_contrato_demanda=${tipoContratoDemanda}`);

            //garante que "agendamentos" vai ser um array e filtra as UCs conforme as selecionadas no filtro de UC
            const listaDemandaComAgendamento = (resp.data || [])
                .filter((item) => !filtro.unidades_consumidoras.length || filtro.unidades_consumidoras.find((uc) => uc.value == item.id_unidade_consumidora))
                .map((demanda) => {
                    return {
                        ...demanda,
                        agendamentos: demanda.agendamentos || [],
                    };
                });

            dispatch(
                {
                    type: "MONITORAMENTO_CONSUMO_DEMANDA_CONTAGEM_LISTADO",
                    payload: { data: listaDemandaComAgendamento },
                },
                setAguardando(false)
            );
        } catch (erro) {
            setErroAPI(erro);
        }
    };
}

export function excluirPeriodoTeste(registro) {
    return (dispatch, getState) => {
        if (window.confirm(`Deseja realmente excluir o período de teste?`)) {
            axios
                .delete(`${consts.API_URL}/demandaContrato/periodoTeste/?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(getDemandaContagem());
                })
                .catch((e) => {
                    setErroAPI(e);
                });
        }
    };
}

export function excluirAgendamento(registro) {
    return (dispatch, getState) => {
        if (window.confirm(`Deseja realmente excluir o agendamento de ${registro.competencia_agendamento}?`)) {
            axios
                .delete(`${consts.API_URL}/demandaAgendamento?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(getDemandaContagem());
                })
                .catch((e) => {
                    setErroAPI(e);
                });
        }
    };
}

//listas usadas nos filtros
export function carregarListas() {
    return (dispatch, getState) => {
        dispatch([setAguardando(true), getListaCliente(), getListaUnidadeConsumidora(), getListaDemandaContratoTipo(), setAguardando(false)]);
    };
}

export function getListaCliente() {
    const request = axios.get(`${consts.API_URL}/cliente/listarSelect`);
    return {
        type: "MONITORAMENTO_CONSUMO_CLIENTE_SELECT_LISTADO",
        payload: request,
    };
}

export function getListaUnidadeConsumidora() {
    const request = axios.get(`${consts.API_URL}/unidadeConsumidora/listarSelect`);
    return {
        type: "MONITORAMENTO_CONSUMO_UNIDADE_CONSUMIDORA_SELECT_LISTADO",
        payload: request,
    };
}

export function getListaDemandaContratoTipo() {
    const request = axios.get(`${consts.API_URL}/demandaContratoTipo/listarselect`);
    return {
        type: "MONITORAMENTO_CONSUMO_CONTRATO_DEMANDA_TIPO_SELECT_LISTADO",
        payload: request,
    };
}
import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ContentCard from "../../common/template/contentCard";
import ContentCardHeader from "../../common/template/contentCardHeader";
import ContentCardBody from "../../common/template/contentCardBody";
import Row from "../../common/layout/row";
import Grid from "../../common/layout/grid";
import Table from "../../common/table/table";
import THead from "../../common/table/tHead";
import Tr from "../../common/table/tr";
import Th from "../../common/table/th";
import TBody from "../../common/table/tBody";
import Td from "../../common/table/td";
import Button from "../../common/button/button";
import Select from "../../common/form/select";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import NumberFormat from "react-number-format";
import { getDemanda, getListaGraficoConsumoAnual, getListaGraficoConsumoPorUnidadeAcumuladoAno, getListaConsumoPorUnidadePeriodo, setFiltro } from "../analiseActions";
import { montarGraficoLineMensal } from "../../common/graficos/montarGraficoLineMensal.js";
import { montarGraficoHorizontalBar } from "../../common/graficos/montarGraficoHorizontalBar.js";
import { montarGraficoLineMensalSafra } from "../../common/graficos/montarGraficoLineMensalSafra.js";
import { montarGraficoClusteredColumnAnual } from "../../common/graficos/montarGraficoClusteredColumnAnual.js";
import { adicionarPontosMilhar } from "../../utils/adicionarPontosMilhar.js";

function analiseConsumo(props) {
    const {
        state,
        listar,
        contratoDemanda,
        listaGraficoConsumoAnual,
        listaConsumoPorUnidadePeriodo,
        listaGraficoConsumoPorUnidadeAcumuladoAno,
        filtro,
        getDemanda,
        getListaGraficoConsumoAnual,
        getListaConsumoPorUnidadePeriodo,
        getListaGraficoConsumoPorUnidadeAcumuladoAno,
        setAguardando,
        setFiltro,
        listaAnos,
    } = props;

    const [graficoConsumoAnual, setGraficoConsumoAnual] = useState(null);
    const [graficoConsumoMensal, setGraficoConsumoMensal] = useState(null);
    const [graficoConsumoPorUnidadeAcumuladoAno, setGraficoConsumoPorUnidadeAcumuladoAno] = useState(null);
    const [listaConsumoEditavel, setListaConsumoEditavel] = useState([]);
    useEffect(() => {
        atualizarTodosGraficos();
        return desmontarTodosGraficos;
    }, []);

    useEffect(() => {
        const buscarDados = async () => {
            if (!state.aguardando) return;

            await Promise.all([getDemanda(), getListaGraficoConsumoAnual(), getListaConsumoPorUnidadePeriodo(), getListaGraficoConsumoPorUnidadeAcumuladoAno()]);

            setAguardando(false);
        };

        buscarDados();
        if (!state.aguardando) {
            atualizarTodosGraficos();
        }
    }, [state.aguardando]);

    function atualizarTodosGraficos() {
        atualizarListaGraficoConsumoAnual();
        atualizarListaGraficoConsumoPorUnidadeAcumuladoAno();
    }
    function desmontarTodosGraficos() {
        if (graficoConsumoAnual) graficoConsumoAnual.dispose();
        if (graficoConsumoMensal) graficoConsumoMensal.dispose();
        if (graficoConsumoPorUnidadeAcumuladoAno) graficoConsumoPorUnidadeAcumuladoAno.dispose();
    }

    function atualizarListaGraficoConsumoAnual() {
        const graficoConsumoAnualAtualizado = montarGraficoClusteredColumnAnual(state, graficoConsumoAnual, "graficoConsumoAnual", listaGraficoConsumoAnual, "Consumo Total (kWh)", "", "kWh");

        setGraficoConsumoAnual(graficoConsumoAnualAtualizado);

        //atualiza a lista quando altera os valores, para recalcular a sazonalidade com os valores simulados
        setListaConsumoEditavel([...listaGraficoConsumoAnual]);

        const montarGraficoMensal = filtro.consumo_ano_safra ? montarGraficoLineMensalSafra : montarGraficoLineMensal;
        const graficoConsumoMensalAtualizado = montarGraficoMensal(state, graficoConsumoMensal, "graficoConsumoMensal", listaGraficoConsumoAnual, "Consumo Mensal (kWh)", "", "kWh");

        setGraficoConsumoMensal(graficoConsumoMensalAtualizado);
    }

    function atualizarListaGraficoConsumoPorUnidadeAcumuladoAno() {
        const graficoConsumoPorUnidadeAcumuladoAnoAtualizado = montarGraficoHorizontalBar({
            component: graficoConsumoPorUnidadeAcumuladoAno,
            chartDivId: "graficoConsumoPorUnidadeAcumuladoAno",
            category: "agrupador",
            value: "valor",
            bullet: "valor",
            bulletPosition: "out",
            bulletColor: "#fff",
            lista: [...listaGraficoConsumoPorUnidadeAcumuladoAno].sort((a, b) => a.valor - b.valor),
            titulo: "Consumo Acumulado Anual por UC (R$)",
            prefixo: "",
            sufixo: "kWh",
        });
        setGraficoConsumoPorUnidadeAcumuladoAno(graficoConsumoPorUnidadeAcumuladoAnoAtualizado);
    }

    function renderizarCelulasComZeros(corCiclo, listaDados) {
        let listaMeses = [];
        for (let i = listaDados.length; i < 12; i++) {
            listaMeses.push(i);
        }

        return listaMeses.map((mes) => (
            <Td key={mes} alignCenter color="#fff" backgroundColor={corCiclo}>
                0
            </Td>
        ));
    }

    function renderizarCabecalhoMeses({ ucFiltradaSazonal, demandaMesInicial, demandaMesFinal }) {
        const janeiroAteMaio = (
            <>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "01" ? "#66bb6a" : demandaMesFinal == "01" ? "#ef5350" : null) : null,
                    }}
                >
                    Janeiro
                    {ucFiltradaSazonal ? (demandaMesInicial == "01" ? " (I)" : demandaMesFinal == "01" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "02" ? "#66bb6a" : demandaMesFinal == "02" ? "#ef5350" : null) : null,
                    }}
                >
                    Fevereiro
                    {ucFiltradaSazonal ? (demandaMesInicial == "02" ? " (I)" : demandaMesFinal == "02" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "03" ? "#66bb6a" : demandaMesFinal == "03" ? "#ef5350" : null) : null,
                    }}
                >
                    Março
                    {ucFiltradaSazonal ? (demandaMesInicial == "03" ? " (I)" : demandaMesFinal == "03" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "04" ? "#66bb6a" : demandaMesFinal == "04" ? "#ef5350" : null) : null,
                    }}
                >
                    Abril
                    {ucFiltradaSazonal ? (demandaMesInicial == "04" ? " (I)" : demandaMesFinal == "04" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "05" ? "#66bb6a" : demandaMesFinal == "05" ? "#ef5350" : null) : null,
                    }}
                >
                    Maio
                    {ucFiltradaSazonal ? (demandaMesInicial == "05" ? " (I)" : demandaMesFinal == "05" ? " (F)" : "") : ""}
                </Th>{" "}
            </>
        );
        const junhoAteDez = (
            <>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "06" ? "#66bb6a" : demandaMesFinal == "06" ? "#ef5350" : null) : null,
                    }}
                >
                    Junho
                    {ucFiltradaSazonal ? (demandaMesInicial == "06" ? " (I)" : demandaMesFinal == "06" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "07" ? "#66bb6a" : demandaMesFinal == "07" ? "#ef5350" : null) : null,
                    }}
                >
                    Julho
                    {ucFiltradaSazonal ? (demandaMesInicial == "07" ? " (I)" : demandaMesFinal == "07" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "08" ? "#66bb6a" : demandaMesFinal == "08" ? "#ef5350" : null) : null,
                    }}
                >
                    Agosto
                    {ucFiltradaSazonal ? (demandaMesInicial == "08" ? " (I)" : demandaMesFinal == "08" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "09" ? "#66bb6a" : demandaMesFinal == "09" ? "#ef5350" : null) : null,
                    }}
                >
                    Setembro
                    {ucFiltradaSazonal ? (demandaMesInicial == "09" ? " (I)" : demandaMesFinal == "09" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "10" ? "#66bb6a" : demandaMesFinal == "10" ? "#ef5350" : null) : null,
                    }}
                >
                    Outubro
                    {ucFiltradaSazonal ? (demandaMesInicial == "10" ? " (I)" : demandaMesFinal == "10" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "11" ? "#66bb6a" : demandaMesFinal == "11" ? "#ef5350" : null) : null,
                    }}
                >
                    Novembro
                    {ucFiltradaSazonal ? (demandaMesInicial == "11" ? " (I)" : demandaMesFinal == "11" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "12" ? "#66bb6a" : demandaMesFinal == "12" ? "#ef5350" : null) : null,
                    }}
                >
                    Dezembro
                    {ucFiltradaSazonal ? (demandaMesInicial == "12" ? " (I)" : demandaMesFinal == "12" ? " (F)" : "") : ""}
                </Th>
            </>
        );

        return filtro.consumo_ano_safra ? (
            <>
                {junhoAteDez}
                {janeiroAteMaio}
            </>
        ) : (
            <>
                {janeiroAteMaio}
                {junhoAteDez}
            </>
        );
    }

    let ucFiltradaSazonal = false;
    let demandaAnoInicial = "";
    let demandaMesInicial = "";
    let demandaAnoFinal = "";
    let demandaMesFinal = "";
    let demandaCompetenciaInicial = "";
    let demandaCompetenciaFinal = "";
    let primeiroMesNaoSazonal = "";
    let primeiroAnoNaoSazonal = "";

    //se é não sazonal, pega os últimos 12 meses,
    const mesAtualDate = new Date().getMonth(); //não soma 1 pq o mes atual não deve ser contabilizado
    const anoAtual = String(new Date().getFullYear());
    //AQUI É A LÓGICA PARA PINTAR AS CÉLULAS DE CINZA
    if (contratoDemanda) {
        const mesLimite = mesAtualDate < 10 ? "0" + mesAtualDate : String(mesAtualDate);
        const mesesDescontar = Number(mesLimite) - 11; //diminui 11 meses para pegar o mes inicial dos últimos 12 meses
        const anoInicialCalculado = mesesDescontar > 0 ? anoAtual : anoAtual - 1; //se descontando 11 meses der negativo, considera ano anterior
        const mesInicialCalculado = Number(mesLimite) + 1 > 12 ? "01" : Number(mesLimite) + 1; //mes atual 10, inicia em 10+1 do ano anterior
        const competenciaInicialCalculada = Number(String(anoInicialCalculado) + String(mesInicialCalculado)); //usada pra comparar com competencia base
        //se competencia base for maior considera ela como inicial, senão considera a calculada
        const competenciaBaseSazonalidade = contratoDemanda.competencia_base_sazonalidade > competenciaInicialCalculada ? contratoDemanda.competencia_base_sazonalidade : competenciaInicialCalculada;

        ucFiltradaSazonal = contratoDemanda.unidade_consumidora_sazonal;
        demandaAnoInicial = ucFiltradaSazonal ? String(contratoDemanda.competencia_inicio).substring(0, 4) : String(competenciaBaseSazonalidade).substring(0, 4);
        demandaMesInicial = ucFiltradaSazonal ? String(contratoDemanda.competencia_inicio).substring(4, 6) : String(competenciaBaseSazonalidade).substring(4, 6);
        demandaAnoFinal = ucFiltradaSazonal ? String(contratoDemanda.competencia_fim).substring(0, 4) : anoAtual;
        demandaMesFinal = ucFiltradaSazonal ? String(contratoDemanda.competencia_fim).substring(4, 6) : mesLimite;
        demandaCompetenciaInicial = ucFiltradaSazonal ? parseInt(contratoDemanda.competencia_inicio) : competenciaBaseSazonalidade; //usado apenas no calculo de sazonalidade de uc Nao Sazonal
        demandaCompetenciaFinal = ucFiltradaSazonal ? parseInt(contratoDemanda.competencia_fim) : parseInt(`${anoAtual}${mesLimite}`);
        primeiroMesNaoSazonal = ucFiltradaSazonal ? "" : String(contratoDemanda.competencia_base_sazonalidade).substring(4, 6);
        primeiroAnoNaoSazonal = ucFiltradaSazonal ? "" : String(contratoDemanda.competencia_base_sazonalidade).substring(0, 4);
    }

    let corCiclo = "#30303d";

    let anosConsumo = [];
    listaGraficoConsumoAnual.forEach((item) => {
        item.anos.forEach((ano) => {
            const anoString = String(ano.ano);
            if (!anosConsumo.includes(anoString)) {
                anosConsumo.push(anoString);
            }
        });
    });

    // testa se na lista de anos (formato '2024' ou '2024/2025') inclui o ano de inicio ou fim da demanda
    if (demandaAnoInicial && !anosConsumo.find((ano) => String(ano).substring(0, 4).includes(demandaAnoInicial))) {
        const demandaAnoInicialFormatado = filtro.consumo_ano_safra ? `${demandaAnoInicial}/${parseInt(demandaAnoInicial) + 1}` : `${demandaAnoInicial}`;
        anosConsumo.push(demandaAnoInicialFormatado);
    }

    if (demandaAnoFinal && !anosConsumo.find((ano) => String(ano).substring(0, 4).includes(demandaAnoFinal))) {
        const demandaAnoFinalFormatado = filtro.consumo_ano_safra ? `${demandaAnoFinal}/${parseInt(demandaAnoFinal) + 1}` : `${demandaAnoFinal}`;
        anosConsumo.push(demandaAnoFinalFormatado);
    }

    anosConsumo = anosConsumo.sort((a, b) => parseInt(String(a).substring(0, 4)) - parseInt(String(b).substring(0, 4)));

    return (
        <div>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",
                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoConsumoAnual" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",
                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoConsumoMensal" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>{" "}
                    <div
                        style={{
                            minWidth: "890px",
                        }}
                    >
                        <Table responsive>
                            <THead>
                                <Tr>
                                    <Th>Ano</Th>
                                    {renderizarCabecalhoMeses({ ucFiltradaSazonal, demandaMesInicial, demandaMesFinal })}
                                    <Th alignCenter>Sazonalidade</Th>
                                </Tr>
                            </THead>
                            <TBody>
                                {anosConsumo.map((anoOriginal) => {
                                    const anoCalculoIndiceSazonal = String(anoOriginal).substring(0, 4); //quando é safra pega só o ano antes da barra ex: 2023/2024, considera 2023

                                    return (
                                        <Tr key={anoOriginal}>
                                            <Td color="#fff">{anoOriginal}</Td>
                                            {listaGraficoConsumoAnual.map((mes) => {
                                                //quando é modo safra, se o mes for jun à dez, pega o ano antes da barra, se for de jan a maio pega o ano depois da barra (2024/2025), se não é safra pega o ano original (2024)
                                                const ano = filtro.consumo_ano_safra ? (parseInt(mes.mes) >= 6 ? `${String(anoOriginal).substring(0, 4)}` : `${String(anoOriginal).substring(5)}`) : anoOriginal;
                                                const competenciaLinha = parseInt(`${ano}${mes.mes}`);
                                                const propsInicioNaoSazonalidade = {
                                                    title: mes.mes == primeiroMesNaoSazonal && ano == primeiroAnoNaoSazonal && !ucFiltradaSazonal ? "Primeiro mês Não Sazonal" : "",
                                                    style: {
                                                        border: mes.mes == primeiroMesNaoSazonal && ano == primeiroAnoNaoSazonal && !ucFiltradaSazonal ? "3px solid #00ff51" : null,
                                                    },
                                                };

                                                if (filtro.unidades_consumidoras.length == 1) {
                                                    if (ucFiltradaSazonal) {
                                                        corCiclo = mes.mes == demandaMesInicial ? (demandaAnoInicial == ano ? "#595967" : "#30303d") : corCiclo;
                                                    } else {
                                                        corCiclo = competenciaLinha >= demandaCompetenciaInicial && competenciaLinha <= demandaCompetenciaFinal ? "#595967" : "#30303d";
                                                    }
                                                } else {
                                                    corCiclo = "#30303d";
                                                }
                                                //mes.anos = [{ano: 2020, mes: '12', valor: 2443.4},...]
                                                const anoMes = mes.anos.find((anoMes) => anoMes.ano == anoOriginal);
                                                if (anoMes) {
                                                    if (anoMes.digitado || (competenciaLinha >= demandaCompetenciaInicial && competenciaLinha <= demandaCompetenciaFinal && !anoMes.valor)) {
                                                        return (
                                                            <Td {...propsInicioNaoSazonalidade} key={`val${mes.mes}${anoMes.ano}`} alignCenter backgroundColor={corCiclo} color="#fff">
                                                                <NumberFormat
                                                                    className="form-control"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        maxWidth: 80,
                                                                        height: 20,
                                                                        fontSize: 13,
                                                                    }}
                                                                    placeholder={"0"}
                                                                    decimalSeparator={","}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={2}
                                                                    value={String(anoMes.valor || "0,00").replace(".", ",")}
                                                                    onChange={(data) => {
                                                                        let lista = listaGraficoConsumoAnual.map((item) => {
                                                                            return {
                                                                                mes: item.mes,
                                                                                anos: item.anos.map((itemAno) => {
                                                                                    if (mes.mes == item.mes && itemAno.ano == anoMes.ano) {
                                                                                        return {
                                                                                            ...itemAno,
                                                                                            valor: parseFloat(data.target.value.replace(",", ".")),
                                                                                        };
                                                                                    } else {
                                                                                        return itemAno;
                                                                                    }
                                                                                }),
                                                                            };
                                                                        });
                                                                        //atualiza a lista quando altera os valores, para recalcular a sazonalidade com os valores simulados
                                                                        setListaConsumoEditavel([...lista]);

                                                                        getListaGraficoConsumoAnual(lista);

                                                                        const graficoConsumoMensalAtualizado = montarGraficoLineMensal(
                                                                            state,
                                                                            graficoConsumoMensal,
                                                                            "graficoConsumoMensal",
                                                                            lista,
                                                                            "Consumo Mensal (kWh)",
                                                                            "",
                                                                            "kWh"
                                                                        );
                                                                        setGraficoConsumoMensal(graficoConsumoMensalAtualizado);
                                                                    }}
                                                                />
                                                            </Td>
                                                        );
                                                    } else {
                                                        return (
                                                            <Td {...propsInicioNaoSazonalidade} key={`${mes.mes}${anoMes.ano}`} alignCenter backgroundColor={corCiclo} color="#fff">
                                                                {parseFloat(anoMes.valor || 0)
                                                                    .toFixed(0)
                                                                    .replace(".", ",")}
                                                            </Td>
                                                        );
                                                    }
                                                    // });
                                                } else {
                                                    if (competenciaLinha >= demandaCompetenciaInicial && competenciaLinha <= demandaCompetenciaFinal) {
                                                        return (
                                                            <Td {...propsInicioNaoSazonalidade} key={`dig${mes.mes}${ano}`} alignCenter backgroundColor={corCiclo} color="#fff">
                                                                <NumberFormat
                                                                    className="form-control"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        maxWidth: 80,
                                                                        height: 20,
                                                                        fontSize: 13,
                                                                    }}
                                                                    placeholder={"0"}
                                                                    decimalSeparator={","}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={2}
                                                                    value={String(0 || "0,00").replace(".", ",")}
                                                                    onChange={(data) => {
                                                                        let lista = listaGraficoConsumoAnual.map((item) => {
                                                                            if (item.mes != mes.mes) {
                                                                                return item;
                                                                            } else {
                                                                                let anosAux = item.anos.map((itemAnoAux) => itemAnoAux);
                                                                                anosAux.push({
                                                                                    ano: filtro.consumo_ano_safra ? anoOriginal : ano,
                                                                                    mes: item.mes,
                                                                                    valor: parseFloat(data.target.value.replace(",", ".")),
                                                                                    digitado: true,
                                                                                });

                                                                                return {
                                                                                    ...item,
                                                                                    anos: anosAux,
                                                                                };
                                                                            }
                                                                        });

                                                                        //atualiza a lista quando altera os valores, para recalcular a sazonalidade com os valores simulados
                                                                        setListaConsumoEditavel([...lista]);

                                                                        getListaGraficoConsumoAnual(lista);

                                                                        const graficoConsumoMensalAtualizado = montarGraficoLineMensal(
                                                                            state,
                                                                            graficoConsumoMensal,
                                                                            "graficoConsumoMensal",
                                                                            lista,
                                                                            "Consumo Mensal (kWh)",
                                                                            "",
                                                                            "kWh"
                                                                        );
                                                                        setGraficoConsumoMensal(graficoConsumoMensalAtualizado);
                                                                    }}
                                                                />
                                                            </Td>
                                                        );
                                                    } else {
                                                        return (
                                                            <Td {...propsInicioNaoSazonalidade} key={`dig${mes.mes}${ano}`} alignCenter backgroundColor={corCiclo} color="#fff">
                                                                0
                                                            </Td>
                                                        );
                                                    }
                                                }
                                            })}
                                            {renderizarCelulasComZeros(corCiclo, listaGraficoConsumoAnual)}
                                            <Td style={{ display: "flex", justifyContent: "space-around" }} color="#fff" backgroundColor={demandaAnoFinal == anoCalculoIndiceSazonal ? "#005662" : "#40838f"}>
                                                {contratoDemanda
                                                    ? (() => {
                                                          let listaSazonalidade = [];
                                                          //altera o calculo conforme edita os valores
                                                          listaConsumoEditavel.forEach((item) => {
                                                              item.anos.forEach((itemMes) => {
                                                                  const anoComparacao = filtro.consumo_ano_safra
                                                                      ? parseInt(itemMes.mes) >= 6
                                                                          ? `${String(itemMes.ano).substring(0, 4)}`
                                                                          : `${String(itemMes.ano).substring(5)}`
                                                                      : itemMes.ano;
                                                                  listaSazonalidade.push({
                                                                      competencia: parseInt(`${anoComparacao}${itemMes.mes}`),
                                                                      valor: itemMes.valor,
                                                                  });
                                                              });
                                                          });
                                                          listaSazonalidade.sort((a, b) => a.competencia - b.competencia);

                                                          //SAZONALIADE = (SOMA DOS QUATRO MENORES) DIVIDIDO (SOMA DOS QUATRO MAIORES)
                                                          if (contratoDemanda) {
                                                              //não sazonal só calcula pro ano atual
                                                              if (!ucFiltradaSazonal && anoAtual != anoCalculoIndiceSazonal) return "-";

                                                              let demandaInicio = parseInt(`${demandaMesInicial == "01" ? anoCalculoIndiceSazonal : anoCalculoIndiceSazonal - 1}${demandaMesInicial}`);
                                                              let demandaFim = parseInt(`${anoCalculoIndiceSazonal}${demandaMesFinal}`);

                                                              //se for não sazonal altera o inicio e fim
                                                              if (!ucFiltradaSazonal && anoAtual == anoCalculoIndiceSazonal) {
                                                                  demandaInicio = demandaCompetenciaInicial;
                                                                  demandaFim = demandaCompetenciaFinal;
                                                              }

                                                              //se for de 01/22 a 12/22 mostra 2022, se for 04/22 a 03/23 mostra 22/23
                                                              const ciclo =
                                                                  demandaAnoInicial == demandaAnoFinal
                                                                      ? `${anoCalculoIndiceSazonal}`
                                                                      : `${demandaMesInicial}/${String(anoCalculoIndiceSazonal - 1)} à ${demandaMesFinal}/${String(anoCalculoIndiceSazonal)}`;

                                                              //se o for não sazonal, sobreescreve o demandaInicio e demandaFim

                                                              //separa só as 12 que devem ser consideradas
                                                              listaSazonalidade = listaSazonalidade.filter((item) => item.competencia >= demandaInicio && item.competencia <= demandaFim && item.valor > 0);
                                                              // só calcula se tiver no mínimo 8 valores
                                                              if (listaSazonalidade.length < 8) {
                                                                  return (
                                                                      <>
                                                                          <div>{`${ciclo}:`}</div>
                                                                          <div style={{ paddingInline: 14 }}>-</div>
                                                                      </>
                                                                  );
                                                              }
                                                              //   console.log("-----------------------------------------");
                                                              //   console.log(listaSazonalidade);

                                                              //ordena para as 4 com menor valor ficar no inicio e as 4 maiores no final
                                                              listaSazonalidade.sort((a, b) => a.valor - b.valor);
                                                              let menores = 0;
                                                              for (let i = 0; i < 4; i++) {
                                                                  //   console.log("menor -> ", listaSazonalidade[i]);
                                                                  menores += listaSazonalidade[i].valor;
                                                              }
                                                              let maiores = 0;
                                                              for (let i = listaSazonalidade.length - 1; i >= listaSazonalidade.length - 4; i--) {
                                                                  //   console.log("maior -> ", listaSazonalidade[i]);
                                                                  maiores += listaSazonalidade[i].valor;
                                                              }

                                                              //   console.log(listaSazonalidade);
                                                              //se estiver iterando

                                                              return (
                                                                  <>
                                                                      <div>{`${ciclo}:`}</div>
                                                                      <div style={{ color: parseFloat(menores / maiores) <= 0.2 ? "#3f3" : "#fff", fontWeight: parseFloat(menores / maiores) <= 0.2 ? "bold" : "normal" }}>
                                                                          {` ${parseFloat(menores / maiores)
                                                                              .toFixed(2)
                                                                              .replace(".", ",")}
                                                                          `}
                                                                      </div>
                                                                  </>
                                                              );
                                                          }
                                                      })()
                                                    : "-"}
                                            </Td>
                                        </Tr>
                                    );
                                })}
                            </TBody>
                        </Table>
                    </div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ padding: 8 }}>
                <ContentCardHeader>
                    <Row>
                        <Grid cols="12 6 5 5">
                            <Row>
                                <Grid cols="12 12 12 12">
                                    <label>Período A</label>
                                </Grid>
                                <Grid cols="6 6 6 6">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                        <DatePicker
                                            variant="inline"
                                            openTo="year"
                                            views={["year", "month"]}
                                            label="De"
                                            autoOk
                                            value={
                                                filtro.competencia_inicial_consumo_comparativo_antes
                                                    ? `${String(filtro.competencia_inicial_consumo_comparativo_antes).substring(0, 4)}/${String(filtro.competencia_inicial_consumo_comparativo_antes).substring(
                                                          4,
                                                          6
                                                      )}/01 12:00:00`
                                                    : null
                                            }
                                            onChange={(value) => {
                                                setFiltro({
                                                    ...filtro,
                                                    competencia_inicial_consumo_comparativo_antes: value ? parseInt(`${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${value.getMonth() + 1}`) : null,
                                                });
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid cols="6 6 6 6">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                        <DatePicker
                                            variant="inline"
                                            openTo="year"
                                            views={["year", "month"]}
                                            label="Até"
                                            autoOk
                                            value={
                                                filtro.competencia_final_custo_comparativo_antes
                                                    ? `${String(filtro.competencia_final_consumo_comparativo_antes).substring(0, 4)}/${String(filtro.competencia_final_consumo_comparativo_antes).substring(
                                                          4,
                                                          6
                                                      )}/01 12:00:00`
                                                    : null
                                            }
                                            onChange={(value) => {
                                                setFiltro({
                                                    ...filtro,
                                                    competencia_final_consumo_comparativo_antes: value ? parseInt(`${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${value.getMonth() + 1}`) : null,
                                                });
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Row>
                        </Grid>
                        <Grid cols="12 6 5 5">
                            <Row>
                                <Grid cols="12 12 12 12">
                                    <label>Período B</label>
                                </Grid>
                                <Grid cols="6 6 6 6">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                        <DatePicker
                                            variant="inline"
                                            openTo="year"
                                            views={["year", "month"]}
                                            label="De"
                                            autoOk
                                            value={
                                                filtro.competencia_inicial_consumo_comparativo_depois
                                                    ? `${String(filtro.competencia_inicial_consumo_comparativo_depois).substring(0, 4)}/${String(filtro.competencia_inicial_consumo_comparativo_depois).substring(
                                                          4,
                                                          6
                                                      )}/01 12:00:00`
                                                    : null
                                            }
                                            onChange={(value) => {
                                                setFiltro({
                                                    ...filtro,
                                                    competencia_inicial_consumo_comparativo_depois: value ? parseInt(`${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${value.getMonth() + 1}`) : null,
                                                });
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid cols="6 6 6 6">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                        <DatePicker
                                            variant="inline"
                                            openTo="year"
                                            views={["year", "month"]}
                                            label="Até"
                                            autoOk
                                            value={
                                                filtro.competencia_final_consumo_comparativo_depois
                                                    ? `${String(filtro.competencia_final_consumo_comparativo_depois).substring(0, 4)}/${String(filtro.competencia_final_consumo_comparativo_depois).substring(
                                                          4,
                                                          6
                                                      )}/01 12:00:00`
                                                    : null
                                            }
                                            onChange={(value) => {
                                                setFiltro({
                                                    ...filtro,
                                                    competencia_final_consumo_comparativo_depois: value ? parseInt(`${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${value.getMonth() + 1}`) : null,
                                                });
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Row>
                        </Grid>

                        <Grid cols="12 12 2 2" style={{ paddingTop: 10 }}>
                            <Button
                                text="Atualizar"
                                type={"primary"}
                                icon={"fa fa-sync"}
                                event={() => {
                                    listar();
                                }}
                            />
                        </Grid>
                    </Row>
                </ContentCardHeader>
                <ContentCardBody
                    style={{
                        color: "#fff",
                        backgroundColor: "#30303d",
                        overflowX: "scroll",
                    }}
                >
                    <p className="text-center" style={{ fontSize: 16 }}>
                        Tabela Comparativa para Diferentes Períodos
                    </p>
                    <div
                        style={{
                            minWidth: "890px",
                        }}
                    >
                        <Table responsive>
                            <THead>
                                <Tr>
                                    <Th>Unidade</Th>
                                    <Th alignCenter>
                                        {filtro.competencia_inicial_consumo_comparativo_antes && filtro.competencia_final_consumo_comparativo_antes
                                            ? `De ${String(filtro.competencia_inicial_consumo_comparativo_antes).substring(4, 6)}/${String(filtro.competencia_inicial_consumo_comparativo_antes).substring(
                                                  0,
                                                  4
                                              )} até ${String(filtro.competencia_final_consumo_comparativo_antes).substring(4, 6)}/${String(filtro.competencia_final_consumo_comparativo_antes).substring(0, 4)}`
                                            : ""}
                                    </Th>
                                    <Th alignCenter>Comparativo</Th>
                                    <Th alignCenter>
                                        {filtro.competencia_inicial_consumo_comparativo_depois && filtro.competencia_final_consumo_comparativo_depois
                                            ? `De ${String(filtro.competencia_inicial_consumo_comparativo_depois).substring(4, 6)}/${String(filtro.competencia_inicial_consumo_comparativo_depois).substring(
                                                  0,
                                                  4
                                              )} até ${String(filtro.competencia_final_consumo_comparativo_depois).substring(4, 6)}/${String(filtro.competencia_final_consumo_comparativo_depois).substring(0, 4)}`
                                            : ""}
                                    </Th>
                                </Tr>
                            </THead>
                            <TBody>
                                {listaConsumoPorUnidadePeriodo.map((item, i) => {
                                    function calcularComparativo(item) {
                                        let comparativo = parseFloat(item.valor_atual) > 0 && parseFloat(item.valor_anterior) > 0 ? (parseFloat(item.valor_anterior) * 100) / parseFloat(item.valor_atual) : 0;
                                        if (comparativo > 0) {
                                            comparativo = 100 - comparativo;
                                        }
                                        return comparativo;
                                    }

                                    const objetoTotais = listaConsumoPorUnidadePeriodo.reduce(
                                        (objetoTotais, elemento) => {
                                            objetoTotais.valor_anterior += parseFloat(elemento.valor_anterior || 0);
                                            objetoTotais.valor_atual += parseFloat(elemento.valor_atual || 0);
                                            return objetoTotais;
                                        },
                                        { valor_anterior: 0, valor_atual: 0 }
                                    );

                                    const comparativo = calcularComparativo(item);
                                    const comparativoTotais = calcularComparativo(objetoTotais);

                                    const linhaTotais = (
                                        <Tr key={"Todas Unidades"}>
                                            <Td color="#fff" backgroundColor="#257bfb">
                                                <b>Todas Unidades</b>
                                            </Td>
                                            <Td alignCenter color="#fff" backgroundColor="#257bfb">
                                                <b>{adicionarPontosMilhar(parseFloat(objetoTotais.valor_anterior || 0).toFixed(2))} kWh</b>
                                            </Td>
                                            <Td alignCenter color="#fff" backgroundColor={comparativoTotais == 0 ? "#257bfb" : comparativoTotais > 0 ? "#ef5350" : "#28a745"}>
                                                <b>{parseFloat(objetoTotais.valor_atual) == 0 ? `100,00` : comparativoTotais.toFixed(2).replace(".", ",")}%</b>
                                            </Td>
                                            <Td alignCenter color="#fff" backgroundColor="#257bfb">
                                                <b>{adicionarPontosMilhar(parseFloat(objetoTotais.valor_atual || 0).toFixed(2))} kWh</b>
                                            </Td>
                                        </Tr>
                                    );

                                    return (
                                        <>
                                            {i == 0 ? linhaTotais : null}
                                            <Tr key={item.nome_unidade_consumidora}>
                                                <Td color="#fff">{item.nome_unidade_consumidora}</Td>
                                                <Td alignCenter color="#fff">
                                                    {adicionarPontosMilhar(parseFloat(item.valor_anterior || 0).toFixed(2))} kWh
                                                </Td>
                                                <Td alignCenter color="#fff" backgroundColor={comparativo == 0 ? null : comparativo > 0 ? "#ef5350" : "#28a745"}>
                                                    {parseFloat(item.valor_atual) == 0 ? `100,00` : comparativo.toFixed(2).replace(".", ",")}%
                                                </Td>
                                                <Td alignCenter color="#fff">
                                                    {adicionarPontosMilhar(parseFloat(item.valor_atual || 0).toFixed(2))} kWh
                                                </Td>
                                            </Tr>
                                        </>
                                    );
                                })}
                            </TBody>
                        </Table>
                    </div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ padding: 8 }}>
                <ContentCardHeader>
                    <Select
                        name="ano"
                        options={listaAnos}
                        label="Ano"
                        cols="12 6 3"
                        placeholder="Selecione o ano"
                        value={filtro.ano_acumulado_consumo}
                        isClearable={false}
                        isSearchable={false}
                        onChange={(value) => {
                            setFiltro({
                                ...filtro,
                                ano_acumulado_consumo: value,
                            });
                            listar();
                        }}
                    />
                </ContentCardHeader>
                <ContentCardBody style={{ backgroundColor: "#30303d", overflowX: "scroll", maxHeight: 400 }}>
                    <div
                        id="graficoConsumoPorUnidadeAcumuladoAno"
                        style={{
                            width: "100%",
                            minWidth: 900,
                            height: (listaGraficoConsumoPorUnidadeAcumuladoAno || []).length * 50 < 400 ? 400 : listaGraficoConsumoPorUnidadeAcumuladoAno.length * 50,
                        }}
                    ></div>
                </ContentCardBody>
            </ContentCard>
        </div>
    );
}

const mapStateToProps = (state) => ({
    filtro: state.analise.filtro,
    contratoDemanda: state.analise.contratoDemanda,
    listaGraficoConsumoAnual: state.analise.listaGraficoConsumoAnual,
    listaUnidadeConsumidora: state.analise.listaUnidadeConsumidora,
    listaConsumoPorUnidadePeriodo: state.analise.listaConsumoPorUnidadePeriodo,
    listaGraficoConsumoPorUnidadeAcumuladoAno: state.analise.listaGraficoConsumoPorUnidadeAcumuladoAno,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getDemanda,
            getListaGraficoConsumoAnual,
            getListaConsumoPorUnidadePeriodo,
            getListaGraficoConsumoPorUnidadeAcumuladoAno,
            setFiltro,
        },
        dispatch
    );
const AnaliseConsumo = connect(mapStateToProps, mapDispatchToProps)(analiseConsumo);
export { AnaliseConsumo };

import React from "react";
import Grid from "../layout/grid";
import NumberFormat from "react-number-format";

export default (props) => {
	const propsInput = props.input || {};
	const onChange = props.onChange || propsInput.onChange;
	const valueRecebido = props.value || propsInput.value;


	return (
		<Grid cols={props.cols}>
			<div className="form-group">
				<label htmlFor={props.name}>{props.label}</label>
				<NumberFormat
					{...props.input}
					className="form-control"
					readOnly={props.readOnly}
					type={props.type}
					placeholder={props.placeholder}
					decimalSeparator={","}
					decimalScale={props.casas}
					fixedDecimalScale={props.casas}
					value={valueRecebido}
					onChange={(e) => onChange(e ? (props.getTarget ? { name: props.name, value: e.target.value } : e) : null)}
					allowNegative={props.allowNegative === undefined ? true : props.allowNegative}
				/>
			</div>
		</Grid>
	);
};

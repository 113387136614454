export function adicionarPontosMilhar(numero) {
    // Valida o número
    const parsedNumber = String(numero).includes(",") ? parseFloat(String(numero).replace(",", ".")) : parseFloat(numero);

    if (isNaN(parsedNumber)) return null;
    //verifica se existe sinal de negativo no número
    const sinalNegativo = parsedNumber < 0 ? "-" : "";

    // une a parte inteira com a parte decimal
    return sinalNegativo + parsedNumber.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

import React from "react";
export default function divBox(props) {
  const color = props.color || "#17a2b8";
  const borderColor = props.borderColor || "#17a2b8";
  return (
      <div
          style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: 80,
              height: 80,
              borderRadius: "0.3rem",
              border: `1px solid ${borderColor}`,
              color: color,
          }}
      >
          {props.children}
      </div>
  );
}

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import NewTr from "../common/table/newTr";
import Tr from "../common/table/tr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import ButtonTable from "../common/table/buttonTable";
import Button from "../common/button/button";
import Select from "../common/form/select";
import { Loading } from "../common/loading/loading";
import { DisplayPDF } from "../displayPDF/displayPDF";
import {
    setModoTela,
    initForm,
    setFiltro,
    salvar,
    salvarMultiplos,
    excluir,
    getLista,
    getListaCliente,
    getListaUnidadeConsumidora,
    buscaPdf,
    setSubsidioIrriganteVerificado,
    setIcmsVerificado,
    setIdStatusSubsidioIrrigante,
    setIdStatusIcms,
} from "./faturaActions";
import { reprocessar, reprocessarTodas, setAguardando } from "./faturaItem/faturaItemActions";
import { setArquivoPDF } from "../displayPDF/displayPDFActions";
import LabelAndCheckbox from "../common/form/labelAndCheckbox";
import { htmlToPdf } from "../htmlToPdf/htmlToPdf";

import TabelaFaturaVerificadorIcmsPDF from "../htmlToPdf/TabelaFaturaVerificadorIcmsPDF";

class FaturaVerificadorIcmsEnergia extends Component {
    state = {
        abaVerificadorIcmsAtiva: true,
        abaVerificadorSubsidioIrriganteAtiva: false,
        apenasDivergente: false,
        apenasCorrecaoEmAndamento: false,
        apenasCorrigido: false,
        exibirTodos: true,
    };

    async componentWillMount() {
        this.props.getListaCliente();
        this.props.getListaUnidadeConsumidora();

        if (this.props.usuarioLogado && this.props.usuarioLogado.administrador) {
            await this.props.getLista();
        } else if (this.props.usuarioLogado && this.props.usuarioLogado.acesso_cliente) {
            this.props.setFiltro({
                ...this.props.filtro,
                id_cliente: this.props.usuarioLogado.id_cliente,
            });
            await this.props.getLista();
        }
    }

    render() {
        if (this.props.pdf) {
            return <DisplayPDF closePdf={this.props.setArquivoPDF} pdfBase64={this.props.pdf} nomePdf={this.props.nomePdf} orientation={this.props.orientation} zoomParametro={1.5} />;
        }
        return (
            <div>
                {this.props.aguardando ? <Loading /> : null}
                <Content>{this.props.modoTela == "lista" ? this.lista() : null}</Content>
            </div>
        );
    }

    getStyleSlimCard(bgColor, textColor) {
        return {
            borderRadius: 8,
            cursor: "pointer",
            backgroundColor: bgColor,
            color: textColor || "#000000",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px 10px 0px 10px",
            width: "100%",
            minHeight: "40px",
            boxShadow: "#9b9b9b 0px 1px 2px 1px",
        };
    }

    lista() {
        let totalValorImportado = 0;
        let totalValorFatura = 0;
        let totalenergia = 0;
        let totalEnergiaPonta = 0;
        let totalEnergiaForaPonta = 0;
        let totalEnergiaReservado = 0;
        let totalDemanda = 0;
        let totalEnergiaReativo = 0;
        let totalGastoReativo = 0;
        let totalGastoDemandaComplementar = 0;
        let totalGastoDemandaUltrapassagem = 0;

        //DADOS PARA IMPRESSÃO DO RELATÓRIO ---------------
        const cabecalho = (
            <NewTr backgroundColorTr={"#FFFFFF"} backgroundColorTd={"#F5F5F5"}>
                <Th style={{ fontSize: "0.8rem" }} sticky minWidth={50}>
                    Status
                </Th>
                <Th style={{ fontSize: "0.8rem" }} sticky minWidth={90}>
                    Competência
                </Th>
                <Th style={{ fontSize: "0.8rem" }} sticky minWidth={50}>
                    Cliente
                </Th>
                <Th style={{ fontSize: "0.8rem" }} sticky minWidth={50}>
                    UC
                </Th>
                <Th style={{ fontSize: "0.7rem" }} sticky minWidth={50} alignCenter>
                    Total Fatura
                </Th>
                <Th style={{ fontSize: "0.7rem" }} sticky minWidth={50} alignCenter>
                    Energia (kWh)
                </Th>
                <Th style={{ fontSize: "0.7rem" }} sticky minWidth={50} alignCenter>
                    E. Ponta (kWh)
                </Th>
                <Th style={{ fontSize: "0.7rem" }} sticky minWidth={70} alignCenter>
                    E. Fora Ponta (kWh)
                </Th>
                <Th style={{ fontSize: "0.7rem" }} sticky minWidth={80} alignCenter backgroundColor={this.state.abaVerificadorSubsidioIrriganteAtiva ? "#AAAAAA" : null}>
                    E. Reservado (kWh)
                </Th>
                <Th style={{ fontSize: "0.7rem" }} sticky minWidth={50} alignCenter>
                    Demanda (kW)
                </Th>
                <Th style={{ fontSize: "0.7rem" }} sticky minWidth={70} alignCenter backgroundColor={this.state.abaVerificadorIcmsAtiva ? "#AAAAAA" : null}>
                    ICMS Esperado (%)
                </Th>
                <Th style={{ fontSize: "0.7rem" }} sticky minWidth={70} alignCenter backgroundColor={this.state.abaVerificadorIcmsAtiva ? "#AAAAAA" : null}>
                    ICMS Importado (%)
                </Th>
                <Th style={{ fontSize: "0.7rem" }} sticky minWidth={70} alignCenter>
                    Multa Reativo (R$)
                </Th>
                <Th style={{ fontSize: "0.7rem" }} sticky minWidth={105} alignCenter>
                    Multa Demanda Complementar (R$)
                </Th>
                <Th style={{ fontSize: "0.7rem" }} sticky minWidth={110} alignCenter>
                    Multa Demanda Ultrapassagem (R$)
                </Th>
                <Th style={{ fontSize: "0.7rem" }} sticky minWidth={110} alignCenter>
                    Multa Total (R$)
                </Th>
                <Th style={{ fontSize: "0.7rem" }} sticky minWidth={50} alignCenter>
                    Verificar
                </Th>
                <Th style={{ fontSize: "0.7rem" }} sticky minWidth={50} alignCenter>
                    PDF
                </Th>
            </NewTr>
        );
        const totalCelulas = cabecalho.props.children.length;
        const linhaModificada = Array.from(cabecalho.props.children).splice(0, totalCelulas - 1);

        const cabecalhoSemColunasBotoes = (
            <NewTr>
                {linhaModificada.map((el, i) => {
                    const copia = React.cloneElement(
                        el,
                        {
                            key: i,
                            sticky: false,
                            style: { fontSize: "0.85rem", paddingTop: 30 },
                        },
                        [...el.props.children]
                    );
                    return copia;
                })}
            </NewTr>
        );

        let rodape = null;
        const linhas = [];
        //-------------------------------------------------
        let labelFiltroDivergente = "";
        let labelFiltroCorrecaoEmAndamento = "";
        let labelFiltroCorrigido = "";
        if (this.state.abaVerificadorIcmsAtiva) {
            labelFiltroDivergente = "Apenas ICMS Divergente (não encaminhado)";
            labelFiltroCorrecaoEmAndamento = "ICMS Divergente - Em andamento (encaminhados junto à concessionária e aguardando validar)";
            labelFiltroCorrigido = "ICMS OK - Já corrigido junto à concessionária";
        } else if (this.state.abaVerificadorSubsidioIrriganteAtiva) {
            labelFiltroDivergente = "Apenas Faturas Irrigantes sem Consumo Reservado";
            labelFiltroCorrecaoEmAndamento = "Sem Consumo Reservado - Em andamento (encaminhados junto à concessionária e aguardando validar)";
            labelFiltroCorrigido = "Subsídio Irrigante OK - Já corrigido junto à concessionária";
        }

        return (
            <>
                <ContentCard>
                    <ContentCardBody>
                        <Row>
                            {this.props.usuarioLogado && !this.props.usuarioLogado.acesso_cliente ? (
                                <Select
                                    name="id_cliente"
                                    options={this.props.listaCliente}
                                    label="Cliente"
                                    cols="12 6 4"
                                    placeholder="Selecione o Cliente"
                                    value={this.props.filtro.id_cliente}
                                    onChange={(value) => {
                                        this.props.setFiltro({
                                            ...this.props.filtro,
                                            id_cliente: value,
                                        });
                                        this.props.getLista();
                                    }}
                                />
                            ) : null}

                            <Select
                                name="id_unidade_consumidora"
                                options={this.props.listaUnidadeConsumidora.filter((item) => (this.props.filtro.id_cliente ? item.id_cliente == this.props.filtro.id_cliente : true))}
                                label="Unidade Consumidora"
                                cols="12 6 4"
                                placeholder="Selecione a UC"
                                value={this.props.filtro.id_unidade_consumidora}
                                onChange={(value) => {
                                    this.props.setFiltro({
                                        ...this.props.filtro,
                                        id_unidade_consumidora: value,
                                    });
                                    this.props.getLista();
                                }}
                            />

                            <Select
                                name="ano"
                                options={Array.from({ length: 20 }, (v, i) => ({
                                    id: new Date().getFullYear() - 5 + i,
                                    valor: new Date().getFullYear() - 5 + i,
                                }))}
                                label="Ano"
                                cols="12 6 2"
                                placeholder="Selecione o ano"
                                value={this.props.filtro.ano}
                                onChange={(value) => {
                                    this.props.setFiltro({
                                        ...this.props.filtro,
                                        ano: value,
                                    });
                                    this.props.getLista();
                                }}
                            />

                            <Select
                                name="mes"
                                options={this.props.listaMeses}
                                label="Mês"
                                cols="12 6 2"
                                placeholder="Selecione o mês"
                                value={this.props.filtro.mes}
                                onChange={(value) => {
                                    this.props.setFiltro({
                                        ...this.props.filtro,
                                        mes: value,
                                    });
                                    this.props.getLista();
                                }}
                            />
                        </Row>
                    </ContentCardBody>
                </ContentCard>

                <ContentCard>
                    <ContentCardBody>
                        <Row style={{ padding: 5 }}>
                            <Grid cols="12 12 3 3">
                                <Button
                                    text="Imprimir Relatório"
                                    style={{ height: 65, margin: 0, minWidth: "100%" }}
                                    type={"primary"}
                                    event={async () => {
                                        await this.gerarRelatorio({ cabecalhoSemColunasBotoes, rodape, linhas });
                                    }}
                                />
                            </Grid>

                            <Grid
                                cols="12 12 3 3"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <label style={this.getStyleSlimCard("#ff5a5a", "#380303")} htmlFor="filtro-apenas-divergente">
                                    <LabelAndCheckbox
                                        style={{ justifyContent: "center", height: 42 }}
                                        readOnly={this.props.aguardando}
                                        modoCard={true}
                                        colorIcon={"#380303"}
                                        id="filtro-apenas-divergente"
                                        label={labelFiltroDivergente}
                                        onChange={(e) =>
                                            this.setState({
                                                ...this.state,
                                                apenasDivergente: !this.state.apenasDivergente,
                                                exibirTodos: this.state.apenasDivergente,
                                                apenasCorrecaoEmAndamento: false,
                                                apenasCorrigido: false,
                                            })
                                        }
                                        value={!!this.state.apenasDivergente}
                                    />
                                </label>
                            </Grid>
                            <Grid
                                cols="12 12 3 3"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <label style={this.getStyleSlimCard("#1db6ff", "#092a53")} htmlFor="filtro-apenas-correcao-encaminhada">
                                    <LabelAndCheckbox
                                        style={{ justifyContent: "center", height: 42 }}
                                        readOnly={this.props.aguardando}
                                        modoCard={true}
                                        colorIcon={"#092a53"}
                                        id="filtro-apenas-correcao-encaminhada"
                                        label={labelFiltroCorrecaoEmAndamento}
                                        onChange={(e) =>
                                            this.setState({
                                                ...this.state,
                                                apenasCorrecaoEmAndamento: !this.state.apenasCorrecaoEmAndamento,
                                                exibirTodos: this.state.apenasCorrecaoEmAndamento,
                                                apenasCorrigido: false,
                                                apenasDivergente: false,
                                            })
                                        }
                                        value={!!this.state.apenasCorrecaoEmAndamento}
                                    />
                                </label>
                            </Grid>
                            <Grid
                                cols="12 12 3 3"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <label style={this.getStyleSlimCard("#8aff7d", "#08361b")} htmlFor="filtro-apenas-corrigido">
                                    <LabelAndCheckbox
                                        style={{ justifyContent: "center", height: 42 }}
                                        readOnly={this.props.aguardando}
                                        modoCard={true}
                                        colorIcon={"#08361b"}
                                        id="filtro-apenas-corrigido"
                                        label={labelFiltroCorrigido}
                                        onChange={(e) =>
                                            this.setState({
                                                ...this.state,
                                                apenasCorrigido: !this.state.apenasCorrigido,
                                                exibirTodos: this.state.apenasCorrigido,
                                                apenasCorrecaoEmAndamento: false,
                                                apenasDivergente: false,
                                            })
                                        }
                                        value={!!this.state.apenasCorrigido}
                                    />
                                </label>
                            </Grid>
                        </Row>

                        <Row style={{ paddingInline: 5, marginBottom: -3 }}>
                            <Grid
                                cols="6 6 4 4"
                                style={{
                                    padding: "15px 0px 5px 10px",
                                    borderRadius: "10px 10px 0 0",
                                    border: `solid 1px ${this.state.abaVerificadorIcmsAtiva ? "#282833" : "#ddd"}`,
                                    color: this.state.abaVerificadorIcmsAtiva ? "#282833" : "#7e7e7e",
                                    cursor: "pointer",
                                    textAlign: "center",
                                }}
                                onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        abaVerificadorIcmsAtiva: true,
                                        abaVerificadorSubsidioIrriganteAtiva: false,
                                    });
                                }}
                            >
                                <h6>Verificador de ICMS</h6>
                            </Grid>
                            <Grid
                                cols="6 6 4 4"
                                style={{
                                    padding: "15px 0px 5px 10px",
                                    borderRadius: "10px 10px 0 0",
                                    border: `solid 1px ${this.state.abaVerificadorSubsidioIrriganteAtiva ? "#282833" : "#ddd"}`,
                                    color: this.state.abaVerificadorSubsidioIrriganteAtiva ? "#282833" : "#7e7e7e",
                                    cursor: "pointer",
                                    textAlign: "center",
                                }}
                                onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        abaVerificadorIcmsAtiva: false,
                                        abaVerificadorSubsidioIrriganteAtiva: true,
                                    });
                                }}
                            >
                                <h6>Verificador de Subsídio de Irrigantes</h6>
                            </Grid>
                        </Row>
                        <Table responsive>
                            <THead>{cabecalho}</THead>
                            <TBody>
                                {this.props.lista
                                    .filter((item) => {
                                        if (this.state.abaVerificadorIcmsAtiva) {
                                            //define o icms_verificado, para definir as cores do módulo verificador de icms ----------------------------------------------------------------
                                            const idStatusIcms = item.id_status_icms;

                                            const temIcmsMesAtual = !!parseFloat(item.icms_importado);
                                            const icmsAtualVerificado = item.icms_verificado;
                                            const icmsMesAtualIgualEsperado = item.icms_importado == item.icms_esperado;

                                            // ATÈ 202410 a logica de vermelha era diferente,
                                            //divergente e verificada  = verde
                                            //divergente nao verificada = vermelho
                                            //icms igual ao esperado era branca
                                            let mesAtualStatusVermelho = !icmsAtualVerificado && temIcmsMesAtual && !icmsMesAtualIgualEsperado;
                                            let mesAtualStatusAzul = icmsAtualVerificado && temIcmsMesAtual && !icmsMesAtualIgualEsperado;
                                            let mesAtualStatusVerde = icmsAtualVerificado && temIcmsMesAtual && icmsMesAtualIgualEsperado;
                                            if (item.competencia >= 202410) {
                                                mesAtualStatusVermelho = idStatusIcms == 2;
                                                mesAtualStatusAzul = idStatusIcms == 3;
                                                mesAtualStatusVerde = idStatusIcms == 4;
                                            }

                                            const condicaoVermelho = this.state.apenasDivergente && mesAtualStatusVermelho;
                                            const condicaoAzul = this.state.apenasCorrecaoEmAndamento && mesAtualStatusAzul;
                                            const condicaoVerde = this.state.apenasCorrigido && mesAtualStatusVerde;
                                            return this.state.exibirTodos || condicaoVermelho || condicaoAzul || condicaoVerde;
                                            //-----------------------------------------------------------------------------------------------------------------------------------------------
                                        } else if (this.state.abaVerificadorSubsidioIrriganteAtiva && item.irrigante) {
                                            const idStatusSubsidioIrrigante = item.id_status_subsidio_irrigante;
                                            const temEnergiaReservadoMesAtual = !!parseFloat(item.energia_reservado);
                                            const subsidioAtualVerificado = item.subsidio_irrigante_verificado;

                                            // ATÈ 202410 a logica de vermelha era diferente,
                                            //divergente e verificada  = verde
                                            //divergente nao verificada = vermelho
                                            //icms igual ao esperado era branca
                                            let mesAtualStatusVermelho = !subsidioAtualVerificado && !temEnergiaReservadoMesAtual;
                                            let mesAtualStatusAzul = subsidioAtualVerificado && !temEnergiaReservadoMesAtual;
                                            let mesAtualStatusVerde = subsidioAtualVerificado && temEnergiaReservadoMesAtual;
                                            if (item.competencia >= 202410) {
                                                mesAtualStatusVermelho = idStatusSubsidioIrrigante == 6;
                                                mesAtualStatusAzul = idStatusSubsidioIrrigante == 7;
                                                mesAtualStatusVerde = idStatusSubsidioIrrigante == 8;
                                            }

                                            const condicaoVermelho = this.state.apenasDivergente && mesAtualStatusVermelho;
                                            const condicaoAzul = this.state.apenasCorrecaoEmAndamento && mesAtualStatusAzul;
                                            const condicaoVerde = this.state.apenasCorrigido && mesAtualStatusVerde;

                                            return this.state.exibirTodos || condicaoVermelho || condicaoAzul || condicaoVerde;
                                        }
                                    })
                                    .map((item, i, array) => {
                                        totalValorImportado += parseFloat(item.total > 0 ? item.total : 0);
                                        totalValorFatura += parseFloat(item.valor_fatura > 0 ? item.valor_fatura : 0);
                                        totalenergia += parseFloat(item.energia || 0);
                                        totalEnergiaPonta += parseFloat(item.energia_ponta || 0);
                                        totalEnergiaForaPonta += parseFloat(item.energia_fora_ponta || 0);
                                        totalEnergiaReservado += parseFloat(item.energia_reservado || 0);
                                        totalDemanda += parseFloat(item.demanda || 0);
                                        totalEnergiaReativo += parseFloat(item.energia_reativo || 0);
                                        totalGastoReativo += parseFloat(item.gasto_reativo || 0);
                                        totalGastoDemandaComplementar += parseFloat(item.gasto_demanda_complementar || 0);
                                        totalGastoDemandaUltrapassagem += parseFloat(item.gasto_demanda_ultrapassagem || 0);
                                        let mensagemTooltip = "";
                                        let corFonte = "#000000";

                                        let corFundoEscuro = "#DDDDDD";
                                        let corFundo = null;
                                        let msgErro = "";
                                        //-----------------------------------------------------------------------------------------------------------------------------------------------

                                        let exibirBotaoEncaminhar = false;
                                        let exibirAvisoEncaminhado = false;

                                        if (this.state.abaVerificadorIcmsAtiva) {
                                            //define o icms_verificado, para definir as cores do módulo verificador de icms ----------------------------------------------------------------
                                            const idStatusIcms = item.id_status_icms;

                                            const temIcmsMesAtual = !!parseFloat(item.icms_importado);
                                            const icmsAtualVerificado = item.icms_verificado;
                                            const icmsMesAtualIgualEsperado = item.icms_importado == item.icms_esperado;

                                            // ATÈ 202410 a logica de vermelha era diferente,
                                            //divergente e verificada  = verde
                                            //divergente nao verificada = vermelho
                                            //icms igual ao esperado era branca
                                            let mesAtualStatusBranco = !icmsAtualVerificado && (!temIcmsMesAtual || icmsMesAtualIgualEsperado);
                                            let mesAtualStatusVermelho = !icmsAtualVerificado && temIcmsMesAtual && !icmsMesAtualIgualEsperado;
                                            let mesAtualStatusAzul = icmsAtualVerificado && temIcmsMesAtual && !icmsMesAtualIgualEsperado;
                                            let mesAtualStatusVerde = icmsAtualVerificado && temIcmsMesAtual && icmsMesAtualIgualEsperado;
                                            if (item.competencia >= 202410) {
                                                mesAtualStatusBranco = idStatusIcms == 1;
                                                mesAtualStatusVermelho = idStatusIcms == 2;
                                                mesAtualStatusAzul = idStatusIcms == 3;
                                                mesAtualStatusVerde = idStatusIcms == 4;
                                            }

                                            if (mesAtualStatusBranco) {
                                                msgErro = "ICMS Correto";
                                            } else if (mesAtualStatusVerde) {
                                                corFundoEscuro = "#52be50";
                                                corFundo = "#8aff7d";
                                                msgErro = "ICMS Corrigido";
                                            } else if (mesAtualStatusVermelho) {
                                                corFundoEscuro = "#d23a3a";
                                                corFundo = "#ff5a5a";
                                                msgErro = "Verifique ICMS";
                                            } else if (mesAtualStatusAzul) {
                                                corFundoEscuro = "#239bda";
                                                corFundo = "#1db6ff";
                                                msgErro = "Correção de ICMS Encaminhada";
                                            }

                                            exibirBotaoEncaminhar = !!mesAtualStatusVermelho;
                                            exibirAvisoEncaminhado = !!mesAtualStatusAzul;
                                        } else if (this.state.abaVerificadorSubsidioIrriganteAtiva) {
                                            const idStatusSubsidioIrrigante = item.id_status_subsidio_irrigante;
                                            const temEnergiaReservadoMesAtual = !!parseFloat(item.energia_reservado);
                                            const subsidioAtualVerificado = item.subsidio_irrigante_verificado;

                                            // ATÈ 202410 a logica de vermelha era diferente,
                                            //divergente e verificada  = verde
                                            //divergente nao verificada = vermelho
                                            //icms igual ao esperado era branca
                                            let mesAtualStatusBranco = !subsidioAtualVerificado && temEnergiaReservadoMesAtual;
                                            let mesAtualStatusVermelho = !subsidioAtualVerificado && !temEnergiaReservadoMesAtual;
                                            let mesAtualStatusAzul = subsidioAtualVerificado && !temEnergiaReservadoMesAtual;
                                            let mesAtualStatusVerde = subsidioAtualVerificado && temEnergiaReservadoMesAtual;
                                            if (item.competencia >= 202410) {
                                                mesAtualStatusBranco = idStatusSubsidioIrrigante == 5;
                                                mesAtualStatusVermelho = idStatusSubsidioIrrigante == 6;
                                                mesAtualStatusAzul = idStatusSubsidioIrrigante == 7;
                                                mesAtualStatusVerde = idStatusSubsidioIrrigante == 8;
                                            }

                                            if (mesAtualStatusBranco) {
                                                msgErro = "Subsídio Irrigante Correto";
                                            } else if (mesAtualStatusVerde) {
                                                corFundoEscuro = "#52be50";
                                                corFundo = "#8aff7d";
                                                msgErro = "Subsídio Irrigante Corrigido";
                                            } else if (mesAtualStatusVermelho) {
                                                corFundoEscuro = "#d23a3a";
                                                corFundo = "#ff5a5a";
                                                msgErro = "Verifique Subsídio Irrigante";
                                            } else if (mesAtualStatusAzul) {
                                                corFundoEscuro = "#239bda";
                                                corFundo = "#1db6ff";
                                                msgErro = "Correção de Consumo Reservado Encaminhada";
                                            }

                                            exibirBotaoEncaminhar = !!mesAtualStatusVermelho;
                                            exibirAvisoEncaminhado = !!mesAtualStatusAzul;
                                        }

                                        const setVerificado = (item) => {
                                            if (this.state.abaVerificadorSubsidioIrriganteAtiva) {
                                                //a partir de outubro, além de verificar seta o id_status
                                                if (item.competencia >= 202410) {
                                                    this.props.setIdStatusSubsidioIrrigante(item);
                                                } else {
                                                    this.props.setSubsidioIrriganteVerificado(item);
                                                }
                                            } else if (this.state.abaVerificadorIcmsAtiva) {
                                                //a partir de outubro, além de verificar seta o id_status
                                                if (item.competencia >= 202410) {
                                                    this.props.setIdStatusIcms(item);
                                                } else {
                                                    this.props.setIcmsVerificado(item);
                                                }
                                            }
                                        };

                                        const linha = (
                                            <NewTr
                                                title={mensagemTooltip}
                                                key={item.id}
                                                backgroundColorTr={corFundo}
                                                style={{
                                                    height: 40,
                                                    color: corFonte,
                                                }}
                                                botoes={[]}
                                            >
                                                <Td
                                                    style={{
                                                        fontSize: "0.8rem",
                                                        backgroundColor: corFundo,
                                                        color: corFonte,
                                                    }}
                                                >
                                                    {msgErro}
                                                </Td>
                                                <Td style={{ fontSize: "0.8rem" }}>{`${String(item.competencia).substring(4, 6)}/${String(item.competencia).substring(0, 4)}`}</Td>
                                                <Td style={{ fontSize: "0.8rem" }} minWidth={100}>
                                                    {item.nome_cliente}
                                                </Td>
                                                <Td style={{ fontSize: "0.8rem" }} minWidth={150}>
                                                    {item.nome_unidade_consumidora}
                                                </Td>
                                                <Td style={{ fontSize: "0.8rem" }} alignCenter>
                                                    {parseFloat(item.valor_fatura || 0)
                                                        .toFixed(2)
                                                        .replace(".", ",")}
                                                </Td>
                                                <Td style={{ fontSize: "0.8rem" }} alignCenter>
                                                    {parseFloat(item.energia || 0)
                                                        .toFixed(2)
                                                        .replace(".", ",")}
                                                </Td>
                                                <Td style={{ fontSize: "0.8rem" }} alignCenter>
                                                    {parseFloat(item.energia_ponta || 0)
                                                        .toFixed(2)
                                                        .replace(".", ",")}
                                                </Td>
                                                <Td style={{ fontSize: "0.8rem" }} alignCenter>
                                                    {parseFloat(item.energia_fora_ponta || 0)
                                                        .toFixed(2)
                                                        .replace(".", ",")}
                                                </Td>
                                                <Td style={{ fontSize: "0.8rem" }} alignCenter backgroundColor={this.state.abaVerificadorSubsidioIrriganteAtiva ? corFundoEscuro : null}>
                                                    {parseFloat(item.energia_reservado || 0)
                                                        .toFixed(2)
                                                        .replace(".", ",")}
                                                </Td>
                                                <Td style={{ fontSize: "0.8rem" }} alignCenter>
                                                    {parseFloat(item.demanda || 0)
                                                        .toFixed(2)
                                                        .replace(".", ",")}
                                                </Td>
                                                <Td style={{ fontSize: "0.8rem" }} alignCenter backgroundColor={this.state.abaVerificadorIcmsAtiva ? corFundoEscuro : null}>
                                                    {item.icms_esperado ? parseFloat(item.icms_esperado).toFixed(2).replace(".", ",") : null}
                                                </Td>
                                                <Td style={{ fontSize: "0.8rem" }} alignCenter backgroundColor={this.state.abaVerificadorIcmsAtiva ? corFundoEscuro : null}>
                                                    {item.icms_importado ? parseFloat(item.icms_importado).toFixed(2).replace(".", ",") : null}
                                                </Td>
                                                <Td style={{ fontSize: "0.8rem" }} alignCenter>
                                                    {parseFloat(item.gasto_reativo || 0)
                                                        .toFixed(2)
                                                        .replace(".", ",")}
                                                </Td>
                                                <Td style={{ fontSize: "0.8rem" }} alignCenter>
                                                    {parseFloat(item.gasto_demanda_complementar || 0)
                                                        .toFixed(2)
                                                        .replace(".", ",")}
                                                </Td>
                                                <Td style={{ fontSize: "0.8rem" }} alignCenter>
                                                    {parseFloat(item.gasto_demanda_ultrapassagem || 0)
                                                        .toFixed(2)
                                                        .replace(".", ",")}
                                                </Td>
                                                <Td style={{ fontSize: "0.8rem" }} alignCenter>
                                                    {(parseFloat(item.gasto_reativo || 0) + parseFloat(item.gasto_demanda_complementar || 0) + parseFloat(item.gasto_demanda_ultrapassagem || 0))
                                                        .toFixed(2)
                                                        .replace(".", ",")}
                                                </Td>
                                                <Td style={{ fontSize: "0.8rem" }} alignCenter>
                                                    {exibirBotaoEncaminhar ? (
                                                        <Button
                                                            style={{ backgroundColor: "#0089ff", color: "#FFF", margin: 0 }}
                                                            type={"light"}
                                                            text="Encaminhar"
                                                            visible={true}
                                                            solid={true}
                                                            event={() => setVerificado(item)}
                                                        />
                                                    ) : exibirAvisoEncaminhado ? (
                                                        <div style={{ padding: 5, backgroundColor: corFundoEscuro, border: "1px solid #FFF", fontWeight: "bold", color: "#FFF" }}>Encaminhado</div>
                                                    ) : null}
                                                </Td>
                                                <Td style={{ fontSize: "0.8rem" }} alignCenter minWidth={60}>
                                                    {item.arquivo_pdf ? (
                                                        <ButtonTable
                                                            type={"primary"}
                                                            icon={"fas fa-file-pdf"}
                                                            visible={true}
                                                            event={() => {
                                                                this.props.buscaPdf(
                                                                    item.id,
                                                                    `Fatura_UC-${item.nome_unidade_consumidora.split("-")[0].trim()}_${String(item.competencia).substring(4, 6)}-${String(item.competencia).substring(
                                                                        0,
                                                                        4
                                                                    )}`
                                                                );
                                                                this.props.setArquivoPDF();
                                                            }}
                                                        />
                                                    ) : null}
                                                </Td>
                                            </NewTr>
                                        );

                                        const totalCelulas = linha.props.children.length;
                                        let linhaModificada = Array.from(linha.props.children).splice(0, totalCelulas - 1);
                                        linhaModificada = React.Children.map(linhaModificada, (child, index) => {
                                            // 0 = Msg Erro Importação
                                            if (index == 0) {
                                                return React.cloneElement(child, {
                                                    children: <div>{msgErro.length > 32 ? msgErro.substring(0, 32) + "..." : msgErro}</div>,
                                                });
                                            }

                                            // 2 = cliente
                                            if (index == 2) {
                                                return React.cloneElement(child, {
                                                    children: <div>{item.nome_cliente.length > 30 ? item.nome_cliente.substring(0, 30) + "..." : item.nome_cliente}</div>,
                                                });
                                            }

                                            // 3 = UC
                                            if (index == 3) {
                                                const nome_unidade_consumidora = item.nome_unidade_consumidora;
                                                return React.cloneElement(child, {
                                                    children: <div>{nome_unidade_consumidora.length > 40 ? `${nome_unidade_consumidora.substring(0, 40)}...` : nome_unidade_consumidora}</div>,
                                                });
                                            }
                                            return child;
                                        });

                                        const linhaSemColunaBotoes = React.cloneElement(linha, {
                                            children: linhaModificada,
                                            botoes: [],
                                        });

                                        linhas.push(linhaSemColunaBotoes);
                                        //ao chegar na última iteração constrói o rodapé
                                        if (i === array.length - 1) {
                                            rodape = this.renderizarRodape({
                                                totalValorImportado,
                                                totalValorFatura,
                                                totalenergia,
                                                totalEnergiaPonta,
                                                totalEnergiaForaPonta,
                                                totalEnergiaReservado,
                                                totalDemanda,
                                                totalEnergiaReativo,
                                                totalGastoReativo,
                                                totalGastoDemandaComplementar,
                                                totalGastoDemandaUltrapassagem,
                                            });
                                        }

                                        return linha;
                                    })}
                                {rodape}
                            </TBody>
                        </Table>
                    </ContentCardBody>
                </ContentCard>
            </>
        );
    }

    async gerarRelatorio({ cabecalhoSemColunasBotoes, rodape, linhas }) {
        let filtros = "";

        if (this.state.exibirTodos) {
            filtros = "Todos";
        } else if (this.state.apenasDivergente) {
            if (this.state.abaVerificadorIcmsAtiva) {
                filtros = "Apenas ICMS Divergente (não encaminhado)";
            } else if (this.state.abaVerificadorSubsidioIrriganteAtiva) {
                filtros = "Apenas Faturas Irrigantes sem Consumo Reservado";
            }
        } else if (this.state.apenasCorrecaoEmAndamento) {
            if (this.state.abaVerificadorIcmsAtiva) {
                filtros = "ICMS Divergente - Em andamento (encaminhados junto à concessionária e aguardando validar)";
            } else if (this.state.abaVerificadorSubsidioIrriganteAtiva) {
                filtros = "Sem Consumo Reservado - Em andamento (encaminhados junto à concessionária e aguardando validar)";
            }
        } else if (this.state.apenasCorrigido) {
            if (this.state.abaVerificadorIcmsAtiva) {
                filtros = "ICMS OK - Já corrigido junto à concessionária";
            } else if (this.state.abaVerificadorSubsidioIrriganteAtiva) {
                filtros = "Subsídio Irrigante OK - Já corrigido junto à concessionária";
            }
        }
        let nomeRelatorio = "";
        if (this.state.abaVerificadorIcmsAtiva) {
            nomeRelatorio = "ICMS";
        } else if (this.state.abaVerificadorSubsidioIrriganteAtiva) {
            nomeRelatorio = "Subsídio Irrigante";
        }
        this.props.setAguardando(true);
        const filtro = (
            <b style={{ fontSize: "0.8rem" }}>
                Cliente:
                {" " + ((this.props.listaCliente.find((cliente) => cliente.id == this.props.filtro.id_cliente) || {}).valor || "Todos") + " - "}
                UC:
                {" " + ((this.props.listaUnidadeConsumidora.find((uc) => uc.id == this.props.filtro.id_unidade_consumidora) || {}).valor || "Todas") + " - "}
                Ano:{" " + (this.props.filtro.ano || "") + " - "}
                Mês:
                {` ${(this.props.listaMeses.find((mes) => mes.id == this.props.filtro.mes) || {}).valor || "Todos"} - `}
                Filtro por Status: {filtros}
            </b>
        );
        const pdfBase64 = await htmlToPdf(
            <TabelaFaturaVerificadorIcmsPDF
                dadosImpressao={{
                    filtro,
                    cabecalhoSemColunasBotoes,
                    rodape,
                    linhas,
                }}
            />,
            this.props.setAguardando
        );
        this.props.setArquivoPDF(pdfBase64, `Relatório verificador de ${nomeRelatorio}.pdf`, "landscape");
    }

    renderizarRodape({
        totalValorImportado,
        totalValorFatura,
        totalenergia,
        totalEnergiaPonta,
        totalEnergiaForaPonta,
        totalEnergiaReservado,
        totalDemanda,
        totalEnergiaReativo,
        totalGastoReativo,
        totalGastoDemandaComplementar,
        totalGastoDemandaUltrapassagem,
    }) {
        const rodape = (
            <Tr>
                <Td style={{ fontSize: "0.8rem" }} colSpan={4} fontWeight="bold">
                    Total
                </Td>
                <Td style={{ fontSize: "0.8rem" }} alignCenter fontWeight="bold">
                    {parseFloat(totalValorFatura || 0)
                        .toFixed(0)
                        .replace(".", ",")}
                </Td>
                <Td style={{ fontSize: "0.8rem" }} alignCenter fontWeight="bold">
                    {parseFloat(totalenergia || 0)
                        .toFixed(0)
                        .replace(".", ",")}
                </Td>
                <Td style={{ fontSize: "0.8rem" }} alignCenter fontWeight="bold">
                    {parseFloat(totalEnergiaPonta || 0)
                        .toFixed(0)
                        .replace(".", ",")}
                </Td>
                <Td style={{ fontSize: "0.8rem" }} alignCenter fontWeight="bold">
                    {parseFloat(totalEnergiaForaPonta || 0)
                        .toFixed(0)
                        .replace(".", ",")}
                </Td>
                <Td style={{ fontSize: "0.8rem" }} alignCenter fontWeight="bold">
                    {parseFloat(totalEnergiaReservado || 0)
                        .toFixed(0)
                        .replace(".", ",")}
                </Td>
                <Td style={{ fontSize: "0.8rem" }} alignCenter fontWeight="bold">
                    {parseFloat(totalDemanda || 0)
                        .toFixed(0)
                        .replace(".", ",")}
                </Td>
                <Td style={{ fontSize: "0.8rem" }} alignCenter fontWeight="bold">
                    {/*rodapé do icms esperado*/}
                </Td>
                <Td style={{ fontSize: "0.8rem" }} alignCenter fontWeight="bold">
                    {/*rodapé do icms importado*/}
                </Td>
                <Td style={{ fontSize: "0.8rem" }} alignCenter fontWeight="bold">
                    {parseFloat(totalGastoReativo || 0)
                        .toFixed(0)
                        .replace(".", ",")}
                </Td>
                <Td style={{ fontSize: "0.8rem" }} alignCenter fontWeight="bold">
                    {parseFloat(totalGastoDemandaComplementar || 0)
                        .toFixed(0)
                        .replace(".", ",")}
                </Td>
                <Td style={{ fontSize: "0.8rem" }} alignCenter fontWeight="bold">
                    {parseFloat(totalGastoDemandaUltrapassagem || 0)
                        .toFixed(0)
                        .replace(".", ",")}
                </Td>
                <Td style={{ fontSize: "0.8rem" }} alignCenter fontWeight="bold">
                    {parseFloat(totalGastoReativo + totalGastoDemandaComplementar + totalGastoDemandaUltrapassagem)
                        .toFixed(0)
                        .replace(".", ",")}
                </Td>
                <Td></Td>
                <Td></Td>
            </Tr>
        );
        return rodape;
    }
}

const mapStateToProps = (state) => ({
    sessao: state.auth.usuario,
    usuarioLogado: state.auth.usuarioLogado,
    modoTela: state.fatura.modoTela,
    aguardando: state.fatura.aguardando,
    filtro: state.fatura.filtro,
    lista: state.fatura.lista,
    listaCliente: state.fatura.listaCliente,
    listaUnidadeConsumidora: state.fatura.listaUnidadeConsumidora,
    listaMeses: state.fatura.listaMeses,
    pdf: state.displayPDF.pdf,
    nomePdf: state.displayPDF.nomePdf,
    orientation: state.displayPDF.orientation,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setModoTela,
            initForm,
            setFiltro,
            salvar,
            salvarMultiplos,
            excluir,
            getLista,
            getListaCliente,
            getListaUnidadeConsumidora,
            reprocessar,
            reprocessarTodas,
            buscaPdf,
            setArquivoPDF,
            setAguardando,
            setSubsidioIrriganteVerificado,
            setIcmsVerificado,
            setIdStatusSubsidioIrrigante,
            setIdStatusIcms,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(FaturaVerificadorIcmsEnergia);

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import NewTr from "../common/table/newTr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import Select from "../common/form/select";
import Button from "../common/button/button";
import Form from "./clienteForm";
import UcForm from "./unidadeConsumidoraForm";
import Anexo from "./clienteAnexo";
import AnexoForm from "./clienteAnexoForm";

import { getLista as getListaUsuario } from "../usuario/usuarioActions";

import {
    setModoTela,
    initForm,
    salvar,
    salvarUC,
    excluir,
    excluirUC,
    reativar,
    getLista,
    getListaCidade,
    getListaConcessionaria,
    getListaGrupoFaturamento,
    getListaModalidadeTarifaria,
    getListaTipoMercado,
    getListaTipoMedicao,
    initFormAnexo,
    salvarAnexo,
    excluirAnexo,
} from "./clienteActions";

import { Loading } from "../common/loading/loading";
import { getLista as getListaTributacao, getlistaEstado, getListaAtividade } from "../tributacao/tributacaoActions";

class Cliente extends Component {
    state = {
        filtro: {
            id_cliente: null,
        },
    };
    componentWillMount() {
        this.props.getLista();
        this.props.getListaCidade();
        this.props.getListaConcessionaria();
        this.props.getListaGrupoFaturamento();
        this.props.getListaTipoMercado();
        this.props.getListaTipoMedicao();
        this.props.getListaUsuario();
        this.props.getlistaEstado();
        this.props.getListaAtividade();
        this.props.getListaTributacao();
        this.props.getListaModalidadeTarifaria();
    }
    render() {
        //Sem acesso
        if (!this.props.usuarioLogado || this.props.usuarioLogado.acesso_cliente) {
            return <div />;
        }

        return (
            <div>
                {this.props.aguardando ? <Loading /> : null}

                {/* SE O FORMULÁRIO DE UC TIVER ABERTO OCULTA O FORMULÁRIO CLIENTE */}
                {this.props.modoTelaUC ? (
                    <Content>
                        {this.props.modoTelaUC === "visualizacaoUC" ? <UcForm visualizar /> : null}
                        {this.props.modoTelaUC === "cadastroUC" ? <UcForm onSubmit={this.props.salvarUC} /> : null}
                        {this.props.modoTelaUC === "reativarUC" ? <UcForm reativar /> : null}
                        {this.props.modoTelaUC === "exclusaoUC" ? <UcForm excluir /> : null}
                    </Content>
                ) : (
                    <Content>
                        {this.props.modoTela === "visualizacao" ? <Form visualizar /> : null}
                        {this.props.modoTela === "cadastro" ? <Form onSubmit={this.props.salvar} /> : null}
                        {this.props.modoTela === "exclusao" ? <Form excluir={this.props.excluir} /> : null}
                        {this.props.modoTela === "reativar" ? <Form reativar={this.props.reativar} /> : null}
                        {this.props.modoTela === "lista" ? this.lista() : null}
                        {this.props.modoTela === "cadastroAnexo" ? <AnexoForm onSubmit={this.props.salvarAnexo} /> : null}
                        {this.props.modoTela === "exclusaoAnexo" ? <AnexoForm excluir onSubmit={this.props.excluirAnexo} /> : null}
                        {this.props.modoTela === "listaAnexo" ? <Anexo /> : null}
                    </Content>
                )}
            </div>
        );
    }

    lista() {
        return (
            <ContentCard>
                <ContentCardHeader>
                    <Row>
                        <Grid cols="6 6 4 2">
                            <Button
                                text="Adicionar"
                                type={"success"}
                                icon={"fa fa-plus"}
                                disabled={!this.props.usuarioLogado.acesso_cadastro_cliente_adicionar}
                                event={() => {
                                    this.props.setModoTela("cadastro", {});
                                    this.props.initForm({});
                                }}
                            />
                        </Grid>
                    </Row>
                    <Row>
                        <Grid cols="12 12 12 12" style={{ paddingTop: 10 }}>
                            {this.props.usuarioLogado && !this.props.usuarioLogado.acesso_cliente ? (
                                <Select
                                    readOnly={this.props.aguardando}
                                    options={this.props.lista.map((cliente) => ({
                                        id: cliente.id,
                                        valor: cliente.nome,
                                    }))}
                                    label="Cliente"
                                    cols="12 6 4"
                                    placeholder="Selecione o Cliente"
                                    value={this.state.filtro.id_cliente}
                                    onChange={(value) => {
                                        this.setState({
                                            ...this.state,
                                            filtro: { ...this.state.filtro, id_cliente: value },
                                        });
                                    }}
                                />
                            ) : null}
                        </Grid>
                    </Row>
                </ContentCardHeader>
                <ContentCardBody>
                    <Table responsive>
                        <THead>
                            <NewTr backgroundColorTr={"#FFFFFF"} backgroundColorTd={"#F5F5F5"}>
                                <Th sticky colSpan={1}>
                                    Nome
                                </Th>
                                <Th sticky colSpan={1}>
                                    CPF
                                </Th>
                                <Th sticky colSpan={1}>
                                    CNPJ
                                </Th>
                                <Th sticky colSpan={1}>
                                    E-mail de Faturamento
                                </Th>
                                <Th sticky colSpan={1}>
                                    E-mail de Contato
                                </Th>
                                <Th sticky colSpan={1}>
                                    Recebe Notificação de Fatura
                                </Th>
                                <Th sticky colSpan={1}>
                                    Controla Vencimento das Faturas
                                </Th>
                            </NewTr>
                        </THead>
                        <TBody>
                            {this.props.lista
                                .filter((item) => (this.state.filtro.id_cliente ? this.state.filtro.id_cliente == item.id : true))
                                .map((item) => {
                                    const objForm = JSON.parse(JSON.stringify(item)); //clona o objeto
                                    const botoes = [
                                        {
                                            type: "primary",
                                            icon: "fas fa-eye",
                                            disabled: !this.props.usuarioLogado.acesso_cadastro_cliente_visualizar,

                                            event: () => {
                                                this.props.setModoTela("visualizacao", objForm);
                                                this.props.initForm(objForm);
                                            },
                                        },
                                    ];

                                    if (item.ativo) {
                                        botoes.push(
                                            {
                                                type: "primary",
                                                icon: "fas fa-paperclip",
                                                disabled: !this.props.usuarioLogado.acesso_cadastro_cliente_editar || item.bloquearEdicaoClienteUsuarioImplantacao,
                                                event: () => {
                                                    this.props.setModoTela("listaAnexo", objForm);
                                                },
                                            },
                                            {
                                                type: "warning",
                                                icon: "fas fa-pencil-alt",
                                                disabled: !this.props.usuarioLogado.acesso_cadastro_cliente_editar || item.bloquearEdicaoClienteUsuarioImplantacao,

                                                event: () => {
                                                    this.props.setModoTela("cadastro", objForm);
                                                    this.props.initForm(objForm);
                                                },
                                            },
                                            {
                                                type: "danger",
                                                icon: "fas fa-trash-alt",
                                                disabled: !this.props.usuarioLogado.acesso_cadastro_cliente_excluir || item.bloquearEdicaoClienteUsuarioImplantacao,

                                                event: () => {
                                                    this.props.setModoTela("exclusao", objForm);
                                                    this.props.initForm(objForm);
                                                },
                                            }
                                        );
                                    } else {
                                        botoes.push({
                                            type: "success",
                                            icon: "fas fa-power-off",
                                            disabled: !this.props.usuarioLogado.acesso_cadastro_cliente_editar || item.bloquearEdicaoClienteUsuarioImplantacao,
                                            event: () => {
                                                this.props.setModoTela("reativar", objForm);
                                                this.props.initForm(objForm);
                                            },
                                        });
                                    }
                                    let backgroundColorTr = "#FFFFFF";
                                    let backgroundColorTd = "#F5F5F5";
                                    if (!item.ativo) {
                                        backgroundColorTr = "#fd6565";
                                        backgroundColorTd = "#f25353";
                                    }

                                    return (
                                        <NewTr key={item.id} backgroundColorTr={backgroundColorTr} backgroundColorTd={backgroundColorTd} botoes={botoes}>
                                            <Td>
                                                {!item.ativo ? <span style={{ padding: 5, margin: 5, backgroundColor: "red", color: "#fff" }}>INATIVO</span> : ""}
                                                {item.nome}
                                            </Td>
                                            <Td>{item.cpf ? item.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : ""}</Td>
                                            <Td>{item.cnpj ? item.cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5") : ""}</Td>
                                            <Td>{item.email_faturamento}</Td>
                                            <Td>{item.email_contato}</Td>
                                            <Td>{item.recebe_notificacao_fatura ? "Sim" : "Não"}</Td>
                                            <Td>{item.controla_vencimento_fatura ? "Sim" : "Não"}</Td>
                                        </NewTr>
                                    );
                                })}
                        </TBody>
                    </Table>
                </ContentCardBody>
            </ContentCard>
        );
    }
}

const mapStateToProps = (state) => ({
    usuarioLogado: state.auth.usuarioLogado,
    modoTela: state.cliente.modoTela,
    modoTelaUC: state.cliente.modoTelaUC,
    lista: state.cliente.lista,
    listaTributacao: state.tributacao.lista,
    aguardando: state.cliente.aguardando,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setModoTela,
            initForm,
            salvar,
            salvarUC,
            excluir,
            excluirUC,
            reativar,
            getLista,
            getListaCidade,
            getListaUsuario,
            getListaConcessionaria,
            getListaGrupoFaturamento,
            getListaModalidadeTarifaria,
            getListaTipoMercado,
            getListaTipoMedicao,
            initFormAnexo,
            salvarAnexo,
            excluirAnexo,
            getListaTributacao,
            getlistaEstado,
            getListaAtividade,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(Cliente);

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import Tr from "../common/table/tr";
import NewTr from "../common/table/newTr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import ButtonTable from "../common/table/buttonTable";
import Button from "../common/button/button";
import Form from "./faturaForm";
import FormatUtils from "../common/formatUtils/FormatUtils";

import FormMultiplo from "./faturaFormMultiplo";
// import FaturaPdf from "./faturaPdf";
import Select from "../common/form/select";
import casaPreta from "../assets/images/casaPreta.png";
import solVerde from "../assets/images/solVerde.png";
import solVermelho from "../assets/images/solVermelho.png";
import solPreto from "../assets/images/solPreto.png";
import { DisplayPDF } from "../displayPDF/displayPDF";
import LabelAndCheckbox from "../common/form/labelAndCheckbox";

import { htmlToPdf } from "../htmlToPdf/htmlToPdf";
import TabelaFaturaAdmPDF from "../htmlToPdf/TabelaFaturaAdmPDF";
import { filtrarUcPorDataDesligamentoCompetencia } from "../utils/filtrarUcPorDataDesligamentoCompetencia";
import { setModoTela, initForm, setFiltro, salvar, salvarMultiplos, excluir, getLista, buscaPdf, setAguardando, resetarFiltro } from "./faturaActions";

import { setArquivoPDF } from "../displayPDF/displayPDFActions";

import { reprocessar, reprocessarTodas, executarManutencao } from "./faturaItem/faturaItemActions";
import { getListaGrupoFaturamento } from "../cliente/clienteActions";
import { Loading } from "../common/loading/loading";
import { adicionarPontosMilhar } from "../utils/adicionarPontosMilhar";
import { setError, setSuccess } from "../common/toast/toast";
import TableInCell from "../common/table/tableInCell";
import { ModalAlert } from "../common/dialog/modalAlert";

class Fatura extends Component {
    state = {
        statusTodasCores: true,
        statusCorBranca: true,
        statusCorVerde: true,
        statusCorAzul: true,
        statusCorAmarela: true,
        statusCorVermelha: true,
        statusImportacaoSolarVerde: false,
        statusImportacaoSolarVermelha: false,
        apenasUnidadesEmImplantacao: false,
        apenasFaturasItemSemTipo: false,
        apenasMercadoLivreSemDespesas: false,
        apenasFaturasRefaturadas: false,
        exibirErros: false,
        conteudoModalAlertaErros: null,
        totaisPorCores: {
            qtdCorBranca: 0,
            qtdCorVerde: 0,
            qtdCorAzul: 0,
            qtdCorAmarela: 0,
            qtdCorVermelha: 0,
            qtdImportacaoSolarVerde: 0,
            qtdImportacaoSolarVermelha: 0,
            qtdUnidadesEmImplantacao: 0,
            qtdFaturasItemSemTipo: 0,
            qtdMercadoLivreSemDespesas: 0,
            qtdFaturasRefaturadas: 0,
        },
    };

    componentWillMount() {
        this.props.getListaGrupoFaturamento();
        if (this.props.usuarioLogado && this.props.usuarioLogado.administrador) {
            this.props.getLista();
        } else if (this.props.usuarioLogado && this.props.usuarioLogado.acesso_cliente) {
            this.props.setFiltro({
                ...this.props.filtro,
                id_cliente: this.props.usuarioLogado.id_cliente,
            });
            this.props.getLista();
        }
    }

    componentWillUnmount() {
        this.props.resetarFiltro();
    }

    render() {
        if (this.props.pdf) {
            return <DisplayPDF closePdf={this.props.setArquivoPDF} pdfBase64={this.props.pdf} nomePdf={this.props.nomePdf} orientation={this.props.orientation} zoomParametro={1.5} />;
        }

        return (
            <div>
                {this.props.aguardando ? <Loading /> : null}
                {this.state.exibirErros ? (
                    <ModalAlert titulo="" handleClose={() => this.fecharModalAlertaErros(this)}>
                        {this.state.conteudoModalAlertaErros}
                    </ModalAlert>
                ) : null}
                <Content>
                    {this.props.modoTela == "visualizacao" ? <Form visualizar /> : null}
                    {this.props.modoTela == "cadastro" ? <Form onSubmit={this.props.salvar} /> : null}
                    {this.props.modoTela == "cadastroMultiplo" ? <FormMultiplo onSubmit={this.props.salvarMultiplos} /> : null}
                    {this.props.modoTela == "exclusao" ? <Form excluir onSubmit={this.props.excluir} /> : null}
                    {/* {this.props.modoTela == "relatorio" ? <FaturaPdf /> : null} */}
                    {this.props.modoTela == "lista" ? this.lista() : null}
                </Content>
            </div>
        );
    }
    fecharModalAlertaErros(referencia) {
        referencia.setState({ ...referencia.state, exibirErros: false, conteudoModalAlertaErros: null });
    }
    getStyleSlimCard(bgColor, textColor) {
        return {
            borderRadius: 8,
            cursor: "pointer",
            backgroundColor: bgColor,
            color: textColor || "#000000",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px 10px 0px 10px",
            width: "100%",
            minHeight: "40px",
            margin: 5,
            boxShadow: "#9b9b9b 0px 1px 2px 1px",
        };
    }
    filtrarImportacaoSolar(item) {
        //fatura é valida a prop unidade_geradora e unidade_remota, uc valida geracao e remota
        let propUnidadeGeradora = "unidade_geradora";
        let propUnidadeRemota = "unidade_remota";
        if (item.unidade_geradora === undefined && item.geracao !== undefined) {
            propUnidadeGeradora = "geracao";
            propUnidadeRemota = "remota";
        }
        const importacaoSolarVermelha = this.state.statusImportacaoSolarVermelha && item[propUnidadeGeradora] && !item[propUnidadeRemota] && parseFloat(item.quantidade_geracao || 0) <= 0;

        const importacaoSolarVerde = this.state.statusImportacaoSolarVerde && item[propUnidadeGeradora] && !item[propUnidadeRemota] && parseFloat(item.quantidade_geracao || 0) > 0;

        const importacaoSolarIgnorada = !this.state.statusImportacaoSolarVermelha && !this.state.statusImportacaoSolarVerde;

        const filtroImportacaoSolar = importacaoSolarVermelha || importacaoSolarVerde || importacaoSolarIgnorada;
        return filtroImportacaoSolar;
    }

    filtrarUCsTabela(props) {
        if (!props.filtro) return [];
        return filtrarUcPorDataDesligamentoCompetencia({
            listaUnidadeConsumidora: props.listaUnidadeConsumidora,
            ano: props.filtro.ano,
            mes: props.filtro.mes,
        }).filter((item) => {
            const clienteFiltrado = !props.filtro.id_cliente || props.filtro.id_cliente == item.id_cliente; //ou não filtra cliente ou retorna as faturas com o id do cliente do filtro
            const ucsFiltradas = (!props.filtro.unidades_consumidoras.length && this.listarUCsFiltradas().find((uc) => uc.id == item.id)) || props.filtro.unidades_consumidoras.find((uc) => uc.value == item.id); //ou não filtra a UC ou retorna as faturas da UC que lista no select de uc ou as que estão selecionadas no select
            const periodoFiltrado =
                props.filtro.ano &&
                props.filtro.ano <= new Date().getFullYear() &&
                props.filtro.mes &&
                //verdadeiro se a competencia do filtro for menor ou igual a atual
                parseInt(`${props.filtro.ano}${props.filtro.mes}`) <= parseInt(`${new Date().getFullYear()}${new Date().getMonth() < 9 ? "0" : ""}${new Date().getMonth() + 1}`);
            const filtroGeracaoSolar =
                !props.filtro.geracao_solar ||
                (props.filtro.geracao_solar === "GERADORAS" && !item.remota && item.geracao) ||
                (props.filtro.geracao_solar === "REMOTAS" && item.remota && !item.geracao) ||
                (props.filtro.geracao_solar === "SEM GERAÇÃO" && !item.remota && !item.geracao) ||
                (props.filtro.geracao_solar === "GERADORAS E REMOTAS" && (item.remota || item.geracao));
            const grupoFaturamentoFiltrado = !props.filtro.id_grupo_faturamento || props.filtro.id_grupo_faturamento == item.id_grupo_faturamento;
            const usuarioImplatacaoFiltrado = !this.state.apenasUnidadesEmImplantacao || item.id_usuario_implantacao;
            const filtroImportacaoSolar = this.filtrarImportacaoSolar(item);
            const naoExisteFaturaDisponivelDaUc = !props.lista.find((fatura) => fatura.id_unidade_consumidora == item.id);
            const usuarioAdmin = props.usuarioLogado.administrador;
            //ou existe na lista mas é vermelho e o usuário não é administrador
            const existeFaturaMasTaVermelha =
                props.lista.find((fatura) => {
                    const temErros = (fatura.erros_importacao || []).length;
                    const vermelhos = temErros && !fatura.importacao_validada;
                    const existeFaturaComIdDaUC = fatura.id_unidade_consumidora == item.id;
                    return existeFaturaComIdDaUC && vermelhos;
                }) && !usuarioAdmin;
            const filtroConcessionaria = !this.props.filtro.id_concessionaria || this.props.filtro.id_concessionaria == item.id_concessionaria;

            return (
                props.usuarioLogado &&
                clienteFiltrado &&
                ucsFiltradas &&
                //o ano do filtro tem que menor ou igual ao ano atual
                periodoFiltrado &&
                (naoExisteFaturaDisponivelDaUc || existeFaturaMasTaVermelha) &&
                this.state.statusCorBranca &&
                grupoFaturamentoFiltrado &&
                filtroImportacaoSolar &&
                filtroGeracaoSolar &&
                usuarioImplatacaoFiltrado &&
                filtroConcessionaria &&
                !this.state.apenasFaturasItemSemTipo &&
                !this.state.apenasMercadoLivreSemDespesas &&
                !this.state.apenasFaturasRefaturadas
            );
        });
    }

    filtrarFaturasTabela(props) {
        return props.lista.filter((item) => {
            const filtroGeracaoSolar =
                !props.filtro.geracao_solar ||
                (props.filtro.geracao_solar === "GERADORAS" && !item.unidade_remota && item.unidade_geradora) ||
                (props.filtro.geracao_solar === "REMOTAS" && item.unidade_remota && !item.unidade_geradora) ||
                (props.filtro.geracao_solar === "SEM GERAÇÃO" && !item.unidade_remota && !item.unidade_geradora) ||
                (props.filtro.geracao_solar === "GERADORAS E REMOTAS" && (item.unidade_remota || item.unidade_geradora));

            const brancos = !item.importacao_validada && !(item.erros_importacao || []).length && this.state.statusCorBranca;

            const verdes = item.importacao_validada && !(item.erros_importacao || []).length && this.state.statusCorVerde;

            const azuis = item.importacao_validada && (item.erros_importacao || []).filter((erro) => erro.validado).length == 1 && this.state.statusCorAzul;

            const amarelas = item.importacao_validada && (item.erros_importacao || []).filter((erro) => erro.validado).length != 1 && (item.erros_importacao || []).length && this.state.statusCorAmarela;

            //forçar status vermelho quando tarifa média liquida for inválida
            const { tarifaMediaLiquida } = this.calcularTarifaMediaLiquidaBruta(item);

            const vermelhos = (!this.validarTarifaMediaLiquida(tarifaMediaLiquida) || ((item.erros_importacao || []).length && !item.importacao_validada)) && this.state.statusCorVermelha;

            const filtroPorCores = brancos || verdes || azuis || amarelas || vermelhos;

            const filtroImportacaoSolar = this.filtrarImportacaoSolar(item);
            const mesmoGrupoFaturamento = !props.filtro.id_grupo_faturamento || item.id_grupo_faturamento == props.filtro.id_grupo_faturamento;
            const filtroUnidadesEmImplantacao = !this.state.apenasUnidadesEmImplantacao || item.id_usuario_implantacao;
            const filtroFaturasItemSemTipo = !this.state.apenasFaturasItemSemTipo || (item.itens || []).find((prod) => !prod.id_tipo);
            const filtroApenasMercadoLivreSemDespasas =
                !this.state.apenasMercadoLivreSemDespesas || (item.id_tipo_mercado == 2 && (!parseFloat(item.custo_energia_acl) || !parseFloat(item.custo_gestao_acl) || !parseFloat(item.contribuicao_associativa_ccee)));
            const filtroApenasRefaturadas = !this.state.apenasFaturasRefaturadas || item.refaturada;
            const filtroConcessionaria = !this.props.filtro.id_concessionaria || this.props.filtro.id_concessionaria == item.id_concessionaria;

            return (
                mesmoGrupoFaturamento &&
                filtroPorCores &&
                filtroImportacaoSolar &&
                props.usuarioLogado &&
                (props.usuarioLogado.administrador || !vermelhos) &&
                filtroUnidadesEmImplantacao &&
                filtroFaturasItemSemTipo &&
                filtroGeracaoSolar &&
                filtroApenasMercadoLivreSemDespasas &&
                filtroApenasRefaturadas &&
                filtroConcessionaria
            );
        });
    }

    listarUCsFiltradas() {
        const ucsFiltradas = this.props.listaUnidadeConsumidora.filter(
            (item) =>
                ((!this.props.filtro.id_cliente && this.props.usuarioLogado && this.props.usuarioLogado.administrador) || item.id_cliente == this.props.filtro.id_cliente) &&
                (!this.props.filtro.id_grupo_faturamento || item.id_grupo_faturamento == this.props.filtro.id_grupo_faturamento) &&
                ((this.props.filtro.irrigante && item.irrigante) || (this.props.filtro.naoIrrigante && !item.irrigante))
        );
        return ucsFiltradas;
    }

    copiarTextoClipboard(texto) {
        navigator.clipboard
            .writeText(texto)
            .then(() => {
                setSuccess(`Conteúdo copiado: ${texto}`);
            })
            .catch((err) => {
                setError("Não foi possível copiar o conteúdo");
            });
    }

    getMensagensErro({ item, type, tarifaMediaLiquida }) {
        const listaMensagensErroImportacaoHtml = (
            <ul style={{ minWidth: 150, paddingLeft: 20 }}>
                {(item.erros_importacao || []).map((erro) => (
                    <li> {erro.mensagem}</li>
                ))}
            </ul>
        );
        const listaMensagensErroImportacaoText = (item.erros_importacao || []).map((erro) => erro.mensagem).join("  |  ");

        const faturaIsenta = (item.erros_importacao || []).filter((erro) => erro.validado).length == 1;
        const temErros = (item.erros_importacao || []).length;
        const msgErroImportacaoHtml = !this.validarTarifaMediaLiquida(tarifaMediaLiquida) ? (
            "TML maior que R$ 6, verifique os valores destacados"
        ) : item.importacao_validada ? (
            !temErros ? (
                "Validado"
            ) : (
                <div>
                    {faturaIsenta ? "Validado" : "Verifique"}:{listaMensagensErroImportacaoHtml}
                </div>
            )
        ) : item.importado ? (
            !temErros ? (
                "Importado"
            ) : (
                <div>Verifique:{listaMensagensErroImportacaoHtml}</div>
            )
        ) : (
            "Não Importado"
        );
        const msgErroImportacaoText = !this.validarTarifaMediaLiquida(tarifaMediaLiquida)
            ? "TML maior que R$ 6, verifique os valores destacados"
            : item.importacao_validada
            ? !temErros
                ? "Validado"
                : `${faturaIsenta ? "Validado" : "Verifique"}: ${listaMensagensErroImportacaoText}`
            : item.importado
            ? !temErros
                ? "Importado"
                : `Verifique: ${listaMensagensErroImportacaoText}`
            : "Não Importado";
        if (type === "html") return msgErroImportacaoHtml;
        if (type === "text") return msgErroImportacaoText;
    }

    lista() {
        let totalValorImportado = 0;
        let totalValorFatura = 0;
        let totalenergia = 0;
        let totalEnergiaPonta = 0;
        let totalEnergiaForaPonta = 0;
        let totalEnergiaReservado = 0;
        let totalDemanda = 0;
        let totalDemandaReservado = 0;
        let totalDemandaPonta = 0;
        let totalDemandaGeracao = 0;
        let totalValorDemanda = 0;
        let totalValorDemandaReservado = 0;
        let totalValorDemandaPonta = 0;
        let totalValorDemandaGeracao = 0;
        let totalEnergiaReativo = 0;
        let totalEnergiaExcedente = 0;
        let totalProducaoSolar = 0;
        let totalGastoReativo = 0;
        let totalSaldo = 0;

        //tatais custos faturas adicionais
        let totalCustoEnergiaACL = 0;
        let totalCustoGestaoACL = 0;
        let totalContribuicaoAssociativaCCEE = 0;

        //totais de registros por cores
        let qtdCorBranca = 0;
        let qtdCorVerde = 0;
        let qtdCorAzul = 0;
        let qtdCorAmarela = 0;
        let qtdCorVermelha = 0;
        let qtdImportacaoSolarVerde = 0;
        let qtdImportacaoSolarVermelha = 0;
        let qtdUnidadesEmImplantacao = 0;
        let qtdFaturasItemSemTipo = 0;
        let qtdMercadoLivreSemDespesas = 0;
        let qtdFaturasRefaturadas = 0;

        function atualizarTotaisPorCores(referencia) {
            const totaisPorCores = {
                qtdCorBranca,
                qtdCorVerde,
                qtdCorAzul,
                qtdCorAmarela,
                qtdCorVermelha,
                qtdImportacaoSolarVerde,
                qtdImportacaoSolarVermelha,
                qtdUnidadesEmImplantacao,
                qtdFaturasItemSemTipo,
                qtdMercadoLivreSemDespesas,
                qtdFaturasRefaturadas,
            };

            if (JSON.stringify(referencia.state.totaisPorCores) !== JSON.stringify(totaisPorCores)) {
                referencia.setState({ ...referencia.state, totaisPorCores });
            }
        }

        let usuarioAcessoEditar = this.props.usuarioLogado && (this.props.usuarioLogado.acesso_fatura_editar || this.props.usuarioLogado.acesso_fatura_editar_fechado);

        let statusAtivos = [];
        if (this.state.statusCorBranca) statusAtivos.push("Faturas não importadas");
        if (this.state.statusCorVerde) statusAtivos.push("Faturas validadas e sem erros");
        if (this.state.statusCorAzul) statusAtivos.push("Faturas isentas");
        if (this.state.statusCorAmarela) statusAtivos.push("Faturas corrigidas");
        if (this.state.statusCorVermelha) statusAtivos.push("Faturas com necessidade de correção");
        if (
            this.state.statusCorBranca &&
            this.state.statusCorVerde &&
            this.state.statusCorAzul &&
            this.state.statusCorAmarela &&
            this.state.statusCorVermelha &&
            !this.state.statusImportacaoSolarVerde &&
            !this.state.statusImportacaoSolarVermelha
        )
            statusAtivos = "Todas as faturas";

        //DADOS PARA IMPRESSÃO DO RELATÓRIO ---------------
        const cabecalho = (
            <NewTr backgroundColorTr="#FFFFFF" backgroundColorTd="#F9F9F9">
                <Th sticky style={{ fontSize: "0.85rem" }} alignCenter title="Status da Importação das Faturas de Energia Elétrica">
                    Status Imp. Fatura
                </Th>
                <Th sticky style={{ fontSize: "0.85rem" }} minWidth={65} alignCenter title="Status da Importação de Produção de Energia Elétrica Solar">
                    Status Imp. Prod. Solar
                </Th>
                <Th sticky style={{ fontSize: "0.85rem" }} minWidth={75} alignCenter title="Saldo de Produção Solar da Fatura de Energia Elétrica">
                    Geração Solar (kWh)
                </Th>
                <Th sticky style={{ fontSize: "0.85rem" }} minWidth={75} alignCenter>
                    Competência
                </Th>
                <Th sticky style={{ fontSize: "0.85rem" }} alignCenter minWidth={65}>
                    Cliente
                </Th>
                <Th sticky style={{ fontSize: "0.7rem" }} alignCenter>
                    Nº da Nota Fiscal
                </Th>
                <Th sticky style={{ fontSize: "0.85rem" }} alignCenter minWidth={65}>
                    UC
                </Th>
                <Th sticky style={{ fontSize: "0.85rem" }} minWidth={75} alignCenter title="Tipo de Medição">
                    Tp. Med.
                </Th>
                <Th sticky style={{ fontSize: "0.85rem" }} minWidth={75} alignCenter title="Grupo de Faturamento">
                    Grp. Fat.
                </Th>
                <Th sticky style={{ fontSize: "0.85rem" }} minWidth={75} alignCenter>
                    ICMS (%)
                </Th>
                <Th sticky style={{ fontSize: "0.85rem" }} minWidth={75} alignCenter>
                    Despesas Adicionais de Mercado Livre (ACL)
                </Th>
                <Th sticky style={{ fontSize: "0.85rem" }} alignCenter>
                    Total (R$)
                </Th>

                <Th sticky style={{ fontSize: "0.85rem" }} alignCenter>
                    Energia (kWh)
                </Th>

                <Th sticky style={{ fontSize: "0.85rem" }} minWidth={50} alignCenter>
                    Demanda
                </Th>

                <Th sticky style={{ fontSize: "0.85rem" }} minWidth={60} alignCenter>
                    E. Reativo (kvarh)
                </Th>
                <Th sticky style={{ fontSize: "0.85rem" }} minWidth={65} alignCenter>
                    Gasto Reativo (R$)
                </Th>
                <Th sticky style={{ fontSize: "0.85rem" }} minWidth={34} alignCenter>
                    Tarifa
                </Th>
                <Th sticky style={{ fontSize: "0.85rem" }} alignCenter>
                    PDF
                </Th>
            </NewTr>
        );
        const linhas = [];
        let rodape = null;
        //------------------------------------------------
        return (
            <ContentCard>
                {this.props.usuarioLogado && !this.props.usuarioLogado.acesso_cliente ? (
                    <ContentCardHeader>
                        <Row style={{ paddingRight: 5 }}>
                            <Grid cols="12 4 4 2">
                                <Button
                                    text="Adicionar"
                                    type={"success"}
                                    icon={"fa fa-plus"}
                                    style={{ marginTop: 28 }}
                                    disabled={!usuarioAcessoEditar || this.props.aguardando}
                                    event={() => {
                                        this.props.setModoTela("cadastro", {});
                                        this.props.initForm({});
                                    }}
                                />
                            </Grid>
                            <Grid cols="12 4 4 2">
                                <Button
                                    text="Adicionar Múltiplos"
                                    type={"success"}
                                    icon={"fa fa-plus-circle"}
                                    style={{ marginTop: 28 }}
                                    disabled={!usuarioAcessoEditar || this.props.aguardando}
                                    event={() => {
                                        this.props.setModoTela("cadastroMultiplo", {});
                                        this.props.initForm({});
                                    }}
                                />
                            </Grid>
                            {this.props.usuarioLogado && [1, 2].includes(this.props.usuarioLogado.id) /*INFINITUM e marthielo*/ ? (
                                <Grid cols="12 4 4 2">
                                    <Button
                                        text="Reprocessar"
                                        type={"success"}
                                        icon={"fa fa-sync"}
                                        style={{ marginTop: 28 }}
                                        disabled={!usuarioAcessoEditar || this.props.aguardando}
                                        event={() => {
                                            this.props.reprocessarTodas(this.filtrarFaturasTabela(this.props));
                                        }}
                                    />
                                </Grid>
                            ) : null}

                            {this.props.usuarioLogado && this.props.usuarioLogado.id == 1 /*INFINITUM*/ ? (
                                <>
                                    <Grid
                                        cols="12 12 12 6"
                                        style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            justifyContent: "center",
                                            paddingTop: window.innerWidth < 992 ? 10 : null,
                                        }}
                                    >
                                        <Select
                                            readOnly={this.props.aguardando}
                                            name="id_manutencao_produto_tipo"
                                            gridMarginBlock={0}
                                            options={this.props.listaProdutoTipo.filter((item) => ![21, 47, 48, 49].includes(item.id))} //oculta os que não vem da fatura
                                            label="Manutenção"
                                            cols="12 8 8 8"
                                            placeholder="Selecione o tipo"
                                            value={this.props.filtro.id_manutencao_produto_tipo}
                                            onChange={(value) => {
                                                this.props.setFiltro({
                                                    ...this.props.filtro,
                                                    id_manutencao_produto_tipo: value,
                                                });
                                            }}
                                        />
                                        <Grid
                                            cols="12 4 4 4"
                                            style={{
                                                padding: window.innerWidth < 992 ? null : "0px 0px 0px 10px",
                                            }}
                                        >
                                            <Button
                                                disabled={this.props.aguardando}
                                                text="Executar Manutenção"
                                                type={"success"}
                                                style={{ marginTop: window.innerWidth < 992 ? null : 28 }}
                                                event={() => {
                                                    this.props.executarManutencao(this.filtrarFaturasTabela(this.props), this.props.filtro.id_manutencao_produto_tipo);
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            ) : null}
                        </Row>
                    </ContentCardHeader>
                ) : null}
                <ContentCardHeader>
                    <Row style={{ paddingBlock: 15 }}>
                        {this.props.usuarioLogado && !this.props.usuarioLogado.acesso_cliente ? (
                            <Select
                                readOnly={this.props.aguardando}
                                name="id_cliente"
                                options={this.props.listaCliente}
                                label="Cliente"
                                cols="12 6 3 3"
                                placeholder="Selecione o Cliente"
                                value={this.props.filtro.id_cliente}
                                onChange={(value) => {
                                    this.props.setFiltro({
                                        ...this.props.filtro,
                                        id_cliente: value,
                                        unidades_consumidoras: [],
                                    });
                                    this.props.getLista();
                                }}
                            />
                        ) : null}

                        <Select
                            readOnly={this.props.aguardando}
                            name="ano"
                            options={Array.from({ length: 20 }, (v, i) => ({
                                id: new Date().getFullYear() - 5 + i,
                                valor: new Date().getFullYear() - 5 + i,
                            }))}
                            label="Ano"
                            cols="12 6 2 2"
                            placeholder="Selecione o ano"
                            value={this.props.filtro.ano}
                            onChange={(value) => {
                                this.props.setFiltro({
                                    ...this.props.filtro,
                                    ano: value,
                                });
                                this.props.getLista();
                            }}
                        />

                        <Select
                            readOnly={this.props.aguardando}
                            name="mes"
                            options={this.props.listaMeses}
                            label="Mês"
                            cols="12 6 2 2"
                            placeholder="Selecione o mês"
                            value={this.props.filtro.mes}
                            onChange={(value) => {
                                this.props.setFiltro({
                                    ...this.props.filtro,
                                    mes: value,
                                });
                                this.props.getLista();
                            }}
                        />
                        <Select
                            readOnly={this.props.aguardando}
                            name="geracao"
                            options={this.props.listaProducaoSolar}
                            label="Geração Solar"
                            cols="12 6 2 2"
                            placeholder="Selecione o tipo"
                            value={this.props.filtro.geracao_solar}
                            onChange={(value) => {
                                this.props.setFiltro({
                                    ...this.props.filtro,
                                    geracao_solar: value,
                                });
                            }}
                        />
                        <Grid cols="6 6 3 3">
                            <Row style={{ paddingLeft: 12, paddingBottom: 12 }}>
                                <b>Tipo de Atividade</b>
                            </Row>
                            <Row style={{ alignItems: "center" }}>
                                <LabelAndCheckbox
                                    readOnly={this.props.aguardando}
                                    gridDisplay="flex"
                                    gridJustifyContent={window.innerWidth < 400 ? "start" : "center"}
                                    gridAlignItems="end"
                                    style={{ marginLeft: 12 }}
                                    component={LabelAndCheckbox}
                                    label="Irrigante"
                                    cols="12 12 4 4"
                                    value={this.props.filtro.irrigante}
                                    onChange={() => {
                                        this.props.setFiltro({
                                            ...this.props.filtro,
                                            irrigante: !this.props.filtro.irrigante,
                                        });
                                        this.props.getLista();
                                    }}
                                />
                                <LabelAndCheckbox
                                    readOnly={this.props.aguardando}
                                    gridDisplay="flex"
                                    gridJustifyContent={window.innerWidth < 400 ? "start" : "center"}
                                    gridAlignItems="end"
                                    style={{ marginLeft: 12 }}
                                    component={LabelAndCheckbox}
                                    label="Não-Irrigante"
                                    cols="12 12 8 8"
                                    value={this.props.filtro.naoIrrigante}
                                    onChange={() => {
                                        this.props.setFiltro({
                                            ...this.props.filtro,
                                            naoIrrigante: !this.props.filtro.naoIrrigante,
                                        });
                                        this.props.getLista();
                                    }}
                                />
                            </Row>
                        </Grid>
                        <Select
                            readOnly={this.props.aguardando}
                            options={this.listarUCsFiltradas()}
                            label="Unidades Consumidoras"
                            cols="12 12 7 7"
                            placeholder="Selecione as UCs"
                            isMulti={true}
                            value={this.props.filtro.unidades_consumidoras}
                            onChange={(ucsSelecionadas) => {
                                this.props.setFiltro({
                                    ...this.props.filtro,
                                    unidades_consumidoras: [...ucsSelecionadas],
                                });
                                this.props.getLista();
                            }}
                        />
                        <Select
                            readOnly={this.props.aguardando}
                            name="id_concessionaria"
                            options={this.props.listaConcessionaria}
                            label="Concessionária"
                            cols="12 12 2 2"
                            placeholder="Selecione a concessionaria"
                            value={this.props.filtro.id_concessionaria}
                            onChange={(id_concessionaria) => {
                                this.props.setFiltro({
                                    ...this.props.filtro,
                                    id_concessionaria,
                                });
                                this.props.getLista();
                            }}
                        />
                        <Select
                            readOnly={this.props.aguardando}
                            name="id_grupo_faturamento"
                            options={this.props.listaGrupoFaturamento}
                            label="Tipo de Faturamento"
                            cols="12 12 3 3"
                            placeholder="Selecione o tipo de faturamento"
                            value={this.props.filtro.id_grupo_faturamento}
                            onChange={(id_grupo_faturamento) => {
                                this.props.setFiltro({
                                    ...this.props.filtro,
                                    id_grupo_faturamento,
                                });
                                this.props.getLista();
                            }}
                        />
                    </Row>
                </ContentCardHeader>
                <ContentCardHeader>
                    {this.props.usuarioLogado && !this.props.usuarioLogado.acesso_cliente ? (
                        <details open={window.innerWidth >= 950} style={{ marginBottom: 20 }}>
                            <summary>
                                <b>
                                    Filtro por Status
                                    {window.innerWidth >= 950 || !statusAtivos ? "" : ` (${statusAtivos})`.replace(/\,/g, ", ")}
                                </b>
                            </summary>
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    marginBottom: 20,
                                    minHeight: "fit-content",
                                }}
                            >
                                <Grid
                                    cols="12 12 6 3"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        cursor: "pointer",
                                    }}
                                >
                                    <label style={this.getStyleSlimCard("#6dc9ff")} htmlFor="filtro-todas">
                                        <LabelAndCheckbox
                                            style={{ justifyContent: "center" }}
                                            readOnly={this.props.aguardando}
                                            modoCard={true}
                                            colorIcon="#000"
                                            id="filtro-todas"
                                            label={`Todas as faturas (${
                                                this.state.totaisPorCores.qtdCorBranca +
                                                this.state.totaisPorCores.qtdCorAmarela +
                                                this.state.totaisPorCores.qtdCorAzul +
                                                this.state.totaisPorCores.qtdCorVerde +
                                                this.state.totaisPorCores.qtdCorVermelha
                                            })`}
                                            onChange={(e) =>
                                                this.setState({
                                                    ...this.state,
                                                    statusCorBranca: e.target.checked,
                                                    statusCorAzul: e.target.checked,
                                                    statusCorAmarela: e.target.checked,
                                                    statusCorVermelha: e.target.checked,
                                                    statusCorVerde: e.target.checked,
                                                    statusImportacaoSolarVerde: false,
                                                    statusImportacaoSolarVermelha: false,
                                                })
                                            }
                                            value={
                                                this.state.statusCorBranca &&
                                                this.state.statusCorAzul &&
                                                this.state.statusCorAmarela &&
                                                this.state.statusCorVermelha &&
                                                this.state.statusCorVerde &&
                                                !this.state.statusImportacaoSolarVerde &&
                                                !this.state.statusImportacaoSolarVermelha
                                            }
                                        />
                                    </label>
                                </Grid>
                                <Grid
                                    cols="12 12 6 3"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <label style={this.getStyleSlimCard("#FFEF71")} htmlFor="filtro-amarelas">
                                        <LabelAndCheckbox
                                            style={{ justifyContent: "center" }}
                                            readOnly={this.props.aguardando}
                                            modoCard={true}
                                            colorIcon="#000"
                                            id="filtro-amarelas"
                                            label={`Faturas corrigidas (${this.state.totaisPorCores.qtdCorAmarela})`}
                                            onChange={(e) =>
                                                this.setState({
                                                    ...this.state,
                                                    statusCorAmarela: e.target.checked,
                                                })
                                            }
                                            value={!!this.state.statusCorAmarela}
                                        />
                                    </label>
                                </Grid>

                                <Grid
                                    cols="12 12 6 3"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <label style={this.getStyleSlimCard("#FF6569")} htmlFor="filtro-vermelhas">
                                        <LabelAndCheckbox
                                            style={{ justifyContent: "center" }}
                                            readOnly={this.props.aguardando}
                                            modoCard={true}
                                            colorIcon="#000"
                                            id="filtro-vermelhas"
                                            label={`Faturas com necessidade de correção (${this.state.totaisPorCores.qtdCorVermelha})`}
                                            onChange={(e) =>
                                                this.setState({
                                                    ...this.state,
                                                    statusCorVermelha: e.target.checked,
                                                })
                                            }
                                            value={!!this.state.statusCorVermelha}
                                        />
                                    </label>
                                </Grid>
                                {window.innerWidth >= 992 ? (
                                    <Grid
                                        cols="12 12 6 3"
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <label style={this.getStyleSlimCard("#2f2f2f", "#FFFFFF")} htmlFor="filtro-solar-importado" title="Solar Importado">
                                            <LabelAndCheckbox
                                                style={{ justifyContent: "center" }}
                                                readOnly={this.props.aguardando}
                                                modoCard={true}
                                                colorIcon="#FFF"
                                                id="filtro-solar-importado"
                                                label={
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <img height={24} src={solVerde} alt=""></img>
                                                        <span style={{ paddingLeft: 10 }}>Solar Importado ({this.state.totaisPorCores.qtdImportacaoSolarVerde})</span>
                                                    </div>
                                                }
                                                onChange={(e) =>
                                                    this.setState({
                                                        ...this.state,
                                                        statusImportacaoSolarVerde: e.target.checked,
                                                    })
                                                }
                                                value={!!this.state.statusImportacaoSolarVerde}
                                            />
                                        </label>
                                    </Grid>
                                ) : null}
                                <Grid
                                    cols="12 12 6 3"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <label style={this.getStyleSlimCard("#44FFAA")} htmlFor="filtro-verdes">
                                        <LabelAndCheckbox
                                            style={{ justifyContent: "center" }}
                                            readOnly={this.props.aguardando}
                                            modoCard={true}
                                            colorIcon="#000"
                                            id="filtro-verdes"
                                            label={`Faturas validadas e sem erros (${this.state.totaisPorCores.qtdCorVerde})`}
                                            onChange={(e) =>
                                                this.setState({
                                                    ...this.state,
                                                    statusCorVerde: e.target.checked,
                                                })
                                            }
                                            value={!!this.state.statusCorVerde}
                                        />
                                    </label>
                                </Grid>
                                <Grid
                                    cols="12 12 6 3"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        cursor: "pointer",
                                    }}
                                >
                                    <label style={this.getStyleSlimCard("#f9f9f9")} htmlFor="filtro-brancas">
                                        <LabelAndCheckbox
                                            style={{ justifyContent: "center" }}
                                            readOnly={this.props.aguardando}
                                            modoCard={true}
                                            colorIcon="#000"
                                            id="filtro-brancas"
                                            label={`Faturas não importadas (${this.state.totaisPorCores.qtdCorBranca})`}
                                            onChange={(e) =>
                                                this.setState({
                                                    ...this.state,
                                                    statusCorBranca: e.target.checked,
                                                })
                                            }
                                            value={!!this.state.statusCorBranca}
                                        />
                                    </label>
                                </Grid>
                                <Grid
                                    cols="12 12 6 3"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <label style={this.getStyleSlimCard("#C5FFFF")} htmlFor="filtro-azuis">
                                        <LabelAndCheckbox
                                            style={{ justifyContent: "center" }}
                                            readOnly={this.props.aguardando}
                                            modoCard={true}
                                            colorIcon="#000"
                                            id="filtro-azuis"
                                            label={`Faturas isentas (${this.state.totaisPorCores.qtdCorAzul})`}
                                            onChange={(e) =>
                                                this.setState({
                                                    ...this.state,
                                                    statusCorAzul: e.target.checked,
                                                })
                                            }
                                            value={!!this.state.statusCorAzul}
                                        />
                                    </label>
                                </Grid>
                                {window.innerWidth < 992 ? (
                                    <Grid
                                        cols="12 12 6 3"
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <label style={this.getStyleSlimCard("#2f2f2f", "#FFFFFF")} htmlFor="filtro-solar-importado" title="Solar Importado">
                                            <LabelAndCheckbox
                                                style={{ justifyContent: "center" }}
                                                readOnly={this.props.aguardando}
                                                modoCard={true}
                                                colorIcon="#FFF"
                                                id="filtro-solar-importado"
                                                label={
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <img alt="" height={24} src={solVerde}></img>
                                                        <span style={{ paddingLeft: 10 }}>Solar Importado ({this.state.totaisPorCores.qtdImportacaoSolarVerde})</span>
                                                    </div>
                                                }
                                                onChange={(e) =>
                                                    this.setState({
                                                        ...this.state,
                                                        statusImportacaoSolarVerde: e.target.checked,
                                                    })
                                                }
                                                value={!!this.state.statusImportacaoSolarVerde}
                                            />
                                        </label>
                                    </Grid>
                                ) : null}
                                <Grid
                                    cols="12 12 6 3"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <label style={this.getStyleSlimCard("#2f2f2f", "#FFFFFF")} htmlFor="filtro-solar-nao-importado" title="Solar Não Importado">
                                        <LabelAndCheckbox
                                            style={{ justifyContent: "center" }}
                                            readOnly={this.props.aguardando}
                                            modoCard={true}
                                            colorIcon="#FFF"
                                            id="filtro-solar-nao-importado"
                                            label={
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <img alt="" height={24} src={solVermelho}></img>
                                                    <span style={{ paddingLeft: 10 }}>Solar Não Importado ({this.state.totaisPorCores.qtdImportacaoSolarVermelha})</span>
                                                </div>
                                            }
                                            onChange={(e) =>
                                                this.setState({
                                                    ...this.state,
                                                    statusImportacaoSolarVermelha: e.target.checked,
                                                })
                                            }
                                            value={!!this.state.statusImportacaoSolarVermelha}
                                        />
                                    </label>
                                </Grid>
                            </div>
                        </details>
                    ) : null}
                </ContentCardHeader>
                <ContentCardHeader>
                    <Row style={{ padding: 7 }}>
                        <Grid
                            cols="12 12 6 3"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                cursor: "pointer",
                            }}
                        >
                            <label style={this.getStyleSlimCard("#257bfb", "#FFFFFF")} htmlFor="filtro-apenas-implantacao">
                                <LabelAndCheckbox
                                    style={{ justifyContent: "center" }}
                                    readOnly={this.props.aguardando}
                                    modoCard={true}
                                    colorIcon="#FFF"
                                    id="filtro-apenas-implantacao"
                                    label={`Apenas Unidades em Implantação (${this.state.totaisPorCores.qtdUnidadesEmImplantacao})`}
                                    onChange={(e) =>
                                        this.setState({
                                            ...this.state,
                                            apenasUnidadesEmImplantacao: !this.state.apenasUnidadesEmImplantacao,
                                        })
                                    }
                                    value={!!this.state.apenasUnidadesEmImplantacao}
                                />
                            </label>
                        </Grid>
                        <Grid
                            cols="12 12 6 3"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                cursor: "pointer",
                            }}
                        >
                            <label style={this.getStyleSlimCard("#ffa348", "#000000")} htmlFor="filtro-apenas-item-sem-tipo">
                                <LabelAndCheckbox
                                    style={{ justifyContent: "center" }}
                                    readOnly={this.props.aguardando}
                                    modoCard={true}
                                    colorIcon="#000"
                                    id="filtro-apenas-item-sem-tipo"
                                    label={`Apenas faturas com itens sem tipo (${this.state.totaisPorCores.qtdFaturasItemSemTipo})`}
                                    onChange={(e) =>
                                        this.setState({
                                            ...this.state,
                                            apenasFaturasItemSemTipo: !this.state.apenasFaturasItemSemTipo,
                                        })
                                    }
                                    value={!!this.state.apenasFaturasItemSemTipo}
                                />
                            </label>
                        </Grid>
                        <Grid
                            cols="12 12 6 3"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                cursor: "pointer",
                            }}
                        >
                            <label style={this.getStyleSlimCard("#53ff84", "#000000")} htmlFor="filtro-apenas-refaturadas">
                                <LabelAndCheckbox
                                    style={{ justifyContent: "center" }}
                                    readOnly={this.props.aguardando}
                                    modoCard={true}
                                    colorIcon="#000"
                                    id="filtro-apenas-refaturadas"
                                    label={`Apenas faturas refaturadas (${this.state.totaisPorCores.qtdFaturasRefaturadas})`}
                                    onChange={(e) =>
                                        this.setState({
                                            ...this.state,
                                            apenasFaturasRefaturadas: !this.state.apenasFaturasRefaturadas,
                                        })
                                    }
                                    value={!!this.state.apenasFaturasRefaturadas}
                                />
                            </label>
                        </Grid>
                        <Grid
                            cols="12 12 6 3"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                cursor: "pointer",
                            }}
                        >
                            <label style={this.getStyleSlimCard("#FEFEFE", "#e63d3d")} htmlFor="filtro-apenas-m-livre-sem-despesas">
                                <LabelAndCheckbox
                                    style={{ justifyContent: "center" }}
                                    modoCard={true}
                                    colorIcon="#000"
                                    readOnly={this.props.aguardando}
                                    id="filtro-apenas-m-livre-sem-despesas"
                                    label={`Apenas faturas M. Livre sem despesas (${this.state.totaisPorCores.qtdMercadoLivreSemDespesas})`}
                                    onChange={(e) =>
                                        this.setState({
                                            ...this.state,
                                            apenasMercadoLivreSemDespesas: !this.state.apenasMercadoLivreSemDespesas,
                                        })
                                    }
                                    value={!!this.state.apenasMercadoLivreSemDespesas}
                                />
                            </label>
                        </Grid>
                    </Row>
                    <Row style={{ padding: 7 }}>
                        <Grid
                            cols="12 12 6 3"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                padding: 5,
                                cursor: "pointer",
                            }}
                        >
                            <Button
                                disabled={this.props.aguardando}
                                text="Imprimir Relatório"
                                type={"primary"}
                                event={async () => {
                                    if (!this.props.filtro.unidades_consumidoras.length && !this.props.filtro.id_cliente) {
                                        return setError("Selecione um cliente ou alguma unidade consumidora para gerar o relatório");
                                    }
                                    await this.gerarRelatorio({
                                        statusAtivos,
                                        cabecalho,
                                        rodape,
                                        linhas,
                                    });
                                }}
                            />
                        </Grid>
                    </Row>
                </ContentCardHeader>
                <ContentCardBody>
                    <Table responsive>
                        <THead>{cabecalho}</THead>
                        {!this.props.aguardando ? (
                            <TBody>
                                {this.filtrarUCsTabela(this.props).map((item) => {
                                    const botoes = [];
                                    if (usuarioAcessoEditar) {
                                        botoes.push({
                                            style: { width: 90 },
                                            text: "Importar",
                                            type: "success",
                                            event: () => {
                                                this.props.setModoTela("cadastro", {});
                                                this.props.initForm({
                                                    id_cliente: (this.props.listaCliente.find((cliente) => cliente.id == item.id_cliente) || {}).id,
                                                    id_unidade_consumidora: item.id,
                                                    competencia: `${this.props.filtro.mes}/${this.props.filtro.ano}`,
                                                });
                                            },
                                        });
                                    }
                                    const nome_cliente = (this.props.listaCliente.find((cliente) => cliente.id == item.id_cliente) || {}).valor;
                                    const nome_unidade_consumidora = item.valor;

                                    const linha = (
                                        <NewTr
                                            backgroundColorTr="#FFFFFF"
                                            backgroundColorTd="#f9f9f9"
                                            key={`UC${item.id}`}
                                            style={{
                                                height: 40,
                                            }}
                                            botoes={botoes}
                                        >
                                            <Td fontSize="0.85rem">
                                                {item.id_usuario_implantacao ? (
                                                    <p
                                                        style={{
                                                            backgroundColor: "#257bfb",
                                                            textAlign: "center",
                                                            borderRadius: "0.3rem",
                                                            color: "#fff",
                                                            margin: "2px 10px 2px 2px",
                                                            padding: "2px 5px",
                                                        }}
                                                    >
                                                        Implantação
                                                    </p>
                                                ) : null}
                                                <p
                                                    style={{
                                                        textAlign: "center",
                                                        margin: "2px 10px 2px 2px",
                                                        padding: "2px 5px",
                                                    }}
                                                >
                                                    Não Importado
                                                </p>
                                            </Td>
                                            <Td fontSize="0.85rem" minWidth={50} alignCenter>
                                                {item.geracao ? (
                                                    <img alt="" height={24} style={{ marginBottom: 6 }} src={solPreto}></img>
                                                ) : item.remota ? (
                                                    <img alt="" title={"Unidade Consumidora Remota"} height={24} style={{ marginBottom: 6 }} src={casaPreta}></img>
                                                ) : null}
                                            </Td>
                                            <Td fontSize="0.85rem" minWidth={50} alignCenter>
                                                <TableInCell
                                                    lista={[
                                                        {
                                                            cabecalho: "Produção (kWh)",
                                                            valor: " - ",
                                                        },
                                                        {
                                                            cabecalho: "Saldo Produção (kWh)",
                                                            valor: " - ",
                                                        },
                                                        {
                                                            cabecalho: "Injetado/Excedente (kWh)",
                                                            valor: " - ",
                                                        },
                                                    ]}
                                                />
                                            </Td>
                                            <Td fontSize="0.85rem" alignCenter>{`${this.props.filtro.mes}/${this.props.filtro.ano}`}</Td>
                                            <Td fontSize="0.85rem" minWidth={80}>
                                                {nome_cliente}
                                            </Td>
                                            <Td fontSize="0.85rem" alignRight></Td>
                                            <Td fontSize="0.85rem" minWidth={120}>
                                                {nome_unidade_consumidora}
                                            </Td>
                                            <Td fontSize="0.85rem" alignCenter>
                                                {item.sigla_tipo_medicao}
                                            </Td>
                                            <Td fontSize="0.85rem" alignCenter>
                                                {item.sigla_grupo_faturamento}
                                            </Td>
                                            <Td fontSize="0.85rem" alignCenter>
                                                <TableInCell
                                                    lista={[
                                                        {
                                                            cabecalho: "Esperado (%)",
                                                            valor: isNaN(parseFloat(item.aliquota_icms)) ? null : adicionarPontosMilhar(item.aliquota_icms),
                                                        },
                                                        {
                                                            cabecalho: "Importado (%)",
                                                            valor: " - ",
                                                        },
                                                    ]}
                                                />
                                            </Td>
                                            <Td fontSize="0.85rem" alignCenter>
                                                <TableInCell
                                                    lista={[
                                                        {
                                                            cabecalho: "Custo de Energia ACL (R$)",
                                                            valor: " - ",
                                                        },
                                                        {
                                                            cabecalho: "Custo de Gestão ACL (R$)",
                                                            valor: " - ",
                                                        },
                                                        {
                                                            cabecalho: "Tx. de Contribuição CCEE (R$)",
                                                            valor: " - ",
                                                        },
                                                    ]}
                                                />
                                            </Td>
                                            <Td fontSize="0.85rem" alignCenter>
                                                <TableInCell
                                                    lista={[
                                                        {
                                                            cabecalho: "Fatura (R$)",
                                                            valor: " - ",
                                                        },
                                                        {
                                                            cabecalho: "Importado (R$)",
                                                            valor: " - ",
                                                        },
                                                    ]}
                                                />
                                            </Td>
                                            <Td fontSize="0.85rem" alignRight>
                                                <TableInCell
                                                    lista={[
                                                        {
                                                            cabecalho: "Total (kWh)",
                                                            valor: " - ",
                                                        },
                                                        {
                                                            cabecalho: "Ponta (kWh)",
                                                            valor: " - ",
                                                        },
                                                        {
                                                            cabecalho: "Fora Ponta (kWh)",
                                                            valor: " - ",
                                                        },
                                                        {
                                                            cabecalho: "Reservado (kWh)",
                                                            valor: " - ",
                                                        },
                                                    ]}
                                                />
                                            </Td>
                                            <Td fontSize="0.85rem" alignRight>
                                                <TableInCell
                                                    lista={[
                                                        {
                                                            cabecalho: "Ponta",
                                                            valores: ["- kWh", " - R$"],
                                                        },
                                                        {
                                                            cabecalho: "Fora Ponta",
                                                            valores: ["- kWh", " - R$"],
                                                        },
                                                        {
                                                            cabecalho: "Reservado",
                                                            valores: ["- kWh", " - R$"],
                                                        },
                                                        {
                                                            cabecalho: "Demanda G",
                                                            valores: ["- kWh", " - R$"],
                                                        },
                                                    ]}
                                                />
                                            </Td>
                                            <Td fontSize="0.85rem" alignCenter>
                                                {" - "}
                                            </Td>
                                            <Td fontSize="0.85rem" alignCenter>
                                                {" - "}
                                            </Td>
                                            <Td fontSize="0.85rem" alignCenter>
                                                {" - "}
                                            </Td>
                                            <Td fontSize="0.85rem" alignCenter minWidth={50}>
                                                {item.mensagem_ultima_importacao_fatura ? (
                                                    <ButtonTable
                                                        solid={true}
                                                        style={{
                                                            margin: 0,
                                                            borderRadius: "0.3rem",
                                                        }}
                                                        title={item.mensagem_ultima_importacao_fatura}
                                                        type={"danger"}
                                                        icon={"fas fa-exclamation"}
                                                        visible={true}
                                                        event={() => ({})}
                                                    />
                                                ) : null}
                                            </Td>
                                        </NewTr>
                                    );
                                    const totalCelulas = linha.props.children.length; //.props.children.length;
                                    let linhaModificada = Array.from(linha.props.children).splice(0, totalCelulas - 1);
                                    linhaModificada = React.Children.map(linhaModificada, (child, index) => {
                                        // 4 = cliente
                                        if (index == 4) {
                                            return React.cloneElement(child, {
                                                children: (
                                                    <div
                                                        style={{
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        {nome_cliente.length > 20 ? nome_cliente.substring(0, 20) + "..." : nome_cliente}
                                                    </div>
                                                ),
                                            });
                                        }
                                        // 6 = UC
                                        if (index == 6) {
                                            return React.cloneElement(child, {
                                                children: (
                                                    <div
                                                        style={{
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        {nome_unidade_consumidora.length > 20
                                                            ? `${nome_unidade_consumidora.substring(0, 20)}... (${item.nome_concessionaria})`
                                                            : `${nome_unidade_consumidora} (${item.nome_concessionaria})`}
                                                    </div>
                                                ),
                                            });
                                        }
                                        return child;
                                    });
                                    const linhaSemColunaBotoes = React.cloneElement(linha, {
                                        children: linhaModificada,
                                        botoes: [],
                                    });

                                    linhas.push(linhaSemColunaBotoes);
                                    if (item.geracao) {
                                        qtdImportacaoSolarVermelha++;
                                    }
                                    if (item.id_usuario_implantacao) {
                                        qtdUnidadesEmImplantacao++;
                                    }
                                    qtdCorBranca++;
                                    return linha;
                                })}
                                {this.filtrarFaturasTabela(this.props).map((item, i, array) => {
                                    const possuiItemSemTipo = (item.itens || []).find((prod) => !prod.id_tipo);
                                    totalValorImportado += parseFloat(item.total > 0 ? item.total : 0);
                                    totalValorFatura += parseFloat(item.valor_fatura > 0 ? item.valor_fatura : 0);
                                    totalenergia += parseFloat(item.energia || 0);
                                    totalEnergiaPonta += parseFloat(item.energia_ponta || 0);
                                    totalEnergiaForaPonta += parseFloat(item.energia_fora_ponta || 0);
                                    totalEnergiaReservado += parseFloat(item.energia_reservado || 0);
                                    totalDemanda += parseFloat(item.demanda || 0);
                                    totalDemandaReservado += parseFloat(item.demanda_reservado || 0);
                                    totalDemandaPonta += parseFloat(item.demanda_ponta || 0);
                                    totalDemandaGeracao += parseFloat(item.demanda_geracao || 0);
                                    totalValorDemanda += parseFloat(item.valor_demanda || 0);
                                    totalValorDemandaReservado += parseFloat(item.valor_demanda_reservado || 0);
                                    totalValorDemandaPonta += parseFloat(item.valor_demanda_ponta || 0);
                                    totalValorDemandaGeracao += parseFloat(item.valor_demanda_geracao || 0);
                                    totalEnergiaReativo += parseFloat(item.energia_reativo || 0);
                                    totalEnergiaExcedente += parseFloat(item.energia_excedente || 0);
                                    totalProducaoSolar += parseFloat(item.quantidade_geracao || 0);
                                    totalGastoReativo += parseFloat(item.gasto_reativo || 0);
                                    totalSaldo += parseFloat(item.saldo_geracao || 0);

                                    totalCustoEnergiaACL += parseFloat(item.custo_energia_acl || 0);
                                    totalCustoGestaoACL += parseFloat(item.custo_gestao_acl || 0);
                                    totalContribuicaoAssociativaCCEE += parseFloat(item.contribuicao_associativa_ccee || 0);

                                    if (item.id_tipo_mercado == 2 && (!parseFloat(item.custo_energia_acl) || !parseFloat(item.custo_gestao_acl) || !parseFloat(item.contribuicao_associativa_ccee))) {
                                        qtdMercadoLivreSemDespesas++;
                                    }

                                    const { tarifaMediaLiquida, tarifaMediaBruta } = this.calcularTarifaMediaLiquidaBruta(item);
                                    let backgroundColorTd = item.importacao_validada
                                        ? (item.erros_importacao || []).filter((erro) => erro.validado).length == 1
                                            ? "#C5FFFF"
                                            : (item.erros_importacao || []).length
                                            ? "#FFEF71"
                                            : "#44FFAA"
                                        : (item.erros_importacao || []).length
                                        ? "#FF6569"
                                        : "#f9f9f9";

                                    let backgroundColorTr = item.importacao_validada
                                        ? (item.erros_importacao || []).filter((erro) => erro.validado).length == 1
                                            ? "#F3FFFF"
                                            : (item.erros_importacao || []).length
                                            ? "#FFFFD4"
                                            : "#DDFFDD"
                                        : (item.erros_importacao || []).length
                                        ? "#FBCACA"
                                        : "#FFFFFF";

                                    //forçar status vermelho quando tarifa média liquida for inválida
                                    if (!this.validarTarifaMediaLiquida(tarifaMediaLiquida)) {
                                        backgroundColorTd = "#CB1E22";
                                        backgroundColorTr = "#FC5959";
                                    }

                                    switch (backgroundColorTd) {
                                        case "#C5FFFF":
                                            qtdCorAzul++;
                                            break;
                                        case "#FFEF71":
                                            qtdCorAmarela++;
                                            break;
                                        case "#44FFAA":
                                            qtdCorVerde++;
                                            break;
                                        case "#FF6569":
                                            qtdCorVermelha++;
                                            break;
                                        case "#CB1E22":
                                            qtdCorVermelha++;
                                            break;
                                        case "#f9f9f9":
                                            qtdCorBranca++;
                                            break;
                                        default:
                                            break;
                                    }
                                    if (item.id_usuario_implantacao) qtdUnidadesEmImplantacao++;
                                    if (possuiItemSemTipo) qtdFaturasItemSemTipo++;
                                    if (item.refaturada) qtdFaturasRefaturadas++;
                                    if (item.unidade_geradora) {
                                        if (parseFloat(item.quantidade_geracao || 0) <= 0) {
                                            qtdImportacaoSolarVermelha++;
                                        } else {
                                            qtdImportacaoSolarVerde++;
                                        }
                                    }
                                    const botoes = [];

                                    if (item.importado || item.arquivo_pdf) {
                                        if (this.props.usuarioLogado && !this.props.usuarioLogado.acesso_cliente) {
                                            botoes.push(
                                                {
                                                    type: "success",
                                                    icon: "fas fa-sync",
                                                    disabled: !item.arquivo_pdf || item.importacao_validada || !usuarioAcessoEditar,
                                                    event: () => {
                                                        this.props.reprocessar(item.id);
                                                    },
                                                },

                                                {
                                                    style: {
                                                        width: 38,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    },
                                                    type: "primary",
                                                    icon: "fas fa-eye",
                                                    disabled: !this.props.usuarioLogado.acesso_fatura_visualizar,
                                                    event: () => {
                                                        this.props.setModoTela("visualizacao", {
                                                            ...item,
                                                            competencia: `${String(item.competencia).substring(4, 6)}/${String(item.competencia).substring(0, 4)}`,
                                                            valor_fatura: FormatUtils.formatarValorTela(item.valor_fatura, 2),
                                                        });
                                                        this.props.initForm({
                                                            ...item,
                                                            competencia: `${String(item.competencia).substring(4, 6)}/${String(item.competencia).substring(0, 4)}`,
                                                            valor_fatura: FormatUtils.formatarValorTela(item.valor_fatura, 2),
                                                        });
                                                    },
                                                },

                                                {
                                                    type: "warning",
                                                    icon: "fas fa-pencil-alt",
                                                    disabled: !usuarioAcessoEditar,
                                                    event: () => {
                                                        this.props.setModoTela("cadastro", {
                                                            ...item,
                                                            competencia: `${String(item.competencia).substring(4, 6)}/${String(item.competencia).substring(0, 4)}`,
                                                            valor_fatura: FormatUtils.formatarValorTela(item.valor_fatura, 2),
                                                        });
                                                        this.props.initForm({
                                                            ...item,
                                                            competencia: `${String(item.competencia).substring(4, 6)}/${String(item.competencia).substring(0, 4)}`,
                                                            valor_fatura: FormatUtils.formatarValorTela(item.valor_fatura, 2),
                                                        });
                                                    },
                                                },
                                                {
                                                    type: "danger",
                                                    icon: "fas fa-trash-alt",
                                                    disabled: item.importacao_validada && !usuarioAcessoEditar,
                                                    event: () => {
                                                        this.props.setModoTela("exclusao", {
                                                            ...item,
                                                            competencia: `${String(item.competencia).substring(4, 6)}/${String(item.competencia).substring(0, 4)}`,
                                                            valor_fatura: FormatUtils.formatarValorTela(item.valor_fatura, 2),
                                                        });
                                                        this.props.initForm({
                                                            ...item,
                                                            competencia: `${String(item.competencia).substring(4, 6)}/${String(item.competencia).substring(0, 4)}`,
                                                            valor_fatura: FormatUtils.formatarValorTela(item.valor_fatura, 2),
                                                        });
                                                    },
                                                }
                                            );
                                        } else {
                                            if (usuarioAcessoEditar) {
                                                botoes.push({
                                                    type: "success",
                                                    icon: "fas fa-eye",
                                                    event: () => {
                                                        this.props.setModoTela("visualizacao", {
                                                            ...item,
                                                            competencia: `${String(item.competencia).substring(4, 6)}/${String(item.competencia).substring(0, 4)}`,
                                                            valor_fatura: FormatUtils.formatarValorTela(item.valor_fatura, 2),
                                                        });
                                                        this.props.initForm({
                                                            ...item,
                                                            competencia: `${String(item.competencia).substring(4, 6)}/${String(item.competencia).substring(0, 4)}`,
                                                            valor_fatura: FormatUtils.formatarValorTela(item.valor_fatura, 2),
                                                        });
                                                    },
                                                });
                                            }
                                        }
                                    } else {
                                        if (usuarioAcessoEditar) {
                                            botoes.push(
                                                {
                                                    style: { width: 90 },
                                                    text: "Excluir",
                                                    type: "danger",
                                                    disabled: item.importacao_validada && !usuarioAcessoEditar,
                                                    event: () => {
                                                        this.props.setModoTela("exclusao", {
                                                            ...item,
                                                            competencia: `${String(item.competencia).substring(4, 6)}/${String(item.competencia).substring(0, 4)}`,
                                                            valor_fatura: FormatUtils.formatarValorTela(item.valor_fatura, 2),
                                                        });
                                                        this.props.initForm({
                                                            ...item,
                                                            competencia: `${String(item.competencia).substring(4, 6)}/${String(item.competencia).substring(0, 4)}`,
                                                            valor_fatura: FormatUtils.formatarValorTela(item.valor_fatura, 2),
                                                        });
                                                    },
                                                },
                                                {
                                                    style: { width: 90 },
                                                    text: "Importar",
                                                    type: "success",
                                                    disabled: !usuarioAcessoEditar,
                                                    event: () => {
                                                        this.props.setModoTela("cadastro", {
                                                            ...item,
                                                            competencia: `${String(item.competencia).substring(4, 6)}/${String(item.competencia).substring(0, 4)}`,
                                                            valor_fatura: FormatUtils.formatarValorTela(item.valor_fatura, 2),
                                                        });
                                                        this.props.initForm({
                                                            ...item,
                                                            competencia: `${String(item.competencia).substring(4, 6)}/${String(item.competencia).substring(0, 4)}`,
                                                            valor_fatura: FormatUtils.formatarValorTela(item.valor_fatura, 2),
                                                        });
                                                    },
                                                }
                                            );
                                        }
                                    }
                                    //se for rge mostra os os 3 valores (ponta,fora ponta, reservado)
                                    const tarifaRGE = parseFloat(item.tarifa_energia_ponta) >= 0 || parseFloat(item.tarifa_energia_fora_ponta) >= 0 || parseFloat(item.tarifa_energia_horario_reservado) >= 0;

                                    const tarifaSelecionada = tarifaRGE
                                        ? [
                                              {
                                                  cabecalho: <div title="Tarifa Energia Ponta">T.P</div>,
                                                  valor: parseFloat(item.tarifa_energia_ponta) >= 0 ? adicionarPontosMilhar(item.tarifa_energia_ponta) : " - ",
                                              },
                                              {
                                                  cabecalho: <div title="Tarifa Energia Fora Ponta">T.FP</div>,
                                                  valor: parseFloat(item.tarifa_energia_fora_ponta) >= 0 ? adicionarPontosMilhar(item.tarifa_energia_fora_ponta) : " - ",
                                              },
                                              {
                                                  cabecalho: <div title="Tarifa Energia Horário Reservado">T.R</div>,
                                                  valor: parseFloat(item.tarifa_energia_horario_reservado) >= 0 ? adicionarPontosMilhar(item.tarifa_energia_horario_reservado) : " - ",
                                              },
                                          ]
                                        : [
                                              {
                                                  cabecalho: <div title="Tarifa">T</div>,
                                                  valor: adicionarPontosMilhar(item.tarifa_economia || 0),
                                              },
                                          ];
                                    const tarifa = (
                                        <TableInCell
                                            lista={[
                                                ...tarifaSelecionada,
                                                {
                                                    cabecalho: <div title="Tarifa Média Líquida">T.ML</div>,
                                                    valor: parseFloat(tarifaMediaLiquida) >= 0 ? adicionarPontosMilhar(tarifaMediaLiquida) : " - ",
                                                },
                                                {
                                                    cabecalho: <div title="Tarifa Média Bruta">T.MB</div>,
                                                    valor: parseFloat(tarifaMediaBruta) >= 0 ? adicionarPontosMilhar(tarifaMediaBruta) : " - ",
                                                },
                                            ]}
                                        />
                                    );

                                    const mensagensErroText = this.getMensagensErro({ item, type: "text", tarifaMediaLiquida });
                                    const mensagensErroHtml = this.getMensagensErro({ item, type: "html", tarifaMediaLiquida });
                                    const linha = (
                                        <NewTr key={item.id} backgroundColorTr={backgroundColorTr} backgroundColorTd={backgroundColorTd} botoes={botoes}>
                                            <Td fontSize="0.85rem" minWidth={60}>
                                                {item.id_usuario_implantacao ? (
                                                    <p
                                                        style={{
                                                            backgroundColor: "#257bfb",
                                                            textAlign: "center",
                                                            borderRadius: "0.3rem",
                                                            color: "#ffffff",
                                                            margin: "0 10px 2px",
                                                            padding: "2px 5px",
                                                            fontSize: "0.875rem",
                                                        }}
                                                    >
                                                        Implantação
                                                    </p>
                                                ) : null}
                                                {possuiItemSemTipo ? (
                                                    <p
                                                        style={{
                                                            backgroundColor: "#ffa348",
                                                            textWrap: "nowrap",
                                                            textAlign: "center",
                                                            borderRadius: "0.3rem",
                                                            color: "#000000",
                                                            margin: "0 10px 2px",
                                                            padding: "2px 5px",
                                                        }}
                                                    >
                                                        <i className="fas fa-tasks"></i> Itens sem tipo!
                                                    </p>
                                                ) : null}
                                                <p
                                                    style={{
                                                        textAlign: "center",
                                                        padding: "0px 15px 0px 5px",
                                                        margin: 0,
                                                    }}
                                                >
                                                    {mensagensErroText.includes("Verifique") ? (
                                                        <Button
                                                            style={{ padding: "2px 5px", fontSize: "0.8rem !important" }}
                                                            title={mensagensErroText}
                                                            text="Exibir Erros"
                                                            solid={true}
                                                            type={"danger"}
                                                            event={() => {
                                                                this.setState({ ...this.state, exibirErros: true, conteudoModalAlertaErros: mensagensErroHtml });
                                                                // window.alert(mensagensErroText);
                                                            }}
                                                        />
                                                    ) : (
                                                        mensagensErroHtml
                                                    )}
                                                </p>
                                                {item.refaturada ? (
                                                    <p
                                                        style={{
                                                            backgroundColor: "#71f793",
                                                            textWrap: "nowrap",
                                                            textAlign: "center",
                                                            borderRadius: "0.3rem",
                                                            color: "#000000",
                                                            margin: "2px 10px 2px",
                                                            fontSize: "0.875rem",
                                                            padding: "2px 5px",
                                                        }}
                                                    >
                                                        <i className="fa fa-file-invoice-dollar"></i> Refaturada
                                                    </p>
                                                ) : null}
                                            </Td>
                                            <Td fontSize="0.85rem" minWidth={50} alignCenter>
                                                {item.unidade_geradora ? (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                        title={parseFloat(item.quantidade_geracao || 0) <= 0 ? "Importação Solar Pendente" : "Geração Solar Importada"}
                                                    >
                                                        {parseFloat(item.quantidade_geracao || 0) <= 0 ? (
                                                            <img alt="" height={24} style={{ marginBottom: 6, marginRight: 3 }} src={solVermelho} />
                                                        ) : (
                                                            <img alt="" height={24} style={{ marginBottom: 6, marginRight: 3 }} src={solVerde} />
                                                        )}
                                                        <span> - FV{item.sequencia_uc_geradora}</span>
                                                    </div>
                                                ) : item.unidade_remota ? (
                                                    <div
                                                        title={"Unidade Consumidora Remota"}
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <img alt="" height={24} style={{ marginBottom: 6, marginRight: 3 }} src={casaPreta}></img>
                                                        <span> - FV{item.sequencia_uc_geradora}</span>
                                                    </div>
                                                ) : null}
                                            </Td>
                                            <Td fontSize="0.85rem" minWidth={50} alignCenter>
                                                <TableInCell
                                                    lista={[
                                                        {
                                                            cabecalho: "Produção (kWh)",
                                                            valor: adicionarPontosMilhar(item.quantidade_geracao || 0),
                                                        },
                                                        {
                                                            cabecalho: (
                                                                <span
                                                                    style={{
                                                                        color: (!item.unidade_geradora && !item.unidade_remota) || adicionarPontosMilhar(item.saldo_geracao || 0) > 0 ? null : "#AF1111",
                                                                    }}
                                                                >
                                                                    Saldo Produção (kWh)
                                                                </span>
                                                            ),
                                                            valor: (
                                                                <span
                                                                    style={{
                                                                        color: (!item.unidade_geradora && !item.unidade_remota) || adicionarPontosMilhar(item.saldo_geracao || 0) > 0 ? null : "#AF1111",
                                                                    }}
                                                                >
                                                                    {(item.saldo_geracao || 0) > 0 || item.unidade_geradora || item.unidade_remota ? Math.round(item.saldo_geracao || 0) : " - "}
                                                                </span>
                                                            ),
                                                        },
                                                        {
                                                            cabecalho: "Injetado/Excedente (kWh)",
                                                            valor: adicionarPontosMilhar(item.energia_excedente || 0),
                                                        },
                                                    ]}
                                                />
                                            </Td>
                                            <Td fontSize="0.85rem" minWidth={50} alignCenter>{`${String(item.competencia).substring(4, 6)}/${String(item.competencia).substring(0, 4)}`}</Td>
                                            <Td fontSize="0.85rem" minWidth={80}>
                                                {item.nome_cliente}
                                            </Td>
                                            <Td minWidth={150}>
                                                {item.numero_nota_fiscal != null ? (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                fontSize: "0.8rem",
                                                                width: 130,
                                                                background: "#fff",
                                                                padding: "6px  3px",
                                                                borderRadius: "5px",
                                                                border: "1px solid #ddd",
                                                            }}
                                                        >
                                                            {`${item.numero_nota_fiscal}${item.serie_nota_fiscal ? " - " + item.serie_nota_fiscal : ""}`}
                                                        </div>

                                                        <button
                                                            title="Copiar conteúdo"
                                                            onClick={() => this.copiarTextoClipboard(`${item.numero_nota_fiscal}${item.serie_nota_fiscal ? " - " + item.serie_nota_fiscal : ""}`)}
                                                            style={{
                                                                marginLeft: -30,
                                                                backgroundColor: "#fff",
                                                                color: "#555",
                                                                height: 29,
                                                                border: "1px solid #999",
                                                                borderRadius: 5,
                                                                outline: "none",
                                                            }}
                                                        >
                                                            <i className="far fa-clone"></i>
                                                        </button>
                                                    </div>
                                                ) : null}
                                            </Td>

                                            <Td fontSize="0.85rem" minWidth={120}>
                                                {item.nome_unidade_consumidora}
                                                {" - "}
                                                {item.nome_concessionaria}
                                            </Td>
                                            <Td fontSize="0.85rem" alignCenter>
                                                {item.sigla_tipo_medicao}
                                            </Td>
                                            <Td fontSize="0.85rem" alignCenter>
                                                {item.sigla_grupo_faturamento}
                                            </Td>
                                            <Td fontSize="0.85rem" alignCenter>
                                                <TableInCell
                                                    lista={[
                                                        {
                                                            cabecalho: "Esperado (%)",
                                                            valor: isNaN(parseFloat(item.icms_esperado)) ? " - " : adicionarPontosMilhar(item.icms_esperado),
                                                        },
                                                        {
                                                            cabecalho: "Importado (%)",
                                                            valor: isNaN(parseFloat(item.icms_importado)) ? " - " : adicionarPontosMilhar(item.icms_importado),
                                                        },
                                                    ]}
                                                />
                                            </Td>
                                            <Td fontSize="0.85rem" alignCenter>
                                                <TableInCell
                                                    lista={[
                                                        {
                                                            cabecalho: "Custo de Energia ACL (R$)",
                                                            valor:
                                                                item.id_tipo_mercado == 2 ? (
                                                                    <div
                                                                        style={{
                                                                            padding: 10,
                                                                            border: parseFloat(item.custo_energia_acl) > 0 ? "none" : "3px solid #F00",
                                                                            color: !this.validarTarifaMediaLiquida(tarifaMediaLiquida) ? "#fff" : null,
                                                                            display: "flex",
                                                                            flexWrap: "nowrap",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            height: "1.4rem",
                                                                        }}
                                                                    >
                                                                        {adicionarPontosMilhar(item.custo_energia_acl)}
                                                                    </div>
                                                                ) : (
                                                                    adicionarPontosMilhar(item.custo_energia_acl)
                                                                ),
                                                        },
                                                        {
                                                            cabecalho: "Custo de Gestão ACL (R$)",
                                                            valor:
                                                                item.id_tipo_mercado == 2 ? (
                                                                    <div
                                                                        style={{
                                                                            padding: 10,
                                                                            border: parseFloat(item.custo_gestao_acl) > 0 ? "none" : "3px solid #F00",
                                                                            color: !this.validarTarifaMediaLiquida(tarifaMediaLiquida) ? "#fff" : null,
                                                                            display: "flex",
                                                                            flexWrap: "nowrap",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            height: "1.4rem",
                                                                        }}
                                                                    >
                                                                        {adicionarPontosMilhar(item.custo_gestao_acl)}
                                                                    </div>
                                                                ) : (
                                                                    adicionarPontosMilhar(item.custo_gestao_acl)
                                                                ),
                                                        },
                                                        {
                                                            cabecalho: "Tx. de Contribuição CCEE (R$)",
                                                            valor:
                                                                item.id_tipo_mercado == 2 ? (
                                                                    <div
                                                                        style={{
                                                                            padding: 10,
                                                                            border: parseFloat(item.contribuicao_associativa_ccee) > 0 ? "none" : "3px solid #F00",
                                                                            color: !this.validarTarifaMediaLiquida(tarifaMediaLiquida) ? "#fff" : null,
                                                                            display: "flex",
                                                                            flexWrap: "nowrap",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            height: "1.4rem",
                                                                        }}
                                                                    >
                                                                        {adicionarPontosMilhar(item.contribuicao_associativa_ccee)}
                                                                    </div>
                                                                ) : (
                                                                    adicionarPontosMilhar(item.contribuicao_associativa_ccee)
                                                                ),
                                                        },
                                                    ]}
                                                />
                                            </Td>
                                            <Td fontSize="0.85rem" minWidth={50} alignCenter>
                                                <TableInCell
                                                    lista={[
                                                        {
                                                            cabecalho: "Fatura (R$)",
                                                            valor: adicionarPontosMilhar(item.total > 0 ? item.total : 0),
                                                        },
                                                        {
                                                            cabecalho: "Importado (R$)",
                                                            valor: (
                                                                <div
                                                                    style={{
                                                                        padding: 2,
                                                                        backgroundColor: !this.validarTarifaMediaLiquida(tarifaMediaLiquida) ? "#000" : null,
                                                                        color: !this.validarTarifaMediaLiquida(tarifaMediaLiquida) ? "#fff" : null,
                                                                        display: "flex",
                                                                        flexWrap: "nowrap",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        height: "1.4rem",
                                                                    }}
                                                                >
                                                                    {adicionarPontosMilhar(item.valor_fatura || 0)}
                                                                </div>
                                                            ),
                                                        },
                                                    ]}
                                                />
                                            </Td>
                                            <Td fontSize={!this.validarTarifaMediaLiquida(tarifaMediaLiquida) ? "0.9rem" : "0.85rem"} minWidth={50} alignCenter>
                                                <TableInCell
                                                    lista={[
                                                        {
                                                            cabecalho: "Total (kWh)",
                                                            valor: (
                                                                <div
                                                                    style={{
                                                                        padding: 2,
                                                                        backgroundColor: !this.validarTarifaMediaLiquida(tarifaMediaLiquida) ? "#000" : null,
                                                                        color: !this.validarTarifaMediaLiquida(tarifaMediaLiquida) ? "#fff" : null,
                                                                        display: "flex",
                                                                        flexWrap: "nowrap",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        height: "1.4rem",
                                                                    }}
                                                                >
                                                                    {adicionarPontosMilhar(item.energia || 0)}
                                                                </div>
                                                            ),
                                                        },
                                                        {
                                                            cabecalho: "Ponta (kW)",
                                                            valor: adicionarPontosMilhar(item.energia_ponta || 0),
                                                        },
                                                        {
                                                            cabecalho: "Fora Ponta (kW)",
                                                            valor: adicionarPontosMilhar(item.energia_fora_ponta || 0),
                                                        },
                                                        {
                                                            cabecalho: "Reservado (kW)",
                                                            valor: adicionarPontosMilhar(item.energia_reservado || 0),
                                                        },
                                                    ]}
                                                />
                                            </Td>
                                            <Td fontSize="0.85rem" minWidth={50} alignCenter>
                                                <TableInCell
                                                    lista={[
                                                        {
                                                            cabecalho: "Ponta",
                                                            valores: [
                                                                adicionarPontosMilhar(item.demanda_ponta || 0) + " kW",
                                                                <div
                                                                    style={{
                                                                        padding: 2,
                                                                        backgroundColor: !this.validarTarifaMediaLiquida(tarifaMediaLiquida) ? "#000" : null,
                                                                        color: !this.validarTarifaMediaLiquida(tarifaMediaLiquida) ? "#fff" : null,
                                                                        display: "flex",
                                                                        flexWrap: "nowrap",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        height: "1.4rem",
                                                                    }}
                                                                >
                                                                    {"R$ " + adicionarPontosMilhar(item.valor_demanda_ponta || 0)}
                                                                </div>,
                                                            ],
                                                        },
                                                        {
                                                            cabecalho: "Fora Ponta",
                                                            valores: [
                                                                adicionarPontosMilhar(item.demanda || 0) + " kW",
                                                                <div
                                                                    style={{
                                                                        padding: 2,
                                                                        backgroundColor: !this.validarTarifaMediaLiquida(tarifaMediaLiquida) ? "#000" : null,
                                                                        color: !this.validarTarifaMediaLiquida(tarifaMediaLiquida) ? "#fff" : null,
                                                                        display: "flex",
                                                                        flexWrap: "nowrap",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        height: "1.4rem",
                                                                    }}
                                                                >
                                                                    {"R$ " + adicionarPontosMilhar(item.valor_demanda || 0)}
                                                                </div>,
                                                            ],
                                                        },
                                                        {
                                                            cabecalho: "Reservado",
                                                            valores: [
                                                                adicionarPontosMilhar(item.demanda_reservado || 0) + " kW",
                                                                <div
                                                                    style={{
                                                                        padding: 2,
                                                                        backgroundColor: !this.validarTarifaMediaLiquida(tarifaMediaLiquida) ? "#000" : null,
                                                                        color: !this.validarTarifaMediaLiquida(tarifaMediaLiquida) ? "#fff" : null,
                                                                        display: "flex",
                                                                        flexWrap: "nowrap",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        height: "1.4rem",
                                                                    }}
                                                                >
                                                                    {"R$ " + adicionarPontosMilhar(item.valor_demanda_reservado || 0)}
                                                                </div>,
                                                            ],
                                                        },
                                                        {
                                                            cabecalho: "Demanda G",
                                                            valores: [
                                                                adicionarPontosMilhar(item.demanda_geracao || 0) + " kW",
                                                                <div
                                                                    style={{
                                                                        padding: 2,
                                                                        backgroundColor: !this.validarTarifaMediaLiquida(tarifaMediaLiquida) ? "#000" : null,
                                                                        color: !this.validarTarifaMediaLiquida(tarifaMediaLiquida) ? "#fff" : null,
                                                                        display: "flex",
                                                                        flexWrap: "nowrap",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        height: "1.4rem",
                                                                    }}
                                                                >
                                                                    {"R$ " + adicionarPontosMilhar(item.valor_demanda_geracao || 0)}
                                                                </div>,
                                                            ],
                                                        },
                                                    ]}
                                                />
                                            </Td>
                                            <Td fontSize="0.85rem" minWidth={50} alignCenter>
                                                {adicionarPontosMilhar(item.energia_reativo || 0)}
                                            </Td>
                                            <Td fontSize="0.85rem" minWidth={50} alignCenter>
                                                <div
                                                    style={{
                                                        padding: 2,
                                                        backgroundColor: !this.validarTarifaMediaLiquida(tarifaMediaLiquida) ? "#000" : null,
                                                        color: !this.validarTarifaMediaLiquida(tarifaMediaLiquida) ? "#fff" : null,
                                                        display: "flex",
                                                        flexWrap: "nowrap",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        height: "1.4rem",
                                                    }}
                                                >
                                                    {adicionarPontosMilhar(item.gasto_reativo || 0)}
                                                </div>
                                            </Td>
                                            <Td fontSize="0.85rem" minWidth={50} alignCenter>
                                                {tarifa}
                                            </Td>
                                            <Td fontSize="0.85rem" alignCenter minWidth={50}>
                                                {item.arquivo_pdf && (!((item.erros_importacao || []).length > 0 && !item.importacao_validada) || this.props.usuarioLogado.administrador) ? (
                                                    <ButtonTable
                                                        style={{ margin: 0 }}
                                                        title="Visualizar"
                                                        type={"primary"}
                                                        icon={"fas fa-file-pdf"}
                                                        visible={true}
                                                        event={() => {
                                                            this.props.buscaPdf(
                                                                item.id,
                                                                `Fatura_UC-${item.nome_unidade_consumidora.split("-")[0].trim()}_${String(item.competencia).substring(4, 6)}-${String(item.competencia).substring(0, 4)}`
                                                            );
                                                            this.props.setArquivoPDF();
                                                        }}
                                                    />
                                                ) : null}
                                            </Td>
                                        </NewTr>
                                    );
                                    const totalCelulas = linha.props.children.length;
                                    let linhaModificada = Array.from(linha.props.children).splice(0, totalCelulas - 1);
                                    linhaModificada = React.Children.map(linhaModificada, (child, index) => {
                                        // 0 = Msg Erro Importação
                                        if (index == 0) {
                                            return React.cloneElement(child, {
                                                children: (
                                                    <div>
                                                        {item.id_usuario_implantacao ? (
                                                            <p
                                                                style={{
                                                                    backgroundColor: "#257bfb",
                                                                    textAlign: "center",
                                                                    borderRadius: "0.3rem",
                                                                    color: "#fff",
                                                                    margin: "2px 10px 2px 2px",
                                                                    padding: "2px 5px",
                                                                }}
                                                            >
                                                                Implantação
                                                            </p>
                                                        ) : null}
                                                        <p
                                                            style={{
                                                                textAlign: "center",
                                                                margin: "2px 10px 2px 2px",
                                                                padding: "2px 5px",
                                                            }}
                                                        >
                                                            {mensagensErroText.length > 20 ? mensagensErroText.substring(0, 20) + "..." : mensagensErroText}
                                                        </p>
                                                    </div>
                                                ),
                                            });
                                        }

                                        // 4 = cliente
                                        if (index == 4) {
                                            return React.cloneElement(child, {
                                                children: (
                                                    <div
                                                        style={{
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        {item.nome_cliente.length > 20 ? item.nome_cliente.substring(0, 20) + "..." : item.nome_cliente}
                                                    </div>
                                                ),
                                            });
                                        }

                                        // 5 = numero nota serie_nota_fiscal
                                        if (index == 5) {
                                            return React.cloneElement(child, {
                                                children: (
                                                    <div
                                                        style={{
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        {item.numero_nota_fiscal != null && item.serie_nota_fiscal != null ? item.numero_nota_fiscal + "-" + item.serie_nota_fiscal : " - "}
                                                    </div>
                                                ),
                                            });
                                        }
                                        // 6 = UC
                                        if (index == 6) {
                                            const nome_unidade_consumidora = item.nome_unidade_consumidora;
                                            return React.cloneElement(child, {
                                                children: (
                                                    <div
                                                        style={{
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        {nome_unidade_consumidora.length > 20
                                                            ? `${nome_unidade_consumidora.substring(0, 20)}... (${item.nome_concessionaria})`
                                                            : `${nome_unidade_consumidora} (${item.nome_concessionaria})`}
                                                    </div>
                                                ),
                                            });
                                        }
                                        return child;
                                    });
                                    const linhaSemColunaBotoes = React.cloneElement(linha, {
                                        ...linha.props,
                                        children: linhaModificada,
                                        botoes: [],
                                    });

                                    linhas.push(linhaSemColunaBotoes);

                                    //ao chegar na última iteração constrói o rodapé
                                    if (i === array.length - 1) {
                                        rodape = this.renderizarRodape({
                                            totalGastoReativo,
                                            totalEnergiaReativo,
                                            totalEnergiaExcedente,
                                            totalProducaoSolar,
                                            totalSaldo,
                                            totalDemanda,
                                            totalDemandaReservado,
                                            totalDemandaPonta,
                                            totalDemandaGeracao,
                                            totalValorDemanda,
                                            totalValorDemandaReservado,
                                            totalValorDemandaPonta,
                                            totalValorDemandaGeracao,
                                            totalEnergiaReservado,
                                            totalEnergiaForaPonta,
                                            totalEnergiaPonta,
                                            totalenergia,
                                            totalValorFatura,
                                            totalValorImportado,
                                            totalCustoEnergiaACL,
                                            totalCustoGestaoACL,
                                            totalContribuicaoAssociativaCCEE,
                                        });
                                    }
                                    return linha;
                                })}
                                {atualizarTotaisPorCores(this)}
                                {rodape}
                            </TBody>
                        ) : null}
                    </Table>
                </ContentCardBody>
            </ContentCard>
        );
    }

    validarTarifaMediaLiquida(tarifaMediaLiquida) {
        //se for maior que 6 tem dados inválidos no cálculo
        return parseFloat(tarifaMediaLiquida || 0) < 6;
    }

    calcularTarifaMediaLiquidaBruta(item) {
        let tarifaMediaLiquida = 0;
        let tarifaMediaBruta = 0;

        const valorFatura = parseFloat(item.valor_fatura || 0);
        const qtdConsumoTotal = parseFloat(item.energia || 0);
        const somaValorDemandas = parseFloat(item.valor_demanda || 0) + parseFloat(item.valor_demanda_ponta || 0) + parseFloat(item.valor_demanda_geracao || 0);
        const somaDemandasComplementares = parseFloat(item.gasto_demanda_complementar || 0) + parseFloat(item.gasto_demanda_complementar_ponta || 0);
        const somaDemandasUltrapassagens = parseFloat(item.gasto_demanda_ultrapassagem || 0) + parseFloat(item.gasto_demanda_ultrapassagem_ponta || 0) + parseFloat(item.gasto_demanda_ultrapassagem_geracao || 0);
        const valorMulta = parseFloat(item.gasto_reativo || 0) + somaDemandasComplementares + somaDemandasUltrapassagens;
        if (qtdConsumoTotal != 0) {
            tarifaMediaLiquida = (valorFatura - valorMulta - somaValorDemandas) / qtdConsumoTotal;
            tarifaMediaBruta = valorFatura / qtdConsumoTotal;

            //troca possível valor negativo por zero
            tarifaMediaLiquida = tarifaMediaLiquida < 0 ? 0 : tarifaMediaLiquida.toFixed(2);
            tarifaMediaBruta = tarifaMediaBruta < 0 ? 0 : tarifaMediaBruta.toFixed(2);
        }
        return { tarifaMediaLiquida, tarifaMediaBruta };
    }

    async gerarRelatorio({ statusAtivos, cabecalho, rodape, linhas }) {
        this.props.setAguardando(true);
        const ucsSelecionadas = this.props.listaUnidadeConsumidora.filter((item) => this.props.filtro.unidades_consumidoras.find((uc) => uc.value == item.id));
        const nomesUcsSelecionadas = String(ucsSelecionadas.map((item) => item.valor));

        const totalCelulas = cabecalho.props.children.length;
        const linhaModificada = Array.from(cabecalho.props.children).splice(0, totalCelulas - 1);

        const cabecalhoSemColunasBotoes = (
            <NewTr>
                {linhaModificada.map((el, i) => {
                    const copia = React.cloneElement(
                        el,
                        {
                            key: i,
                            sticky: false,
                            style: { fontSize: "0.85rem", paddingTop: 30 },
                        },
                        [...el.props.children]
                    );
                    return copia;
                })}
            </NewTr>
        );

        const filtro = (
            <b fontSize="0.85rem">
                Cliente:
                {` ${(this.props.listaCliente.find((cliente) => cliente.id == this.props.filtro.id_cliente) || {}).valor || "Todos"} - `}
                UCs:
                {` ${nomesUcsSelecionadas.length ? nomesUcsSelecionadas : "Todas"} - `}
                Ano:{" " + (this.props.filtro.ano || "") + " - "}
                Mês:
                {` ${(this.props.listaMeses.find((mes) => mes.id == this.props.filtro.mes) || {}).valor || "Todos"} - `}
                Filtro por Status: {String(statusAtivos).replace(/\,/g, ", ")}
            </b>
        );
        const pdfBase64 = await htmlToPdf(
            <TabelaFaturaAdmPDF
                dadosImpressao={{
                    filtro,
                    rodape,
                    cabecalhoSemColunasBotoes,
                    linhas,
                }}
            />,
            this.props.setAguardando
        );
        this.props.setArquivoPDF(pdfBase64, "Relatório faturas.pdf", "landscape");
    }

    renderizarRodape({
        totalGastoReativo,
        totalEnergiaReativo,
        totalEnergiaExcedente,
        totalProducaoSolar,
        totalSaldo,
        totalDemanda,
        totalDemandaReservado,
        totalDemandaPonta,
        totalDemandaGeracao,
        totalValorDemanda,
        totalValorDemandaReservado,
        totalValorDemandaPonta,
        totalValorDemandaGeracao,
        totalEnergiaReservado,
        totalEnergiaForaPonta,
        totalEnergiaPonta,
        totalenergia,
        totalValorFatura,
        totalValorImportado,
        totalCustoEnergiaACL,
        totalCustoGestaoACL,
        totalContribuicaoAssociativaCCEE,
    }) {
        const rodape = (
            <Tr>
                <Td fontSize="0.85rem" colSpan={2} fontWeight="bold">
                    Total
                </Td>
                <Td fontSize="0.85rem" fontWeight="bold">
                    <TableInCell
                        lista={[
                            {
                                cabecalho: "Produção (kWh)",

                                valor: parseFloat(totalProducaoSolar || 0)
                                    .toFixed(0)
                                    .replace(".", ","),
                            },
                            {
                                cabecalho: "Saldo Produção (kWh)",
                                valor: parseFloat(totalSaldo || 0)
                                    .toFixed(0)
                                    .replace(".", ","),
                            },
                            {
                                cabecalho: "Injetado/Excedente (kWh)",
                                valor: parseFloat(totalEnergiaExcedente || 0)
                                    .toFixed(0)
                                    .replace(".", ","),
                            },
                        ]}
                    />
                </Td>
                <Td fontSize="0.85rem" colSpan={7} fontWeight="bold"></Td>
                <Td fontSize="0.85rem" fontWeight="bold">
                    <TableInCell
                        lista={[
                            {
                                cabecalho: "Custo de Energia ACL (R$)",
                                valor: parseFloat(totalCustoEnergiaACL || 0)
                                    .toFixed(0)
                                    .replace(".", ","),
                            },
                            {
                                cabecalho: "Custo de Gestão ACL (R$)",
                                valor: parseFloat(totalCustoGestaoACL || 0)
                                    .toFixed(0)
                                    .replace(".", ","),
                            },
                            {
                                cabecalho: "Tx. de Contribuição CCEE (R$)",
                                valor: parseFloat(totalContribuicaoAssociativaCCEE || 0)
                                    .toFixed(0)
                                    .replace(".", ","),
                            },
                        ]}
                    />
                </Td>
                <Td fontSize="0.85rem" fontWeight="bold">
                    <TableInCell
                        lista={[
                            {
                                cabecalho: "Importado (R$)",
                                valor: parseFloat(totalValorImportado || 0)
                                    .toFixed(0)
                                    .replace(".", ","),
                            },
                            {
                                cabecalho: "Fatura (R$)",
                                valor: parseFloat(totalValorFatura || 0)
                                    .toFixed(0)
                                    .replace(".", ","),
                            },
                        ]}
                    />
                </Td>
                <Td fontSize="0.85rem" fontWeight="bold">
                    <TableInCell
                        lista={[
                            {
                                cabecalho: "Total (kWh)",
                                valor: parseFloat(totalenergia || 0)
                                    .toFixed(0)
                                    .replace(".", ","),
                            },
                            {
                                cabecalho: "Ponta (kWh)",
                                valor: parseFloat(totalEnergiaPonta || 0)
                                    .toFixed(0)
                                    .replace(".", ","),
                            },
                            {
                                cabecalho: "Fora Ponta (kWh)",
                                valor: parseFloat(totalEnergiaForaPonta || 0)
                                    .toFixed(0)
                                    .replace(".", ","),
                            },
                            {
                                cabecalho: "Reservado (kWh)",
                                valor: parseFloat(totalEnergiaReservado || 0)
                                    .toFixed(0)
                                    .replace(".", ","),
                            },
                        ]}
                    />
                </Td>
                <Td fontSize="0.85rem" fontWeight="bold">
                    <TableInCell
                        lista={[
                            {
                                cabecalho: "Ponta",
                                valores: [
                                    parseFloat(totalDemandaPonta || 0)
                                        .toFixed(0)
                                        .replace(".", ",") + " kW",
                                    "R$ " +
                                        parseFloat(totalValorDemandaPonta || 0)
                                            .toFixed(0)
                                            .replace(".", ","),
                                ],
                            },
                            {
                                cabecalho: "Fora Ponta",
                                valores: [
                                    parseFloat(totalDemanda || 0)
                                        .toFixed(0)
                                        .replace(".", ",") + " kW",
                                    "R$ " +
                                        parseFloat(totalValorDemanda || 0)
                                            .toFixed(0)
                                            .replace(".", ","),
                                ],
                            },
                            {
                                cabecalho: "Reservado",
                                valores: [
                                    parseFloat(totalDemandaReservado || 0)
                                        .toFixed(0)
                                        .replace(".", ",") + " kW",
                                    "R$ " +
                                        parseFloat(totalValorDemandaReservado || 0)
                                            .toFixed(0)
                                            .replace(".", ","),
                                ],
                            },
                            {
                                cabecalho: "Demanda G",
                                valores: [
                                    parseFloat(totalDemandaGeracao || 0)
                                        .toFixed(0)
                                        .replace(".", ",") + " kW",
                                    "R$ " +
                                        parseFloat(totalValorDemandaGeracao || 0)
                                            .toFixed(0)
                                            .replace(".", ","),
                                ],
                            },
                        ]}
                    />
                </Td>
                <Td fontSize="0.85rem" fontWeight="bold" alignCenter>
                    {parseFloat(totalEnergiaReativo || 0)
                        .toFixed(0)
                        .replace(".", ",")}
                </Td>
                <Td fontSize="0.85rem" fontWeight="bold" alignCenter>
                    {parseFloat(totalGastoReativo || 0)
                        .toFixed(0)
                        .replace(".", ",")}
                </Td>
                <Td fontSize="0.85rem"></Td>
                <Td fontSize="0.85rem"></Td>
                <Td fontSize="0.85rem"></Td>
            </Tr>
        );
        return rodape;
    }
}

const mapStateToProps = (state) => {
    return {
        sessao: state.auth.usuario,
        usuarioLogado: state.auth.usuarioLogado,
        modoTela: state.fatura.modoTela,
        aguardando: state.fatura.aguardando,
        filtro: state.fatura.filtro,
        lista: state.fatura.lista,
        listaCliente: state.fatura.listaCliente,
        listaConcessionaria: state.fatura.listaConcessionaria,
        listaUnidadeConsumidora: state.fatura.listaUnidadeConsumidora,
        listaProdutoTipo: state.fatura.listaProdutoTipo,
        listaGrupoFaturamento: state.cliente.listaGrupoFaturamento,
        listaMeses: state.fatura.listaMeses,
        listaProducaoSolar: state.fatura.listaProducaoSolar,
        pdf: state.displayPDF.pdf,
        nomePdf: state.displayPDF.nomePdf,
        orientation: state.displayPDF.orientation,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setModoTela,
            initForm,
            setFiltro,
            salvar,
            salvarMultiplos,
            excluir,
            getLista,
            reprocessar,
            reprocessarTodas,
            buscaPdf,
            setArquivoPDF,
            executarManutencao,
            setAguardando,
            getListaGrupoFaturamento,
            resetarFiltro,
        },
        dispatch,
    );
export default connect(mapStateToProps, mapDispatchToProps)(Fatura);

import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ContentCard from "../../common/template/contentCard";
import ContentCardHeader from "../../common/template/contentCardHeader";
import ContentCardBody from "../../common/template/contentCardBody";
import Row from "../../common/layout/row";
import Grid from "../../common/layout/grid";
import Table from "../../common/table/table";
import THead from "../../common/table/tHead";
import Tr from "../../common/table/tr";
import Th from "../../common/table/th";
import TBody from "../../common/table/tBody";
import Td from "../../common/table/td";
import Button from "../../common/button/button";
import FormatUtils from "../../common/formatUtils/FormatUtils";
import Select from "../../common/form/select";
import {
	getDemanda,
	getListaGraficoCustoAnualPeriodoSafra,
	getListaGraficoConsumoAnualPeriodoSafra,
	getListaGraficoTarifaMediaPeriodoSafra,
	getListaGraficoTarifaMediaGeralPeriodoSafra,
	getListaGraficoCustoPorUnidadeAnualPeriodoSafra,
	getListaGraficoCustoPorUnidadeMensalPeriodoSafra,
	getListaGraficoCustoReativoPeriodoSafra,
	getListaGraficoCustoPorHorarioAnualPeriodoSafra,
	getListaGraficoConsumoPorHorarioAnualPeriodoSafra,
	getListaGraficoCustoDemandaEnergiaConsumidaPeriodoSafra,
	getListaGraficoCustoPorUnidadeAcumuladoAnoPeriodoSafra,
	getListaGraficoCustoReativoPorUnidadeAcumuladoAnoPeridoSafra,
	setFiltro,
} from "../analiseActions";
import { montarGraficoPieAnual } from "../../common/graficos/montarGraficoPieAnual.js";
import { montarGraficoClusteredColumnMensalSafra } from "../../common/graficos/montarGraficoClusteredColumnMensalSafra.js";
import { montarGraficoClusteredColumnAnual } from "../../common/graficos/montarGraficoClusteredColumnAnual.js";
import { montarGraficoClusteredColumnGrupo } from "../../common/graficos/montarGraficoClusteredColumnGrupo.js";
import { montarGraficoHorizontalBar } from "../../common/graficos/montarGraficoHorizontalBar.js";

function analisePeriodoSafra(props) {
    const {
        state,
        listar,
        filtro,
        contratoDemanda,
        listaUnidadeConsumidora,
        listaGraficoCustoAnualPeriodoSafra,
        listaGraficoConsumoAnualPeriodoSafra,
        listaGraficoTarifaMediaPeriodoSafra,
        listaGraficoTarifaMediaGeralPeriodoSafra,
        listaGraficoCustoUnidadeAnualPeriodoSafra,
        listaGraficoCustoUnidadeMensalPeriodoSafra,
        listaGraficoCustoReativoPeriodoSafra,
        listaGraficoCustoPorHorarioAnualPeriodoSafra,
        listaGraficoConsumoPorHorarioAnualPeriodoSafra,
        listaGraficoCustoDemandaEnergiaConsumidaPeriodoSafra,
        listaGraficoCustoPorUnidadeAcumuladoAnoPeriodoSafra,
        listaGraficoCustoReativoPorUnidadeAcumuladoAnoPeriodoSafra,
        getDemanda,
        getListaGraficoCustoAnualPeriodoSafra,
        getListaGraficoConsumoAnualPeriodoSafra,
        getListaGraficoTarifaMediaPeriodoSafra,
        getListaGraficoTarifaMediaGeralPeriodoSafra,
        getListaGraficoCustoPorUnidadeAnualPeriodoSafra,
        getListaGraficoCustoPorUnidadeMensalPeriodoSafra,
        getListaGraficoCustoReativoPeriodoSafra,
        getListaGraficoCustoPorHorarioAnualPeriodoSafra,
        getListaGraficoConsumoPorHorarioAnualPeriodoSafra,
        getListaGraficoCustoDemandaEnergiaConsumidaPeriodoSafra,
        getListaGraficoCustoPorUnidadeAcumuladoAnoPeriodoSafra,
        getListaGraficoCustoReativoPorUnidadeAcumuladoAnoPeridoSafra,
        setAguardando,
        setFiltro,
    } = props;

    const [graficoCustoAnualPeriodoSafra, setGraficoCustoAnualPeriodoSafra] = useState(null);
    const [graficoCustoMensalPeriodoSafra, setGraficoCustoMensalPeriodoSafra] = useState(null);
    const [graficoConsumoAnualPeriodoSafra, setGraficoConsumoAnualPeriodoSafra] = useState(null);
    const [graficoConsumoMensalPeriodoSafra, setGraficoConsumoMensalPeriodoSafra] = useState(null);
    const [graficoTarifaMediaMensalPeriodoSafra, setGraficoTarifaMediaMensalPeriodoSafra] = useState(null);
    const [graficoTarifaMediaMensalGeralPeriodoSafra, setGraficoTarifaMediaMensalGeralPeriodoSafra] = useState(null);
    const [graficoCustoUnidadeAnualPeriodoSafra, setGraficoCustoUnidadeAnualPeriodoSafra] = useState(null);
    const [graficoCustoUnidadeMensalPeriodoSafra, setGraficoCustoUnidadeMensalPeriodoSafra] = useState(null);
    const [graficoCustoReativoAnualPeriodoSafra, setGraficoCustoReativoAnualPeriodoSafra] = useState(null);
    const [graficoCustoReativoMensalPeriodoSafra, setGraficoCustoReativoMensalPeriodoSafra] = useState(null);
    const [graficoCustoHorarioAnualPeriodoSafra, setGraficoCustoHorarioAnualPeriodoSafra] = useState(null);
    const [graficoConsumoHorarioAnualPeriodoSafra, setGraficoConsumoHorarioAnualPeriodoSafra] = useState(null);
    const [graficoCustoDemandaEnergiaConsumidaAnualPeriodoSafra, setGraficoCustoDemandaEnergiaConsumidaAnualPeriodoSafra] = useState(null);
    const [graficoCustoPorUnidadeAcumuladoAnoPeriodoSafra, setGraficoCustoPorUnidadeAcumuladoAnoPeriodoSafra] = useState(null);
    const [graficoCustoReativoPorUnidadeAcumuladoAnoPeriodoSafra, setGraficoCustoReativoPorUnidadeAcumuladoAnoPeriodoSafra] = useState(null);

    useEffect(() => {
        atualizarTodosGraficos();
        return desmontarTodosGraficos;
    }, []);

    useEffect(() => {
        const buscarDados = async () => {
            if (!state.aguardando) return;

            await Promise.all([
                getDemanda(),
                getListaGraficoCustoAnualPeriodoSafra(),
                getListaGraficoConsumoAnualPeriodoSafra(),
                getListaGraficoTarifaMediaPeriodoSafra(),
                getListaGraficoTarifaMediaGeralPeriodoSafra(),
                getListaGraficoCustoPorUnidadeAnualPeriodoSafra(),
                getListaGraficoCustoPorUnidadeMensalPeriodoSafra(),
                getListaGraficoCustoReativoPeriodoSafra(),
                getListaGraficoCustoPorHorarioAnualPeriodoSafra(),
                getListaGraficoConsumoPorHorarioAnualPeriodoSafra(),
                getListaGraficoCustoDemandaEnergiaConsumidaPeriodoSafra(),
                getListaGraficoCustoPorUnidadeAcumuladoAnoPeriodoSafra(),
                getListaGraficoCustoReativoPorUnidadeAcumuladoAnoPeridoSafra(),
            ]);

            setAguardando(false);
        };

        buscarDados();
       if (!state.aguardando) {
           atualizarTodosGraficos();
       }
    }, [state.aguardando]);

    function atualizarTodosGraficos() {
        atualizarListaGraficoCustoAnualPeriodoSafra();
        atualizarListaGraficoConsumoAnualPeriodoSafra();
        atualizarListaGraficoTarifaMediaPeriodoSafra();
        atualizarListaGraficoTarifaMediaGeralPeriodoSafra();
        atualizarListaGraficoCustoPorUnidadeAnualPeriodoSafra();
        atualizarListaGraficoCustoPorUnidadeMensalPeriodoSafra();
        atualizarListaGraficoCustoReativoPeriodoSafra();
        atualizarListaGraficoCustoPorHorarioAnualPeriodoSafra();
        atualizarListaGraficoConsumoPorHorarioAnualPeriodoSafra();
        atualizarListaGraficoCustoDemandaEnergiaConsumidaPeriodoSafra();
        atualizarListaGraficoCustoPorUnidadeAcumuladoAnoPeriodoSafra();
        atualizarListaGraficoCustoReativoPorUnidadeAcumuladoAnoPeriodoSafra();
    }
    function desmontarTodosGraficos() {
        if (graficoCustoAnualPeriodoSafra) graficoCustoAnualPeriodoSafra.dispose();
        if (graficoCustoMensalPeriodoSafra) graficoCustoMensalPeriodoSafra.dispose();
        if (graficoConsumoAnualPeriodoSafra) graficoConsumoAnualPeriodoSafra.dispose();
        if (graficoConsumoMensalPeriodoSafra) graficoConsumoMensalPeriodoSafra.dispose();
        if (graficoTarifaMediaMensalPeriodoSafra) graficoTarifaMediaMensalPeriodoSafra.dispose();
        if (graficoTarifaMediaMensalGeralPeriodoSafra) graficoTarifaMediaMensalGeralPeriodoSafra.dispose();
        if (graficoCustoUnidadeAnualPeriodoSafra) graficoCustoUnidadeAnualPeriodoSafra.dispose();
        if (graficoCustoUnidadeMensalPeriodoSafra) graficoCustoUnidadeMensalPeriodoSafra.dispose();
        if (graficoCustoReativoAnualPeriodoSafra) graficoCustoReativoAnualPeriodoSafra.dispose();
        if (graficoCustoReativoMensalPeriodoSafra) graficoCustoReativoMensalPeriodoSafra.dispose();
        if (graficoCustoHorarioAnualPeriodoSafra) graficoCustoHorarioAnualPeriodoSafra.dispose();
        if (graficoConsumoHorarioAnualPeriodoSafra) graficoConsumoHorarioAnualPeriodoSafra.dispose();
        if (graficoCustoDemandaEnergiaConsumidaAnualPeriodoSafra) graficoCustoDemandaEnergiaConsumidaAnualPeriodoSafra.dispose();
        if (graficoCustoPorUnidadeAcumuladoAnoPeriodoSafra) graficoCustoPorUnidadeAcumuladoAnoPeriodoSafra.dispose();
        if (graficoCustoReativoPorUnidadeAcumuladoAnoPeriodoSafra) graficoCustoReativoPorUnidadeAcumuladoAnoPeriodoSafra.dispose();
    }

    function atualizarListaGraficoCustoAnualPeriodoSafra() {
        const graficoCustoAnualPeriodoSafraAtualizado = montarGraficoClusteredColumnAnual(
            state,
            graficoCustoAnualPeriodoSafra,
            "graficoCustoAnualPeriodoSafra",
            listaGraficoCustoAnualPeriodoSafra,
            "Custo Total Período Safra (R$)",
            "R$",
            ""
        );
        setGraficoCustoAnualPeriodoSafra(graficoCustoAnualPeriodoSafraAtualizado);
        const graficoCustoMensalPeriodoSafraAtualizado = montarGraficoClusteredColumnMensalSafra(
            state,
            graficoCustoMensalPeriodoSafra,
            "graficoCustoMensalPeriodoSafra",
            listaGraficoCustoAnualPeriodoSafra,
            "Custo Mensal Período Safra (R$)",
            "R$",
            ""
        );
        setGraficoCustoMensalPeriodoSafra(graficoCustoMensalPeriodoSafraAtualizado);
    }

    function atualizarListaGraficoConsumoAnualPeriodoSafra() {
        const graficoConsumoAnualPeriodoSafraAtualizado = montarGraficoClusteredColumnAnual(
            state,
            graficoConsumoAnualPeriodoSafra,
            "graficoConsumoAnualPeriodoSafra",
            listaGraficoConsumoAnualPeriodoSafra,
            "Consumo Total Período Safra (kWh)",
            "kWh",
            ""
        );
        setGraficoConsumoAnualPeriodoSafra(graficoConsumoAnualPeriodoSafraAtualizado);

        const graficoConsumoMensalPeriodoSafraAtualizado = montarGraficoClusteredColumnMensalSafra(
            state,
            graficoConsumoMensalPeriodoSafra,
            "graficoConsumoMensalPeriodoSafra",
            listaGraficoConsumoAnualPeriodoSafra,
            "Consumo Mensal Período Safra (kWh)",
            "kWh",
            ""
        );
        setGraficoConsumoMensalPeriodoSafra(graficoConsumoMensalPeriodoSafraAtualizado);
    }

    function atualizarListaGraficoTarifaMediaPeriodoSafra() {
        const graficoTarifaMediaMensalPeriodoSafraAtualizado = montarGraficoClusteredColumnMensalSafra(
            state,
            graficoTarifaMediaMensalPeriodoSafra,
            "graficoTarifaMediaMensalPeriodoSafra",
            listaGraficoTarifaMediaPeriodoSafra,
            "Tarifa Média Mensal Período Safra (Sem Demanda e Reativos) (R$)",
            "R$",
            ""
        );
        setGraficoTarifaMediaMensalPeriodoSafra(graficoTarifaMediaMensalPeriodoSafraAtualizado);
    }

    function atualizarListaGraficoTarifaMediaGeralPeriodoSafra() {
        const graficoTarifaMediaMensalGeralPeriodoSafraAtualizado = montarGraficoClusteredColumnMensalSafra(
            state,
            graficoTarifaMediaMensalGeralPeriodoSafra,
            "graficoTarifaMediaMensalGeralPeriodoSafra",
            listaGraficoTarifaMediaGeralPeriodoSafra,
            "Tarifa Média Mensal Geral Período Safra (com demanda e reativos) (R$)",
            "R$",
            ""
        );
        setGraficoTarifaMediaMensalGeralPeriodoSafra(graficoTarifaMediaMensalGeralPeriodoSafraAtualizado);
    }

    function atualizarListaGraficoCustoPorUnidadeAnualPeriodoSafra() {
        const graficoCustoUnidadeAnualPeriodoSafraAtualizado = montarGraficoClusteredColumnGrupo(
            state,
            graficoCustoUnidadeAnualPeriodoSafra,
            "graficoCustoUnidadeAnualPeriodoSafra",
            listaGraficoCustoUnidadeAnualPeriodoSafra.filter(
                (item) =>
                    !(filtro.unidades_custo_anual_periodo_safra || []).length ||
                    (filtro.unidades_custo_anual_periodo_safra || []).find((itemFiltro) => itemFiltro.label == item.agrupador)
            ),
            "Custo Total por Unidade Período Safra (R$)",
            "R$",
            ""
        );
        setGraficoCustoUnidadeAnualPeriodoSafra(graficoCustoUnidadeAnualPeriodoSafraAtualizado);
    }

    function atualizarListaGraficoCustoPorUnidadeMensalPeriodoSafra() {
        const graficoCustoUnidadeMensalPeriodoSafraAtualizado = montarGraficoClusteredColumnMensalSafra(
            state,
            graficoCustoUnidadeMensalPeriodoSafra,
            "graficoCustoUnidadeMensalPeriodoSafra",
            listaGraficoCustoUnidadeMensalPeriodoSafra.map((item) => ({
                mes: item.mes,
                anos: item.tipos
                    .filter(
                        (tipo) =>
                            !(filtro.unidades_custo_anual_periodo_safra || []).length ||
                            (filtro.unidades_custo_anual_periodo_safra || []).find((itemFiltro) => itemFiltro.label == tipo.tipo)
                    )
                    .map((tipo) => ({
                        ano: tipo.tipo.substring(0, 20) + (tipo.tipo.length > 20 ? "..." : ""),
                        valor: tipo.valor,
                    })),
            })),
            "Custo Mensal por Unidade Período Safra (R$)",
            "R$",
            ""
        );
        setGraficoCustoUnidadeMensalPeriodoSafra(graficoCustoUnidadeMensalPeriodoSafraAtualizado);
    }

    function atualizarListaGraficoCustoReativoPeriodoSafra() {
        const graficoCustoReativoAnualPeriodoSafraAtualizado = montarGraficoClusteredColumnAnual(
            state,
            graficoCustoReativoAnualPeriodoSafra,
            "graficoCustoReativoAnualPeriodoSafra",
            listaGraficoCustoReativoPeriodoSafra,
            "Custo Reativo Total Período Safra (R$)",
            "R$",
            ""
        );
        setGraficoCustoReativoAnualPeriodoSafra(graficoCustoReativoAnualPeriodoSafraAtualizado);

        const graficoCustoReativoMensalPeriodoSafraAtualizado = montarGraficoClusteredColumnMensalSafra(
            state,
            graficoCustoReativoMensalPeriodoSafra,
            "graficoCustoReativoMensalPeriodoSafra",
            listaGraficoCustoReativoPeriodoSafra,
            "Custo Reativo Mensal Período Safra (R$)",
            "R$",
            ""
        );
        setGraficoCustoReativoMensalPeriodoSafra(graficoCustoReativoMensalPeriodoSafraAtualizado);
    }

    function atualizarListaGraficoCustoPorHorarioAnualPeriodoSafra() {
        const graficoCustoHorarioAnualPeriodoSafraAtualizado = montarGraficoPieAnual(
            state,
            graficoCustoHorarioAnualPeriodoSafra,
            "graficoCustoHorarioAnualPeriodoSafra",
            listaGraficoCustoPorHorarioAnualPeriodoSafra,
            "Custo Total por Horário Período Safra (R$)",
            "R$",
            ""
        );
        setGraficoCustoHorarioAnualPeriodoSafra(graficoCustoHorarioAnualPeriodoSafraAtualizado);
    }

    function atualizarListaGraficoConsumoPorHorarioAnualPeriodoSafra() {
        const graficoConsumoHorarioAnualPeriodoSafraAtualizado = montarGraficoPieAnual(
            state,
            graficoConsumoHorarioAnualPeriodoSafra,
            "graficoConsumoHorarioAnualPeriodoSafra",
            listaGraficoConsumoPorHorarioAnualPeriodoSafra,
            "Consumo Total por Horário Período Safra (kWh)",
            "",
            "kWh"
        );
        setGraficoConsumoHorarioAnualPeriodoSafra(graficoConsumoHorarioAnualPeriodoSafraAtualizado);
        // graficoConsumoHorarioBarraAnual = montarGraficoClusteredColumnAnual(state,graficoConsumoHorarioBarraAnual, 'graficoConsumoHorarioBarraAnual', props.listaGraficoConsumoHorarioAnual.map(item => ({
        // 	mes: item.mes,
        // 	anos: item.tipos.map(tipo => ({
        // 		ano: tipo.tipo,
        // 		valor: tipo.valor
        // 	}))
        // })), 'Consumo Total Anual (kWh)', '', 'kWh');
    }

    function atualizarListaGraficoCustoDemandaEnergiaConsumidaPeriodoSafra() {
        const graficoCustoDemandaEnergiaConsumidaAnualPeriodoSafraAtualizado = montarGraficoPieAnual(
            state,
            graficoCustoDemandaEnergiaConsumidaAnualPeriodoSafra,
            "graficoCustoDemandaEnergiaConsumidaAnualPeriodoSafra",
            listaGraficoCustoDemandaEnergiaConsumidaPeriodoSafra,
            "Demanda X Energia Consumida Período Safra (R$)",
            "R$",
            ""
        );
        setGraficoCustoDemandaEnergiaConsumidaAnualPeriodoSafra(graficoCustoDemandaEnergiaConsumidaAnualPeriodoSafraAtualizado);
    }

    function atualizarListaGraficoCustoPorUnidadeAcumuladoAnoPeriodoSafra() {
        const graficoCustoPorUnidadeAcumuladoAnoPeriodoSafraAtualizado = montarGraficoHorizontalBar({
            component: graficoCustoPorUnidadeAcumuladoAnoPeriodoSafra,
            chartDivId: "graficoCustoAcumuladoAnualPorUcPeriodoSafra",
            category: "agrupador",
            value: "valor",
            bullet: "valor",
            bulletPosition: "out",
            bulletColor: "#fff",
            lista: listaGraficoCustoPorUnidadeAcumuladoAnoPeriodoSafra.sort((a, b) => a.valor - b.valor),
            titulo: "Custo Acumulado Anual por UC Período Safra(R$)",
            prefixo: "R$",
            sufixo: "",
        });
        setGraficoCustoPorUnidadeAcumuladoAnoPeriodoSafra(graficoCustoPorUnidadeAcumuladoAnoPeriodoSafraAtualizado);
    }

    function atualizarListaGraficoCustoReativoPorUnidadeAcumuladoAnoPeriodoSafra() {
        const graficoCustoReativoPorUnidadeAcumuladoAnoPeridoSafraAtualizado = montarGraficoHorizontalBar({
            component: graficoCustoReativoPorUnidadeAcumuladoAnoPeriodoSafra,
            chartDivId: "graficoCustoReativoAcumuladoAnualPorUcPeriodoSafra",
            category: "agrupador",
            value: "valor",
            bullet: "valor",
            bulletPosition: "out",
            bulletColor: "#fff",
            lista: listaGraficoCustoReativoPorUnidadeAcumuladoAnoPeriodoSafra.sort((a, b) => a.valor - b.valor),
            titulo: "Custo Reativo Acumulado Anual por UC Período Safra (R$)",
            prefixo: "R$",
            sufixo: "",
        });
        setGraficoCustoReativoPorUnidadeAcumuladoAnoPeriodoSafra(graficoCustoReativoPorUnidadeAcumuladoAnoPeridoSafraAtualizado);
    }

    let demandaMesInicial = contratoDemanda && contratoDemanda.competencia_inicio ? String(contratoDemanda.competencia_inicio).substring(4, 6) : "";
    let demandaMesFinal = contratoDemanda && contratoDemanda.competencia_fim ? String(contratoDemanda.competencia_fim).substring(4, 6) : "";

    let anosCusto = [];
    listaGraficoCustoAnualPeriodoSafra.forEach((item) => {
        item.anos.forEach((ano) => {
            if (anosCusto.filter((itemAno) => itemAno == ano.ano).length <= 0) {
                anosCusto.push(ano.ano);
            }
        });
    });

    anosCusto = anosCusto.sort((a, b) => (a < b ? -1 : 1));

    let anosConsumo = [];
    listaGraficoConsumoAnualPeriodoSafra.forEach((item) => {
        item.anos.forEach((ano) => {
            if (anosConsumo.filter((itemAno) => itemAno == ano.ano).length <= 0) {
                anosConsumo.push(ano.ano);
            }
        });
    });

    anosConsumo = anosConsumo.sort((a, b) => (a < b ? -1 : 1));

    let anosTarifa = [];
    listaGraficoTarifaMediaPeriodoSafra.forEach((item) => {
        item.anos.forEach((ano) => {
            if (anosTarifa.filter((itemAno) => itemAno == ano.ano).length <= 0) {
                anosTarifa.push(ano.ano);
            }
        });
    });

    anosTarifa = anosTarifa.sort((a, b) => (a < b ? -1 : 1));

    let anosTarifaGeral = [];
    listaGraficoTarifaMediaGeralPeriodoSafra.forEach((item) => {
        item.anos.forEach((ano) => {
            if (anosTarifaGeral.filter((itemAno) => itemAno == ano.ano).length <= 0) {
                anosTarifaGeral.push(ano.ano);
            }
        });
    });

    anosTarifaGeral = anosTarifaGeral.sort((a, b) => (a < b ? -1 : 1));

    let valorUnidadeAnualTotal = 0;
    listaGraficoCustoPorUnidadeAcumuladoAnoPeriodoSafra.forEach((item) => {
        valorUnidadeAnualTotal = parseFloat(item.valor) > valorUnidadeAnualTotal ? parseFloat(item.valor) : valorUnidadeAnualTotal;
    });

    return (
        <div>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoCustoAnualPeriodoSafra" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoCustoMensalPeriodoSafra" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>{" "}
                    <div
                        style={{
                            minWidth: "890px",
                        }}
                    >
                        <Table responsive>
                            <THead>
                                <Tr>
                                    <Th>Ano</Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "06" ? "#66bb6a" : demandaMesFinal == "06" ? "#ef5350" : null,
                                        }}
                                    >
                                        Junho
                                        {demandaMesInicial == "06" ? " (I)" : demandaMesFinal == "06" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "07" ? "#66bb6a" : demandaMesFinal == "07" ? "#ef5350" : null,
                                        }}
                                    >
                                        Julho
                                        {demandaMesInicial == "07" ? " (I)" : demandaMesFinal == "07" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "08" ? "#66bb6a" : demandaMesFinal == "08" ? "#ef5350" : null,
                                        }}
                                    >
                                        Agosto
                                        {demandaMesInicial == "08" ? " (I)" : demandaMesFinal == "08" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "09" ? "#66bb6a" : demandaMesFinal == "09" ? "#ef5350" : null,
                                        }}
                                    >
                                        Setembro
                                        {demandaMesInicial == "09" ? " (I)" : demandaMesFinal == "09" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "10" ? "#66bb6a" : demandaMesFinal == "10" ? "#ef5350" : null,
                                        }}
                                    >
                                        Outubro
                                        {demandaMesInicial == "10" ? " (I)" : demandaMesFinal == "10" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "11" ? "#66bb6a" : demandaMesFinal == "11" ? "#ef5350" : null,
                                        }}
                                    >
                                        Novembro
                                        {demandaMesInicial == "11" ? " (I)" : demandaMesFinal == "11" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "12" ? "#66bb6a" : demandaMesFinal == "12" ? "#ef5350" : null,
                                        }}
                                    >
                                        Dezembro
                                        {demandaMesInicial == "12" ? " (I)" : demandaMesFinal == "12" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "01" ? "#66bb6a" : demandaMesFinal == "01" ? "#ef5350" : null,
                                        }}
                                    >
                                        Janeiro
                                        {demandaMesInicial == "01" ? " (I)" : demandaMesFinal == "01" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "02" ? "#66bb6a" : demandaMesFinal == "02" ? "#ef5350" : null,
                                        }}
                                    >
                                        Fevereiro
                                        {demandaMesInicial == "02" ? " (I)" : demandaMesFinal == "02" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "03" ? "#66bb6a" : demandaMesFinal == "03" ? "#ef5350" : null,
                                        }}
                                    >
                                        Março
                                        {demandaMesInicial == "03" ? " (I)" : demandaMesFinal == "03" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "04" ? "#66bb6a" : demandaMesFinal == "04" ? "#ef5350" : null,
                                        }}
                                    >
                                        Abril
                                        {demandaMesInicial == "04" ? " (I)" : demandaMesFinal == "04" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "05" ? "#66bb6a" : demandaMesFinal == "05" ? "#ef5350" : null,
                                        }}
                                    >
                                        Maio
                                        {demandaMesInicial == "05" ? " (I)" : demandaMesFinal == "05" ? " (F)" : ""}
                                    </Th>
                                </Tr>
                            </THead>
                            <TBody>
                                {anosCusto.map((ano) => {
                                    return (
                                        <Tr key={ano}>
                                            <Td color="#fff">{ano}</Td>
                                            {listaGraficoCustoAnualPeriodoSafra.map((mes) => {
                                                if (mes.anos.filter((anoMes) => anoMes.ano == ano).length > 0) {
                                                    return mes.anos
                                                        .filter((anoMes) => anoMes.ano == ano)
                                                        .map((anoMes) => {
                                                            return (
                                                                <Td key={anoMes.ano} alignCenter color="#fff">
                                                                    {parseFloat(anoMes.valor || 0)
                                                                        .toFixed(2)
                                                                        .replace(".", ",")}
                                                                </Td>
                                                            );
                                                        });
                                                } else {
                                                    return (
                                                        <Td key={ano} alignCenter color="#fff">
                                                            0,00
                                                        </Td>
                                                    );
                                                }
                                            })}

                                            {(() => {
                                                let listaMeses = [];
                                                for (var i = listaGraficoCustoAnualPeriodoSafra.length; i < 12; i++) {
                                                    listaMeses.push(i);
                                                }

                                                return listaMeses.map((mes) => (
                                                    <Td key={mes} alignCenter color="#fff">
                                                        0,00
                                                    </Td>
                                                ));
                                            })()}
                                        </Tr>
                                    );
                                })}
                            </TBody>
                        </Table>
                    </div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoConsumoAnualPeriodoSafra" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoConsumoMensalPeriodoSafra" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>{" "}
                    <div
                        style={{
                            minWidth: "890px",
                        }}
                    >
                        <Table responsive>
                            <THead>
                                <Tr>
                                    <Th>Ano</Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "06" ? "#66bb6a" : demandaMesFinal == "06" ? "#ef5350" : null,
                                        }}
                                    >
                                        Junho
                                        {demandaMesInicial == "06" ? " (I)" : demandaMesFinal == "06" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "07" ? "#66bb6a" : demandaMesFinal == "07" ? "#ef5350" : null,
                                        }}
                                    >
                                        Julho
                                        {demandaMesInicial == "07" ? " (I)" : demandaMesFinal == "07" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "08" ? "#66bb6a" : demandaMesFinal == "08" ? "#ef5350" : null,
                                        }}
                                    >
                                        Agosto
                                        {demandaMesInicial == "08" ? " (I)" : demandaMesFinal == "08" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "09" ? "#66bb6a" : demandaMesFinal == "09" ? "#ef5350" : null,
                                        }}
                                    >
                                        Setembro
                                        {demandaMesInicial == "09" ? " (I)" : demandaMesFinal == "09" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "10" ? "#66bb6a" : demandaMesFinal == "10" ? "#ef5350" : null,
                                        }}
                                    >
                                        Outubro
                                        {demandaMesInicial == "10" ? " (I)" : demandaMesFinal == "10" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "11" ? "#66bb6a" : demandaMesFinal == "11" ? "#ef5350" : null,
                                        }}
                                    >
                                        Novembro
                                        {demandaMesInicial == "11" ? " (I)" : demandaMesFinal == "11" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "12" ? "#66bb6a" : demandaMesFinal == "12" ? "#ef5350" : null,
                                        }}
                                    >
                                        Dezembro
                                        {demandaMesInicial == "12" ? " (I)" : demandaMesFinal == "12" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "01" ? "#66bb6a" : demandaMesFinal == "01" ? "#ef5350" : null,
                                        }}
                                    >
                                        Janeiro
                                        {demandaMesInicial == "01" ? " (I)" : demandaMesFinal == "01" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "02" ? "#66bb6a" : demandaMesFinal == "02" ? "#ef5350" : null,
                                        }}
                                    >
                                        Fevereiro
                                        {demandaMesInicial == "02" ? " (I)" : demandaMesFinal == "02" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "03" ? "#66bb6a" : demandaMesFinal == "03" ? "#ef5350" : null,
                                        }}
                                    >
                                        Março
                                        {demandaMesInicial == "03" ? " (I)" : demandaMesFinal == "03" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "04" ? "#66bb6a" : demandaMesFinal == "04" ? "#ef5350" : null,
                                        }}
                                    >
                                        Abril
                                        {demandaMesInicial == "04" ? " (I)" : demandaMesFinal == "04" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "05" ? "#66bb6a" : demandaMesFinal == "05" ? "#ef5350" : null,
                                        }}
                                    >
                                        Maio
                                        {demandaMesInicial == "05" ? " (I)" : demandaMesFinal == "05" ? " (F)" : ""}
                                    </Th>
                                </Tr>
                            </THead>
                            <TBody>
                                {anosConsumo.map((ano) => {
                                    return (
                                        <Tr key={ano}>
                                            <Td color="#fff">{ano}</Td>
                                            {listaGraficoConsumoAnualPeriodoSafra.map((mes) => {
                                                if (mes.anos.filter((anoMes) => anoMes.ano == ano).length > 0) {
                                                    return mes.anos
                                                        .filter((anoMes) => anoMes.ano == ano)
                                                        .map((anoMes) => {
                                                            return (
                                                                <Td key={anoMes.ano} alignCenter color="#fff">
                                                                    {parseFloat(anoMes.valor || 0)
                                                                        .toFixed(0)
                                                                        .replace(".", ",")}
                                                                </Td>
                                                            );
                                                        });
                                                } else {
                                                    return (
                                                        <Td key={ano} alignCenter color="#fff">
                                                            0
                                                        </Td>
                                                    );
                                                }
                                            })}

                                            {(() => {
                                                let listaMeses = [];
                                                for (var i = listaGraficoConsumoAnualPeriodoSafra.length; i < 12; i++) {
                                                    listaMeses.push(i);
                                                }

                                                return listaMeses.map((mes) => (
                                                    <Td key={mes} alignCenter color="#fff">
                                                        0
                                                    </Td>
                                                ));
                                            })()}
                                        </Tr>
                                    );
                                })}
                            </TBody>
                        </Table>
                    </div>
                </ContentCardBody>
            </ContentCard>

            <ContentCard style={{ padding: 8 }}>
                <ContentCardHeader style={{ backgroundColor: "#fff" }}>
                    <Row>
                        <Select
                            options={listaUnidadeConsumidora.filter((item) => (filtro.id_cliente ? item.id_cliente == filtro.id_cliente : true))}
                            label="Unidades Consumidoras"
                            cols="12 12 12 12"
                            placeholder="Selecione as UCs"
                            isMulti={true}
                            value={filtro.unidades_custo_anual_periodo_safra}
                            onChange={(value) => {
                                setFiltro({
                                    ...filtro,
                                    unidades_custo_anual_periodo_safra: value,
                                });
                            }}
                        />

                        <Select
                            options={Array.from({ length: 7 }, (v, i) => ({
                                id: new Date().getFullYear() - 6 + i - 1,
                                valor: `${new Date().getFullYear() - 6 + i - 1} - ${new Date().getFullYear() - 6 + i}`,
                            }))}
                            label="Período"
                            cols="6 4 3 2"
                            placeholder="Selecione o período"
                            value={filtro.ano_safra_custo_anual_periodo_safra}
                            onChange={(value) => {
                                setFiltro({
                                    ...filtro,
                                    ano_safra_custo_anual_periodo_safra: value,
                                });
                            }}
                        />
                        <Grid cols="6 4 3 2" style={{ display: "flex", alignItems: "center", paddingTop: 10 }}>
                            <Button text="Atualizar" type={"primary"} icon={"fa fa-sync"} event={listar} />
                        </Grid>
                    </Row>
                </ContentCardHeader>
                <ContentCardBody
                    style={{
                        color: "#fff",
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoCustoUnidadeAnualPeriodoSafra" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoCustoUnidadeMensalPeriodoSafra" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>{" "}
                    <div
                        style={{
                            minWidth: "890px",
                        }}
                    >
                        <Table responsive>
                            <THead>
                                <Tr>
                                    <Th width={200}>UC</Th>
                                    <Th alignCenter>Agosto</Th>
                                    <Th alignCenter>Setembro</Th>
                                    <Th alignCenter>Outubro</Th>
                                    <Th alignCenter>Novembro</Th>
                                    <Th alignCenter>Dezembro</Th>
                                    <Th alignCenter>Janeiro</Th>
                                    <Th alignCenter>Fevereiro</Th>
                                    <Th alignCenter>Março</Th>
                                    <Th alignCenter>Abril</Th>
                                    <Th alignCenter>Maio</Th>
                                    <Th alignCenter>Junho</Th>
                                    <Th alignCenter>Julho</Th>
                                </Tr>
                            </THead>
                            <TBody>
                                {((listaGraficoCustoUnidadeMensalPeriodoSafra[0] && listaGraficoCustoUnidadeMensalPeriodoSafra[0].tipos) || [])
                                    .sort((a, b) => a.tipo - b.tipo)
                                    .filter(
                                        (tipo) =>
                                            (filtro.unidades_custo_anual || []).length == 0 ||
                                            (filtro.unidades_custo_anual || []).filter((itemFiltro) => itemFiltro.label == tipo.tipo).length > 0
                                    )
                                    .map((tipo) => (
                                        <Tr key={tipo.tipo}>
                                            <Td color="#fff">{tipo.tipo}</Td>
                                            {listaGraficoCustoUnidadeMensalPeriodoSafra.map((mes) => {
                                                if (mes.tipos.filter((tipoMes) => tipoMes.tipo == tipo.tipo).length > 0) {
                                                    return mes.tipos
                                                        .filter((tipoMes) => tipoMes.tipo == tipo.tipo)
                                                        .map((tipoMes) => {
                                                            return (
                                                                <Td key={tipoMes.tipo} alignCenter color="#fff">
                                                                    {parseFloat(tipoMes.valor || 0)
                                                                        .toFixed(2)
                                                                        .replace(".", ",")}
                                                                </Td>
                                                            );
                                                        });
                                                } else {
                                                    return (
                                                        <Td key={tipo.tipo} alignCenter color="#fff">
                                                            0,00
                                                        </Td>
                                                    );
                                                }
                                            })}

                                            {(() => {
                                                let listaMeses = [];
                                                for (var i = listaGraficoCustoUnidadeMensalPeriodoSafra.length; i < 12; i++) {
                                                    listaMeses.push(i);
                                                }

                                                return listaMeses.map((mes) => (
                                                    <Td key={mes} alignCenter color="#fff">
                                                        0,00
                                                    </Td>
                                                ));
                                            })()}
                                        </Tr>
                                    ))}
                            </TBody>
                        </Table>
                    </div>
                </ContentCardBody>
            </ContentCard>

            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoTarifaMediaMensalPeriodoSafra" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>{" "}
                    <div
                        style={{
                            minWidth: "890px",
                        }}
                    >
                        <Table responsive>
                            <THead>
                                <Tr>
                                    <Th>Ano</Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "06" ? "#66bb6a" : demandaMesFinal == "06" ? "#ef5350" : null,
                                        }}
                                    >
                                        Junho
                                        {demandaMesInicial == "06" ? " (I)" : demandaMesFinal == "06" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "07" ? "#66bb6a" : demandaMesFinal == "07" ? "#ef5350" : null,
                                        }}
                                    >
                                        Julho
                                        {demandaMesInicial == "07" ? " (I)" : demandaMesFinal == "07" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "08" ? "#66bb6a" : demandaMesFinal == "08" ? "#ef5350" : null,
                                        }}
                                    >
                                        Agosto
                                        {demandaMesInicial == "08" ? " (I)" : demandaMesFinal == "08" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "09" ? "#66bb6a" : demandaMesFinal == "09" ? "#ef5350" : null,
                                        }}
                                    >
                                        Setembro
                                        {demandaMesInicial == "09" ? " (I)" : demandaMesFinal == "09" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "10" ? "#66bb6a" : demandaMesFinal == "10" ? "#ef5350" : null,
                                        }}
                                    >
                                        Outubro
                                        {demandaMesInicial == "10" ? " (I)" : demandaMesFinal == "10" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "11" ? "#66bb6a" : demandaMesFinal == "11" ? "#ef5350" : null,
                                        }}
                                    >
                                        Novembro
                                        {demandaMesInicial == "11" ? " (I)" : demandaMesFinal == "11" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "12" ? "#66bb6a" : demandaMesFinal == "12" ? "#ef5350" : null,
                                        }}
                                    >
                                        Dezembro
                                        {demandaMesInicial == "12" ? " (I)" : demandaMesFinal == "12" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "01" ? "#66bb6a" : demandaMesFinal == "01" ? "#ef5350" : null,
                                        }}
                                    >
                                        Janeiro
                                        {demandaMesInicial == "01" ? " (I)" : demandaMesFinal == "01" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "02" ? "#66bb6a" : demandaMesFinal == "02" ? "#ef5350" : null,
                                        }}
                                    >
                                        Fevereiro
                                        {demandaMesInicial == "02" ? " (I)" : demandaMesFinal == "02" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "03" ? "#66bb6a" : demandaMesFinal == "03" ? "#ef5350" : null,
                                        }}
                                    >
                                        Março
                                        {demandaMesInicial == "03" ? " (I)" : demandaMesFinal == "03" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "04" ? "#66bb6a" : demandaMesFinal == "04" ? "#ef5350" : null,
                                        }}
                                    >
                                        Abril
                                        {demandaMesInicial == "04" ? " (I)" : demandaMesFinal == "04" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "05" ? "#66bb6a" : demandaMesFinal == "05" ? "#ef5350" : null,
                                        }}
                                    >
                                        Maio
                                        {demandaMesInicial == "05" ? " (I)" : demandaMesFinal == "05" ? " (F)" : ""}
                                    </Th>
                                    <Th alignCenter style={{}}>
                                        Média
                                    </Th>
                                </Tr>
                            </THead>
                            <TBody>
                                {anosTarifa.map((ano, i) => {
                                    let tarifaMediaAnual = 0;
                                    let quantidadeTarifas = 0;

                                    return (
                                        <Tr key={i}>
                                            <Td color="#fff">{ano}</Td>
                                            {listaGraficoTarifaMediaPeriodoSafra.map((mes) => {
                                                if (mes.anos.filter((anoMes) => anoMes.ano == ano).length > 0) {
                                                    return mes.anos
                                                        .filter((anoMes) => anoMes.ano == ano)
                                                        .map((anoMes) => {
                                                            if (parseFloat(anoMes.valor || 0) > 0) {
                                                                tarifaMediaAnual += parseFloat(anoMes.valor || 0);
                                                                quantidadeTarifas++;
                                                            }

                                                            return (
                                                                <Td key={anoMes.ano} alignCenter color="#fff">
                                                                    {parseFloat(anoMes.valor || 0)
                                                                        .toFixed(6)
                                                                        .replace(".", ",")}
                                                                </Td>
                                                            );
                                                        });
                                                } else {
                                                    return (
                                                        <Td key={ano} alignCenter color="#fff">
                                                            0,00
                                                        </Td>
                                                    );
                                                }
                                            })}

                                            {(() => {
                                                let listaMeses = [];
                                                for (var i = listaGraficoTarifaMediaPeriodoSafra.length; i < 12; i++) {
                                                    listaMeses.push(i);
                                                }

                                                return listaMeses.map((mes) => (
                                                    <Td key={mes} alignCenter color="#fff">
                                                        0,00
                                                    </Td>
                                                ));
                                            })()}

                                            <Td alignCenter color="#fff">
                                                {(tarifaMediaAnual > 0 && quantidadeTarifas > 0 ? tarifaMediaAnual / quantidadeTarifas : 0).toFixed(6).replace(".", ",")}
                                            </Td>
                                        </Tr>
                                    );
                                })}
                            </TBody>
                        </Table>
                    </div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoTarifaMediaMensalGeralPeriodoSafra" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>{" "}
                    <div
                        style={{
                            minWidth: "890px",
                        }}
                    >
                        <Table responsive>
                            <THead>
                                <Tr>
                                    <Th>Ano</Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "06" ? "#66bb6a" : demandaMesFinal == "06" ? "#ef5350" : null,
                                        }}
                                    >
                                        Junho
                                        {demandaMesInicial == "06" ? " (I)" : demandaMesFinal == "06" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "07" ? "#66bb6a" : demandaMesFinal == "07" ? "#ef5350" : null,
                                        }}
                                    >
                                        Julho
                                        {demandaMesInicial == "07" ? " (I)" : demandaMesFinal == "07" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "08" ? "#66bb6a" : demandaMesFinal == "08" ? "#ef5350" : null,
                                        }}
                                    >
                                        Agosto
                                        {demandaMesInicial == "08" ? " (I)" : demandaMesFinal == "08" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "09" ? "#66bb6a" : demandaMesFinal == "09" ? "#ef5350" : null,
                                        }}
                                    >
                                        Setembro
                                        {demandaMesInicial == "09" ? " (I)" : demandaMesFinal == "09" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "10" ? "#66bb6a" : demandaMesFinal == "10" ? "#ef5350" : null,
                                        }}
                                    >
                                        Outubro
                                        {demandaMesInicial == "10" ? " (I)" : demandaMesFinal == "10" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "11" ? "#66bb6a" : demandaMesFinal == "11" ? "#ef5350" : null,
                                        }}
                                    >
                                        Novembro
                                        {demandaMesInicial == "11" ? " (I)" : demandaMesFinal == "11" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "12" ? "#66bb6a" : demandaMesFinal == "12" ? "#ef5350" : null,
                                        }}
                                    >
                                        Dezembro
                                        {demandaMesInicial == "12" ? " (I)" : demandaMesFinal == "12" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "01" ? "#66bb6a" : demandaMesFinal == "01" ? "#ef5350" : null,
                                        }}
                                    >
                                        Janeiro
                                        {demandaMesInicial == "01" ? " (I)" : demandaMesFinal == "01" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "02" ? "#66bb6a" : demandaMesFinal == "02" ? "#ef5350" : null,
                                        }}
                                    >
                                        Fevereiro
                                        {demandaMesInicial == "02" ? " (I)" : demandaMesFinal == "02" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "03" ? "#66bb6a" : demandaMesFinal == "03" ? "#ef5350" : null,
                                        }}
                                    >
                                        Março
                                        {demandaMesInicial == "03" ? " (I)" : demandaMesFinal == "03" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "04" ? "#66bb6a" : demandaMesFinal == "04" ? "#ef5350" : null,
                                        }}
                                    >
                                        Abril
                                        {demandaMesInicial == "04" ? " (I)" : demandaMesFinal == "04" ? " (F)" : ""}
                                    </Th>
                                    <Th
                                        alignCenter
                                        style={{
                                            backgroundColor: demandaMesInicial == "05" ? "#66bb6a" : demandaMesFinal == "05" ? "#ef5350" : null,
                                        }}
                                    >
                                        Maio
                                        {demandaMesInicial == "05" ? " (I)" : demandaMesFinal == "05" ? " (F)" : ""}
                                    </Th>
                                    <Th alignCenter style={{}}>
                                        Média
                                    </Th>
                                </Tr>
                            </THead>
                            <TBody>
                                {anosTarifaGeral.map((ano) => {
                                    let tarifaMediaAnual = 0;
                                    let quantidadeTarifas = 0;

                                    return (
                                        <Tr key={ano}>
                                            <Td color="#fff">{ano}</Td>
                                            {listaGraficoTarifaMediaGeralPeriodoSafra.map((mes) => {
                                                if (mes.anos.filter((anoMes) => anoMes.ano == ano).length > 0) {
                                                    return mes.anos
                                                        .filter((anoMes) => anoMes.ano == ano)
                                                        .map((anoMes) => {
                                                            if (parseFloat(anoMes.valor || 0) > 0) {
                                                                tarifaMediaAnual += parseFloat(anoMes.valor || 0);
                                                                quantidadeTarifas++;
                                                            }

                                                            return (
                                                                <Td key={anoMes.ano} alignCenter color="#fff">
                                                                    {parseFloat(anoMes.valor || 0)
                                                                        .toFixed(6)
                                                                        .replace(".", ",")}
                                                                </Td>
                                                            );
                                                        });
                                                } else {
                                                    return (
                                                        <Td key={ano} alignCenter color="#fff">
                                                            0,00
                                                        </Td>
                                                    );
                                                }
                                            })}

                                            {(() => {
                                                let listaMeses = [];
                                                for (var i = listaGraficoTarifaMediaGeralPeriodoSafra.length; i < 12; i++) {
                                                    listaMeses.push(i);
                                                }

                                                return listaMeses.map((mes) => (
                                                    <Td key={mes} alignCenter color="#fff">
                                                        0,00
                                                    </Td>
                                                ));
                                            })()}

                                            <Td alignCenter color="#fff">
                                                {(tarifaMediaAnual > 0 && quantidadeTarifas > 0 ? tarifaMediaAnual / quantidadeTarifas : 0).toFixed(6).replace(".", ",")}
                                            </Td>
                                        </Tr>
                                    );
                                })}
                            </TBody>
                        </Table>
                    </div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoCustoReativoAnualPeriodoSafra" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoCustoReativoMensalPeriodoSafra" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard
                style={{
                    padding: 8,
                    display: true /*!buscarPropriedadeUcFiltrada() || buscarPropriedadeUcFiltrada("irrigante")*/ ? "" : "none",
                }}
            >
                <ContentCardHeader style={{ backgroundColor: "#fff" }}>
                    <Row>
                        <Select
                            name="ano"
                            options={Array.from({ length: 20 }, (v, i) => ({
                                id: new Date().getFullYear() - 5 + i,
                                valor: `${new Date().getFullYear() - 5 + i - 1} - ${new Date().getFullYear() - 5 + i}`,
                            }))}
                            label="Ano"
                            cols="12 6 2"
                            placeholder="Selecione o ano"
                            value={filtro.anoPeriodoSafraAcumulado}
                            onChange={(value) => {
                                setFiltro({
                                    ...filtro,
                                    anoPeriodoSafraAcumulado: value,
                                });
                                listar();
                            }}
                        />
                    </Row>
                </ContentCardHeader>
                <ContentCardBody
                    style={{
                        color: "#fff",
                        backgroundColor: "#30303d",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            overflowX: "scroll",
                            justifyContent: window.innerWidth < 768 ? "left" : "center",
                        }}
                    >
                        <div id="graficoCustoHorarioAnualPeriodoSafra" style={{ minWidth: 550, paddingBlock: 20, height: "300px" }}></div>

                        <div id="graficoConsumoHorarioAnualPeriodoSafra" style={{ minWidth: 550, paddingBlock: 20, height: "300px" }}></div>

                        <div id="graficoCustoDemandaEnergiaConsumidaAnualPeriodoSafra" style={{ minWidth: 600, paddingBlock: 20, height: "300px" }}></div>
                    </div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ padding: 8 }}>
                <ContentCardBody style={{ backgroundColor: "#30303d", overflowX: "scroll", maxHeight: 400 }}>
                    <div
                        id="graficoCustoAcumuladoAnualPorUcPeriodoSafra"
                        style={{
                            width: "100%",
                            minWidth: 900,
                            height:
                                (listaGraficoCustoPorUnidadeAcumuladoAnoPeriodoSafra || []).length * 50 < 400
                                    ? 400
                                    : listaGraficoCustoPorUnidadeAcumuladoAnoPeriodoSafra.length * 50,
                        }}
                    ></div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ padding: 8 }}>
                <ContentCardBody style={{ backgroundColor: "#30303d", overflowX: "scroll", maxHeight: 400 }}>
                    <div
                        id="graficoCustoReativoAcumuladoAnualPorUcPeriodoSafra"
                        style={{
                            width: "100%",
                            minWidth: 900,
                            height:
                                (listaGraficoCustoReativoPorUnidadeAcumuladoAnoPeriodoSafra || []).length * 50 < 400
                                    ? 400
                                    : listaGraficoCustoReativoPorUnidadeAcumuladoAnoPeriodoSafra.length * 50,
                        }}
                    ></div>
                </ContentCardBody>
            </ContentCard>
        </div>
    );
}

const mapStateToProps = (state) => ({
	filtro: state.analise.filtro,
	contratoDemanda: state.analise.contratoDemanda,
	listaUnidadeConsumidora: state.analise.listaUnidadeConsumidora,
	listaGraficoCustoAnualPeriodoSafra: state.analise.listaGraficoCustoAnualPeriodoSafra,
	listaGraficoConsumoAnualPeriodoSafra: state.analise.listaGraficoConsumoAnualPeriodoSafra,
	listaGraficoTarifaMediaPeriodoSafra: state.analise.listaGraficoTarifaMediaPeriodoSafra,
	listaGraficoTarifaMediaGeralPeriodoSafra: state.analise.listaGraficoTarifaMediaGeralPeriodoSafra,
	listaGraficoCustoUnidadeAnualPeriodoSafra: state.analise.listaGraficoCustoUnidadeAnualPeriodoSafra,
	listaGraficoCustoUnidadeMensalPeriodoSafra: state.analise.listaGraficoCustoUnidadeMensalPeriodoSafra,
	listaGraficoCustoReativoPeriodoSafra: state.analise.listaGraficoCustoReativoPeriodoSafra,
	listaGraficoCustoPorHorarioAnualPeriodoSafra: state.analise.listaGraficoCustoPorHorarioAnualPeriodoSafra,
	listaGraficoConsumoPorHorarioAnualPeriodoSafra: state.analise.listaGraficoConsumoPorHorarioAnualPeriodoSafra,
	listaGraficoCustoDemandaEnergiaConsumidaPeriodoSafra: state.analise.listaGraficoCustoDemandaEnergiaConsumidaPeriodoSafra,
	listaGraficoCustoPorUnidadeAcumuladoAnoPeriodoSafra: state.analise.listaGraficoCustoPorUnidadeAcumuladoAnoPeriodoSafra,
	listaGraficoCustoReativoPorUnidadeAcumuladoAnoPeriodoSafra: state.analise.listaGraficoCustoReativoPorUnidadeAcumuladoAnoPeriodoSafra,
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getDemanda,
			getListaGraficoCustoAnualPeriodoSafra,
			getListaGraficoConsumoAnualPeriodoSafra,
			getListaGraficoTarifaMediaPeriodoSafra,
			getListaGraficoTarifaMediaGeralPeriodoSafra,
			getListaGraficoCustoPorUnidadeAnualPeriodoSafra,
			getListaGraficoCustoPorUnidadeMensalPeriodoSafra,
			getListaGraficoCustoReativoPeriodoSafra,
			getListaGraficoCustoPorHorarioAnualPeriodoSafra,
			getListaGraficoConsumoPorHorarioAnualPeriodoSafra,
			getListaGraficoCustoDemandaEnergiaConsumidaPeriodoSafra,
			getListaGraficoCustoPorUnidadeAcumuladoAnoPeriodoSafra,
			getListaGraficoCustoReativoPorUnidadeAcumuladoAnoPeridoSafra,
			setFiltro,
		},
		dispatch
	);
const AnalisePeriodoSafra = connect(mapStateToProps, mapDispatchToProps)(analisePeriodoSafra);
export { AnalisePeriodoSafra };

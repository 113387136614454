import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ContentCard from "../../common/template/contentCard";
import ContentCardBody from "../../common/template/contentCardBody";
import NumberFormat from "react-number-format";
import Table from "../../common/table/table";
import THead from "../../common/table/tHead";
import Tr from "../../common/table/tr";
import Th from "../../common/table/th";
import TBody from "../../common/table/tBody";
import Td from "../../common/table/td";
import Row from "../../common/layout/row";
import Grid from "../../common/layout/grid";
import { getListaGraficoDemanda, getDemanda, getDemandaContagem, excluirPeriodoTeste, excluirAgendamento } from "../analiseActions";
import { montarGraficoClusteredColumnLineMensal } from "../../common/graficos/montarGraficoClusteredColumnLineMensal.js";
import BotaoExcluir from "../../common/button/botaoExcluir";

function GraficoTabelaDemandaMensal(props) {
    const {
        state,
        filtro,
        contratoDemanda,
        listaAgendamentoDemanda,
        setAguardando,
        listaGraficoDemanda,
        listaDemandaContagem,
        getDemanda,
        getDemandaContagem,
        getListaGraficoDemanda,
    } = props;

    const [contagemDemanda, setContagemDemanda] = useState(null);
    const [filtroInterno, setFiltroInterno] = useState({ ...filtro });
    const [graficoDemandaMensal, setGraficoDemandaMensal] = useState(null);

    useEffect(() => {
        return desmontarTodosGraficos;
    }, []);

    useEffect(() => {
        const buscarDados = async () => {
            setFiltroInterno({ unidades_consumidoras: [] });
            if (!state.aguardando) return;
            await Promise.all([getDemanda(), getDemandaContagem(), getListaGraficoDemanda()]);
            setAguardando(false);
            setFiltroInterno({ ...filtro });
            setContagemDemanda(
                filtro.unidades_consumidoras.length == 1 ? listaDemandaContagem.find((contagem) => contagem.id_unidade_consumidora == filtro.unidades_consumidoras[0].value) : null
            );
        };

        buscarDados();
    }, [state.aguardando]);

    function desmontarTodosGraficos() {
        if (graficoDemandaMensal) graficoDemandaMensal.dispose();
    }

    function atualizarListaGraficoDemanda() {
        if (!listaGraficoDemanda.length) return;
        const exibirLinhas = filtro.unidades_consumidoras.length == 1;
        const graficoDemandaMensalAtualizado = montarGraficoClusteredColumnLineMensal(
            state,
            graficoDemandaMensal,
            "graficoDemandaMensal",
            listaGraficoDemanda.map((item) => ({
                ...item,
                anos: item.anos.map((ano) => ({
                    ...ano,
                    ano: ano.ano || "",
                })),
            })),
            "Demanda Mensal (kW)",
            "",
            "kW",
            exibirLinhas
        );
        setGraficoDemandaMensal(graficoDemandaMensalAtualizado);
    }

    function renderizarCelulasZeradas(listaGraficoDemanda, listaPeriodoTeste, ano) {
        let listaMeses = [];
        for (let i = listaGraficoDemanda.length; i < 12; i++) {
            listaMeses.push(i < 10 ? "0" + i : i);
        }

        return listaMeses.map((mes) => {
            const anoMesEstaEmTeste = !!listaPeriodoTeste.find((periodoTeste) => periodoTeste.mes === mes && periodoTeste.ano === ano);

            return (
                <Td
                    key={mes}
                    alignCenter
                    color="#fff"
                    title={mes == primeiroMesNaoSazonal && ano == primeiroAnoNaoSazonal && !ucFiltradaSazonal ? "Primeiro mês Não Sazonal" : ""}
                    style={{
                        border:
                            mes == primeiroMesNaoSazonal && ano == primeiroAnoNaoSazonal && !ucFiltradaSazonal
                                ? "3px solid #00ff51"
                                : anoMesEstaEmTeste
                                ? "3px solid #257bfb"
                                : null,
                    }}
                >
                    0,00
                </Td>
            );
        });
    }

    useEffect(atualizarListaGraficoDemanda, [listaGraficoDemanda]);

    let ucFiltradaSazonal = false;
    let demandaAnoInicial = "";
    let demandaMesInicial = "";
    let demandaAnoFinal = "";
    let demandaMesFinal = "";
    let demandaCompetenciaInicial = "";
    let demandaCompetenciaFinal = "";
    let primeiroMesNaoSazonal = "";
    let primeiroAnoNaoSazonal = "";

    //se é não sazonal, pega os últimos 12 meses,
    const mesAtualDate = new Date().getMonth(); //não soma 1 pq o mes atual não deve ser contabilizado
    const anoAtual = String(new Date().getFullYear());
    //AQUI É A LÓGICA PARA PINTAR AS CÉLULAS DE CINZA
    if (contratoDemanda) {
        const mesLimite = mesAtualDate < 10 ? "0" + mesAtualDate : String(mesAtualDate);
        const mesesDescontar = Number(mesLimite) - 11; //diminui 11 meses para pegar o mes inicial dos últimos 12 meses
        const anoInicialCalculado = mesesDescontar > 0 ? anoAtual : anoAtual - 1; //se descontando 11 meses der negativo, considera ano anterior
        const mesInicialCalculado = Number(mesLimite) + 1 > 12 ? "01" : Number(mesLimite) + 1; //mes atual 10, inicia em 10+1 do ano anterior
        const competenciaInicialCalculada = Number(String(anoInicialCalculado) + String(mesInicialCalculado)); //usada pra comparar com competencia base
        //se competencia base for maior considera ela como inicial, senão considera a calculada
        const competenciaBaseSazonalidade =
            contratoDemanda.competencia_base_sazonalidade > competenciaInicialCalculada ? contratoDemanda.competencia_base_sazonalidade : competenciaInicialCalculada;

        ucFiltradaSazonal = contratoDemanda.unidade_consumidora_sazonal;
        demandaAnoInicial = ucFiltradaSazonal ? String(contratoDemanda.competencia_inicio).substring(0, 4) : String(competenciaBaseSazonalidade).substring(0, 4);
        demandaMesInicial = ucFiltradaSazonal ? String(contratoDemanda.competencia_inicio).substring(4, 6) : String(competenciaBaseSazonalidade).substring(4, 6);
        demandaAnoFinal = ucFiltradaSazonal ? String(contratoDemanda.competencia_fim).substring(0, 4) : anoAtual;
        demandaMesFinal = ucFiltradaSazonal ? String(contratoDemanda.competencia_fim).substring(4, 6) : mesLimite;
        demandaCompetenciaInicial = ucFiltradaSazonal ? parseInt(contratoDemanda.competencia_inicio) : competenciaBaseSazonalidade; //usado apenas no calculo de sazonalidade de uc Nao Sazonal
        demandaCompetenciaFinal = ucFiltradaSazonal ? parseInt(contratoDemanda.competencia_fim) : parseInt(`${anoAtual}${mesLimite}`);
        primeiroMesNaoSazonal = ucFiltradaSazonal ? "" : String(contratoDemanda.competencia_base_sazonalidade).substring(4, 6);
        primeiroAnoNaoSazonal = ucFiltradaSazonal ? "" : String(contratoDemanda.competencia_base_sazonalidade).substring(0, 4);
    }

    let corCiclo = "#30303d";
    let anos = [];
    listaGraficoDemanda.forEach((item) => {
        item.anos.forEach((ano) => {
            if (filtroInterno.unidades_consumidoras.length != 1 && ano.ano.includes("Demanda")) return;
            if (!anos.includes(ano.ano)) {
                anos.push(ano.ano);
            }
        });
    });
    if (demandaAnoInicial && !anos.includes(demandaAnoInicial)) {
        anos.push(demandaAnoInicial);
    }

    if (demandaAnoFinal && !anos.includes(demandaAnoFinal)) {
        anos.push(demandaAnoFinal);
    }
    anos = anos.sort((a, b) => {
        if (a.includes("Demanda") || b.includes("Demanda")) {
            return a < b ? 1 : -1;
        }
        return a < b ? -1 : 1;
    });

    return (
        <>
            <div id="graficoDemandaMensal" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
            <ContentCardBody
                style={{
                    minWidth: 890,
                }}
            >
                <Table responsive>
                    <THead>
                        <Tr>
                            <Th>Demanda kW</Th>
                            <Th
                                alignCenter
                                style={{
                                    backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "01" ? "#66bb6a" : demandaMesFinal == "01" ? "#ef5350" : null) : null,
                                }}
                            >
                                Janeiro
                                {ucFiltradaSazonal ? (demandaMesInicial == "01" ? " (I)" : demandaMesFinal == "01" ? " (F)" : "") : ""}
                            </Th>
                            <Th
                                alignCenter
                                style={{
                                    backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "02" ? "#66bb6a" : demandaMesFinal == "02" ? "#ef5350" : null) : null,
                                }}
                            >
                                Fevereiro
                                {ucFiltradaSazonal ? (demandaMesInicial == "02" ? " (I)" : demandaMesFinal == "02" ? " (F)" : "") : ""}
                            </Th>
                            <Th
                                alignCenter
                                style={{
                                    backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "03" ? "#66bb6a" : demandaMesFinal == "03" ? "#ef5350" : null) : null,
                                }}
                            >
                                Março
                                {ucFiltradaSazonal ? (demandaMesInicial == "03" ? " (I)" : demandaMesFinal == "03" ? " (F)" : "") : ""}
                            </Th>
                            <Th
                                alignCenter
                                style={{
                                    backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "04" ? "#66bb6a" : demandaMesFinal == "04" ? "#ef5350" : null) : null,
                                }}
                            >
                                Abril
                                {ucFiltradaSazonal ? (demandaMesInicial == "04" ? " (I)" : demandaMesFinal == "04" ? " (F)" : "") : ""}
                            </Th>
                            <Th
                                alignCenter
                                style={{
                                    backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "05" ? "#66bb6a" : demandaMesFinal == "05" ? "#ef5350" : null) : null,
                                }}
                            >
                                Maio
                                {ucFiltradaSazonal ? (demandaMesInicial == "05" ? " (I)" : demandaMesFinal == "05" ? " (F)" : "") : ""}
                            </Th>
                            <Th
                                alignCenter
                                style={{
                                    backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "06" ? "#66bb6a" : demandaMesFinal == "06" ? "#ef5350" : null) : null,
                                }}
                            >
                                Junho
                                {ucFiltradaSazonal ? (demandaMesInicial == "06" ? " (I)" : demandaMesFinal == "06" ? " (F)" : "") : ""}
                            </Th>
                            <Th
                                alignCenter
                                style={{
                                    backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "07" ? "#66bb6a" : demandaMesFinal == "07" ? "#ef5350" : null) : null,
                                }}
                            >
                                Julho
                                {ucFiltradaSazonal ? (demandaMesInicial == "07" ? " (I)" : demandaMesFinal == "07" ? " (F)" : "") : ""}
                            </Th>
                            <Th
                                alignCenter
                                style={{
                                    backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "08" ? "#66bb6a" : demandaMesFinal == "08" ? "#ef5350" : null) : null,
                                }}
                            >
                                Agosto
                                {ucFiltradaSazonal ? (demandaMesInicial == "08" ? " (I)" : demandaMesFinal == "08" ? " (F)" : "") : ""}
                            </Th>
                            <Th
                                alignCenter
                                style={{
                                    backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "09" ? "#66bb6a" : demandaMesFinal == "09" ? "#ef5350" : null) : null,
                                }}
                            >
                                Setembro
                                {ucFiltradaSazonal ? (demandaMesInicial == "09" ? " (I)" : demandaMesFinal == "09" ? " (F)" : "") : ""}
                            </Th>
                            <Th
                                alignCenter
                                style={{
                                    backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "10" ? "#66bb6a" : demandaMesFinal == "10" ? "#ef5350" : null) : null,
                                }}
                            >
                                Outubro
                                {ucFiltradaSazonal ? (demandaMesInicial == "10" ? " (I)" : demandaMesFinal == "10" ? " (F)" : "") : ""}
                            </Th>
                            <Th
                                alignCenter
                                style={{
                                    backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "11" ? "#66bb6a" : demandaMesFinal == "11" ? "#ef5350" : null) : null,
                                }}
                            >
                                Novembro
                                {ucFiltradaSazonal ? (demandaMesInicial == "11" ? " (I)" : demandaMesFinal == "11" ? " (F)" : "") : ""}
                            </Th>
                            <Th
                                alignCenter
                                style={{
                                    backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "12" ? "#66bb6a" : demandaMesFinal == "12" ? "#ef5350" : null) : null,
                                }}
                            >
                                Dezembro
                                {ucFiltradaSazonal ? (demandaMesInicial == "12" ? " (I)" : demandaMesFinal == "12" ? " (F)" : "") : ""}
                            </Th>
                        </Tr>
                    </THead>
                    <TBody>
                        {anos.map((ano, i) => {
                            //adiciona cada período de teste encontrado na lista, para ser exibido na tabela
                            const listaPeriodoTeste = [];
                            listaDemandaContagem.map((demandaContagem) => {
                                (demandaContagem.periodo_teste || []).map((periodoTeste) => {
                                    if (
                                        filtroInterno.unidades_consumidoras.length == 1 &&
                                        filtroInterno.unidades_consumidoras.find((uc) => uc.value == demandaContagem.id_unidade_consumidora)
                                    ) {
                                        listaPeriodoTeste.push(JSON.parse(periodoTeste));
                                    }
                                });
                            });
                            return (
                                <>
                                    {
                                        //se existir agendamento na UC selecionada, lista abaixo da 2ª linha da tabela, como linha amarela
                                        listaAgendamentoDemanda.length && i == 2
                                            ? listaAgendamentoDemanda.map((agendamento) => (
                                                  <Tr key={agendamento.competencia_formatada} style={{ backgroundColor: "#f1cf1a", color: "#111" }}>
                                                      <Td>{agendamento.competencia_formatada}</Td>
                                                      {Object.keys(agendamento)
                                                          .filter((item) => item.substring(0, 6) === "valor_")
                                                          .map((_, indiceAgendamento) => (
                                                              <Td key={indiceAgendamento} alignCenter color="#111">
                                                                  {parseFloat(agendamento[`valor_${indiceAgendamento + 1}`] || 0)
                                                                      .toFixed(2)
                                                                      .replace(".", ",")}
                                                              </Td>
                                                          ))}
                                                  </Tr>
                                              ))
                                            : null
                                    }
                                    <Tr key={ano}>
                                        <Td color="#fff">{ano}</Td>
                                        {listaGraficoDemanda.map((mes) => {
                                            const anoMesEstaEmTeste = !!listaPeriodoTeste.find((periodoTeste) => periodoTeste.mes === mes.mes && periodoTeste.ano === ano);
                                            const competenciaLinha = parseInt(`${ano}${mes.mes}`);
                                            const propsInicioNaoSazonalidade = {
                                                title: mes.mes == primeiroMesNaoSazonal && ano == primeiroAnoNaoSazonal && !ucFiltradaSazonal ? "Primeiro mês Não Sazonal" : "",
                                                style: {
                                                    border:
                                                        mes.mes == primeiroMesNaoSazonal && ano == primeiroAnoNaoSazonal && !ucFiltradaSazonal
                                                            ? "3px solid #00ff51"
                                                            : anoMesEstaEmTeste
                                                            ? "3px solid #257bfb"
                                                            : null,
                                                },
                                            };
                                            if (filtro.unidades_consumidoras.length == 1) {
                                                if (ucFiltradaSazonal) {
                                                    corCiclo = mes.mes == demandaMesInicial ? (demandaAnoInicial == ano ? "#595967" : "#30303d") : corCiclo;
                                                } else {
                                                    corCiclo = competenciaLinha >= demandaCompetenciaInicial && competenciaLinha <= demandaCompetenciaFinal ? "#595967" : "#30303d";
                                                }
                                            } else {
                                                corCiclo = "#30303d";
                                            }

                                            if (mes.anos.find((anoMes) => anoMes.ano == ano)) {
                                                return mes.anos
                                                    .filter((anoMes) => anoMes.ano == ano)
                                                    .map((anoMes) => {
                                                        if (anoMes.ano.includes("Demanda Proposta")) {
                                                            return (
                                                                <Td
                                                                    key={anoMes.ano}
                                                                    alignCenter
                                                                    backgroundColor={corCiclo}
                                                                    style={{
                                                                        border: anoMesEstaEmTeste ? "3px solid #257bfb" : null,
                                                                    }}
                                                                >
                                                                    <NumberFormat
                                                                        className="form-control"
                                                                        style={{
                                                                            textAlign: "center",
                                                                            maxWidth: 80,
                                                                            height: 20,
                                                                            fontSize: 13,
                                                                            backgroundColor: anoMes.ano.includes("Demanda Proposta Máxima") ? "#cfd8dc" : null,
                                                                        }}
                                                                        disabled={anoMes.ano.includes("Demanda Proposta Máxima")}
                                                                        placeholder={"0"}
                                                                        decimalSeparator={","}
                                                                        decimalScale={2}
                                                                        fixedDecimalScale={2}
                                                                        value={String(anoMes.valor || "").replace(".", ",")}
                                                                        onChange={(data) => {
                                                                            let listaDemanda = listaGraficoDemanda.map((item) => {
                                                                                return {
                                                                                    mes: item.mes,
                                                                                    anos: item.anos.map((itemAno) => {
                                                                                        if (mes.mes == item.mes && itemAno.ano == anoMes.ano) {
                                                                                            return {
                                                                                                ...itemAno,
                                                                                                valor: data.target.value.replace(",", "."),
                                                                                            };
                                                                                        } else if (mes.mes == item.mes && itemAno.ano == `${anoMes.ano} Máxima`) {
                                                                                            return {
                                                                                                ...itemAno,
                                                                                                valor: (parseFloat(data.target.value.replace(",", ".")) * 1.05).toFixed(2),
                                                                                            };
                                                                                        } else {
                                                                                            return itemAno;
                                                                                        }
                                                                                    }),
                                                                                };
                                                                            });

                                                                            getListaGraficoDemanda(listaDemanda);

                                                                            const graficoDemandaMensalAtualizado = montarGraficoClusteredColumnLineMensal(
                                                                                state,
                                                                                graficoDemandaMensal,
                                                                                "graficoDemandaMensal",
                                                                                listaDemanda,
                                                                                "Demanda Mensal (kW)"
                                                                            );
                                                                            setGraficoDemandaMensal(graficoDemandaMensalAtualizado);
                                                                        }}
                                                                    />
                                                                </Td>
                                                            );
                                                        } else {
                                                            let corDemanda = "";
                                                            if (!anoMes.ano.includes("Demanda Contratada")) {
                                                                let demandaAtual = contratoDemanda
                                                                    ? contratoDemanda.itens.filter((demandaItem) => demandaItem.competencia == `${anoMes.ano}${anoMes.mes}`)[0]
                                                                    : null;

                                                                if (demandaAtual) {
                                                                    if (demandaAtual.quantidade * 1.05 <= anoMes.valor) {
                                                                        corDemanda = "#ef5350";
                                                                    } else if (demandaAtual.quantidade <= anoMes.valor) {
                                                                        corDemanda = "#66bb6a";
                                                                    } else {
                                                                        corDemanda = corCiclo;
                                                                    }
                                                                } else {
                                                                    corDemanda = corCiclo;
                                                                }
                                                            }
                                                            return (
                                                                <Td
                                                                    key={anoMes.ano + "-" + anoMes.mes}
                                                                    alignCenter
                                                                    color="#fff"
                                                                    backgroundColor={corDemanda}
                                                                    {...propsInicioNaoSazonalidade}
                                                                >
                                                                    {parseFloat(anoMes.valor || 0)
                                                                        .toFixed(2)
                                                                        .replace(".", ",")}
                                                                </Td>
                                                            );
                                                        }
                                                    });
                                            } else {
                                                return (
                                                    <Td key={ano + "-" + mes.mes} alignCenter backgroundColor={corCiclo} color="#fff" {...propsInicioNaoSazonalidade}>
                                                        0,00
                                                    </Td>
                                                );
                                            }
                                        })}
                                        {renderizarCelulasZeradas(listaGraficoDemanda, listaPeriodoTeste, ano)}
                                    </Tr>
                                </>
                            );
                        })}
                    </TBody>
                </Table>
            </ContentCardBody>
            {contagemDemanda ? (
                <Row>
                    <Grid cols="12 4 4 4">
                        <div style={{ margin: 5, minHeight: 104 }} className="info-box bg-yellow">
                            <span className="info-box-icon">
                                <i className="fas fa-circle-notch"></i>
                            </span>
                            <div className="info-box-content">
                                <span style={{ marginBlock: 5 }} className="info-box-text">
                                    {ucFiltradaSazonal ? "FATURAS DO CICLO SAZONAL" : " Nº MESES SEM SAZONALIDADE"}
                                </span>
                                <span style={{ marginBlock: 5 }} className="info-box-number">
                                    {ucFiltradaSazonal ? contagemDemanda.lidas || 0 : contagemDemanda.meses_nao_sazonal}
                                </span>
                                {ucFiltradaSazonal ? (
                                    <>
                                        <div style={{ marginBlock: 5, height: 8 }} className="progress">
                                            <div
                                                className="progress-bar"
                                                style={{
                                                    marginTop: 2,
                                                    marginInline: 2,
                                                    height: 4,
                                                    width: `${((contagemDemanda.lidas || 0) * 100) / contagemDemanda.competencias}%`,
                                                }}
                                            ></div>
                                        </div>
                                        <span className="progress-description">{(contagemDemanda.lidas || 1) + " de 12"}</span>
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </Grid>
                    <Grid cols="12 4 4 4">
                        <div style={{ margin: 5 }} className="info-box bg-green">
                            <span className="info-box-icon">
                                <i className="fas fa-angle-up"></i>
                            </span>
                            <div className="info-box-content">
                                <span style={{ marginBlock: 5 }} className="info-box-text">
                                    ATINGIU SEM ULTRAPASSAR
                                </span>
                                <span style={{ marginBlock: 5 }} className="info-box-number">
                                    {(contagemDemanda.atingidas || 0) - (contagemDemanda.ultrapassagens || 0)}
                                </span>
                                <div style={{ marginBlock: 5, height: 8 }} className="progress">
                                    <div
                                        className="progress-bar"
                                        style={{
                                            marginTop: 2,
                                            marginInline: 2,
                                            height: 4,
                                            width: `${(((contagemDemanda.atingidas || 0) - (contagemDemanda.ultrapassagens || 0)) * 100) / 12}%`,
                                        }}
                                    ></div>
                                </div>
                                <span className="progress-description">{(contagemDemanda.lidas || 1) + " de 12"}</span>
                            </div>
                        </div>
                    </Grid>
                    <Grid cols="12 4 4 4">
                        <div style={{ margin: 5 }} className="info-box bg-red">
                            <span className="info-box-icon">
                                <i className="fas fa-angle-double-up"></i>
                            </span>
                            <div className="info-box-content">
                                <span style={{ marginBlock: 5 }} className="info-box-text">
                                    ATINGIU COM ULTRAPASSAGEM
                                </span>
                                <span style={{ marginBlock: 5 }} className="info-box-number">
                                    {contagemDemanda.ultrapassagens || 0}
                                </span>
                                <div style={{ marginBlock: 5, height: 8 }} className="progress">
                                    <div
                                        className="progress-bar"
                                        style={{
                                            marginTop: 2,
                                            marginInline: 2,
                                            height: 4,
                                            width: `${((contagemDemanda.ultrapassagens || 0) * 100) / 12}%`,
                                        }}
                                    ></div>
                                </div>
                                <span className="progress-description">{(contagemDemanda.lidas || 1) + " de 12"}</span>
                            </div>
                        </div>
                    </Grid>
                </Row>
            ) : null}
        </>
    );
}

function analiseDemanda(props) {
    const { buscarPropriedadeUcFiltrada, filtro, listaDemandaContagem, usuarioLogado } = props;

    const [abaSazonalSelcionada, setAbaSazonalSelcionada] = useState(true);

    function montarTooltipoPeriodoTeste({ periodoTeste, item, props }) {
        if (!periodoTeste.length) return null;
        const mesInicio = "" + JSON.parse(periodoTeste[0]).mes;
        const anoInicio = "" + JSON.parse(periodoTeste[0]).ano;
        const mesFim = "" + JSON.parse(periodoTeste[periodoTeste.length - 1]).mes;
        const anoFim = "" + JSON.parse(periodoTeste[periodoTeste.length - 1]).ano;
        const inicioTeste = mesInicio + "/" + anoInicio;
        const fimTeste = mesFim + "/" + anoFim;

        const dataAtual = new Date();
        const mesAtual = "" + (dataAtual.getMonth() + 1);
        const anoAtual = "" + dataAtual.getFullYear();
        const competenciaAtual = parseInt(anoAtual + mesAtual);
        const competenciaFimTeste = parseInt(anoFim + mesFim);
        const exibirBotaoExcluirTeste = props.usuarioLogado.acesso_periodo_teste_demanda_excluir && competenciaAtual > competenciaFimTeste;
        return (
            <div
                style={{
                    backgroundColor: "#257bfb",
                    padding: 4,
                    borderRadius: "0.3rem",
                    fontSize: "0.8rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexWrap: "wrap",
                    width: 190,
                    minHeight: `${1.3 * (item.agendamentos.length + 2)}rem`, //+2 pq o tooltip teste tem 2 linhas
                    margin: 3,
                }}
            >
                <div
                    style={{
                        margin: 0,
                        display: "flex",
                        justifyContent: exibirBotaoExcluirTeste ? "flex-end" : "center",
                        flexGrow: 1,
                    }}
                >
                    <u>
                        <b>{`Período de Testes ativo: `}</b>
                    </u>
                </div>
                {exibirBotaoExcluirTeste ? (
                    <BotaoExcluir
                        event={() =>
                            props.excluirPeriodoTeste({
                                id: item.id,
                            })
                        }
                    />
                ) : null}
                <div
                    style={{
                        margin: 0,
                        textAlign: "center",
                        minWidth: "100%",
                    }}
                >{`Início ${inicioTeste} - Fim ${fimTeste}`}</div>
            </div>
        );
    }

    function montarTooltipAgendamento({ item, props }) {
        if (!item.agendamentos.length) return null;

        return (
            <div
                style={{
                    backgroundColor: "#f1cf1a",
                    color: "#111",
                    padding: 4,
                    borderRadius: "0.3rem",
                    fontSize: "0.8rem",
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    width: 200,
                    margin: 3,
                }}
            >
                <div
                    style={{
                        margin: 0,
                        textAlign: "center",
                        minWidth: "100%",
                    }}
                >
                    <u>
                        <b>Agendamento de Alteração de Demanda:</b>
                    </u>
                </div>
                {item.agendamentos.map((agendamentoDemanda) => {
                    const dataAtual = new Date();
                    const mesAtual = (dataAtual.getMonth() + 1 < 10 ? "0" : "") + (dataAtual.getMonth() + 1);
                    const anoAtual = String(dataAtual.getFullYear());
                    const mesAgendamento = String(agendamentoDemanda.competencia_inicio_vigencia).substring(4, 6);
                    const anoAgendamento = String(agendamentoDemanda.competencia_inicio_vigencia).substring(0, 4);

                    const competenciaAgendamentoVencida = agendamentoDemanda.competencia_inicio_vigencia < parseInt(anoAtual + mesAtual);
                    const estiloAgendamentoVencido = competenciaAgendamentoVencida
                        ? {
                              border: "1px solid #000000",
                              padding: "0 2px",
                              borderRadius: "0.3rem",
                              color: "#ff0000",
                              fontWeight: 450,
                          }
                        : {};
                    const exibitBotaoExcluirAgendamento = props.usuarioLogado.acesso_cadastro_demanda_editar && competenciaAgendamentoVencida;
                    return (
                        <div
                            style={{
                                display: "flex",
                                flexWrap: "nowrap",
                                justifyContent: "center",
                                alignItems: "center",
                                minWidth: "100%",
                            }}
                        >
                            <span style={estiloAgendamentoVencido}>{agendamentoDemanda.mensagem_tooltip}</span>
                            {exibitBotaoExcluirAgendamento ? (
                                <BotaoExcluir
                                    event={() =>
                                        props.excluirAgendamento({
                                            id: agendamentoDemanda.id,
                                            competencia_agendamento: mesAgendamento + "/" + anoAgendamento,
                                        })
                                    }
                                />
                            ) : null}
                        </div>
                    );
                })}
            </div>
        );
    }

    return (
        <ContentCard
            style={{
                color: "#fff",
                padding: 8,
                display:
                    !buscarPropriedadeUcFiltrada() || buscarPropriedadeUcFiltrada("id_grupo_faturamento") == 1 || buscarPropriedadeUcFiltrada("id_grupo_faturamento") == 3
                        ? ""
                        : "none",
            }}
        >
            <ContentCardBody
                style={{
                    backgroundColor: "#30303d",
                    overflowX: "scroll",
                }}
            >
                <GraficoTabelaDemandaMensal {...props} />
                {filtro.id_tipo_contrato_demanda == 3 ? null : (
                    <ContentCardBody
                        style={{
                            minWidth: 890,
                        }}
                    >
                        <Row>
                            <Grid
                                cols="4 4 4 4"
                                style={{
                                    padding: "15px 0px 5px 10px",
                                    marginLeft: 7,
                                    backgroundColor: abaSazonalSelcionada ? "#30303d" : "#282833",
                                    color: abaSazonalSelcionada ? "#FFF" : "#9b9b9b",
                                    marginBottom: 0,
                                    zIndex: abaSazonalSelcionada ? 1 : 0,
                                    borderRadius: "10px 10px 0 0",
                                    border: `solid 1px ${abaSazonalSelcionada ? "#ddd" : "#282833"}`,
                                    cursor: "pointer",
                                    textAlign: "center",
                                }}
                                onClick={() => {
                                    setAbaSazonalSelcionada(true);
                                }}
                            >
                                <h6>Sazonal</h6>
                            </Grid>
                            <Grid
                                cols="4 4 4 4"
                                style={{
                                    padding: "15px 0px 5px 10px",
                                    backgroundColor: abaSazonalSelcionada ? "#282833" : "#30303d",
                                    color: abaSazonalSelcionada ? "#9b9b9b" : "#FFF",
                                    marginBottom: 0,
                                    zIndex: abaSazonalSelcionada ? 0 : 1,
                                    borderRadius: "10px 10px 0 0",
                                    border: `solid 1px ${abaSazonalSelcionada ? "#282833" : "#ddd"}`,
                                    cursor: "pointer",
                                    textAlign: "center",
                                }}
                                onClick={() => {
                                    setAbaSazonalSelcionada(false);
                                }}
                            >
                                <h6>Não Sazonal</h6>
                            </Grid>
                        </Row>
                        <Table responsive>
                            <THead>
                                <Tr>
                                    <Th sticky backgroundColor="#30303d" alignCenter colSpan={abaSazonalSelcionada ? "4" : "2"}>
                                        Atingidas
                                    </Th>
                                    {usuarioLogado.administrador ? (
                                        <Th sticky backgroundColor="#30303d">
                                            Cliente
                                        </Th>
                                    ) : null}
                                    <Th sticky backgroundColor="#30303d">
                                        Unidade Consumidora
                                    </Th>
                                    <Th sticky backgroundColor="#30303d"></Th>
                                    <Th sticky backgroundColor="#30303d"></Th>
                                    {abaSazonalSelcionada ? (
                                        <>
                                            <Th sticky backgroundColor="#30303d" alignCenter>
                                                Início
                                            </Th>
                                            <Th sticky backgroundColor="#30303d" alignCenter>
                                                Fim
                                            </Th>
                                            <Th sticky backgroundColor="#30303d" alignCenter>
                                                Faturas do Ciclo
                                            </Th>
                                        </>
                                    ) : (
                                        <>
                                            <Th sticky backgroundColor="#30303d" alignCenter>
                                                Primeiro Mês Não Sazonal
                                            </Th>
                                            <Th sticky backgroundColor="#30303d" alignCenter>
                                                Nº Meses Sem Sazonalidade
                                            </Th>
                                        </>
                                    )}

                                    <Th sticky backgroundColor="#30303d" alignCenter>
                                        Sazonalidade
                                    </Th>
                                </Tr>
                            </THead>
                            <TBody>
                                {listaDemandaContagem
                                    .filter((item) => (abaSazonalSelcionada ? item.contrato_demanda_sazonal : !item.contrato_demanda_sazonal))
                                    .map((item) => {
                                        const sazonalidadeDiferenteDaUC = item.contrato_demanda_sazonal != item.sazonal;

                                        let restantes = item.competencias - item.lidas;
                                        let cor = "#fff";
                                        if (item.atingidas < 3) {
                                            if (restantes <= 3 && item.atingidas <= 1) {
                                                cor = "#c92e2b";
                                            } else if (restantes <= 4 && item.atingidas <= 2) {
                                                cor = "#eeff41";
                                            }
                                        }
                                        //Configura o Tooltip de agendamento de demanda-------------------------------------------------------------------------------------
                                        let tooltipAgendamentoDemanda = montarTooltipAgendamento({ item, props });

                                        //Configura o Tooltip de período de testes -----------------------------------------------------------------------------------------
                                        const periodoTeste = item.periodo_teste || [];
                                        let tooltipPeriodoTeste = montarTooltipoPeriodoTeste({ periodoTeste, item, props });

                                        const avisoSazonalidadeDiferente = (
                                            <div style={{ backgroundColor: "#fd5959", color: "#ffffff", padding: 5, borderRadius: "0.3rem", textAlign: "center" }}>
                                                <div style={{ width: "100%", textWrap: "nowrap" }}>A sazonalidade da UC é diferente do contrato</div>
                                                <div>
                                                    (UC:{item.contrato_demanda_sazonal ? " Sazonal " : " Não Sazonal "} - Contrato:
                                                    {item.sazonal ? " Sazonal " : " Não Sazonal "})
                                                </div>
                                            </div>
                                        );
                                        //----------------------------------------------------------------------------------------------------------------------------------
                                        return (
                                            <Tr key={item.id}>
                                                {abaSazonalSelcionada ? (
                                                    <>
                                                        <Td color={"#fff"} minWidth={40} backgroundColor={item.atingidas >= 1 ? "#28a745" : "#fff"} alignCenter></Td>
                                                        <Td
                                                            minWidth={40}
                                                            borderLeft="1px solid #81c784"
                                                            borderRight="1px solid #81c784"
                                                            color={item.atingidas >= 2 ? "#fff" : "#000"}
                                                            backgroundColor={item.atingidas >= 2 ? "#28a745" : "#fff"}
                                                            alignCenter
                                                        >
                                                            {item.atingidas}
                                                        </Td>
                                                        <Td color={"#fff"} minWidth={40} backgroundColor={item.atingidas >= 3 ? "#28a745" : "#fff"} alignCenter></Td>
                                                        <Td
                                                            color={"#fff"}
                                                            title={`Total de ultrapassagens:${parseInt(item.ultrapassagens || 0)}`}
                                                            minWidth={40}
                                                            backgroundColor={parseInt(item.ultrapassagens) ? "#fa3c3c" : "black"}
                                                            alignCenter
                                                        >
                                                            <div style={{ display: "flex", flexGrow: 1, flexDirection: "column", borderRadius: 3 }}>
                                                                <div style={{ fontSize: "1.4rem", fontWeight: "bold" }} className="info-box-icon">
                                                                    <i className="fas fa-angle-double-up"></i>
                                                                </div>
                                                                <div style={{ fontSize: "0.9rem", fontWeight: "bold" }} className="info-box-number">
                                                                    {item.ultrapassagens}
                                                                </div>
                                                            </div>
                                                        </Td>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Td>
                                                            <div
                                                                style={{
                                                                    margin: 0,
                                                                    flexDirection: "column",
                                                                    alignItems: "center",
                                                                    padding: "5px 5px 0px 5px",
                                                                    minHeight: "fit-content",
                                                                }}
                                                                className="info-box bg-green"
                                                                title="Atingidas sem Ultrapassagens"
                                                            >
                                                                <span className="info-box-icon" style={{ width: window.innerWidth < 576 ? 20 : 70, fontSize: "1.3rem" }}>
                                                                    <i className="fas fa-angle-up"></i>
                                                                </span>
                                                                <span style={{ margin: 0 }} className="info-box-number">
                                                                    {item.atingidas - (item.ultrapassagens || 0)}
                                                                </span>
                                                            </div>
                                                        </Td>
                                                        <Td>
                                                            <div
                                                                style={{
                                                                    margin: 0,
                                                                    flexDirection: "column",
                                                                    alignItems: "center",
                                                                    padding: "5px 5px 0px 5px",
                                                                    minHeight: "fit-content",
                                                                }}
                                                                className="info-box bg-red"
                                                                title="Atingidas com Ultrapassagens"
                                                            >
                                                                <span className="info-box-icon" style={{ width: window.innerWidth < 576 ? 20 : 70, fontSize: "1.3rem" }}>
                                                                    <i className="fas fa-angle-double-up"></i>
                                                                </span>
                                                                <span style={{ margin: 0 }} className="info-box-number">
                                                                    {item.ultrapassagens || 0}
                                                                </span>
                                                            </div>
                                                        </Td>
                                                    </>
                                                )}
                                                {usuarioLogado.administrador ? (
                                                    <Td padding={10} color="#fff">
                                                        {item.nome_cliente}
                                                    </Td>
                                                ) : null}
                                                <Td color="#fff">{item.nome_unidade_consumidora}</Td>
                                                {sazonalidadeDiferenteDaUC ? (
                                                    <Td colSpan="2">{avisoSazonalidadeDiferente}</Td>
                                                ) : (
                                                    <>
                                                        <Td padding={10}>{tooltipAgendamentoDemanda}</Td>
                                                        <Td padding={10} color="#fff">
                                                            {tooltipPeriodoTeste}
                                                        </Td>
                                                    </>
                                                )}
                                                {abaSazonalSelcionada ? (
                                                    <>
                                                        <Td padding={10} color="#fff" alignCenter>
                                                            {sazonalidadeDiferenteDaUC ? "-" : `${String(item.competencia_inicio).substring(4, 6)}/${String(item.competencia_inicio).substring(0, 4)}`}
                                                        </Td>
                                                        <Td padding={10} color="#fff" alignCenter>
                                                            {sazonalidadeDiferenteDaUC ? "-" : `${String(item.competencia_fim).substring(4, 6)}/${String(item.competencia_fim).substring(0, 4)}`}
                                                        </Td>
                                                    </>
                                                ) : (
                                                    <Td padding={10} color="#fff" alignCenter>
                                                        {sazonalidadeDiferenteDaUC ? "-" : `${String(item.competencia_base_sazonalidade).substring(4, 6)}/${String(item.competencia_base_sazonalidade).substring(0, 4)}`}
                                                    </Td>
                                                )}
                                                {item.sazonal ? (
                                                    <Td
                                                        color={cor}
                                                        fontWeight="bold"
                                                        title={
                                                            item.competencias - item.lidas > 3 - item.atingidas || item.atingidas >= 3
                                                                ? null
                                                                : item.competencias - item.lidas == 3 - item.atingidas
                                                                ? `É necessário atingir ${3 - item.atingidas} demanda${3 - item.atingidas > 1 ? "s" : ""} e resta${item.competencias - item.lidas > 1 ? "m" : ""} ${
                                                                      item.competencias - item.lidas
                                                                  } fatura${item.competencias - item.lidas > 1 ? "s" : ""} no ciclo sazonal`
                                                                : `É necessário atingir ${3 - item.atingidas} demanda${3 - item.atingidas > 1 ? "s" : ""} e ${
                                                                      item.competencias == item.lidas
                                                                          ? "o ciclo sazonal já está completo"
                                                                          : `resta${item.competencias - item.lidas > 1 ? "m" : ""} ${item.competencias - item.lidas} fatura${
                                                                                item.competencias - item.lidas > 1 ? "s" : ""
                                                                            } no ciclo sazonal`
                                                                  }`
                                                        }
                                                        backgroundColor={
                                                            item.competencias - item.lidas > 3 - item.atingidas || item.atingidas >= 3 ? null : item.competencias - item.lidas == 3 - item.atingidas ? "#ffb74d" : "#e57373"
                                                        }
                                                        alignCenter
                                                    >
                                                        {sazonalidadeDiferenteDaUC ? "-" : `${item.lidas || 1} de ${item.competencias}`}
                                                    </Td>
                                                ) : (
                                                    <Td padding={10} color="#fff" alignCenter>
                                                        {item.meses_nao_sazonal || 1}
                                                    </Td>
                                                )}

                                                <Td
                                                    fontWeight={parseFloat(item.sazonalidade) <= 0.2 ? "bold" : "normal"}
                                                    color={parseFloat(item.sazonalidade) <= 0.2 ? "#3f3" : "#fff"}
                                                    backgroundColor="#40838f"
                                                    alignCenter
                                                >
                                                    {sazonalidadeDiferenteDaUC ? "-" : parseFloat(item.sazonalidade).toFixed(2).replace(".", ",")}
                                                </Td>
                                            </Tr>
                                        );
                                    })}
                            </TBody>
                        </Table>
                    </ContentCardBody>
                )}
            </ContentCardBody>
        </ContentCard>
    );
}
const mapStateToProps = (state) => ({
    usuarioLogado: state.auth.usuarioLogado,
    filtro: state.analise.filtro,
    listaGraficoDemanda: state.analise.listaGraficoDemanda,
    listaDemandaContagem: state.analise.listaDemandaContagem,
    listaAgendamentoDemanda: state.analise.listaAgendamentoDemanda,
    contratoDemanda: state.analise.contratoDemanda,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getDemanda,
            getDemandaContagem,
            getListaGraficoDemanda,
            excluirPeriodoTeste,
            excluirAgendamento,
        },
        dispatch
    );
const AnaliseDemanda = connect(mapStateToProps, mapDispatchToProps)(analiseDemanda);
export { AnaliseDemanda };

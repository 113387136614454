import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ContentCard from "../../common/template/contentCard";
import ContentCardBody from "../../common/template/contentCardBody";
import Table from "../../common/table/table";
import THead from "../../common/table/tHead";
import Tr from "../../common/table/tr";
import Th from "../../common/table/th";
import TBody from "../../common/table/tBody";
import Td from "../../common/table/td";
import { getDemanda, getListaGraficoTarifaMedia, getListaGraficoTarifaMediaGeral } from "../analiseActions";
import { montarGraficoClusteredColumnMensal } from "../../common/graficos/montarGraficoClusteredColumnMensal.js";

function analiseTarifa(props) {
    const {
        state,
        contratoDemanda,
        listaGraficoTarifaMedia,
        listaGraficoTarifaMediaGeral,
        getDemanda,
        getListaGraficoTarifaMedia,
        getListaGraficoTarifaMediaGeral,
        setAguardando,
    } = props;

    const [graficoTarifaMediaMensal, setGraficoTarifaMediaMensal] = useState(null);
    const [graficoTarifaMediaMensalGeral, setGraficoTarifaMediaMensalGeral] = useState(null);

    useEffect(() => {
        atualizarTodosGraficos();
        return desmontarTodosGraficos;
    }, []);

    useEffect(() => {
        const buscarDados = async () => {
            if (!state.aguardando) return;

            await Promise.all([getDemanda(), getListaGraficoTarifaMedia(), getListaGraficoTarifaMediaGeral()]);

            setAguardando(false);
        };

        buscarDados();
        if (!state.aguardando) {
            atualizarTodosGraficos();
        }
    }, [state.aguardando]);

    function atualizarTodosGraficos() {
        atualizarListaGraficoTarifaMedia();
        atualizarListaGraficoTarifaMediaGeral();
    }
    function desmontarTodosGraficos() {
        if (graficoTarifaMediaMensal) graficoTarifaMediaMensal.dispose();
        if (graficoTarifaMediaMensalGeral) graficoTarifaMediaMensalGeral.dispose();
    }

    function atualizarListaGraficoTarifaMedia() {
        const graficoTarifaMediaMensalAtualizado = montarGraficoClusteredColumnMensal(
            state,
            graficoTarifaMediaMensal,
            "graficoTarifaMediaMensal",
            listaGraficoTarifaMedia,
            "Tarifa Média Líquida - TML (Sem Demanda e Reativos) (R$)",
            "R$",
            ""
        );
        setGraficoTarifaMediaMensal(graficoTarifaMediaMensalAtualizado);
    }

    function atualizarListaGraficoTarifaMediaGeral() {
        const graficoTarifaMediaMensalGeralAtualizado = montarGraficoClusteredColumnMensal(
            state,
            graficoTarifaMediaMensalGeral,
            "graficoTarifaMediaMensalGeral",
            listaGraficoTarifaMediaGeral,
            "Tarifa Média Bruta - TMB (Com Demanda e Reativos) (R$)",
            "R$",
            ""
        );
        setGraficoTarifaMediaMensalGeral(graficoTarifaMediaMensalGeralAtualizado);
    }

    function renderizarCelulasComZeros(corCiclo, listaDados) {
        let listaMeses = [];
        for (let i = listaDados.length; i < 12; i++) {
            listaMeses.push(i);
        }

        return listaMeses.map((mes) => (
            <Td key={mes} alignCenter color="#fff" backgroundColor={corCiclo}>
                0
            </Td>
        ));
    }

    function renderizarCabecalhoMeses({ ucFiltradaSazonal, demandaMesInicial, demandaMesFinal }) {
        return (
            <>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "01" ? "#66bb6a" : demandaMesFinal == "01" ? "#ef5350" : null) : null,
                    }}
                >
                    Janeiro
                    {ucFiltradaSazonal ? (demandaMesInicial == "01" ? " (I)" : demandaMesFinal == "01" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "02" ? "#66bb6a" : demandaMesFinal == "02" ? "#ef5350" : null) : null,
                    }}
                >
                    Fevereiro
                    {ucFiltradaSazonal ? (demandaMesInicial == "02" ? " (I)" : demandaMesFinal == "02" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "03" ? "#66bb6a" : demandaMesFinal == "03" ? "#ef5350" : null) : null,
                    }}
                >
                    Março
                    {ucFiltradaSazonal ? (demandaMesInicial == "03" ? " (I)" : demandaMesFinal == "03" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "04" ? "#66bb6a" : demandaMesFinal == "04" ? "#ef5350" : null) : null,
                    }}
                >
                    Abril
                    {ucFiltradaSazonal ? (demandaMesInicial == "04" ? " (I)" : demandaMesFinal == "04" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "05" ? "#66bb6a" : demandaMesFinal == "05" ? "#ef5350" : null) : null,
                    }}
                >
                    Maio
                    {ucFiltradaSazonal ? (demandaMesInicial == "05" ? " (I)" : demandaMesFinal == "05" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "06" ? "#66bb6a" : demandaMesFinal == "06" ? "#ef5350" : null) : null,
                    }}
                >
                    Junho
                    {ucFiltradaSazonal ? (demandaMesInicial == "06" ? " (I)" : demandaMesFinal == "06" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "07" ? "#66bb6a" : demandaMesFinal == "07" ? "#ef5350" : null) : null,
                    }}
                >
                    Julho
                    {ucFiltradaSazonal ? (demandaMesInicial == "07" ? " (I)" : demandaMesFinal == "07" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "08" ? "#66bb6a" : demandaMesFinal == "08" ? "#ef5350" : null) : null,
                    }}
                >
                    Agosto
                    {ucFiltradaSazonal ? (demandaMesInicial == "08" ? " (I)" : demandaMesFinal == "08" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "09" ? "#66bb6a" : demandaMesFinal == "09" ? "#ef5350" : null) : null,
                    }}
                >
                    Setembro
                    {ucFiltradaSazonal ? (demandaMesInicial == "09" ? " (I)" : demandaMesFinal == "09" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "10" ? "#66bb6a" : demandaMesFinal == "10" ? "#ef5350" : null) : null,
                    }}
                >
                    Outubro
                    {ucFiltradaSazonal ? (demandaMesInicial == "10" ? " (I)" : demandaMesFinal == "10" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "11" ? "#66bb6a" : demandaMesFinal == "11" ? "#ef5350" : null) : null,
                    }}
                >
                    Novembro
                    {ucFiltradaSazonal ? (demandaMesInicial == "11" ? " (I)" : demandaMesFinal == "11" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "12" ? "#66bb6a" : demandaMesFinal == "12" ? "#ef5350" : null) : null,
                    }}
                >
                    Dezembro
                    {ucFiltradaSazonal ? (demandaMesInicial == "12" ? " (I)" : demandaMesFinal == "12" ? " (F)" : "") : ""}
                </Th>
            </>
        );
    }

    let ucFiltradaSazonal = false;
    let demandaMesInicial = "";
    let demandaMesFinal = "";

    //se é não sazonal, pega os últimos 12 meses,
    const mesAtualDate = new Date().getMonth(); //não soma 1 pq o mes atual não deve ser contabilizado
    const anoAtual = String(new Date().getFullYear());
    //AQUI É A LÓGICA PARA PINTAR AS CÉLULAS DE CINZA
    if (contratoDemanda) {
        const mesLimite = mesAtualDate < 10 ? "0" + mesAtualDate : String(mesAtualDate);
        const mesesDescontar = Number(mesLimite) - 11; //diminui 11 meses para pegar o mes inicial dos últimos 12 meses
        const anoInicialCalculado = mesesDescontar > 0 ? anoAtual : anoAtual - 1; //se descontando 11 meses der negativo, considera ano anterior
        const mesInicialCalculado = Number(mesLimite) + 1 > 12 ? "01" : Number(mesLimite) + 1; //mes atual 10, inicia em 10+1 do ano anterior
        const competenciaInicialCalculada = Number(String(anoInicialCalculado) + String(mesInicialCalculado)); //usada pra comparar com competencia base
        //se competencia base for maior considera ela como inicial, senão considera a calculada
        const competenciaBaseSazonalidade =
            contratoDemanda.competencia_base_sazonalidade > competenciaInicialCalculada ? contratoDemanda.competencia_base_sazonalidade : competenciaInicialCalculada;

        ucFiltradaSazonal = contratoDemanda.unidade_consumidora_sazonal;
        demandaMesInicial = ucFiltradaSazonal ? String(contratoDemanda.competencia_inicio).substring(4, 6) : String(competenciaBaseSazonalidade).substring(4, 6);
        demandaMesFinal = ucFiltradaSazonal ? String(contratoDemanda.competencia_fim).substring(4, 6) : mesLimite;
    }

    let corCiclo = "#30303d";

    let anosTarifa = [];
    listaGraficoTarifaMedia.forEach((item) => {
        item.anos.forEach((ano) => {
            if (!anosTarifa.find((itemAno) => itemAno == ano.ano)) {
                anosTarifa.push(ano.ano);
            }
        });
    });

    anosTarifa = anosTarifa.sort((a, b) => a - b);

    let anosTarifaGeral = [];
    listaGraficoTarifaMediaGeral.forEach((item) => {
        item.anos.forEach((ano) => {
            if (!anosTarifaGeral.find((itemAno) => itemAno == ano.ano)) {
                anosTarifaGeral.push(ano.ano);
            }
        });
    });

    anosTarifaGeral = anosTarifaGeral.sort((a, b) => a - b);

    return (
        <div>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",
                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoTarifaMediaMensal" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                    <div
                        style={{
                            minWidth: "890px",
                        }}
                    >
                        <Table responsive>
                            <THead>
                                <Tr>
                                    <Th>Ano</Th>
                                    {renderizarCabecalhoMeses({ ucFiltradaSazonal, demandaMesInicial, demandaMesFinal })}
                                    <Th alignCenter>Média</Th>
                                </Tr>
                            </THead>
                            <TBody>
                                {anosTarifa.map((ano, i) => {
                                    let tarifaMediaAnual = 0;
                                    let quantidadeTarifas = 0;

                                    return (
                                        <Tr key={i}>
                                            <Td color="#fff">{ano}</Td>
                                            {listaGraficoTarifaMedia.map((mes) => {
                                                if (mes.anos.filter((anoMes) => anoMes.ano == ano).length > 0) {
                                                    return mes.anos
                                                        .filter((anoMes) => anoMes.ano == ano)
                                                        .map((anoMes) => {
                                                            if (parseFloat(anoMes.valor || 0) > 0) {
                                                                tarifaMediaAnual += parseFloat(anoMes.valor || 0);
                                                                quantidadeTarifas++;
                                                            }

                                                            return (
                                                                <Td key={anoMes.ano} alignCenter color="#fff">
                                                                    {parseFloat(anoMes.valor || 0)
                                                                        .toFixed(6)
                                                                        .replace(".", ",")}
                                                                </Td>
                                                            );
                                                        });
                                                } else {
                                                    return (
                                                        <Td key={ano} alignCenter color="#fff">
                                                            0,00
                                                        </Td>
                                                    );
                                                }
                                            })}
                                            {renderizarCelulasComZeros(corCiclo, listaGraficoTarifaMedia)}

                                            <Td alignCenter color="#fff">
                                                {(tarifaMediaAnual > 0 && quantidadeTarifas > 0 ? tarifaMediaAnual / quantidadeTarifas : 0).toFixed(6).replace(".", ",")}
                                            </Td>
                                        </Tr>
                                    );
                                })}
                            </TBody>
                        </Table>
                    </div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoTarifaMediaMensalGeral" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                    <div
                        style={{
                            minWidth: "890px",
                        }}
                    >
                        <Table responsive>
                            <THead>
                                <Tr>
                                    <Th>Ano</Th>
                                    {renderizarCabecalhoMeses({ ucFiltradaSazonal, demandaMesInicial, demandaMesFinal })}
                                    <Th alignCenter>Média</Th>
                                </Tr>
                            </THead>
                            <TBody>
                                {anosTarifaGeral.map((ano) => {
                                    let tarifaMediaAnual = 0;
                                    let quantidadeTarifas = 0;

                                    return (
                                        <Tr key={ano}>
                                            <Td color="#fff">{ano}</Td>
                                            {listaGraficoTarifaMediaGeral.map((mes) => {
                                                if (mes.anos.filter((anoMes) => anoMes.ano == ano).length > 0) {
                                                    return mes.anos
                                                        .filter((anoMes) => anoMes.ano == ano)
                                                        .map((anoMes) => {
                                                            if (parseFloat(anoMes.valor || 0) > 0) {
                                                                tarifaMediaAnual += parseFloat(anoMes.valor || 0);
                                                                quantidadeTarifas++;
                                                            }

                                                            return (
                                                                <Td key={anoMes.ano} alignCenter color="#fff">
                                                                    {parseFloat(anoMes.valor || 0)
                                                                        .toFixed(6)
                                                                        .replace(".", ",")}
                                                                </Td>
                                                            );
                                                        });
                                                } else {
                                                    return (
                                                        <Td key={ano} alignCenter color="#fff">
                                                            0,00
                                                        </Td>
                                                    );
                                                }
                                            })}
                                            {renderizarCelulasComZeros(corCiclo, listaGraficoTarifaMediaGeral)}

                                            <Td alignCenter color="#fff">
                                                {(tarifaMediaAnual > 0 && quantidadeTarifas > 0 ? tarifaMediaAnual / quantidadeTarifas : 0).toFixed(6).replace(".", ",")}
                                            </Td>
                                        </Tr>
                                    );
                                })}
                            </TBody>
                        </Table>
                    </div>
                </ContentCardBody>
            </ContentCard>
        </div>
    );
}

const mapStateToProps = (state) => ({
    contratoDemanda: state.analise.contratoDemanda,
    listaGraficoTarifaMedia: state.analise.listaGraficoTarifaMedia,
    listaGraficoTarifaMediaGeral: state.analise.listaGraficoTarifaMediaGeral,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getDemanda,
            getListaGraficoTarifaMedia,
            getListaGraficoTarifaMediaGeral,
        },
        dispatch
    );
const AnaliseTarifa = connect(mapStateToProps, mapDispatchToProps)(analiseTarifa);
export { AnaliseTarifa };

import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ContentCard from "../../common/template/contentCard";
import ContentCardHeader from "../../common/template/contentCardHeader";
import ContentCardBody from "../../common/template/contentCardBody";
import Row from "../../common/layout/row";
import Grid from "../../common/layout/grid";
import Table from "../../common/table/table";
import THead from "../../common/table/tHead";
import Tr from "../../common/table/tr";
import Th from "../../common/table/th";
import TBody from "../../common/table/tBody";
import Td from "../../common/table/td";
import Button from "../../common/button/button";
import Select from "../../common/form/select";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";

import { setError } from "../../common/toast/toast.jsx";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
    getDemanda,
    getListaGraficoCustoAnual,
    getListaCustoPorUnidadePeriodo,
    getListaGraficoCustoPorUnidadeMensal,
    getListaGraficoCustoPorUnidadeAcumuladoAno,
    getListarGraficoCustoJurosPorAtrasoPagamentoAnual,
    getListarGraficoCustoJurosPorAtrasoPagamentoMensal,
    getListaGraficoCustoPorUnidadeAnual,
    setFiltro,
} from "../analiseActions";

import { montarGraficoColumn } from "../../common/graficos/montarGraficoColumn.js";
import { montarGraficoGroupColumn } from "../../common/graficos/montarGraficoGroupColumn.js";
import { montarGraficoClusteredColumnMensalSafra } from "../../common/graficos/montarGraficoClusteredColumnMensalSafra.js";
import { montarGraficoClusteredColumnMensal } from "../../common/graficos/montarGraficoClusteredColumnMensal.js";
import { montarGraficoClusteredColumnAnual } from "../../common/graficos/montarGraficoClusteredColumnAnual.js";
import { montarGraficoClusteredColumnGrupo } from "../../common/graficos/montarGraficoClusteredColumnGrupo.js";
import { montarGraficoHorizontalBar } from "../../common/graficos/montarGraficoHorizontalBar.js";
import { adicionarPontosMilhar } from "../../utils/adicionarPontosMilhar.js";

function analiseCusto(props) {
    const {
        state,
        listar,
        contratoDemanda,
        listaGraficoCustoAnual,
        listaGraficoCustoPorUnidadeAcumuladoAno,
        listaGraficoCustoJurosPorAtrasoPagamentoAnual,
        listaGraficoCustoJurosPorAtrasoPagamentoMensal,
        listaUnidadeConsumidora,
        listaGraficoCustoUnidadeAnual,
        listaGraficoCustoUnidadeMensal,
        listaCustoPorUnidadePeriodo,
        filtro,
        getDemanda,
        getListaGraficoCustoAnual,
        getListaCustoPorUnidadePeriodo,
        getListaGraficoCustoPorUnidadeMensal,
        getListaGraficoCustoPorUnidadeAcumuladoAno,
        getListarGraficoCustoJurosPorAtrasoPagamentoAnual,
        getListarGraficoCustoJurosPorAtrasoPagamentoMensal,
        getListaGraficoCustoPorUnidadeAnual,
        setAguardando,
        setFiltro,
        listaAnos,
    } = props;

    const [graficoCustoAnual, setGraficoCustoAnual] = useState(null);
    const [graficoCustoMensal, setGraficoCustoMensal] = useState(null);
    const [graficoCustoUnidadeAnual, setGraficoCustoUnidadeAnual] = useState(null);
    const [graficoCustoUnidadeMensal, setGraficoCustoUnidadeMensal] = useState(null);
    const [graficoCustoPorUnidadeAcumuladoAno, setGraficoCustoPorUnidadeAcumuladoAno] = useState(null);
    const [graficoCustoJurosPorAtrasoPagamentoAnual, setGraficoCustoJurosPorAtrasoPagamentoAnual] = useState(null);
    const [graficoCustoJurosPorAtrasoPagamentoMensal, setGraficoCustoJurosPorAtrasoPagamentoMensal] = useState(null);

    useEffect(() => {
        atualizarTodosGraficos();
        return desmontarTodosGraficos;
    }, []);

    useEffect(() => {
        const buscarDados = async () => {
            if (!state.aguardando) return;

            await Promise.all([
                getDemanda(),
                getListaGraficoCustoAnual(),
                getListaCustoPorUnidadePeriodo(),
                getListaGraficoCustoPorUnidadeMensal(),
                getListaGraficoCustoPorUnidadeAcumuladoAno(),
                getListarGraficoCustoJurosPorAtrasoPagamentoAnual(),
                getListarGraficoCustoJurosPorAtrasoPagamentoMensal(),
                getListaGraficoCustoPorUnidadeAnual(),
            ]);

            setAguardando(false);
        };

        buscarDados();
        if (!state.aguardando) {
            atualizarTodosGraficos();
        }
    }, [state.aguardando]);

    function atualizarTodosGraficos() {
        atualizarListaGraficoCustoAnual();
        atualizarListaGraficoCustoPorUnidadeAnual();
        atualizarListaGraficoCustoPorUnidadeMensal();
        atualizarListaGraficoCustoPorUnidadeAcumuladoAno();
        atualizarListaGraficoCustoJurosPorAtrasoPagamentoAnual();
        atualizarListaGraficoCustoJurosPorAtrasoPagamentoMensal();
    }
    function desmontarTodosGraficos() {
        if (graficoCustoAnual) graficoCustoAnual.dispose();
        if (graficoCustoMensal) graficoCustoMensal.dispose();
        if (graficoCustoUnidadeAnual) graficoCustoUnidadeAnual.dispose();
        if (graficoCustoUnidadeMensal) graficoCustoUnidadeMensal.dispose();
        if (graficoCustoPorUnidadeAcumuladoAno) graficoCustoPorUnidadeAcumuladoAno.dispose();
        if (graficoCustoJurosPorAtrasoPagamentoAnual) graficoCustoJurosPorAtrasoPagamentoAnual.dispose();
        if (graficoCustoJurosPorAtrasoPagamentoMensal) graficoCustoJurosPorAtrasoPagamentoMensal.dispose();
    }

    function atualizarListaGraficoCustoAnual() {
        const graficoCustoAnualAtualizado = montarGraficoClusteredColumnAnual(state, graficoCustoAnual, "graficoCustoAnual", listaGraficoCustoAnual, "Custo Total (R$)", "R$", "");
        setGraficoCustoAnual(graficoCustoAnualAtualizado);

        const montarGraficoMensal = filtro.custo_ano_safra ? montarGraficoClusteredColumnMensalSafra : montarGraficoClusteredColumnMensal;
        const graficoCustoMensalAtualizado = montarGraficoMensal(state, graficoCustoMensal, "graficoCustoMensal", listaGraficoCustoAnual, "Custo Mensal (R$)", "R$", "");
        setGraficoCustoMensal(graficoCustoMensalAtualizado);
    }

    function atualizarListaGraficoCustoPorUnidadeAnual() {
        const graficoCustoUnidadeAnualAtualizado = montarGraficoClusteredColumnGrupo(
            state,
            graficoCustoUnidadeAnual,
            "graficoCustoUnidadeAnual",
            listaGraficoCustoUnidadeAnual.filter((item) => !(filtro.unidades_custo_anual || []).length || (filtro.unidades_custo_anual || []).find((itemFiltro) => itemFiltro.label == item.agrupador)),
            "Custo Total por Unidade (R$)",
            "R$",
            ""
        );
        setGraficoCustoUnidadeAnual(graficoCustoUnidadeAnualAtualizado);
    }
    function atualizarListaGraficoCustoPorUnidadeMensal() {
        const montarGrafico = filtro.custo_ano_safra ? montarGraficoClusteredColumnMensalSafra : montarGraficoClusteredColumnMensal;
        const graficoCustoUnidadeMensalAtuaizado = montarGrafico(
            state,
            graficoCustoUnidadeMensal,
            "graficoCustoUnidadeMensal",
            listaGraficoCustoUnidadeMensal.map((item) => ({
                mes: item.mes,
                anos: item.tipos
                    .filter((tipo) => !(filtro.unidades_custo_anual || []).length || (filtro.unidades_custo_anual || []).find((itemFiltro) => itemFiltro.label == tipo.tipo))
                    .map((tipo) => ({
                        ano: tipo.tipo.substring(0, 20) + (tipo.tipo.length > 20 ? "..." : ""),
                        valor: tipo.valor || 0,
                    })),
            })),
            "Custo Mensal por Unidade (R$)",
            "R$",
            ""
        );
        setGraficoCustoUnidadeMensal(graficoCustoUnidadeMensalAtuaizado);
    }

    function mapearListaGraficoColunasAgrupadas(lista) {
        const listaMapeada = [];
        const nomeColuna = filtro.custo_ano_safra ? "ano_inicio_fim_safra" : "ano";
        const anos = [];

        try {
            //monta uma lista com todos os anos existentes em todos meses
            for (const registro of lista) {
                const listaAnoValor = registro.itens.filter((item) => item[nomeColuna]); //remove registro com ano nulo
                for (const item of listaAnoValor) {
                    const ano = item[nomeColuna];
                    if (!anos.includes(ano)) {
                        anos.push(ano);
                    }
                }
            }
            anos.sort((a, b) => {
                return filtro.custo_ano_safra ? parseInt(a.split("/")[0]) - parseInt(b.split("/")[0]) : parseInt(a) - parseInt(b);
            });
            //percorre todos os meses
            for (const registro of lista) {
                //se encontrar o ano do mes na lista de anos, pega o respectivo valor, senao define o valor zero para o ano
                const colunas = { mes: registro.mes };
                for (const ano of anos) {
                    const anoExisteNoMes = registro.itens.find((item) => item[nomeColuna] == ano);
                    if (anoExisteNoMes && parseFloat(anoExisteNoMes.valor)) {
                        colunas[ano] = parseFloat(anoExisteNoMes.valor);
                    } else {
                        colunas[ano] = 0;
                    }
                }
                listaMapeada.push(colunas);
            }
            return {
                dados: listaMapeada,
                valuesAndNames: anos.map((item) => ({ value: item, name: item })),
            };
        } catch (erro) {
            console.log(erro);
            setError("Erro ao gerar gráficos de custo");
            return {
                dados: [],
                valuesAndNames: [],
            };
        }
    }

    function atualizarListaGraficoCustoJurosPorAtrasoPagamentoAnual() {
        const graficoCustoJurosPorAtrasoPagamentoAtualAtualizado = montarGraficoColumn({
            state,
            component: graficoCustoJurosPorAtrasoPagamentoAnual,
            nome: "graficoCustoJurosPorAtrasoPagamentoAnual",
            dados: listaGraficoCustoJurosPorAtrasoPagamentoAnual,
            category: filtro.custo_ano_safra ? "ano_inicio_fim_safra" : "ano",
            value: "valor",
            titulo: `Custo de Juros por Atraso de Pagamento por ${filtro.custo_ano_safra ? "Safra" : "Ano"} (R$)`,
            prefixo: "R$",
            sufixo: "",
        });
        setGraficoCustoJurosPorAtrasoPagamentoAnual(graficoCustoJurosPorAtrasoPagamentoAtualAtualizado);
    }
    function atualizarListaGraficoCustoJurosPorAtrasoPagamentoMensal() {
        const { dados, valuesAndNames } = mapearListaGraficoColunasAgrupadas(listaGraficoCustoJurosPorAtrasoPagamentoMensal);
        const graficoCustoJurosPorAtrasoPagamentoMensalAtualizado = montarGraficoGroupColumn({
            state,
            component: graficoCustoJurosPorAtrasoPagamentoMensal,
            nome: "graficoCustoJurosPorAtrasoPagamentoMensal",
            dados,
            category: "mes",
            valuesAndNames,
            titulo: "Custo de Juros por Atraso de Pagamento (R$)",
            prefixo: "R$",
            sufixo: "",
            dimensions: "3D",
            mostrarLegenda: true,
            chartBackground: "#30303d",
        });
        setGraficoCustoJurosPorAtrasoPagamentoMensal(graficoCustoJurosPorAtrasoPagamentoMensalAtualizado);
    }

    function atualizarListaGraficoCustoPorUnidadeAcumuladoAno() {
        const graficoCustoPorUnidadeAcumuladoAnoAtualizado = montarGraficoHorizontalBar({
            component: graficoCustoPorUnidadeAcumuladoAno,
            chartDivId: "graficoCustoPorUnidadeAcumuladoAno",
            category: "agrupador",
            value: "valor",
            bullet: "valor",
            bulletPosition: "out",
            bulletColor: "#fff",
            lista: [...listaGraficoCustoPorUnidadeAcumuladoAno].sort((a, b) => a.valor - b.valor),
            titulo: "Custo Acumulado Anual por UC (R$)",
            prefixo: "R$",
            sufixo: "",
        });
        setGraficoCustoPorUnidadeAcumuladoAno(graficoCustoPorUnidadeAcumuladoAnoAtualizado);
    }

    function renderizarCelulasComZeros(corCiclo, listaDados) {
        let listaMeses = [];
        for (let i = listaDados.length; i < 12; i++) {
            listaMeses.push(i);
        }

        return listaMeses.map((mes) => (
            <Td key={mes} alignCenter color="#fff" backgroundColor={corCiclo}>
                0
            </Td>
        ));
    }

    function renderizarCabecalhoMeses({ ucFiltradaSazonal, demandaMesInicial, demandaMesFinal }) {
        const janeiroAteMaio = (
            <>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "01" ? "#66bb6a" : demandaMesFinal == "01" ? "#ef5350" : null) : null,
                    }}
                >
                    Janeiro
                    {ucFiltradaSazonal ? (demandaMesInicial == "01" ? " (I)" : demandaMesFinal == "01" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "02" ? "#66bb6a" : demandaMesFinal == "02" ? "#ef5350" : null) : null,
                    }}
                >
                    Fevereiro
                    {ucFiltradaSazonal ? (demandaMesInicial == "02" ? " (I)" : demandaMesFinal == "02" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "03" ? "#66bb6a" : demandaMesFinal == "03" ? "#ef5350" : null) : null,
                    }}
                >
                    Março
                    {ucFiltradaSazonal ? (demandaMesInicial == "03" ? " (I)" : demandaMesFinal == "03" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "04" ? "#66bb6a" : demandaMesFinal == "04" ? "#ef5350" : null) : null,
                    }}
                >
                    Abril
                    {ucFiltradaSazonal ? (demandaMesInicial == "04" ? " (I)" : demandaMesFinal == "04" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "05" ? "#66bb6a" : demandaMesFinal == "05" ? "#ef5350" : null) : null,
                    }}
                >
                    Maio
                    {ucFiltradaSazonal ? (demandaMesInicial == "05" ? " (I)" : demandaMesFinal == "05" ? " (F)" : "") : ""}
                </Th>{" "}
            </>
        );
        const junhoAteDez = (
            <>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "06" ? "#66bb6a" : demandaMesFinal == "06" ? "#ef5350" : null) : null,
                    }}
                >
                    Junho
                    {ucFiltradaSazonal ? (demandaMesInicial == "06" ? " (I)" : demandaMesFinal == "06" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "07" ? "#66bb6a" : demandaMesFinal == "07" ? "#ef5350" : null) : null,
                    }}
                >
                    Julho
                    {ucFiltradaSazonal ? (demandaMesInicial == "07" ? " (I)" : demandaMesFinal == "07" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "08" ? "#66bb6a" : demandaMesFinal == "08" ? "#ef5350" : null) : null,
                    }}
                >
                    Agosto
                    {ucFiltradaSazonal ? (demandaMesInicial == "08" ? " (I)" : demandaMesFinal == "08" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "09" ? "#66bb6a" : demandaMesFinal == "09" ? "#ef5350" : null) : null,
                    }}
                >
                    Setembro
                    {ucFiltradaSazonal ? (demandaMesInicial == "09" ? " (I)" : demandaMesFinal == "09" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "10" ? "#66bb6a" : demandaMesFinal == "10" ? "#ef5350" : null) : null,
                    }}
                >
                    Outubro
                    {ucFiltradaSazonal ? (demandaMesInicial == "10" ? " (I)" : demandaMesFinal == "10" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "11" ? "#66bb6a" : demandaMesFinal == "11" ? "#ef5350" : null) : null,
                    }}
                >
                    Novembro
                    {ucFiltradaSazonal ? (demandaMesInicial == "11" ? " (I)" : demandaMesFinal == "11" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "12" ? "#66bb6a" : demandaMesFinal == "12" ? "#ef5350" : null) : null,
                    }}
                >
                    Dezembro
                    {ucFiltradaSazonal ? (demandaMesInicial == "12" ? " (I)" : demandaMesFinal == "12" ? " (F)" : "") : ""}
                </Th>
            </>
        );

        return filtro.custo_ano_safra ? (
            <>
                {junhoAteDez}
                {janeiroAteMaio}
            </>
        ) : (
            <>
                {janeiroAteMaio}
                {junhoAteDez}
            </>
        );
    }

    let ucFiltradaSazonal = false;
    let demandaMesInicial = "";
    let demandaMesFinal = "";

    //se é não sazonal, pega os últimos 12 meses,
    const mesAtualDate = new Date().getMonth(); //não soma 1 pq o mes atual não deve ser contabilizado
    const anoAtual = String(new Date().getFullYear());
    //AQUI É A LÓGICA PARA PINTAR AS CÉLULAS DE CINZA
    if (contratoDemanda) {
        const mesLimite = mesAtualDate < 10 ? "0" + mesAtualDate : String(mesAtualDate);
        const mesesDescontar = Number(mesLimite) - 11; //diminui 11 meses para pegar o mes inicial dos últimos 12 meses
        const anoInicialCalculado = mesesDescontar > 0 ? anoAtual : anoAtual - 1; //se descontando 11 meses der negativo, considera ano anterior
        const mesInicialCalculado = Number(mesLimite) + 1 > 12 ? "01" : Number(mesLimite) + 1; //mes atual 10, inicia em 10+1 do ano anterior
        const competenciaInicialCalculada = Number(String(anoInicialCalculado) + String(mesInicialCalculado)); //usada pra comparar com competencia base
        //se competencia base for maior considera ela como inicial, senão considera a calculada
        const competenciaBaseSazonalidade = contratoDemanda.competencia_base_sazonalidade > competenciaInicialCalculada ? contratoDemanda.competencia_base_sazonalidade : competenciaInicialCalculada;

        ucFiltradaSazonal = contratoDemanda.unidade_consumidora_sazonal;
        demandaMesInicial = ucFiltradaSazonal ? String(contratoDemanda.competencia_inicio).substring(4, 6) : String(competenciaBaseSazonalidade).substring(4, 6);
        demandaMesFinal = ucFiltradaSazonal ? String(contratoDemanda.competencia_fim).substring(4, 6) : mesLimite;
    }

    let corCiclo = "#30303d";

    let anosCusto = [];
    listaGraficoCustoAnual.forEach((item) => {
        item.anos.forEach((ano) => {
            if (anosCusto.filter((itemAno) => itemAno == ano.ano).length <= 0) {
                anosCusto.push(ano.ano);
            }
        });
    });

    anosCusto = anosCusto.sort((a, b) => parseInt(String(a).substring(0, 4)) - parseInt(String(b).substring(0, 4)));

    let valorUnidadeAnualTotal = 0;
    listaGraficoCustoPorUnidadeAcumuladoAno.forEach((item) => {
        valorUnidadeAnualTotal = parseFloat(item.valor) > valorUnidadeAnualTotal ? parseFloat(item.valor) : valorUnidadeAnualTotal;
    });

    let listaNomesUCs = [];
    listaGraficoCustoUnidadeMensal.forEach((item) =>
        item.tipos.forEach((uc) => {
            if (!listaNomesUCs.includes(uc.tipo)) listaNomesUCs.push(uc.tipo);
        })
    );

    const listaTabelaCustoJurosPorAtrasoPagamentoAnual = graficoCustoJurosPorAtrasoPagamentoAnual ? graficoCustoJurosPorAtrasoPagamentoAnual._data : [];
    const periodoCustoJurosPorAtrasoPagamentoAnual = Object.keys(listaGraficoCustoJurosPorAtrasoPagamentoAnual[0] || {})[0];
    return (
        <div>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",
                        overflowX: "scroll",
                    }}
                >
                    <div
                        id="graficoCustoAnual"
                        style={{
                            width: "100%",
                            minWidth: "890px",
                            height: "400px",
                        }}
                    ></div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",
                        overflowX: "scroll",
                    }}
                >
                    <div
                        id="graficoCustoMensal"
                        style={{
                            width: "100%",
                            minWidth: "890px",
                            height: "400px",
                        }}
                    ></div>
                    <div
                        style={{
                            minWidth: "890px",
                        }}
                    >
                        <Table responsive>
                            <THead>
                                <Tr>
                                    <Th>Ano</Th>
                                    {renderizarCabecalhoMeses({ ucFiltradaSazonal, demandaMesInicial, demandaMesFinal })}
                                </Tr>
                            </THead>
                            <TBody>
                                {anosCusto.map((ano) => {
                                    return (
                                        <Tr key={ano}>
                                            <Td color="#fff">{ano}</Td>

                                            {listaGraficoCustoAnual.map((mes) => {
                                                if (mes.anos.find((anoMes) => anoMes.ano == ano)) {
                                                    return mes.anos
                                                        .filter((anoMes) => anoMes.ano == ano)
                                                        .map((anoMes) => {
                                                            return (
                                                                <Td key={anoMes.ano} alignCenter color="#fff">
                                                                    {parseFloat(anoMes.valor || 0)
                                                                        .toFixed(2)
                                                                        .replace(".", ",")}
                                                                </Td>
                                                            );
                                                        });
                                                } else {
                                                    return (
                                                        <Td key={ano} alignCenter color="#fff">
                                                            0,00
                                                        </Td>
                                                    );
                                                }
                                            })}

                                            {renderizarCelulasComZeros(corCiclo, listaGraficoCustoAnual)}
                                        </Tr>
                                    );
                                })}
                            </TBody>
                        </Table>
                    </div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",
                        overflowX: "scroll",
                    }}
                >
                    <div
                        id="graficoCustoJurosPorAtrasoPagamentoAnual"
                        style={{
                            width: "100%",
                            minWidth: "890px",
                            height: "400px",
                        }}
                    ></div>
                </ContentCardBody>
                <ContentCardBody style={{ backgroundColor: "#30303d", overflowX: "scroll" }}>
                    <div style={{ minWidth: "890px" }}>
                        <Table responsive>
                            <THead>
                                <Tr>
                                    <Th width={250}></Th>
                                    {listaTabelaCustoJurosPorAtrasoPagamentoAnual.map((item) => (
                                        <Th alignCenter>{item[periodoCustoJurosPorAtrasoPagamentoAnual]}</Th>
                                    ))}
                                </Tr>
                            </THead>
                            <TBody>
                                <Tr>
                                    <Td color="#fff">R$ Total</Td>
                                    {listaTabelaCustoJurosPorAtrasoPagamentoAnual.map((item) => (
                                        <Td key={item[periodoCustoJurosPorAtrasoPagamentoAnual]} alignCenter color="#fff">
                                            {parseFloat(item.valor_fatura || 0)
                                                .toFixed(2)
                                                .replace(".", ",")}
                                        </Td>
                                    ))}
                                </Tr>
                                <Tr>
                                    <Td color="#fff">R$ Juros por Atraso de Pagamento</Td>
                                    {listaTabelaCustoJurosPorAtrasoPagamentoAnual.map((item) => (
                                        <Td key={item[periodoCustoJurosPorAtrasoPagamentoAnual]} alignCenter color="#fff">
                                            {parseFloat(item.valor || 0)
                                                .toFixed(2)
                                                .replace(".", ",")}
                                        </Td>
                                    ))}
                                </Tr>
                                <Tr>
                                    <Td color="#fff">Rel. %</Td>
                                    {listaTabelaCustoJurosPorAtrasoPagamentoAnual.map((item) => (
                                        <Td key={item[periodoCustoJurosPorAtrasoPagamentoAnual]} alignCenter color="#fff">
                                            {parseFloat(item.valor_fatura > 0 ? (item.valor * 100) / item.valor_fatura : 0)
                                                .toFixed(2)
                                                .replace(".", ",")}
                                            %
                                        </Td>
                                    ))}
                                </Tr>
                            </TBody>
                        </Table>
                    </div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",
                        overflowX: "scroll",
                    }}
                >
                    <div
                        id="graficoCustoJurosPorAtrasoPagamentoMensal"
                        style={{
                            width: "100%",
                            minWidth: "890px",
                            height: "400px",
                        }}
                    ></div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ padding: 8 }}>
                <ContentCardHeader style={{}}>
                    <Row>
                        <Select
                            options={listaUnidadeConsumidora.filter((item) => !filtro.id_cliente || item.id_cliente == filtro.id_cliente)}
                            label="Unidades Consumidoras"
                            cols="12 12 12 12"
                            placeholder="Selecione as UCs"
                            isMulti={true}
                            value={filtro.unidades_custo_anual}
                            onChange={(value) => {
                                setFiltro({
                                    ...filtro,
                                    unidades_custo_anual: value,
                                });
                            }}
                        />
                        <Grid cols="6 4 3 2">
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                <DatePicker
                                    variant="inline"
                                    openTo="year"
                                    views={["year", "month"]}
                                    label="De"
                                    autoOk
                                    value={
                                        filtro.competencia_inicial_unidade_custo
                                            ? `${String(filtro.competencia_inicial_unidade_custo).substring(0, 4)}/${String(filtro.competencia_inicial_unidade_custo).substring(4, 6)}/01 12:00:00`
                                            : null
                                    }
                                    onChange={(value) => {
                                        setFiltro({
                                            ...filtro,
                                            competencia_inicial_unidade_custo: value ? parseInt(`${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${value.getMonth() + 1}`) : null,
                                        });
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid cols="6 4 3 2">
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                <DatePicker
                                    variant="inline"
                                    openTo="year"
                                    views={["year", "month"]}
                                    label="Até"
                                    autoOk
                                    value={
                                        filtro.competencia_final_unidade_custo
                                            ? `${String(filtro.competencia_final_unidade_custo).substring(0, 4)}/${String(filtro.competencia_final_unidade_custo).substring(4, 6)}/01 12:00:00`
                                            : null
                                    }
                                    onChange={(value) => {
                                        setFiltro({
                                            ...filtro,
                                            competencia_final_unidade_custo: value ? parseInt(`${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${value.getMonth() + 1}`) : null,
                                        });
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid cols="6 4 3 2" style={{ paddingTop: 10 }}>
                            <Button
                                text="Limpar Período"
                                type={"primary"}
                                icon={"fa fa-minus"}
                                event={async () => {
                                    setFiltro({
                                        ...filtro,
                                        competencia_inicial_unidade_custo: null,
                                        competencia_final_unidade_custo: null,
                                    });
                                }}
                            />
                        </Grid>
                        <Grid cols="6 4 3 2" style={{ paddingTop: 10 }}>
                            <Button text="Atualizar" type={"primary"} icon={"fa fa-sync"} event={listar} />
                        </Grid>
                    </Row>
                </ContentCardHeader>
                <ContentCardBody
                    style={{
                        color: "#fff",
                        backgroundColor: "#30303d",
                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoCustoUnidadeAnual" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",
                        overflowX: "scroll",
                        color: "#fff",
                    }}
                >
                    <div id="graficoCustoUnidadeMensal" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>{" "}
                    <div style={{ minWidth: "890px" }}>
                        <Table responsive>
                            <THead>
                                <Tr>
                                    <Th width={200}>UC</Th>
                                    {renderizarCabecalhoMeses({})}
                                </Tr>
                            </THead>
                            <TBody>
                                {listaNomesUCs
                                    .sort((a, b) => a - b)
                                    .filter((nomeUc) => !(filtro.unidades_custo_anual || []).length || (filtro.unidades_custo_anual || []).find((itemFiltro) => itemFiltro.label == nomeUc))
                                    .map((nomeUc) => (
                                        <Tr key={nomeUc}>
                                            <Td color="#fff">{nomeUc}</Td>
                                            {listaGraficoCustoUnidadeMensal.map((mes) => {
                                                if (mes.tipos.find((tipoMes) => tipoMes.tipo == nomeUc)) {
                                                    return mes.tipos
                                                        .filter((tipoMes) => tipoMes.tipo == nomeUc)
                                                        .map((tipoMes) => {
                                                            return (
                                                                <Td key={tipoMes.tipo} alignCenter color="#fff">
                                                                    {parseFloat(tipoMes.valor || 0)
                                                                        .toFixed(2)
                                                                        .replace(".", ",")}
                                                                </Td>
                                                            );
                                                        });
                                                } else {
                                                    return (
                                                        <Td key={nomeUc} alignCenter color="#fff">
                                                            0,00
                                                        </Td>
                                                    );
                                                }
                                            })}
                                            {renderizarCelulasComZeros(corCiclo, listaGraficoCustoUnidadeMensal)}
                                        </Tr>
                                    ))}
                            </TBody>
                        </Table>
                    </div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ padding: 8 }}>
                <ContentCardHeader>
                    <Row>
                        <Grid cols="12 6 5 5">
                            <Row>
                                <Grid cols="12 12 12 12">
                                    <label>Período A</label>
                                </Grid>
                                <Grid cols="6 6 6 6">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                        <DatePicker
                                            variant="inline"
                                            openTo="year"
                                            views={["year", "month"]}
                                            label="De"
                                            autoOk
                                            value={
                                                filtro.competencia_inicial_custo_comparativo_antes
                                                    ? `${String(filtro.competencia_inicial_custo_comparativo_antes).substring(0, 4)}/${String(filtro.competencia_inicial_custo_comparativo_antes).substring(
                                                          4,
                                                          6
                                                      )}/01 12:00:00`
                                                    : null
                                            }
                                            onChange={(value) => {
                                                setFiltro({
                                                    ...filtro,
                                                    competencia_inicial_custo_comparativo_antes: value ? parseInt(`${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${value.getMonth() + 1}`) : null,
                                                });
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid cols="6 6 6 6">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                        <DatePicker
                                            variant="inline"
                                            openTo="year"
                                            views={["year", "month"]}
                                            label="Até"
                                            autoOk
                                            value={
                                                filtro.competencia_final_custo_comparativo_antes
                                                    ? `${String(filtro.competencia_final_custo_comparativo_antes).substring(0, 4)}/${String(filtro.competencia_final_custo_comparativo_antes).substring(4, 6)}/01 12:00:00`
                                                    : null
                                            }
                                            onChange={(value) => {
                                                setFiltro({
                                                    ...filtro,
                                                    competencia_final_custo_comparativo_antes: value ? parseInt(`${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${value.getMonth() + 1}`) : null,
                                                });
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Row>
                        </Grid>
                        <Grid cols="12 6 5 5">
                            <Row>
                                <Grid cols="12 12 12 12">
                                    <label>Período B</label>
                                </Grid>
                                <Grid cols="6 6 6 6">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                        <DatePicker
                                            variant="inline"
                                            openTo="year"
                                            views={["year", "month"]}
                                            label="De"
                                            autoOk
                                            value={
                                                filtro.competencia_inicial_custo_comparativo_depois
                                                    ? `${String(filtro.competencia_inicial_custo_comparativo_depois).substring(0, 4)}/${String(filtro.competencia_inicial_custo_comparativo_depois).substring(
                                                          4,
                                                          6
                                                      )}/01 12:00:00`
                                                    : null
                                            }
                                            onChange={(value) => {
                                                setFiltro({
                                                    ...filtro,
                                                    competencia_inicial_custo_comparativo_depois: value ? parseInt(`${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${value.getMonth() + 1}`) : null,
                                                });
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid cols="6 6 6 6">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                        <DatePicker
                                            variant="inline"
                                            openTo="year"
                                            views={["year", "month"]}
                                            label="Até"
                                            autoOk
                                            value={
                                                filtro.competencia_final_custo_comparativo_depois
                                                    ? `${String(filtro.competencia_final_custo_comparativo_depois).substring(0, 4)}/${String(filtro.competencia_final_custo_comparativo_depois).substring(
                                                          4,
                                                          6
                                                      )}/01 12:00:00`
                                                    : null
                                            }
                                            onChange={(value) => {
                                                setFiltro({
                                                    ...filtro,
                                                    competencia_final_custo_comparativo_depois: value ? parseInt(`${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${value.getMonth() + 1}`) : null,
                                                });
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Row>
                        </Grid>

                        <Grid cols="12 12 2 2" style={{ paddingTop: 10 }}>
                            <Button
                                text="Atualizar"
                                type={"primary"}
                                icon={"fa fa-sync"}
                                event={() => {
                                    listar();
                                }}
                            />
                        </Grid>
                    </Row>
                </ContentCardHeader>
                <ContentCardBody
                    style={{
                        color: "#fff",
                        backgroundColor: "#30303d",
                        overflowX: "scroll",
                    }}
                >
                    <p className="text-center" style={{ fontSize: 16 }}>
                        Tabela Comparativa para Diferentes Períodos
                    </p>
                    <div
                        style={{
                            minWidth: "890px",
                        }}
                    >
                        <Table responsive>
                            <THead>
                                <Tr>
                                    <Th>Unidade</Th>
                                    <Th alignCenter>
                                        {filtro.competencia_inicial_custo_comparativo_antes && filtro.competencia_final_custo_comparativo_antes
                                            ? `De ${String(filtro.competencia_inicial_custo_comparativo_antes).substring(4, 6)}/${String(filtro.competencia_inicial_custo_comparativo_antes).substring(0, 4)} até ${String(
                                                  filtro.competencia_final_custo_comparativo_antes
                                              ).substring(4, 6)}/${String(filtro.competencia_final_custo_comparativo_antes).substring(0, 4)}`
                                            : ""}
                                    </Th>
                                    <Th alignCenter>Comparativo</Th>
                                    <Th alignCenter>
                                        {filtro.competencia_inicial_custo_comparativo_depois && filtro.competencia_final_custo_comparativo_depois
                                            ? `De ${String(filtro.competencia_inicial_custo_comparativo_depois).substring(4, 6)}/${String(filtro.competencia_inicial_custo_comparativo_depois).substring(
                                                  0,
                                                  4
                                              )} até ${String(filtro.competencia_final_custo_comparativo_depois).substring(4, 6)}/${String(filtro.competencia_final_custo_comparativo_depois).substring(0, 4)}`
                                            : ""}
                                    </Th>
                                </Tr>
                            </THead>
                            <TBody>
                                {listaCustoPorUnidadePeriodo.map((item, i) => {
                                    function calcularComparativo(item) {
                                        let comparativo = parseFloat(item.valor_atual) > 0 && parseFloat(item.valor_anterior) > 0 ? (parseFloat(item.valor_anterior) * 100) / parseFloat(item.valor_atual) : 0;
                                        if (comparativo > 0) {
                                            comparativo = 100 - comparativo;
                                        }
                                        return comparativo;
                                    }

                                    const objetoTotais = listaCustoPorUnidadePeriodo.reduce(
                                        (objetoTotais, elemento) => {
                                            objetoTotais.valor_anterior += parseFloat(elemento.valor_anterior || 0);
                                            objetoTotais.valor_atual += parseFloat(elemento.valor_atual || 0);
                                            return objetoTotais;
                                        },
                                        { valor_anterior: 0, valor_atual: 0 }
                                    );

                                    const comparativo = calcularComparativo(item);
                                    const comparativoTotais = calcularComparativo(objetoTotais);

                                    const linhaTotais = (
                                        <Tr key={"Todas Unidades"}>
                                            <Td color="#fff" backgroundColor="#257bfb">
                                                <b>Todas Unidades</b>
                                            </Td>
                                            <Td alignCenter color="#fff" backgroundColor="#257bfb">
                                                <b>R$ {adicionarPontosMilhar(parseFloat(objetoTotais.valor_anterior || 0).toFixed(2))}</b>
                                            </Td>
                                            <Td alignCenter color="#fff" backgroundColor={comparativoTotais == 0 ? "#257bfb" : comparativoTotais > 0 ? "#ef5350" : "#28a745"}>
                                                <b>{parseFloat(objetoTotais.valor_atual) == 0 ? `100,00` : comparativoTotais.toFixed(2).replace(".", ",")}%</b>
                                            </Td>
                                            <Td alignCenter color="#fff" backgroundColor="#257bfb">
                                                <b>R$ {adicionarPontosMilhar(parseFloat(objetoTotais.valor_atual || 0).toFixed(2))}</b>
                                            </Td>
                                        </Tr>
                                    );

                                    return (
                                        <>
                                            {i == 0 ? linhaTotais : null}
                                            <Tr key={item.nome_unidade_consumidora}>
                                                <Td color="#fff">{item.nome_unidade_consumidora}</Td>
                                                <Td alignCenter color="#fff">
                                                    R$ {adicionarPontosMilhar(parseFloat(item.valor_anterior || 0).toFixed(2))}
                                                </Td>
                                                <Td alignCenter color="#fff" backgroundColor={comparativo == 0 ? null : comparativo > 0 ? "#ef5350" : "#28a745"}>
                                                    {parseFloat(item.valor_atual) == 0 ? `100,00` : comparativo.toFixed(2).replace(".", ",")}%
                                                </Td>
                                                <Td alignCenter color="#fff">
                                                    R$ {adicionarPontosMilhar(parseFloat(item.valor_atual || 0).toFixed(2))}
                                                </Td>
                                            </Tr>
                                        </>
                                    );
                                })}
                            </TBody>
                        </Table>
                    </div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ padding: 8 }}>
                <ContentCardHeader>
                    <Select
                        name="ano"
                        options={listaAnos}
                        label="Ano"
                        cols="12 6 3"
                        placeholder="Selecione o ano"
                        value={filtro.ano_acumulado_custo}
                        isClearable={false}
                        isSearchable={false}
                        onChange={(value) => {
                            setFiltro({
                                ...filtro,
                                ano_acumulado_custo: value,
                            });
                            listar();
                        }}
                    />
                </ContentCardHeader>

                <ContentCardBody style={{ backgroundColor: "#30303d", overflowX: "scroll", maxHeight: 400 }}>
                    <div
                        id="graficoCustoPorUnidadeAcumuladoAno"
                        style={{
                            width: "100%",
                            minWidth: 900,
                            height: (listaGraficoCustoPorUnidadeAcumuladoAno || []).length * 50 < 400 ? 400 : listaGraficoCustoPorUnidadeAcumuladoAno.length * 50,
                        }}
                    ></div>
                </ContentCardBody>
            </ContentCard>
        </div>
    );
}

const mapStateToProps = (state) => ({
    filtro: state.analise.filtro,
    contratoDemanda: state.analise.contratoDemanda,
    listaGraficoCustoAnual: state.analise.listaGraficoCustoAnual,
    listaCustoPorUnidadePeriodo: state.analise.listaCustoPorUnidadePeriodo,
    listaGraficoCustoUnidadeAnual: state.analise.listaGraficoCustoUnidadeAnual,
    listaGraficoCustoUnidadeMensal: state.analise.listaGraficoCustoUnidadeMensal,
    listaGraficoCustoPorUnidadeAcumuladoAno: state.analise.listaGraficoCustoPorUnidadeAcumuladoAno,
    listaGraficoCustoJurosPorAtrasoPagamentoAnual: state.analise.listaGraficoCustoJurosPorAtrasoPagamentoAnual,
    listaGraficoCustoJurosPorAtrasoPagamentoMensal: state.analise.listaGraficoCustoJurosPorAtrasoPagamentoMensal,
    listaUnidadeConsumidora: state.analise.listaUnidadeConsumidora,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getDemanda,
            getListaGraficoCustoAnual,
            getListaCustoPorUnidadePeriodo,
            getListaGraficoCustoPorUnidadeMensal,
            getListaGraficoCustoPorUnidadeAcumuladoAno,
            getListarGraficoCustoJurosPorAtrasoPagamentoAnual,
            getListarGraficoCustoJurosPorAtrasoPagamentoMensal,
            getListaGraficoCustoPorUnidadeAnual,
            setFiltro,
        },
        dispatch
    );
const AnaliseCusto = connect(mapStateToProps, mapDispatchToProps)(analiseCusto);
export { AnaliseCusto };

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, getFormValues } from "redux-form";

import consts from "../consts";
import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import ContentCardFooter from "../common/template/contentCardFooter";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import Form from "../common/form/form";
import LabelAndInput from "../common/form/labelAndInput";
import Select from "../common/form/select";
import { setError } from "../common/toast/toast";
import arquivoAdd from "../assets/images/file_circle_plus_solid.png";
import arquivoAdicionado from "../assets/images/file_solid.png";
import { setModoTela, initForm } from "./documentoAdmActions";

class DocumentoAdmForm extends Component {
    componentDidMount() {
        let initSeletorAnexo = () => {
            const fileSelectorAnexo = document.createElement("input");
            fileSelectorAnexo.setAttribute("type", "file");
            fileSelectorAnexo.addEventListener("change", (data) => {
                let reader = new FileReader();
                let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
                if (!file) {
                    file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
                }

                if (file) {
                    // Tipos MIME para os formatos suportados
                    const allowedMimeTypes = [
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // docx
                        "application/msword", // doc
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // xlsx
                        "application/vnd.ms-excel", // xls
                        "application/pdf",
                        "image/png",
                        "image/jpg",
                        "image/jpeg",
                    ];

                    if (!allowedMimeTypes.includes(file.type)) {
                        return setError("Selecione apenas (.DOC, .DOCX, .XLS, .XLSX, .PDF, .PNG, .JPG e .JPEG)");
                    }

                    reader.onloadend = () => {
                        let lista = (this.props.formularioValues.anexos || []).map((item) => item);
                        lista.push({
                            anexo_base64: reader.result,
                            legenda: "",
                        });

                        this.props.initForm({
                            ...this.props.formularioValues,
                            anexos: lista,
                        });

                        initSeletorAnexo();
                    };

                    reader.readAsDataURL(file);
                }
            });

            this.fileSelectorAnexo = fileSelectorAnexo;
        };

        initSeletorAnexo();
    }

    render() {
        let readOnly = this.props.excluir || this.props.visualizacao ? "readOnly" : "";

        return (
            <>
                <ContentCard>
                    <Form event={this.props.handleSubmit}>
                        <ContentCardBody>
                            <Row>
                                <Field
                                    name="id_documento_tipo"
                                    component={Select}
                                    options={this.props.listaDocumentoTipo}
                                    label="Tipo"
                                    cols="12 12 12 12"
                                    placeholder="Selecione o tipo"
                                    readOnly={readOnly}
                                />

                                <Field name="nome" component={LabelAndInput} label="Nome" placeholder="Informe o nome" cols="12 12 12 12" readOnly={readOnly} />

                                <Field name="observacao" component={LabelAndInput} label="Observação" placeholder="Informe a observação" cols="12 12 12 12" readOnly={readOnly} />
                            </Row>
                            <Row>
                                <Grid cols="12 12 12 12">
                                    <label>Anexos</label>
                                </Grid>
                                {(this.props.formularioValues.anexos || []).map((arquivo, i) => (
                                    <div
                                        key={i}
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            padding: 4,
                                        }}
                                    >
                                        <span className="btn btn-outline-primary fileinput-button dz-clickable">
                                            <img src={arquivo.anexo_base64 ? arquivoAdicionado : arquivo.anexo ? arquivoAdicionado : arquivoAdd} style={{ height: 30 }} />
                                        </span>
                                        <input
                                            className="form-control"
                                            placeholder="Legenda"
                                            style={{ marginTop: 4 }}
                                            readOnly={readOnly}
                                            type="text"
                                            value={arquivo.legenda}
                                            onChange={(event) => {
                                                this.props.initForm({
                                                    ...this.props.formularioValues,
                                                    anexos: (this.props.formularioValues.anexos || []).map((arquivoAux, j) => {
                                                        if (i == j) {
                                                            return {
                                                                ...arquivoAux,
                                                                legenda: event.target.value,
                                                            };
                                                        } else {
                                                            return arquivoAux;
                                                        }
                                                    }),
                                                });
                                            }}
                                        />
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                paddingRight: 0,
                                                marginTop: 4,
                                            }}
                                        >
                                            <button
                                                type="button"
                                                className="btn btn-flat btn-outline-primary"
                                                style={{ width: "48%", marginLeft: 0 }}
                                                disabled={arquivo.anexo_base64 ? true : false}
                                                onClick={() => {
                                                    window.open(`${consts.ARQUIVOS_URL}/${arquivo.anexo}`, "_blank");
                                                }}
                                            >
                                                <i className="fas fa-download"></i>
                                            </button>

                                            <button
                                                type="button"
                                                className="btn btn-flat btn-outline-danger"
                                                style={{ width: "48%" }}
                                                disabled={readOnly}
                                                onClick={() => {
                                                    this.props.initForm({
                                                        ...this.props.formularioValues,
                                                        anexos: this.props.formularioValues.anexos.filter((item) => item != arquivo),
                                                    });
                                                }}
                                            >
                                                <i className="fas fa-trash-alt"></i>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                                {!readOnly ? (
                                    <span
                                        className="btn btn-outline-primary fileinput-button dz-clickable"
                                        style={{ marginLeft: 8 }}
                                        onClick={() => this.fileSelectorAnexo.click()}
                                    >
                                        <img src={arquivoAdd} style={{ height: 30 }} />
                                    </span>
                                ) : null}
                            </Row>
                        </ContentCardBody>
                        <ContentCardFooter>
                            <Row alignCenter>
                                <Grid cols="6 6 4 3">
                                    {this.props.excluir ? (
                                        <Button text="Excluir" submit type={"danger"} icon={"fa fa-trash"} disabled={this.props.visualizacao} />
                                    ) : (
                                        <Button text={"Salvar"} submit type={"success"} icon={"fa fa-check"} disabled={this.props.visualizacao} />
                                    )}
                                </Grid>
                                <Grid cols="6 6 4 3">
                                    <Button text="Voltar" type={"warning"} icon={"fa fa-chevron-left"} event={() => this.props.setModoTela("lista")} />
                                </Grid>
                            </Row>
                        </ContentCardFooter>
                    </Form>
                </ContentCard>
            </>
        );
    }
}

DocumentoAdmForm = reduxForm({ form: "documentoAdmForm", destroyOnUnmount: false })(DocumentoAdmForm);
const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	formularioValues: getFormValues("documentoAdmForm")(state),
	registro: state.documentoAdm.registro,
	lista: state.documentoAdm.lista,
	listaDocumentoTipo: state.documentoAdm.listaDocumentoTipo,
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setModoTela,
			initForm,
		},
		dispatch
	);
export default connect(mapStateToProps, mapDispatchToProps)(DocumentoAdmForm);

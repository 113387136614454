import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ContentCard from "../../common/template/contentCard";
import ContentCardBody from "../../common/template/contentCardBody";
import Table from "../../common/table/table";
import THead from "../../common/table/tHead";
import Tr from "../../common/table/tr";
import NewTr from "../../common/table/newTr";
import Th from "../../common/table/th";
import TBody from "../../common/table/tBody";
import Td from "../../common/table/td";
import { ModalForm } from "../../common/dialog/modalForm";
import { getListaEmpenho } from "../analiseActions";
import { adicionarPontosMilhar } from "../../utils/adicionarPontosMilhar";
import ButtonTable from "../../common/table/buttonTable";
import { montarGraficoHorizontalBar } from "../../common/graficos/montarGraficoHorizontalBar";

function analiseGestaoEmpenho(props) {
	const { state, listaEmpenho, setAguardando, getListaEmpenho } = props;
	const [empenho, setEmpenho] = useState(null);
	const [exibirModal, setExibirModal] = useState(null);
	const [graficoValorUtilizadoPorUc, setGraficoValorUtilizadoPorUc] = useState(null);

	useEffect(() => {
		const buscarDados = async () => {
			if (!state.aguardando) return;

			await Promise.all([getListaEmpenho()]);

			setAguardando(false);
		};

		buscarDados();
	}, [state.aguardando]);

	useEffect(() => {
		const graficoValorUtilizadoPorUcAtualizado = montarGraficoHorizontalBar({
			component: graficoValorUtilizadoPorUc,
			chartDivId: "grafico-empenho",
			category: "nome_unidade_consumidora",
			value: "valor_utilizado",
			bullet: "percentual_utilizado",
			bulletPosition: "out",
			bulletColor: "#fff",
			lista: (empenho && empenho.unidades_consumidoras) || [],
			titulo: "",
			prefixo: "R$",
			sufixo: "%",
		});
		setGraficoValorUtilizadoPorUc(graficoValorUtilizadoPorUcAtualizado);
	}, [exibirModal]);

	return (
        <div>
            {exibirModal ? (
                <ModalForm titulo="Valor utilizado por UC" handleClose={() => setExibirModal(false)} contentStyle={{ maxHeight: 500, overflow: "scroll" }}>
                    <div
                        id="grafico-empenho"
                        style={{
                            backgroundColor: "#30303d",
                            borderRadius: "0.3rem",
                            width: "100%",
                            minWidth: 900,
                            height: (empenho.unidades_consumidoras || []).length * 50 < 400 ? 400 : (empenho.unidades_consumidoras || []).length * 50,
                        }}
                    />
                </ModalForm>
            ) : null}
            <ContentCard style={{ color: "#fff", padding: 8, maxHeight: 500, overflowY: "hidden" }}>
                <ContentCardBody style={{ backgroundColor: "#30303d", borderRadius: "0.3rem" }}>
                    <Table maxHeight={467} responsive>
                        <THead>
                            <Tr>
                                <Th alignCenter backgroundColor="#30303d" sticky style={{ fontSize: "0.8rem" }}>
                                    Nº do Empenho
                                </Th>
                                <Th alignCenter backgroundColor="#30303d" sticky style={{ fontSize: "0.8rem" }}>
                                    Valor Previsto
                                </Th>
                                <Th alignCenter backgroundColor="#30303d" sticky style={{ fontSize: "0.8rem" }}>
                                    Valor Total Utilizado
                                </Th>
                                <Th alignCenter backgroundColor="#30303d" sticky style={{ fontSize: "0.8rem" }}>
                                    Valor Total Disponível
                                </Th>
                                <Th alignCenter backgroundColor="#30303d" sticky style={{ fontSize: "0.8rem" }}>
                                    Detalhes
                                </Th>
                                <Th alignCenter backgroundColor="#30303d" sticky style={{ fontSize: "0.8rem" }}>
                                    Mensagem de Alerta
                                </Th>
                                <Th backgroundColor="#30303d" sticky></Th>
                            </Tr>
                        </THead>
                        {!state.aguardando ? (
                            <TBody>
                                {listaEmpenho.map((item) => (
                                    <NewTr
                                        key={item.numero}
                                        backgroundColorTr={item.percentual_utilizado < 80 ? "#66bb6a" : item.percentual_utilizado < 100 ? "#dfbb31" : "#ef5350"}
                                        style={{
                                            height: 40,
                                        }}
                                        botoes={[]}
                                    >
                                        <Td style={{ fontSize: "0.8rem" }} alignCenter minWidth={140}>
                                            {item.numero}
                                        </Td>

                                        <Td style={{ fontSize: "0.8rem" }} alignCenter minWidth={140}>
                                            {"R$ " + adicionarPontosMilhar(String(item.valor_previsto).replace(".", ","))}
                                        </Td>
                                        <Td style={{ fontSize: "0.8rem" }} alignCenter minWidth={140}>
                                            {"R$ " + adicionarPontosMilhar(String(item.valor_utilizado).replace(".", ","))}
                                        </Td>
                                        <Td style={{ fontSize: "0.8rem" }} alignCenter minWidth={140}>
                                            {"R$ " + adicionarPontosMilhar(String(item.valor_disponivel).replace(".", ","))}
                                        </Td>
                                        <Td style={{ fontSize: "0.8rem" }} alignCenter minWidth={50}>
                                            <ButtonTable
                                                solid={true}
                                                style={{
                                                    margin: 0,
                                                    borderRadius: "0.3rem",
                                                    border: "1px solid #fff",
                                                    color: "#fff",
                                                    backgroundColor: "#009aff",
                                                }}
                                                borderHover="1px solid #000"
                                                colorHover="#000"
                                                backgroundColorHover="#2dacff"
                                                title="Exibir valores por UC"
                                                icon={"fas fa-chart-bar"}
                                                visible={true}
                                                event={() => {
                                                    setExibirModal(true);
                                                    setEmpenho(JSON.parse(JSON.stringify(item)));
                                                }}
                                            />
                                        </Td>
                                        <Td style={{ fontSize: "0.8rem" }} alignCenter>
                                            <div style={{ display: "flex" }}>
                                                {item.percentual_utilizado < 80 ? (
                                                    <>
                                                        <div style={{ display: "flex", alignItems: "center", fontSize: "1.4rem", paddingInline: 20 }}>
                                                            <i className="fas fa-check-circle" />{" "}
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                paddingInline: 20,
                                                                flexGrow: 1,
                                                                minWidth: 290,
                                                            }}
                                                        >
                                                            OK
                                                        </div>
                                                    </>
                                                ) : item.percentual_utilizado < 100 ? (
                                                    <>
                                                        <div style={{ display: "flex", alignItems: "center", fontSize: "1.4rem", paddingInline: 20 }}>
                                                            <i className="fas fa-exclamation-triangle" />
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                paddingInline: 20,
                                                                flexGrow: 1,
                                                                minWidth: 290,
                                                            }}
                                                        >
                                                            Deve revisar os valores, pois o valor utilizado ultrapassou 80% do esperado!
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div style={{ display: "flex", alignItems: "center", fontSize: "1.4rem", paddingInline: 20 }}>
                                                            <i className="fas fa-exclamation-triangle" />
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                paddingInline: 20,
                                                                flexGrow: 1,
                                                                minWidth: 290,
                                                            }}
                                                        >
                                                            Deve revisar os valores, pois o valor utilizado ultrapassou o valor esperado!
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </Td>
                                    </NewTr>
                                ))}
                            </TBody>
                        ) : null}
                    </Table>
                </ContentCardBody>
            </ContentCard>
        </div>
    );
}

const mapStateToProps = (state) => ({
	filtro: state.analise.filtro,
	listaEmpenho: state.analise.listaEmpenho,
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getListaEmpenho,
		},
		dispatch
	);
const AnaliseGestaoEmpenho = connect(mapStateToProps, mapDispatchToProps)(analiseGestaoEmpenho);
export { AnaliseGestaoEmpenho };

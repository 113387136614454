import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import locale from "@amcharts/amcharts4/lang/pt_BR.js";

function montarGraficoGroupColumn({
    state,
    component,
    nome,
    dados = [],
    category,
    valuesAndNames = [],
    titulo,
    prefixo,
    sufixo,
    negativo = false,
    fontColor = "#ffffff",
    bullets = false,
    positionBullet = -7,
    dimensions = "3D",
    fontSizeTitle = 16,
    fontWeightTitle = "normal",
    mostrarLegenda = false,
    chartBackground = null,
}) {
    //se não vier uma serie sai da função
    if (valuesAndNames[0] && !valuesAndNames[0].value) return;
    if (component) {
        component.dispose();
    }

    let chart = dimensions == "2D" ? am4core.create(nome, am4charts.XYChart) : am4core.create(nome, am4charts.XYChart3D);
    chart.colors.step = 1;

    if (state) {
        chart.colors.list = state.cores;
    }

    chart.language.locale = locale;

    let title = chart.titles.create();
    title.text = titulo;
    title.fontSize = fontSizeTitle;
    title.fontWeight = fontWeightTitle;
    title.marginBottom = 30;

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.filePrefix = "Gráfico";
    chart.exporting.menu.align = "right";
    chart.exporting.menu.verticalAlign = "top";
    chart.exporting.menu.items = [
        {
            label: "...",
            menu: [
                {
                    type: "png",
                    label: "PNG",
                },
            ],
        },
    ];

    if (mostrarLegenda) {
        chart.legend = new am4charts.Legend();
        chart.legend.position = "top";
        chart.legend.paddingBottom = 20;
        chart.legend.labels.template.maxWidth = 95;
    }
    chart.language.locale["_decimalSeparator"] = ",";
    chart.language.locale["_thousandSeparator"] = ".";
    chart.numberFormatter.numberFormat = "###,###,###,###.00";

    chart.background.fill = chartBackground;
    chart.filters.clear();

    chart.maskBullets = false;
    let xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    xAxis.dataFields.category = category;
    xAxis.renderer.cellStartLocation = 0.1;
    xAxis.renderer.cellEndLocation = 0.9;
    xAxis.renderer.grid.template.location = 0;
    xAxis.paddingBottom = 15;
    xAxis.renderer.minGridDistance = 5;
    xAxis.renderer.labels.template.maxWidth = 120;
    xAxis.renderer.labels.template.wrap = true;
    xAxis.renderer.labels.template.truncate = false;

    let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.min = negativo ? null : 0;

    let arrangeColumns = () => {
        let series = chart.series.getIndex(0);

        let w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
        if (series.dataItems.length > 1) {
            let x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
            let x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
            let delta = ((x1 - x0) / chart.series.length) * w;
            if (am4core.isNumber(delta)) {
                let middle = chart.series.length / 2;

                let newIndex = 0;
                chart.series.each(function (series) {
                    if (!series.isHidden && !series.isHiding) {
                        series.dummyData = newIndex;
                        newIndex++;
                    } else {
                        series.dummyData = chart.series.indexOf(series);
                    }
                });
                let visibleCount = newIndex;
                let newMiddle = visibleCount / 2;

                chart.series.each(function (series) {
                    let trueIndex = chart.series.indexOf(series);
                    let newIndex = series.dummyData;

                    let dx = (newIndex - trueIndex + middle - newMiddle) * delta;

                    series.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
                    series.bulletsContainer.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
                });
            }
        }
    };

    let createSeries = (value, name) => {
        let series = dimensions == "2D" ? chart.series.push(new am4charts.ColumnSeries()) : chart.series.push(new am4charts.ColumnSeries3D());
        series.dataFields.valueY = value;
        series.dataFields.categoryX = category;
        series.name = name;

        series.events.on("hidden", arrangeColumns);
        series.events.on("shown", arrangeColumns);

        if (bullets) {
            let bullet = series.bullets.push(new am4charts.LabelBullet());
            bullet.interactionsEnabled = false;
            // bullet.dy = 2;
            bullet.label.dy = positionBullet;

            bullet.label.text = "{valueY}";
            bullet.label.fill = am4core.color(fontColor);
            bullet.label.truncate = false;
            // bullet.label.hideOversized = true;
        }

        series.columns.template.tooltipText = `{name}: [bold]${prefixo} {valueY.formatNumber("###,###,###,###.00")} ${sufixo}[/]`;
        series.columns.template.fillOpacity = 0.8;

        return series;
    };
    chart.data = [...dados];

    for (const valueAndName of valuesAndNames) {
        createSeries(valueAndName.value, valueAndName.name);
    }

    return chart;
}

export { montarGraficoGroupColumn };

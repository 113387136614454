import React from "react";
import Grid from "../layout/grid";

export default (props) => {
  const propsInput = props.input || {};
  const onChange = props.onChange || propsInput.onChange;
  const valueRecebido = props.value || propsInput.value;
  return (
      <Grid cols={props.cols}>
          <div className="form-group">
              <label htmlFor={props.name}>{props.label}</label>
              <input
                  {...props.input}
                  className="form-control"
                  placeholder={props.placeholder}
                  disabled={props.readOnly}
                  type={props.type}
                  value={valueRecebido}
                  onChange={onChange}
                  maxLength={props.maxLength}
              />
          </div>
      </Grid>
  );
};

import React, { useRef, useEffect } from "react";
import "./fileChooser.css";

function FileChooser({ onFileSelect, label, readOnly, base64, nome }) {
    const fileInputRef = useRef(null);
    useEffect(() => {
        if (base64 && nome) {
            const file = base64ToFile(base64, nome);
            setFileToInput(file);
        }
    }, [base64, nome]);

    function base64ToFile(base64String, fileName) {
        const byteString = atob(base64String.split(",")[1]);
        const mimeString = base64String.split(",")[0].split(":")[1].split(";")[0];

        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([ab], { type: mimeString });
        return new File([blob], fileName, { type: mimeString });
    }

    function setFileToInput(file) {
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);
        if (fileInputRef.current) {
            fileInputRef.current.files = dataTransfer.files;
        }
    }

    const handleButtonClick = () => {
        if (readOnly) return;
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        if (!event.target.files[0]) return;
        const reader = new FileReader();
        const nome = event.target.files[0].name;
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = () => {
            const base64 = reader.result;
            const fileSelected = { nome, base64 };
            onFileSelect(fileSelected);
        };
        reader.onerror = (error) => {
            console.error("Error converting file to base64:", error);
        };
    };

    return (
        <div className="file-chooser">
            {label ? (
                <div>
                    <label>{label}</label>
                </div>
            ) : null}
            <form className="file-chooser-input" style={{ backgroundColor: readOnly ? "#EEEEEE" : "" }}>
                <span
                    className={nome ? "" : "vazio"}
                    onClick={() => {
                        if (nome || base64) return;
                        handleButtonClick();
                    }}
                    style={{ cursor: readOnly ? "normal" : "pointer" }}
                >
                    {nome || "Selecione um arquivo"}
                </span>
                <input type="file" ref={fileInputRef} onChange={handleFileChange} style={{ display: "none" }} />
                {nome || base64 ? (
                    <button
                        className="remover"
                        type="button"
                        disabled={readOnly}
                        onClick={() => {
                            onFileSelect({ nome: null, base64: null });
                        }}
                    >
                        <i className="fas fa-minus-square"></i> Remover
                    </button>
                ) : (
                    <button disabled={readOnly} type="button" onClick={handleButtonClick}>
                        <i className="fas fa-file-import"></i> Carregar
                    </button>
                )}
            </form>
        </div>
    );
}

export default FileChooser;

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, getFormValues } from "redux-form";

import ContentCard from "../../common/template/contentCard";
import ContentCardBody from "../../common/template/contentCardBody";
import ContentCardFooter from "../../common/template/contentCardFooter";
import Row from "../../common/layout/row";
import Grid from "../../common/layout/grid";
import Button from "../../common/button/button";
import Form from "../../common/form/form";
import LabelAndInputNumber from "../../common/form/labelAndInputNumber";
import SelectAlteracao from "../../common/form/selectAlteracao";

import { setModoTela, initForm, salvarProduto, salvarUnidadeMedida } from "./faturaItemActions";

class FaturaForm extends Component {
	state = {
		modoCadastroProduto: false,
		produto: null,
		modoCadastroUnidadeMedida: false,
		unidadeMedida: null,
	};

	componentWillMount() {}

	render() {
		let usuarioEdicao =
			this.props.usuarioLogado && (this.props.usuarioLogado.acesso_fatura_editar || this.props.usuarioLogado.acesso_fatura_editar_fechado);

		let readOnly = this.props.excluir || !usuarioEdicao ? "readOnly" : "";

		let produto = this.props.listaProduto.filter((produto) => produto.id == this.props.formularioValues.id_produto)[0];

		return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name="id_produto"
								component={SelectAlteracao}
								options={this.props.listaProduto}
								label="Produto"
								cols="12 12 12 12"
								placeholder="Selecione o produto"
								modoCadastro={this.state.modoCadastroProduto}
								acaoBotaoLabel={this.props.formularioValues.id_produto && this.props.formularioValues.id_produto != 0 ? "Alterar" : "Novo"}
								acaoBotao={() => {
									let itemLista = this.props.listaProduto.filter((item) => item.id == this.props.formularioValues.id_produto)[0];

									this.setState({
										...this.state,
										modoCadastroProduto: true,
										produto: {
											id:
												this.props.formularioValues.id_produto && this.props.formularioValues.id_produto != 0
													? this.props.formularioValues.id_produto
													: null,
											nome: itemLista ? itemLista.valor : null,
										},
									});
								}}
								componenteCadastro={
									<div className="input-group">
										<input
											type="text"
											className="form-control"
											placeholder="Informe o código"
											value={this.state.produto ? this.state.produto.codigo : ""}
											onChange={(data) => {
												this.setState({
													...this.state,
													produto: {
														id: this.state.produto ? this.state.produto.id : null,
														codigo: data.target.value,
														nome: this.state.produto ? this.state.produto.nome : null,
													},
												});
											}}
										/>
										<input
											type="text"
											className="form-control"
											placeholder="Informe o nome"
											value={this.state.produto ? this.state.produto.nome : ""}
											onChange={(data) => {
												this.setState({
													...this.state,
													produto: {
														id: this.state.produto ? this.state.produto.id : null,
														codigo: this.state.produto ? this.state.produto.codigo : null,
														nome: data.target.value,
													},
												});
											}}
										/>
										<span className="input-group-btn">
											<button
												type="button"
												className="btn btn-success btn-flat"
												onClick={() => {
													this.props.salvarProduto(this.state.produto, this.props.formularioValues);
													this.setState({
														...this.state,
														modoCadastroProduto: false,
														produto: null,
													});
												}}
											>
												Salvar
											</button>
										</span>
									</div>
								}
								readOnly={readOnly}
							/>
						</Row>
						<Row>
							<Field
								name="id_unidade_medida"
								component={SelectAlteracao}
								options={this.props.listaUnidadeMedida}
								label="Unidade de Medida"
								cols="12 12 12 12"
								placeholder="Selecione a unidade de medida"
								modoCadastro={this.state.modoCadastroUnidadeMedida}
								acaoBotaoLabel={
									this.props.formularioValues.id_unidade_medida && this.props.formularioValues.id_unidade_medida != 0 ? "Alterar" : "Novo"
								}
								acaoBotao={() => {
									let itemLista = this.props.listaUnidadeMedida.filter((item) => item.id == this.props.formularioValues.id_unidade_medida)[0];

									this.setState({
										...this.state,
										modoCadastroUnidadeMedida: true,
										unidadeMedida: {
											id:
												this.props.formularioValues.id_unidade_medida && this.props.formularioValues.id_unidade_medida != 0
													? this.props.formularioValues.id_unidade_medida
													: null,
											nome: itemLista ? itemLista.valor : null,
										},
									});
								}}
								componenteCadastro={
									<div className="input-group">
										<input
											type="text"
											className="form-control"
											placeholder="Informe o nome"
											value={this.state.unidadeMedida ? this.state.unidadeMedida.nome : ""}
											onChange={(data) => {
												this.setState({
													...this.state,
													unidadeMedida: {
														id: this.state.unidadeMedida ? this.state.unidadeMedida.id : null,
														nome: data.target.value,
													},
												});
											}}
										/>
										<span className="input-group-btn">
											<button
												type="button"
												className="btn btn-success btn-flat"
												onClick={() => {
													this.props.salvarUnidadeMedida(this.state.unidadeMedida, this.props.formularioValues);
													this.setState({
														...this.state,
														modoCadastroUnidadeMedida: false,
														unidadeMedida: null,
													});
												}}
											>
												Salvar
											</button>
										</span>
									</div>
								}
								readOnly={readOnly}
							/>
						</Row>

						<Row>
							<Field
								name="quantidade_registrada"
								component={LabelAndInputNumber}
								label="Quantidade Registrada"
								placeholder="Informe a quantidade"
								cols="12 12 4 4"
								casas={5}
								readOnly={readOnly}
							/>

							{!produto || produto.id_tipo != 21 ? (
								<>
									<Field
										name="quantidade_faturada"
										component={LabelAndInputNumber}
										label="Quantidade Faturada"
										placeholder="Informe a quantidade"
										cols="12 12 4 4"
										casas={5}
										readOnly={readOnly}
									/>

									<Field
										name="tarifa"
										component={LabelAndInputNumber}
										label="Tarifa"
										placeholder="Informe a tarifa"
										cols="12 12 4 4"
										casas={5}
										readOnly={readOnly}
									/>

									<Field
										name="valor_imposto"
										component={LabelAndInputNumber}
										label="Valor Imposto"
										placeholder="Informe o valor"
										cols="12 12 4 4"
										casas={5}
										readOnly={readOnly}
									/>

									<Field
										name="valor_total"
										component={LabelAndInputNumber}
										label="Valor Total"
										placeholder="Informe o valor"
										cols="12 12 4 4"
										casas={5}
										readOnly={readOnly}
									/>
								</>
							) : null}
						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols="6 6 4 3">
								{this.props.excluir ? (
									<Button text="Excluir" submit type={"danger"} icon={"fa fa-trash"} disabled={!usuarioEdicao} />
								) : (
									<Button text="Salvar" submit type={"success"} icon={"fa fa-check"} disabled={!usuarioEdicao} />
								)}
							</Grid>
							<Grid cols="6 6 4 3">
								<Button text="Voltar" type={"warning"} icon={"fa fa-chevron-left"} event={() => this.props.setModoTela("lista")} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
		);
	}
}

FaturaForm = reduxForm({ form: "faturaItemForm", destroyOnUnmount: false })(FaturaForm);
const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	formularioValues: getFormValues("faturaItemForm")(state),
	registro: state.faturaItem.registro,
	listaProduto: state.faturaItem.listaProduto,
	listaUnidadeMedida: state.faturaItem.listaUnidadeMedida,
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setModoTela,
			initForm,
			salvarProduto,
			salvarUnidadeMedida,
		},
		dispatch
	);
export default connect(mapStateToProps, mapDispatchToProps)(FaturaForm);

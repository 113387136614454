import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ContentCard from "../../common/template/contentCard";
import ContentCardHeader from "../../common/template/contentCardHeader";
import ContentCardBody from "../../common/template/contentCardBody";
import Row from "../../common/layout/row";
import Grid from "../../common/layout/grid";
import Table from "../../common/table/table";
import THead from "../../common/table/tHead";
import Tr from "../../common/table/tr";
import Th from "../../common/table/th";
import TBody from "../../common/table/tBody";
import Td from "../../common/table/td";
import Button from "../../common/button/button";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
    getListaGraficoCustoReativoAnual,
    getListaGraficoCustoReativoMensal,
    getListaGraficoConsumoReativoMensal,
    getListaGraficoCustoReativoPorUnidadeAnual,
    getListaGraficoCustoReativoPorUnidadeAcumuladoAno,
    getListaGraficoCustoReativoTipo,
    setFiltro,
} from "../analiseActions";

import { montarGraficoColumn } from "../../common/graficos/montarGraficoColumn.js";
import { montarGraficoPieAnual } from "../../common/graficos/montarGraficoPieAnual.js";
import { montarGraficoGroupColumn } from "../../common/graficos/montarGraficoGroupColumn.js";
import { montarGraficoHorizontalBar } from "../../common/graficos/montarGraficoHorizontalBar.js";
import { setError } from "../../common/toast/toast.jsx";

function analiseReativo(props) {
    const {
        state,
        listar,
        filtro,
        listaGraficoCustoReativoAnual,
        listaGraficoCustoReativoMensal,
        listaGraficoConsumoReativoMensal,
        listarGraficoCustoReativoTipo,
        listaGraficoCustoReativoUnidadeAnual,
        listaGraficoCustoReativoPorUnidadeAcumuladoAno,
        getListaGraficoCustoReativoAnual,
        getListaGraficoCustoReativoMensal,
        getListaGraficoConsumoReativoMensal,
        getListaGraficoCustoReativoPorUnidadeAnual,
        getListaGraficoCustoReativoPorUnidadeAcumuladoAno,
        getListaGraficoCustoReativoTipo,
        setAguardando,
        setFiltro,
    } = props;

    const [graficoCustoReativoAnual, setGraficoCustoReativoAnual] = useState(null);
    const [graficoCustoReativoMensal, setGraficoCustoReativoMensal] = useState(null);
    const [graficoConsumoReativoMensal, setGraficoConsumoReativoMensal] = useState(null);
    const [graficoCustoReativoUnidadeAnual, setGraficoCustoReativoUnidadeAnual] = useState(null);
    const [graficoCustoReativoTipo, setGraficoCustoReativoTipo] = useState(null);
    const [graficoCustoReativoAcumuladoAnualPorUC, setGraficoCustoReativoAcumuladoAnualPorUC] = useState(null);

    useEffect(() => {
        atualizarTodosGraficos();
        return desmontarTodosGraficos;
    }, []);

    useEffect(() => {
        const buscarDados = async () => {
            if (!state.aguardando) return;

            await Promise.all([
                getListaGraficoCustoReativoAnual(),
                getListaGraficoCustoReativoMensal(),
                getListaGraficoConsumoReativoMensal(),
                getListaGraficoCustoReativoPorUnidadeAnual(),
                getListaGraficoCustoReativoTipo(),
                getListaGraficoCustoReativoPorUnidadeAcumuladoAno(),
            ]);

            setAguardando(false);
        };

        buscarDados();
        if (!state.aguardando) {
            atualizarTodosGraficos();
        }
    }, [state.aguardando]);

    function atualizarTodosGraficos() {
        atualizarListaGraficoCustoReativo();
        atualizarListaGraficoConsumoReativo();
        atualizarListaGraficoCustoReativoPorUnidadeAnual();
        atualizarListaGraficoCustoReativoTipo();
        atualizarListaGraficoCustoReativoAcumuladoAnualPorUC();
    }
    function desmontarTodosGraficos() {
        if (graficoCustoReativoAnual) graficoCustoReativoAnual.dispose();
        if (graficoCustoReativoMensal) graficoCustoReativoMensal.dispose();
        if (graficoConsumoReativoMensal) graficoConsumoReativoMensal.dispose();
        if (graficoCustoReativoUnidadeAnual) graficoCustoReativoUnidadeAnual.dispose();
        if (graficoCustoReativoTipo) graficoCustoReativoTipo.dispose();
        if (graficoCustoReativoAcumuladoAnualPorUC) graficoCustoReativoAcumuladoAnualPorUC.dispose();
    }

    function mapearListaGraficoColunasAgrupadas(lista) {
        const listaMapeada = [];
        const nomeColuna = filtro.reativo_ano_safra ? "ano_inicio_fim_safra" : "ano";
        const anos = [];

        try {
            //monta uma lista com todos os anos existentes em todos meses
            for (const registro of lista) {
                const listaAnoValor = registro.itens.filter((item) => item[nomeColuna]); //remove registro com ano nulo
                for (const item of listaAnoValor) {
                    const ano = item[nomeColuna];
                    if (!anos.includes(ano)) {
                        anos.push(ano);
                    }
                }
            }
            anos.sort((a, b) => {
                return filtro.reativo_ano_safra ? parseInt(a.split("/")[0]) - parseInt(b.split("/")[0]) : parseInt(a) - parseInt(b);
            });
            //percorre todos os meses
            for (const registro of lista) {
                //se encontrar o ano do mes na lista de anos, pega o respectivo valor, senao define o valor zero para o ano
                const colunas = { mes: registro.mes };
                for (const ano of anos) {
                    const anoExisteNoMes = registro.itens.find((item) => item[nomeColuna] == ano);
                    if (anoExisteNoMes && parseFloat(anoExisteNoMes.valor)) {
                        colunas[ano] = parseFloat(anoExisteNoMes.valor);
                    } else {
                        colunas[ano] = 0;
                    }
                }
                listaMapeada.push(colunas);
            }
            return {
                dados: listaMapeada,
                valuesAndNames: anos.map((item) => ({ value: item, name: item })),
            };
        } catch (erro) {
            setError("Erro ao gerar gráfico Custo Reativo Mensal (R$)");
            return {
                dados: [],
                valuesAndNames: [],
            };
        }
    }

    function atualizarListaGraficoCustoReativo() {
        const graficoCustoReativoAnualAtualizado = montarGraficoColumn({
            state,
            component: graficoCustoReativoAnual,
            nome: "graficoCustoReativoAnual",
            dados: listaGraficoCustoReativoAnual,
            category: filtro.reativo_ano_safra ? "ano_inicio_fim_safra" : "ano",
            value: "valor",
            titulo: `Custo Reativo Total por ${filtro.reativo_ano_safra ? "Safra" : "Ano"} (R$)`,
            prefixo: "R$",
            sufixo: "",
        });
        setGraficoCustoReativoAnual(graficoCustoReativoAnualAtualizado);

        const { dados, valuesAndNames } = mapearListaGraficoColunasAgrupadas(listaGraficoCustoReativoMensal);
        const graficoCustoReativoMensalAtualizado = montarGraficoGroupColumn({
            state,
            component: graficoCustoReativoMensal,
            nome: "graficoCustoReativoMensal",
            dados,
            category: "mes",
            valuesAndNames,
            titulo: `Custo Reativo Mensal (R$)`,
            prefixo: "R$",
            sufixo: "",
            // fontColor: "#000000",
            // positionBullet: -15,
            dimensions: "3D",
            // fontSizeTitle: 30,
            // fontWeightTitle: "bold",
            mostrarLegenda: true,
            chartBackground: "#30303d",
        });
        setGraficoCustoReativoMensal(graficoCustoReativoMensalAtualizado);
    }
    function atualizarListaGraficoConsumoReativo() {
        const { dados, valuesAndNames } = mapearListaGraficoColunasAgrupadas(listaGraficoConsumoReativoMensal);
        const graficoConsumoReativoMensalAtualizado = montarGraficoGroupColumn({
            state,
            component: graficoConsumoReativoMensal,
            nome: "graficoConsumoReativoMensal",
            dados,
            category: "mes",
            valuesAndNames,
            titulo: "Consumo Reativo Mensal (kVAr)",
            prefixo: "",
            sufixo: "kVAr",
            dimensions: "3D",
            mostrarLegenda: true,
            chartBackground: "#30303d",
        });
        setGraficoConsumoReativoMensal(graficoConsumoReativoMensalAtualizado);
    }

    function atualizarListaGraficoCustoReativoPorUnidadeAnual() {
        const graficoCustoReativoUnidadeAnualAtualizado = montarGraficoColumn({
            state,
            component: graficoCustoReativoUnidadeAnual,
            nome: "graficoCustoReativoUnidadeAnual",
            dados: listaGraficoCustoReativoUnidadeAnual,
            category: "nome_unidade_consumidora",
            value: "valor",
            titulo: "Custo Reativo por Unidade Total (R$)",
            prefixo: "R$",
            sufixo: "",
        });
        setGraficoCustoReativoUnidadeAnual(graficoCustoReativoUnidadeAnualAtualizado);
    }

    function atualizarListaGraficoCustoReativoTipo() {
        const graficoCustoReativoTipoAtualizado = montarGraficoPieAnual(
            state,
            graficoCustoReativoTipo,
            "graficoCustoReativoTipo",
            listarGraficoCustoReativoTipo,
            "Custo Reativo por Tipo (R$)",
            "R$",
            ""
        );
        setGraficoCustoReativoTipo(graficoCustoReativoTipoAtualizado);
    }

    function atualizarListaGraficoCustoReativoAcumuladoAnualPorUC() {
        const graficoCustoReativoAcumuladoAnualPorUCAtualizado = montarGraficoHorizontalBar({
            component: graficoCustoReativoAcumuladoAnualPorUC,
            chartDivId: "graficoCustoReativoAcumuladoAnualPorUC",
            category: "agrupador",
            value: "valor",
            bullet: "valor",
            bulletPosition: "out",
            bulletColor: "#fff",
            lista: [...listaGraficoCustoReativoPorUnidadeAcumuladoAno].sort((a, b) => a.valor - b.valor),
            titulo: "Custo Reativo Acumulado Anual por UC (R$)",
            prefixo: "R$",
            sufixo: "",
        });
        setGraficoCustoReativoAcumuladoAnualPorUC(graficoCustoReativoAcumuladoAnualPorUCAtualizado);
    }

    const listaTabelaCustoReativoTotal = graficoCustoReativoAnual ? graficoCustoReativoAnual._data : [];
    const periodo = Object.keys(listaTabelaCustoReativoTotal[0] || {})[0];

    return (
        <div>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoCustoReativoAnual" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                </ContentCardBody>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <div
                        style={{
                            minWidth: "890px",
                        }}
                    >
                        <Table responsive>
                            <THead>
                                <Tr>
                                    <Th width={200}></Th>
                                    {listaTabelaCustoReativoTotal.map((item) => (
                                        <Th alignCenter>{item[periodo]}</Th>
                                    ))}
                                </Tr>
                            </THead>
                            <TBody>
                                <Tr>
                                    <Td color="#fff">R$ Total</Td>
                                    {listaTabelaCustoReativoTotal.map((item) => (
                                        <Td key={item[periodo]} alignCenter color="#fff">
                                            {parseFloat(item.valor_fatura || 0)
                                                .toFixed(2)
                                                .replace(".", ",")}
                                        </Td>
                                    ))}
                                </Tr>
                                <Tr>
                                    <Td color="#fff">R$ Reativo</Td>
                                    {listaTabelaCustoReativoTotal.map((item) => (
                                        <Td key={item[periodo]} alignCenter color="#fff">
                                            {parseFloat(item.valor || 0)
                                                .toFixed(2)
                                                .replace(".", ",")}
                                        </Td>
                                    ))}
                                </Tr>
                                <Tr>
                                    <Td color="#fff">Rel. %</Td>
                                    {listaTabelaCustoReativoTotal.map((item) => (
                                        <Td key={item[periodo]} alignCenter color="#fff">
                                            {parseFloat(item.valor_fatura > 0 ? (item.valor * 100) / item.valor_fatura : 0)
                                                .toFixed(2)
                                                .replace(".", ",")}
                                            %
                                        </Td>
                                    ))}
                                </Tr>
                            </TBody>
                        </Table>
                    </div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",
                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoCustoReativoMensal" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",
                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoConsumoReativoMensal" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardHeader>
                    <Row>
                        <Grid cols="6 4 3 2">
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                <DatePicker
                                    variant="inline"
                                    openTo="year"
                                    views={["year", "month"]}
                                    label="De"
                                    autoOk
                                    value={
                                        filtro.competencia_inicial_reativo
                                            ? `${String(filtro.competencia_inicial_reativo).substring(0, 4)}/${String(filtro.competencia_inicial_reativo).substring(
                                                  4,
                                                  6
                                              )}/01 12:00:00`
                                            : null
                                    }
                                    onChange={(value) => {
                                        setFiltro({
                                            ...filtro,
                                            competencia_inicial_reativo: value ? parseInt(`${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${value.getMonth() + 1}`) : null,
                                        });
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid cols="6 4 3 2">
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                <DatePicker
                                    variant="inline"
                                    openTo="year"
                                    views={["year", "month"]}
                                    label="Até"
                                    autoOk
                                    value={
                                        filtro.competencia_final_reativo
                                            ? `${String(filtro.competencia_final_reativo).substring(0, 4)}/${String(filtro.competencia_final_reativo).substring(4, 6)}/01 12:00:00`
                                            : null
                                    }
                                    onChange={(value) => {
                                        setFiltro({
                                            ...filtro,
                                            competencia_final_reativo: value ? parseInt(`${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${value.getMonth() + 1}`) : null,
                                        });
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid cols="6 4 3 2" style={{ paddingTop: 10 }}>
                            <Button
                                text="Limpar Período"
                                type={"primary"}
                                icon={"fa fa-minus"}
                                event={async () => {
                                    setFiltro({
                                        ...filtro,
                                        competencia_inicial_reativo: null,
                                        competencia_final_reativo: null,
                                    });
                                }}
                            />
                        </Grid>
                        <Grid cols="6 4 3 2" style={{ paddingTop: 10 }}>
                            <Button text="Atualizar" type={"primary"} icon={"fa fa-sync"} event={listar} />
                        </Grid>
                    </Row>
                </ContentCardHeader>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",
                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoCustoReativoUnidadeAnual" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                </ContentCardBody>
            </ContentCard>

            <ContentCard style={{ padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",
                        overflowX: "scroll",
                    }}
                >
                    <Row>
                        <Grid cols="12 12 12 12">
                            <div id="graficoCustoReativoTipo" style={{ width: "100%", height: "500px" }}></div>
                        </Grid>
                    </Row>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ padding: 8 }}>
                <ContentCardBody style={{ backgroundColor: "#30303d", overflowX: "scroll", maxHeight: 400 }}>
                    <div
                        id="graficoCustoReativoAcumuladoAnualPorUC"
                        style={{
                            width: "100%",
                            minWidth: 900,
                            height: (listaGraficoCustoReativoPorUnidadeAcumuladoAno || []).length * 50 < 400 ? 400 : listaGraficoCustoReativoPorUnidadeAcumuladoAno.length * 50,
                        }}
                    ></div>
                </ContentCardBody>
            </ContentCard>
        </div>
    );
}

const mapStateToProps = (state) => ({
    filtro: state.analise.filtro,
    listaGraficoCustoReativoAnual: state.analise.listaGraficoCustoReativoAnual,
    listaGraficoCustoReativoMensal: state.analise.listaGraficoCustoReativoMensal,
    listaGraficoConsumoReativoMensal: state.analise.listaGraficoConsumoReativoMensal,
    listarGraficoCustoReativoTipo: state.analise.listarGraficoCustoReativoTipo,
    listaGraficoCustoReativoUnidadeAnual: state.analise.listaGraficoCustoReativoUnidadeAnual,
    listaGraficoCustoReativoPorUnidadeAcumuladoAno: state.analise.listaGraficoCustoReativoPorUnidadeAcumuladoAno,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getListaGraficoCustoReativoAnual,
            getListaGraficoCustoReativoMensal,
            getListaGraficoConsumoReativoMensal,
            getListaGraficoCustoReativoPorUnidadeAnual,
            getListaGraficoCustoReativoPorUnidadeAcumuladoAno,
            getListaGraficoCustoReativoTipo,
            setFiltro,
        },
        dispatch
    );
const AnaliseReativo = connect(mapStateToProps, mapDispatchToProps)(analiseReativo);
export { AnaliseReativo };

const INITIAL_STATE = {
    registro: {},
    aguardando: false,
    modoTela: "lista",
    lista: [],
    listaCliente: [],
    listaUnidadeConsumidora: [],
    listaDemandaContratoTipo: [],
    listaDemandaContagem: [],
    filtro: {
        id_cliente: null,
        unidades_consumidoras: [],
        id_tipo_contrato_demanda: 1,
        intervalo_tempo: 15,
    },
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "MONITORAMENTO_CONSUMO_MODO_TELA":
            return {
                ...state,
                modoTela: action.payload.modo,
                registro: action.payload.registro,
            };

        case "MONITORAMENTO_CONSUMO_SET_AGUARDANDO":
            return { ...state, aguardando: action.payload };

        case "MONITORAMENTO_CONSUMO_SET_DATA_GRAFICO":
            return { ...state, dataGrafico: action.payload };

        case "MONITORAMENTO_CONSUMO_FILTRO":
            return { ...state, filtro: action.payload };

        case "MONITORAMENTO_CONSUMO_CLIENTE_SELECT_LISTADO":
            return {
                ...state,
                listaCliente: action.payload.data,
            };

        case "MONITORAMENTO_CONSUMO_UNIDADE_CONSUMIDORA_SELECT_LISTADO":
            return {
                ...state,
                listaUnidadeConsumidora: action.payload.data,
            };

        case "MONITORAMENTO_CONSUMO_DEMANDA_CONTAGEM_LISTADO":
            return {
                ...state,
                listaDemandaContagem: action.payload.data,
            };

        case "MONITORAMENTO_CONSUMO_CONTRATO_DEMANDA_TIPO_SELECT_LISTADO":
            return {
                ...state,
                listaDemandaContratoTipo: action.payload.data,
            };

        default:
            return state;
    }
};

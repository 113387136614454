import ReactDOM from "react-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { setError } from "../common/toast/toast";

export function htmlToPdf(componente, callbackAguardando, orientation = "landscape") {
    return new Promise((resolve, reject) => {
        // Cria um novo iframe invisível e adiciona à div app
        const screen = document.createElement("iframe");
        screen.setAttribute("id", "printFrame");
        screen.style.visibility = "hidden"; // Deixa o iframe invisível
        const app = document.getElementById("app");
        app.appendChild(screen);

        // Aguarda o iframe carregar completamente
        screen.onload = function () {
            const iframeDoc = screen.contentDocument || screen.contentWindow.document;

            // Adiciona o estilo global dentro do iframe
            const styleElement = document.createElement("style");
            styleElement.textContent = `
				.linha-cores-intercaladas > td:nth-child(even){
				  background-color:  var(--backgroundColorTd); 
				}

				.linha-cores-intercaladas > th:nth-child(odd){
				  background-color: var(--backgroundColorTr);
				}

				.linha-cores-intercaladas > th:nth-child(even){
				  background-color: var(--backgroundColorTd);
				}
			`;
            iframeDoc.head.appendChild(styleElement);

            // Estilos específicos para o body do iframe
            iframeDoc.body.style = `
                margin: 0;
                font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: #212529;
                text-align: left;
                background-color: #ffffff;
            `;

            // Renderiza o componente no iframe
            const targetNode = iframeDoc.body;

            if (componente.current) {
                targetNode.appendChild(componente.current.cloneNode(true));
                processHtmlToPdf(screen, orientation, callbackAguardando)
                    .then(resolve)
                    .catch(reject)
                    .finally(() => app.removeChild(screen));
            } else {
                ReactDOM.render(componente, targetNode, () => {
                    processHtmlToPdf(screen, orientation, callbackAguardando)
                        .then(resolve)
                        .catch(reject)
                        .finally(() => app.removeChild(screen));
                });
            }
        };

        // Define o src do iframe para garantir que ele carregue
        screen.src = "about:blank";
    });
}
function processHtmlToPdf(screen, orientation, callbackAguardando) {
    return new Promise((resolve, reject) => {
        html2canvas(screen.contentWindow.document.body, {
            allowTaint: true,
        })
            .then((canvas) => {
                const doc = new jsPDF(orientation, "mm", "a4");
                const imgData = canvas.toDataURL("image/jpeg", 1.0);
                const imgWidth = doc.internal.pageSize.getWidth();
                const pageHeight = doc.internal.pageSize.getHeight();
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                const totalPageSize = Math.ceil(imgHeight / pageHeight);
                let yPosition = 10;
                doc.addImage(imgData, "JPEG", 0, yPosition, imgWidth, imgHeight);
                for (let pageIndex = 1; pageIndex < totalPageSize; pageIndex++) {
                    yPosition = pageIndex * pageHeight * -1 + 10;
                    if (pageIndex < totalPageSize - 1) {
                        doc.addPage("a4", orientation);
                    }
                    doc.addImage(imgData, "JPEG", 0, yPosition, imgWidth, imgHeight);
                }

                callbackAguardando(false);

                const blob = doc.output("blob");
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                    const pdfBase64 = reader.result;
                    resolve(pdfBase64);
                };
            })
            .catch((err) => {
                reject(setError("Erro ao gerar o relatório em PDF"));
            });
    });
}

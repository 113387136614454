import React, { useState, useEffect, useRef } from "react";
import { Document, Page } from "react-pdf";
import "./displayPDF.css";

const options = {
    cMapUrl: "cmaps/",
    cMapPacked: true,
    standardFontDataUrl: "standard_fonts/",
};

export const PdfToBePrinted = React.forwardRef(({ pdfBase64, zoom, orientation = "portrait", onClick = () => ({}), style = {}, cursor }, ref) => {
    const scrollableRef = useRef(null); // Criar uma referência para o elemento scrollable

    useEffect(() => {
        const scrollable = scrollableRef.current; // Usar a referência para acessar o elemento
        //quando o cursor for flecha o arrastar não deve funcionar, pois está no modo seleção de texto
        if (!scrollable || cursor !== "pointer") return; // Verifica se o elemento existe

        let isDown = false;
        let startY, startX; // Variáveis para armazenar a posição inicial
        let scrollTop, scrollLeft; // Variáveis para armazenar a posição atual do scroll

        const mouseDownHandler = (e) => {
            isDown = true;
            scrollable.style.cursor = "grabbing"; // Altera o cursor para indicar o arrasto
            startY = e.pageY - scrollable.offsetTop; // Posição inicial do mouse na vertical
            startX = e.pageX - scrollable.offsetLeft; // Posição inicial do mouse na horizontal
            scrollTop = scrollable.scrollTop; // Posição atual do scroll vertical
            scrollLeft = scrollable.scrollLeft; // Posição atual do scroll horizontal
        };

        const mouseLeaveHandler = () => {
            isDown = false; // Reseta ao sair do elemento
        };

        const mouseUpHandler = () => {
            isDown = false; // Reseta ao soltar o botão do mouse
            scrollable.style.cursor = "grab"; // Restaura o cursor
        };

        const mouseMoveHandler = (e) => {
            if (!isDown) return; // Apenas continua se o mouse estiver pressionado
            e.preventDefault(); // Previne a seleção de texto enquanto arrasta

            // Posição atual do mouse
            const y = e.pageY - scrollable.offsetTop;
            const x = e.pageX - scrollable.offsetLeft;

            // Cálculo da rolagem
            const walkY = (y - startY) * 2; // Multiplicador para controlar a velocidade de rolagem vertical
            const walkX = (x - startX) * 2; // Multiplicador para controlar a velocidade de rolagem horizontal

            // Atualiza a posição do scroll
            scrollable.scrollTop = scrollTop - walkY;
            scrollable.scrollLeft = scrollLeft - walkX;
        };

        // Adiciona os event listeners
        scrollable.addEventListener("mousedown", mouseDownHandler);
        scrollable.addEventListener("mouseleave", mouseLeaveHandler);
        scrollable.addEventListener("mouseup", mouseUpHandler);
        scrollable.addEventListener("mousemove", mouseMoveHandler);

        // Remove os event listeners ao desmontar o componente
        return () => {
            scrollable.removeEventListener("mousedown", mouseDownHandler);
            scrollable.removeEventListener("mouseleave", mouseLeaveHandler);
            scrollable.removeEventListener("mouseup", mouseUpHandler);
            scrollable.removeEventListener("mousemove", mouseMoveHandler);
        };
    }, [cursor]); // Executa apenas na montagem do componente

    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        setNumPages(nextNumPages);
    }

    useEffect(() => {
        setPageSize(orientation);
        return () => {
            const child = document.getElementById("page-orientation");
            if (child) {
                child.parentNode.removeChild(child);
            }
        };
    }, [orientation]);

    return (
        <div className="displayPDF__container" ref={ref} onClick={onClick} style={style}>
            <div
                className="displayPDF__container__document"
                ref={scrollableRef}
                style={{
                    overflow: "auto",
                    height: "100vh", // Altera a altura para 100% da altura da tela
                    cursor,
                    paddingBlock: 10,
                }}
            >
                <Document file={pdfBase64} options={options} onLoadSuccess={onDocumentLoadSuccess}>
                    {Array.from(new Array(numPages), (el, index) => (
                        <Page key={`page_${index + 1}`} scale={zoom} pageNumber={index + 1} />
                    ))}
                </Document>
            </div>
        </div>
    );

    function setPageSize(cssPageSize) {
        const style = document.createElement("style");
        style.innerHTML = `@page {size: ${cssPageSize}}`;
        style.id = "page-orientation";
        document.head.appendChild(style);
    }
});

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import locale from "@amcharts/amcharts4/lang/pt_BR.js";

function montarGraficoLineMensalSafra(state, component, nome, dados = [], titulo, prefixo, sufixo) {
    if (component) {
        component.dispose();
    }

    let chart = am4core.create(nome, am4charts.XYChart3D);

    // Increase contrast by taking evey second color
    chart.colors.step = 1;

    chart.colors.list = state.cores;

    chart.language.locale = locale;

    let title = chart.titles.create();
    title.text = titulo;
    title.fontSize = 16;
    title.marginBottom = 30;

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.filePrefix = "Gráfico";
    chart.exporting.menu.align = "right";
    chart.exporting.menu.verticalAlign = "top";
    chart.exporting.menu.items = [
        {
            label: "...",
            menu: [
                {
                    type: "png",
                    label: "PNG",
                },
            ],
        },
    ];

    chart.language.locale["_decimalSeparator"] = ",";
    chart.language.locale["_thousandSeparator"] = ".";
    chart.numberFormatter.numberFormat = "###,###,###,###.00";

    // Create axes
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    // dateAxis.renderer.minGridDistance = 50;
    dateAxis.dateFormats.setKey("month", "MMMM");
    dateAxis.periodChangeDateFormats.setKey("month", "MMMM");
    dateAxis.baseInterval = {
        timeUnit: "month",
        count: 1,
    };
    dateAxis.startLocation = 0.5;
    dateAxis.endLocation = 0.8;

    // Add legend
    chart.legend = new am4charts.Legend();

    // Add cursor
    chart.cursor = new am4charts.XYCursor();

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    if (chart.yAxes.indexOf(valueAxis) != 0) {
        valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
    }
    // Create series
    let createAxisAndSeries = (field, name, opposite) => {
        let series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = field;
        series.dataFields.dateX = "date";
        series.strokeWidth = 2;
        series.yAxis = valueAxis;
        series.name = name;
        series.tooltipText = `{name}: [bold]${prefixo} {valueY.formatNumber("###,###,###,###.00")} ${sufixo}[/]`;
        series.tensionX = 0.8;
        series.showOnInit = true;

        let interfaceColors = new am4core.InterfaceColorSet();
    };

    valueAxis.renderer.line.strokeOpacity = 1;
    valueAxis.renderer.line.strokeWidth = 2;
    // valueAxis.renderer.line.stroke = series.stroke;
    // valueAxis.renderer.labels.template.fill = series.stroke;
    // valueAxis.renderer.opposite = opposite;

    const series = [];
    dados.forEach((item) => {
        item.anos.forEach((itemAno) => {
            if (!series.includes(itemAno.ano)) {
                series.push(itemAno.ano);
            }
        });
    });
    series.sort();
    const seriesPorMes = [];

    for (let i = 0; i < 12; i++) {
        const date = new Date(2021, i, 1, null, null, null, null); //ano e dia fixo porque não importa, só importa o mês
        const elemento = { date };

        //adiciona a lista de propriedades inicialmente com valores zerados, para cada mes
        series.forEach((nomePropriedade) => {
            elemento[nomePropriedade] = 0;
        });
        seriesPorMes.push(elemento);
    }
    chart.data = seriesPorMes;

    for (let i = 0; i < 12; i++) {
        let mes = dados.find((item) => parseInt(item.mes) == [6, 7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5][i]);

        if (mes) {
            chart.data = chart.data.map((item, j) => {
                //identifica o elemento do mesmo mês que estava na lista original com os dados zerados
                if (i == j) {
                    const valoresSeries = {};

                    series.forEach((nomeSerie) => {
                        //EX: {"ano": "2021-2022",	"mes": "01", "valor": 941}
                        const itemMesAno = (mes.anos || []).find((item) => item && item.ano == nomeSerie) || {};
                        valoresSeries[nomeSerie] = itemMesAno.valor || 0;
                    });

                    return {
                        ...item,
                        ...valoresSeries,
                    };
                } else {
                    return item;
                }
            });
        }
    }

    series.forEach((item) => {
        createAxisAndSeries(String(item), String(item), false);
    });

    return chart;
}

export { montarGraficoLineMensalSafra };

import React from "react";

export default (props) => (
    <div
        className="info-box"
        style={{
            ...props.style,
            minHeight: props.minHeight || 70,
            padding: 0,
            backgroundColor: props.twoColors ? "#fff" : props.backgroundColor || "#BA0000",
            color: props.twoColors ? "#222" : "#fff",
            margin: 5,
        }}
        onClick={props.onClick}
    >
        {props.icon && (
            <span
                className="info-box-icon"
                style={{
                    height: props.minHeight || 70,
                    width: 70,
                    backgroundColor: props.twoColors ? props.twoColors : props.backgroundColor ? props.backgroundColor + "EE" : "#C60000EE",
                    boxShadow: "#200 1px 1px 3px",
                    color: "#fff",
                }}
            >
                <i className={props.icon} aria-hidden="true"></i>
            </span>
        )}
        {props.children && (
            <span
                className="info-box-icon"
                style={{
                    backgroundColor: props.twoColors ? props.twoColors : props.backgroundColor ? props.backgroundColor + "EE" : "#C60000EE",
                    boxShadow: "#200 1px 1px 3px",
                }}
            >
                {props.children}
            </span>
        )}
        <div
            className="info-box-content"
            style={{
                overflowWrap: "break-word",
                fontWeight: props.textBold ? "bold" : null,
            }}
        >
            <span style={{ marginBottom: 5 }}>{props.text}</span>
            <strong>{props.number}</strong>
        </div>
    </div>
);

import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import "react-circular-progressbar/dist/styles.css";
import InfoBox from "../common/template/infoBox";
import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import Tr from "../common/table/tr";
import Select from "../common/form/select";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import BotaoExcluir from "../common/button/botaoExcluir";

import graphMonitor from "../assets/images/graphMonitor.png";
import plugInversor from "../assets/images/plugInversor.png";
import consumedEnergy from "../assets/images/consumedEnergy.png";
import calendarCheck from "../assets/images/calendar-check.svg";
import capacitor from "../assets/images/capacitor.png";
import volts from "../assets/images/volts.png";

import { setFiltro, setAguardando, getDemandaContagem, carregarListas, excluirPeriodoTeste, excluirAgendamento } from "./monitoramentoConsumoActions";
import { adicionarPontosMilhar } from "../utils/adicionarPontosMilhar";
import { Loading } from "../common/loading/loading";
import GraficoMonitoramentoConsumo from "./graficoMonitoramentoConsumo";

function MonitoramentoConsumo(props) {
    const { aguardando, filtro, listaCliente, listaUnidadeConsumidora, listaDemandaContratoTipo, listaDemandaContagem, usuarioLogado, setFiltro, getDemandaContagem, carregarListas } = props;

    const [abaSazonalSelcionada, setAbaSazonalSelcionada] = useState(true);

    useEffect(() => {
        carregarListas();
        getDemandaContagem();
    }, []);

    function listarUCsFiltradas() {
        return listaUnidadeConsumidora.filter((item) => {
            const filtroClienteValidado = !filtro.id_cliente || item.id_cliente == filtro.id_cliente;
            return filtroClienteValidado && item.id_grupo_faturamento == 1;
        });
    }

    function montarTooltipoPeriodoTeste({ periodoTeste, item, props }) {
        if (!periodoTeste.length) return null;
        const mesInicio = "" + JSON.parse(periodoTeste[0]).mes;
        const anoInicio = "" + JSON.parse(periodoTeste[0]).ano;
        const mesFim = "" + JSON.parse(periodoTeste[periodoTeste.length - 1]).mes;
        const anoFim = "" + JSON.parse(periodoTeste[periodoTeste.length - 1]).ano;
        const inicioTeste = mesInicio + "/" + anoInicio;
        const fimTeste = mesFim + "/" + anoFim;

        const dataAtual = new Date();
        const mesAtual = "" + (dataAtual.getMonth() + 1);
        const anoAtual = "" + dataAtual.getFullYear();
        const competenciaAtual = parseInt(anoAtual + mesAtual);
        const competenciaFimTeste = parseInt(anoFim + mesFim);
        const exibirBotaoExcluirTeste = props.usuarioLogado.acesso_periodo_teste_demanda_excluir && competenciaAtual > competenciaFimTeste;
        return (
            <div
                style={{
                    backgroundColor: "#257bfb",
                    padding: 4,
                    borderRadius: "0.3rem",
                    fontSize: "0.8rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexWrap: "wrap",
                    width: 190,
                    minHeight: `${1.3 * (item.agendamentos.length + 2)}rem`, //+2 pq o tooltip teste tem 2 linhas
                    margin: 3,
                }}
            >
                <div
                    style={{
                        margin: 0,
                        display: "flex",
                        justifyContent: exibirBotaoExcluirTeste ? "flex-end" : "center",
                        flexGrow: 1,
                    }}
                >
                    <u>
                        <b>{`Período de Testes ativo: `}</b>
                    </u>
                </div>
                {exibirBotaoExcluirTeste ? (
                    <BotaoExcluir
                        event={() =>
                            props.excluirPeriodoTeste({
                                id: item.id,
                            })
                        }
                    />
                ) : null}
                <div
                    style={{
                        margin: 0,
                        textAlign: "center",
                        minWidth: "100%",
                    }}
                >{`Início ${inicioTeste} - Fim ${fimTeste}`}</div>
            </div>
        );
    }

    function montarTooltipAgendamento({ item, props }) {
        if (!item.agendamentos.length) return null;

        return (
            <div
                style={{
                    backgroundColor: "#f1cf1a",
                    color: "#111",
                    padding: 4,
                    borderRadius: "0.3rem",
                    fontSize: "0.8rem",
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    width: 200,
                    margin: 3,
                }}
            >
                <div
                    style={{
                        margin: 0,
                        textAlign: "center",
                        minWidth: "100%",
                    }}
                >
                    <u>
                        <b>Agendamento de Alteração de Demanda:</b>
                    </u>
                </div>
                {item.agendamentos.map((agendamentoDemanda) => {
                    const dataAtual = new Date();
                    const mesAtual = (dataAtual.getMonth() + 1 < 10 ? "0" : "") + (dataAtual.getMonth() + 1);
                    const anoAtual = String(dataAtual.getFullYear());
                    const mesAgendamento = String(agendamentoDemanda.competencia_inicio_vigencia).substring(4, 6);
                    const anoAgendamento = String(agendamentoDemanda.competencia_inicio_vigencia).substring(0, 4);

                    const competenciaAgendamentoVencida = agendamentoDemanda.competencia_inicio_vigencia < parseInt(anoAtual + mesAtual);
                    const estiloAgendamentoVencido = competenciaAgendamentoVencida
                        ? {
                              border: "1px solid #000000",
                              padding: "0 2px",
                              borderRadius: "0.3rem",
                              color: "#ff0000",
                              fontWeight: 450,
                          }
                        : {};
                    const exibitBotaoExcluirAgendamento = props.usuarioLogado.acesso_cadastro_demanda_editar && competenciaAgendamentoVencida;
                    return (
                        <div
                            style={{
                                display: "flex",
                                flexWrap: "nowrap",
                                justifyContent: "center",
                                alignItems: "center",
                                minWidth: "100%",
                            }}
                        >
                            <span style={estiloAgendamentoVencido}>{agendamentoDemanda.mensagem_tooltip}</span>
                            {exibitBotaoExcluirAgendamento ? (
                                <BotaoExcluir
                                    event={() =>
                                        props.excluirAgendamento({
                                            id: agendamentoDemanda.id,
                                            competencia_agendamento: mesAgendamento + "/" + anoAgendamento,
                                        })
                                    }
                                />
                            ) : null}
                        </div>
                    );
                })}
            </div>
        );
    }

    // VALORES DOS CARDS DO CABEÇALHO --------------------------------------
    const totalUsinas = 0;
    const potenciaTotalInstalada = (0).toFixed(2).replace(".", ",") + " MW";

    const energiaTotalDia = (0).toFixed(2).replace(".", ",") + " MWh";
    const energiaTotalMes = (0).toFixed(2).replace(".", ",") + " MWh";
    const totalUsinasOperandoNormalmente = 0;
    const totalUsinasSemComunicacao = 0;

    const totalUsinasProducaoOtima = 0;

    const totalUsinasProducaoModerada = 0;
    const totalUsinasProducaoBaixa = 0;
    //----------------------------------------------------------------------

    //CARDS

    const cardsTotalizadores = (
        <>
            <Grid cols="12 6 6 6" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                <InfoBox text="Número de Unidades" number={totalUsinas} style={{ height: window.innerWidth < 992 ? null : 50, fontSize: "0.9rem" }} minHeight={50}>
                    <img
                        src={graphMonitor}
                        style={{
                            height: 30,
                            width: 30,
                            filter: "brightness(0) invert(1)",
                        }}
                        alt=""
                    />
                </InfoBox>
            </Grid>
            <Grid cols="12 6 6 6" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                <InfoBox text="Potência Total Instalada" number={potenciaTotalInstalada} style={{ height: window.innerWidth < 992 ? null : 50, fontSize: "0.9rem" }} minHeight={50}>
                    <img
                        src={plugInversor}
                        style={{
                            height: 40,
                            width: 40,
                            filter: "brightness(0) invert(1)",
                        }}
                        alt=""
                    />
                </InfoBox>
            </Grid>
            <Grid cols="12 6 6 6" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                <InfoBox text="Energia Total Consumida no Dia" number={energiaTotalDia} style={{ height: window.innerWidth < 992 ? null : 50, fontSize: "0.9rem" }} minHeight={50}>
                    <img
                        src={consumedEnergy}
                        style={{
                            height: 40,
                            width: 40,
                            filter: "brightness(0) invert(1)",
                        }}
                        alt=""
                    />
                </InfoBox>
            </Grid>
            <Grid cols="12 6 6 6" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                <InfoBox text="Energia Total Consumida no Mês" number={energiaTotalMes} style={{ height: window.innerWidth < 992 ? null : 50, fontSize: "0.9rem" }} minHeight={50}>
                    <img
                        src={calendarCheck}
                        style={{
                            height: 30,
                            width: 30,
                            filter: "brightness(0) invert(1)",
                        }}
                        alt=""
                    />
                </InfoBox>
            </Grid>
        </>
    );

    const cardsStatusProducao = (
        <>
            <Grid cols="12 6 6 6" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                <InfoBox icon="fas fa-check" text="Operando Normalmente" number={totalUsinasOperandoNormalmente} twoColors="#3EFF3E" minHeight={50} />
            </Grid>
            <Grid cols="12 6 6 6" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                <InfoBox icon="fas fa-exclamation-triangle" text="Sem Comunicação ou Desligado" number={totalUsinasSemComunicacao} twoColors="#F56969" minHeight={50} />
            </Grid>
            <Grid cols="12 4 4 4" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null, fontSize: "0.8rem" }}>
                <InfoBox text="Adv. por Fator de Potência Baixo" textBold number={totalUsinasProducaoOtima} twoColors="#34c6ff" minHeight={50}>
                    <img
                        src={capacitor}
                        style={{
                            height: 40,
                            width: 40,
                        }}
                        alt=""
                    />
                </InfoBox>
            </Grid>
            <Grid cols="12 4 4 4" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null, fontSize: "0.8rem" }}>
                <InfoBox text="Advertência por Tensão Baixa" textBold number={totalUsinasProducaoModerada} twoColors="#ffe53b" minHeight={50}>
                    <img
                        src={volts}
                        style={{
                            height: 45,
                            width: 45,
                        }}
                        alt=""
                    />
                </InfoBox>
            </Grid>
            <Grid cols="12 4 4 4" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null, fontSize: "0.8rem" }}>
                <InfoBox text="Advertência por Tensão Alta" textBold number={totalUsinasProducaoBaixa} twoColors="#ffa600" minHeight={50}>
                    <img
                        src={volts}
                        style={{
                            height: 50,
                            width: 50,
                        }}
                        alt=""
                    />
                </InfoBox>
            </Grid>
        </>
    );
    //----------------------------------------------------------------------

    const corAbaAtiva = "#ffffff";
    const corAbaInativa = "#ebebeb";
    const corTextoAbaAtiva = "#000000";
    const corTextoAbaInativa = "#cccccc";

    return aguardando ? (
        <Loading />
    ) : (
        <ContentCard>
            <ContentCardBody>
                <Row
                    style={{
                        marginBottom: 5,
                        justifyContent: "space-between",
                        paddingInline: 10,
                    }}
                >
                    <GraficoMonitoramentoConsumo />
                    <Grid cols="12 12 12 6">
                        {window.innerWidth > 768 ? (
                            <Row>
                                {cardsTotalizadores}
                                {cardsStatusProducao}
                            </Row>
                        ) : (
                            <details open={usuarioLogado && !usuarioLogado.acesso_cliente} style={{ marginTop: 20, padding: 20, fontSize: "1.1rem", border: "1px solid #ddd", borderRadius: 5 }}>
                                <summary>
                                    <b>Exibir totalizadores</b>
                                </summary>
                                <Row style={{ marginTop: 10 }}>
                                    {cardsTotalizadores}
                                    {cardsStatusProducao}
                                </Row>
                            </details>
                        )}
                    </Grid>
                </Row>
                <Row>
                    {usuarioLogado && usuarioLogado.acesso_cliente ? null : (
                        <Select
                            style={{ margin: 0 }}
                            name="id_cliente"
                            options={listaCliente}
                            label="Cliente"
                            cols="12 12 3 3"
                            placeholder="Selecione o Cliente"
                            value={filtro.id_cliente}
                            onChange={(value) => {
                                setFiltro({
                                    ...filtro,
                                    id_cliente: value,
                                    id_unidade_consumidora: null,
                                });
                                // buscarDadosTela();
                            }}
                        />
                    )}
                    <Select
                        style={{ margin: 0 }}
                        options={listarUCsFiltradas()}
                        label="Unidades Consumidoras"
                        cols="12 12 3 3"
                        placeholder="Selecione as UCs"
                        isMulti={true}
                        value={filtro.unidades_consumidoras}
                        onChange={(ucsSelecionadas) => {
                            const ucQualquerDoFiltro = ucsSelecionadas[0] || {};
                            const ucCompleta = listaUnidadeConsumidora.find((uc) => ucQualquerDoFiltro.value == uc.id) || { id_cliente: null };
                            setFiltro({
                                ...filtro,
                                unidades_consumidoras: [...ucsSelecionadas],
                                id_cliente: filtro.id_cliente || ucCompleta.id_cliente,
                            });
                        }}
                    />
                    <Select
                        style={{ margin: 0 }}
                        name="demanda_contrato_tipos"
                        options={listaDemandaContratoTipo.filter((tipo) => {
                            if (!filtro.id_cliente && tipo.id != 3) return true;
                            const ucFiltradaPeloCliente = listaUnidadeConsumidora.filter((uc) => uc.id_cliente == filtro.id_cliente);
                            const condicaoForaPonta = tipo.id == 1;
                            const condicaoPonta = tipo.id == 2 && !!ucFiltradaPeloCliente.find((uc) => uc.id_modalidade_tarifaria == 2); //alguma uc do cliente selecionado tem q ser horosazonal azul
                            return condicaoForaPonta || condicaoPonta;
                        })}
                        label="Tipo de Contrato de Demanda"
                        cols="12 12 3 3"
                        placeholder="Selecione o tipo de contrato de demanda"
                        value={filtro.id_tipo_contrato_demanda}
                        isClearable={false}
                        isSearchable={false}
                        onChange={(id_tipo_contrato_demanda) => {
                            setFiltro({
                                ...filtro,
                                id_tipo_contrato_demanda,
                            });
                        }}
                    />

                    <Grid
                        cols="12 12 1 1"
                        style={{
                            paddingTop: 15,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            // fontSize: "1.7rem",
                            paddingLeft: window.innerWidth < 992 ? 0 : null,
                        }}
                    >
                        <Button
                            solid={true}
                            style={{
                                marginTop: 13,
                                color: "#fff",

                                transform: "scaleX(-1)",
                            }}
                            text={usuarioLogado.emitir_alerta_sonoro ? <i style={{ fontSize: "1.2rem" }} className="far fa-bell" /> : <i style={{ fontSize: "1.2rem" }} className="far fa-bell-slash" />}
                            type={"warning"}
                            // event={setEmitirAlertaSonoro}
                        />
                    </Grid>
                    <Grid cols="12 12 2 2" style={{ paddingTop: 29, paddingRight: 15 }}>
                        <Button text="Atualizar" type={"primary"} icon={"fa fa-sync"} event={getDemandaContagem} />
                    </Grid>
                </Row>
            </ContentCardBody>
            <ContentCardBody
                style={{
                    paddingTop: 0,
                    minWidth: 890,
                }}
            >
                <Row>
                    <Grid
                        cols="4 4 4 4"
                        style={{
                            padding: "15px 0px 5px 10px",
                            marginLeft: 7,
                            backgroundColor: abaSazonalSelcionada ? corAbaAtiva : corAbaInativa,
                            color: abaSazonalSelcionada ? corTextoAbaAtiva : corTextoAbaInativa,
                            marginBottom: 0,
                            zIndex: abaSazonalSelcionada ? 1 : 0,
                            borderRadius: "10px 10px 0 0",
                            border: `solid 1px ${abaSazonalSelcionada ? "#ddd" : corAbaInativa}`,
                            cursor: "pointer",
                            textAlign: "center",
                        }}
                        onClick={() => {
                            setAbaSazonalSelcionada(true);
                        }}
                    >
                        <h6>Sazonal</h6>
                    </Grid>
                    <Grid
                        cols="4 4 4 4"
                        style={{
                            padding: "15px 0px 5px 10px",
                            backgroundColor: abaSazonalSelcionada ? corAbaInativa : corAbaAtiva,
                            color: abaSazonalSelcionada ? corTextoAbaInativa : corTextoAbaAtiva,
                            marginBottom: 0,
                            zIndex: abaSazonalSelcionada ? 0 : 1,
                            borderRadius: "10px 10px 0 0",
                            border: `solid 1px ${abaSazonalSelcionada ? corAbaInativa : "#ddd"}`,
                            cursor: "pointer",
                            textAlign: "center",
                        }}
                        onClick={() => {
                            setAbaSazonalSelcionada(false);
                        }}
                    >
                        <h6>Não Sazonal</h6>
                    </Grid>
                </Row>
                <Table responsive>
                    <THead>
                        <Tr>
                            <Th backgroundColor={"#FFFFFF"} sticky alignCenter colSpan={abaSazonalSelcionada ? "4" : "2"}>
                                Atingidas
                            </Th>
                            {usuarioLogado.administrador ? (
                                <Th backgroundColor={"#FFFFFF"} sticky>
                                    Cliente
                                </Th>
                            ) : null}
                            <Th colSpan={3} backgroundColor={"#FFFFFF"} sticky>
                                Unidade Consumidora
                            </Th>
                            {abaSazonalSelcionada ? (
                                <>
                                    <Th backgroundColor={"#FFFFFF"} sticky alignCenter>
                                        Início
                                    </Th>
                                    <Th backgroundColor={"#FFFFFF"} sticky alignCenter>
                                        Fim
                                    </Th>
                                    <Th minWidth={80} backgroundColor={"#FFFFFF"} sticky alignCenter>
                                        Faturas do Ciclo
                                    </Th>
                                </>
                            ) : (
                                <>
                                    <Th backgroundColor={"#FFFFFF"} sticky alignCenter>
                                        Primeiro Mês Não Sazonal
                                    </Th>
                                    <Th backgroundColor={"#FFFFFF"} sticky alignCenter>
                                        Nº Meses Sem Sazonalidade
                                    </Th>
                                </>
                            )}

                            <Th backgroundColor={"#FFFFFF"} sticky alignCenter>
                                Sazonalidade
                            </Th>
                            <Th minWidth={50} backgroundColor={"#FFFFFF"} sticky alignCenter>
                                <p style={{ margin: 0 }}>P</p>(kW)
                            </Th>
                            <Th minWidth={50} backgroundColor={"#FFFFFF"} sticky alignCenter>
                                <p style={{ margin: 0 }}>Q</p>(kVAr)
                            </Th>
                            <Th minWidth={50} backgroundColor={"#FFFFFF"} sticky alignCenter>
                                <p style={{ margin: 0 }}>S</p>(kVA)
                            </Th>
                            <Th minWidth={50} backgroundColor={"#FFFFFF"} sticky alignCenter>
                                <p style={{ margin: 0 }}>I</p>(Ampere)
                            </Th>
                            <Th minWidth={50} backgroundColor={"#FFFFFF"} sticky alignCenter>
                                <p style={{ margin: 0 }}>V</p>(volts)
                            </Th>
                            <Th minWidth={50} backgroundColor={"#FFFFFF"} sticky alignCenter>
                                <p style={{ margin: 0 }}>FP</p>
                            </Th>
                            <Th minWidth={100} backgroundColor={"#FFFFFF"} sticky alignCenter>
                                <p style={{ margin: 0 }}>P (kW)</p>
                                <p style={{ margin: 0 }}> Máx. no Mês</p>
                                <p style={{ margin: 0 }}>(Demanda)</p>
                            </Th>
                            <Th minWidth={150} backgroundColor={"#FFFFFF"} sticky alignCenter>
                                <p style={{ margin: 0 }}>Valor de Demanda</p>
                                <p style={{ margin: 0 }}>Contratada do</p>
                                <p style={{ margin: 0 }}>Mês Atual (kW)</p>
                            </Th>
                        </Tr>
                    </THead>
                    <TBody>
                        {listaDemandaContagem
                            .filter((item) => (abaSazonalSelcionada ? item.contrato_demanda_sazonal : !item.contrato_demanda_sazonal))
                            .map((item, i) => {
                                const sazonalidadeDiferenteDaUC = item.contrato_demanda_sazonal != item.sazonal;

                                let restantes = item.competencias - item.lidas;
                                let cor = "#222";
                                if (item.atingidas < 3) {
                                    if (restantes <= 3 && item.atingidas <= 1) {
                                        cor = "#c92e2b";
                                    } else if (restantes <= 4 && item.atingidas <= 2) {
                                        cor = "#f7e11b";
                                    }
                                }
                                //Configura o Tooltip de agendamento de demanda-------------------------------------------------------------------------------------
                                let tooltipAgendamentoDemanda = montarTooltipAgendamento({ item, props });

                                //Configura o Tooltip de período de testes -----------------------------------------------------------------------------------------
                                const periodoTeste = item.periodo_teste || [];
                                let tooltipPeriodoTeste = montarTooltipoPeriodoTeste({ periodoTeste, item, props });

                                const avisoSazonalidadeDiferente = (
                                    <div style={{ backgroundColor: "#fd5959", color: "#ffffff", padding: 5, borderRadius: "0.3rem", textAlign: "center" }}>
                                        <div style={{ width: "100%", textWrap: "nowrap" }}>A sazonalidade da UC é diferente do contrato</div>
                                        <div>
                                            (UC:{item.contrato_demanda_sazonal ? " Sazonal " : " Não Sazonal "} - Contrato:
                                            {item.sazonal ? " Sazonal " : " Não Sazonal "})
                                        </div>
                                    </div>
                                );
                                //----------------------------------------------------------------------------------------------------------------------------------
                                const ultimaLinha = i == listaDemandaContagem.length - 1;
                                const horosazonalVerde = item.id_modalidade_tarifaria == 1;
                                return (
                                    <Tr key={item.id}>
                                        {abaSazonalSelcionada ? (
                                            <>
                                                <Td
                                                    borderLeft="1px solid #81c784"
                                                    borderRight="1px solid #81c784"
                                                    borderBottom={ultimaLinha ? "1px solid #81c784" : null}
                                                    color={"#fff"}
                                                    minWidth={40}
                                                    backgroundColor={item.atingidas >= 1 ? "#28a745" : "#fff"}
                                                    alignCenter
                                                ></Td>
                                                <Td
                                                    minWidth={40}
                                                    borderLeft="1px solid #81c784"
                                                    borderRight="1px solid #81c784"
                                                    borderBottom={ultimaLinha ? "1px solid #81c784" : null}
                                                    color={item.atingidas >= 2 ? "#fff" : "#000"}
                                                    backgroundColor={item.atingidas >= 2 ? "#28a745" : "#fff"}
                                                    alignCenter
                                                >
                                                    {item.atingidas}
                                                </Td>
                                                <Td
                                                    borderLeft="1px solid #81c784"
                                                    borderRight="1px solid #81c784"
                                                    borderBottom={ultimaLinha ? "1px solid #81c784" : null}
                                                    color={"#fff"}
                                                    minWidth={40}
                                                    backgroundColor={item.atingidas >= 3 ? "#28a745" : "#fff"}
                                                    alignCenter
                                                ></Td>
                                                <Td
                                                    color={"#fff"}
                                                    title={`Total de ultrapassagens:${parseInt(item.ultrapassagens || 0)}`}
                                                    minWidth={40}
                                                    backgroundColor={parseInt(item.ultrapassagens) ? "#fa3c3c" : "black"}
                                                    borderBottom={ultimaLinha ? `1px solid ${parseInt(item.ultrapassagens) ? "#fa3c3c" : "black"}` : null}
                                                    alignCenter
                                                >
                                                    <div style={{ display: "flex", flexGrow: 1, flexDirection: "column", borderRadius: 3 }}>
                                                        <div style={{ fontSize: "1.4rem", fontWeight: "bold" }} className="info-box-icon">
                                                            <i className="fas fa-angle-double-up"></i>
                                                        </div>
                                                        <div style={{ fontSize: "0.9rem", fontWeight: "bold" }} className="info-box-number">
                                                            {item.ultrapassagens}
                                                        </div>
                                                    </div>
                                                </Td>
                                            </>
                                        ) : (
                                            <>
                                                <Td>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            flexDirection: "column",
                                                            alignItems: "center",
                                                            padding: "5px 5px 0px 5px",
                                                            minHeight: "fit-content",
                                                        }}
                                                        className="info-box bg-green"
                                                        title="Atingidas sem Ultrapassagens"
                                                    >
                                                        <span className="info-box-icon" style={{ width: window.innerWidth < 576 ? 20 : 70, fontSize: "1.3rem" }}>
                                                            <i className="fas fa-angle-up"></i>
                                                        </span>
                                                        <span style={{ margin: 0 }} className="info-box-number">
                                                            {item.atingidas - (item.ultrapassagens || 0)}
                                                        </span>
                                                    </div>
                                                </Td>
                                                <Td>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            flexDirection: "column",
                                                            alignItems: "center",
                                                            padding: "5px 5px 0px 5px",
                                                            minHeight: "fit-content",
                                                        }}
                                                        className="info-box bg-red"
                                                        title="Atingidas com Ultrapassagens"
                                                    >
                                                        <span className="info-box-icon" style={{ width: window.innerWidth < 576 ? 20 : 70, fontSize: "1.3rem" }}>
                                                            <i className="fas fa-angle-double-up"></i>
                                                        </span>
                                                        <span style={{ margin: 0 }} className="info-box-number">
                                                            {item.ultrapassagens || 0}
                                                        </span>
                                                    </div>
                                                </Td>
                                            </>
                                        )}
                                        {usuarioLogado.administrador ? <Td title={item.nome_cliente}>{item.nome_cliente.length > 25 ? item.nome_cliente.substring(0, 25) + "..." : item.nome_cliente}</Td> : null}
                                        <Td title={item.nome_unidade_consumidora} colSpan={!sazonalidadeDiferenteDaUC && !tooltipAgendamentoDemanda && !tooltipPeriodoTeste ? "3" : "1"}>
                                            {item.nome_unidade_consumidora}
                                        </Td>
                                        {sazonalidadeDiferenteDaUC ? (
                                            <Td colSpan="2">{avisoSazonalidadeDiferente}</Td>
                                        ) : tooltipAgendamentoDemanda || tooltipPeriodoTeste ? (
                                            <>
                                                <Td padding={10}>{tooltipAgendamentoDemanda}</Td>
                                                <Td padding={10} color="#fff">
                                                    {tooltipPeriodoTeste}
                                                </Td>
                                            </>
                                        ) : null}
                                        {abaSazonalSelcionada ? (
                                            <>
                                                <Td padding={10} alignCenter>
                                                    {sazonalidadeDiferenteDaUC ? "-" : `${String(item.competencia_inicio).substring(4, 6)}/${String(item.competencia_inicio).substring(0, 4)}`}
                                                </Td>
                                                <Td padding={10} alignCenter>
                                                    {sazonalidadeDiferenteDaUC ? "-" : `${String(item.competencia_fim).substring(4, 6)}/${String(item.competencia_fim).substring(0, 4)}`}
                                                </Td>
                                            </>
                                        ) : (
                                            <Td padding={10} alignCenter>
                                                {sazonalidadeDiferenteDaUC ? "-" : `${String(item.competencia_base_sazonalidade).substring(4, 6)}/${String(item.competencia_base_sazonalidade).substring(0, 4)}`}
                                            </Td>
                                        )}
                                        {item.sazonal ? (
                                            <Td
                                                color={cor}
                                                fontWeight="bold"
                                                title={
                                                    item.competencias - item.lidas > 3 - item.atingidas || item.atingidas >= 3
                                                        ? null
                                                        : item.competencias - item.lidas == 3 - item.atingidas
                                                        ? `É necessário atingir ${3 - item.atingidas} demanda${3 - item.atingidas > 1 ? "s" : ""} e resta${item.competencias - item.lidas > 1 ? "m" : ""} ${
                                                              item.competencias - item.lidas
                                                          } fatura${item.competencias - item.lidas > 1 ? "s" : ""} no ciclo sazonal`
                                                        : `É necessário atingir ${3 - item.atingidas} demanda${3 - item.atingidas > 1 ? "s" : ""} e ${
                                                              item.competencias == item.lidas
                                                                  ? "o ciclo sazonal já está completo"
                                                                  : `resta${item.competencias - item.lidas > 1 ? "m" : ""} ${item.competencias - item.lidas} fatura${
                                                                        item.competencias - item.lidas > 1 ? "s" : ""
                                                                    } no ciclo sazonal`
                                                          }`
                                                }
                                                backgroundColor={
                                                    item.competencias - item.lidas > 3 - item.atingidas || item.atingidas >= 3 ? null : item.competencias - item.lidas == 3 - item.atingidas ? "#ffb74d" : "#e57373"
                                                }
                                                alignCenter
                                            >
                                                {sazonalidadeDiferenteDaUC ? "-" : `${item.lidas || 1} de ${item.competencias}`}
                                            </Td>
                                        ) : (
                                            <Td padding={10} color="#fff" alignCenter>
                                                {item.meses_nao_sazonal || 1}
                                            </Td>
                                        )}

                                        <Td color="#fff" backgroundColor="#40838f" alignCenter>
                                            {sazonalidadeDiferenteDaUC ? "-" : parseFloat(item.sazonalidade).toFixed(2).replace(".", ",")}
                                        </Td>
                                        <Td alignCenter>0</Td>
                                        <Td alignCenter>0</Td>
                                        <Td alignCenter>0</Td>
                                        <Td alignCenter>0</Td>
                                        <Td alignCenter>0</Td>
                                        <Td alignCenter>0</Td>
                                        <Td alignCenter>
                                            {horosazonalVerde ? (
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ border: "1px solid #ddd", padding: 4, textAlign: "center", minWidth: "fit-content", width: "100vw", textWrap: "nowrap", fontSize: "0.8rem" }}>
                                                                Fora Ponta
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ border: "1px solid #ddd", textAlign: "center", padding: 4, fontSize: "0.8rem" }}>
                                                                {adicionarPontosMilhar(item.demanda_fora_ponta_contratada_mes_atual) || "-"}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ border: "1px solid #ddd", padding: 4, textAlign: "center", minWidth: "fit-content", width: "50vw", textWrap: "nowrap", fontSize: "0.8rem" }}>
                                                                Fora Ponta
                                                            </th>
                                                            <th style={{ border: "1px solid #ddd", padding: 4, textAlign: "center", minWidth: "fit-content", width: "50vw", textWrap: "nowrap", fontSize: "0.8rem" }}>
                                                                Ponta
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ border: "1px solid #ddd", textAlign: "center", padding: 4 }}>{adicionarPontosMilhar(item.demanda_fora_ponta_contratada_mes_atual) || "-"}</td>
                                                            <td style={{ border: "1px solid #ddd", textAlign: "center", padding: 4 }}>{adicionarPontosMilhar(item.demanda_ponta_contratada_mes_atual) || "-"}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            )}
                                        </Td>
                                        <Td alignCenter minWidth={160}>
                                            {horosazonalVerde ? (
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ border: "1px solid #ddd", padding: 4, textAlign: "center", minWidth: "fit-content", width: "100vw", textWrap: "nowrap", fontSize: "0.8rem" }}>
                                                                Fora Ponta
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ border: "1px solid #ddd", textAlign: "center", padding: 4, fontSize: "0.8rem" }}>
                                                                {adicionarPontosMilhar(item.demanda_fora_ponta_contratada_mes_atual) || "-"}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ border: "1px solid #ddd", padding: 4, textAlign: "center", minWidth: "fit-content", width: "50vw", textWrap: "nowrap", fontSize: "0.8rem" }}>
                                                                Fora Ponta
                                                            </th>
                                                            <th style={{ border: "1px solid #ddd", padding: 4, textAlign: "center", minWidth: "fit-content", width: "50vw", textWrap: "nowrap", fontSize: "0.8rem" }}>
                                                                Ponta
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ border: "1px solid #ddd", textAlign: "center", padding: 4 }}>{adicionarPontosMilhar(item.demanda_fora_ponta_contratada_mes_atual) || "-"}</td>
                                                            <td style={{ border: "1px solid #ddd", textAlign: "center", padding: 4 }}>{adicionarPontosMilhar(item.demanda_ponta_contratada_mes_atual) || "-"}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            )}
                                        </Td>
                                    </Tr>
                                );
                            })}
                    </TBody>
                </Table>
            </ContentCardBody>
        </ContentCard>
    );
}

const mapStateToProps = (state) => {
    return {
        usuarioLogado: state.auth.usuarioLogado,
        aguardando: state.monitoramentoConsumo.aguardando,
        filtro: state.monitoramentoConsumo.filtro,
        listaCliente: state.monitoramentoConsumo.listaCliente,
        listaUnidadeConsumidora: state.monitoramentoConsumo.listaUnidadeConsumidora,
        listaDemandaContagem: state.monitoramentoConsumo.listaDemandaContagem,
        listaDemandaContratoTipo: state.monitoramentoConsumo.listaDemandaContratoTipo,
    };
};
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setFiltro,
            setAguardando,
            getDemandaContagem,
            carregarListas,
            excluirPeriodoTeste,
            excluirAgendamento,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(MonitoramentoConsumo);

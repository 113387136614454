import React from "react";
import { Link } from "react-router-dom";

export default (props) => {
	if (props.permitido) {
		return (
            <li
                className="nav-item"
                title={props.title}
                style={{
                    backgroundColor: props.submenu ? "#d60000" : "#BA0000",
                }}
            >
                <Link
                    to={props.path}
                    className={`nav-link ${props.active ? "active" : ""}`}
                    onClick={() => {
                        if (window.innerWidth <= 990) {
                            const hamburgerMenu = document.getElementById("hamburger-menu");
                            hamburgerMenu.click();
                        }
                    }}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <i
                        className={`nav-icon ${props.icon}`}
                        style={{
                            color: "#fff",
                            marginLeft: props.submenu ? props.marginLeft || 20 : props.marginLeft || null,
                            marginRight: props.marginRight || null,
                        }}
                    >
                        {props.muiIcon}
                    </i>

                    <p style={{ color: "#fff", width: 190, textWrap: "balance", fontSize: props.submenu ? 13 : null }}>
                        {props.label}
                        <span className={`badge badge-${props.spanType} right`}>{props.span}</span>
                    </p>
                </Link>
            </li>
        );
	} else {
		return (
            <li className="nav-item" style={{ backgroundColor: props.submenu ? "#d60000" : "#BA0000" }}>
                <div className={`nav-link ${props.active ? "active" : ""}`}>
                    <i
                        className={`nav-icon ${props.icon}`}
                        style={{
                            color: "#d196a4",
                            marginLeft: props.submenu ? props.marginLeft || 20 : props.marginLeft || null,
                            marginRight: props.marginRight || null,
                        }}
                    >
                        {props.muiIcon}
                    </i>

                    <p style={{ color: "#fff", width: 190, textWrap: "balance", fontSize: props.submenu ? 13 : null }}>
                        {props.label}
                        <span className={`badge badge-${props.spanType} right`}>{props.span}</span>
                    </p>
                </div>
            </li>
        );
	}
};

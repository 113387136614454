import axios from "axios";
import consts from "../consts";
import { setError, setErroAPI, setSuccess } from "../common/toast/toast";
import { setArquivoPDF } from "../displayPDF/displayPDFActions";

//actions em uso
/**  setModoTela,
    setFiltro,
    setAguardando,
    getLista,
    getListaCliente,
    getListaUnidadeConsumidora,
    gerarRelatorioMensal,
    gerarRelatorioSolarMensal,
    enviarRelatorio,
    getDadosRelatorioSafra, */

export function setModoTela(modo, registro = {}) {
    return {
        type: "FATURA_RELATORIO_MODO_TELA",
        payload: {
            modo: modo,
            registro: registro,
        },
    };
}

export function setFiltro(filtro) {
    return {
        type: "FATURA_RELATORIO_FILTRO",
        payload: filtro,
    };
}

export function setAguardando(aguardando) {
    return {
        type: "FATURA_RELATORIO_SET_AGUARDANDO",
        payload: aguardando,
    };
}

export function gerarRelatorioMensal(registro) {
    return async (dispatch, getState) => {
        try {
            dispatch(setAguardando(true));

            const filtro = getState().faturaRelatorio.filtro;

            const mesAno = `${filtro.mes}_${filtro.ano}`;
            if (!mesAno) {
                setError("Selecione o ano e o mês");
                return {};
            }

            // Prefeitura Municipal De Santiago
            if (registro.id_cliente == 58) {
                const resp = await axios.get(
                    `${consts.API_URL}/relatorioMensalGrupoB?id_cliente=${registro.id_cliente || null}&nome_cliente=${
                        registro.nome_cliente || null
                    }&competencia=${mesAno}&resumido=${registro.relatorio_resumido}`,
                );

                if (resp.data && resp.data.arquivo_pdf && resp.data.nomePdf) {
                    dispatch(setArquivoPDF(resp.data.arquivo_pdf, resp.data.nomePdf));
                }
            } else {
                const resp = await axios.get(
                    `${consts.API_URL}/relatorioMensal?id_cliente=${registro.id_cliente || null}&nome_cliente=${
                        registro.nome_cliente || null
                    }&competencia=${mesAno}&resumido=${registro.relatorio_resumido}&id_tipo_contrato_demanda=1` //id_tipo_contrato_demanda 1 = fora ponta, 2 ponta, 3 geracao
                );

                if (resp.data && resp.data.arquivo_pdf && resp.data.nomePdf) {
                    dispatch(setArquivoPDF(resp.data.arquivo_pdf, resp.data.nomePdf));
                }
            }
        } catch (ex) {
            setErroAPI(ex);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export function gerarRelatorioSolarMensal(registro) {
    return async (dispatch, getState) => {
        try {
            dispatch(setAguardando(true));
            const filtro = getState().faturaRelatorio.filtro;
            const mesAno = `${filtro.mes}_${filtro.ano}`;
            if (!mesAno) {
                setError("Selecione o ano e o mês");
                return {};
            }
            const resp = await axios.get(
                `${consts.API_URL}/relatorioSolarMensal?
			id_cliente=${registro.id_cliente || null}
			&id_unidade_consumidora=${registro.id_unidade_consumidora || null}
			&nome_unidade_consumidora=${registro.nome_unidade_consumidora || null}
			&nome_cliente=${registro.nome_cliente || null}
			&competencia=${mesAno}`,
            );

            if (resp.data && resp.data.arquivo_pdf && resp.data.nomePdf) {
                dispatch(setArquivoPDF(resp.data.arquivo_pdf, resp.data.nomePdf));
            }
        } catch (ex) {
            setErroAPI(ex);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export function enviarRelatorio(id_cliente) {
    //IMPLEMENTAR BUSCA DA LISTA DE TIPO DE RELATORI DO BANCO POSTERIORMENTE, POR ENQUANTO ESTÁ ESTATICO NO REDUCER
    return async (dispatch, getState) => {
        try {
            const filtro = getState().faturaRelatorio.filtro;

            let registro = null;

            if (filtro.id_tipo_relatorio == 1) {
                //tipo 1 passa a mesma competencia como inicial e final
                registro = {
                    id_cliente,
                    id_tipo_relatorio: filtro.id_tipo_relatorio,
                    competencia_inicial: `${filtro.ano}${filtro.mes}`,
                    competencia_final: `${filtro.ano}${filtro.mes}`,
                };
            }

            if (filtro.id_tipo_relatorio == 2) {
                //tipo 2 passa a mesma competencia inicio e fim de safra
                registro = {
                    id_cliente,
                    id_tipo_relatorio: filtro.id_tipo_relatorio,
                    competencia_inicial: `${filtro.ano_safra.split("/")[0]}06`,
                    competencia_final: `${filtro.ano_safra.split("/")[1]}05`,
                };
            }

            if (!registro) {
                setError("Não foi possível concluir a operação");
                return {};
            }
            dispatch(setAguardando(true));

            await axios.post(`${consts.API_URL}/fatura/enviarRelatorio`, registro);

            dispatch(getLista());

            setSuccess('Status do relatorio alterado para  "Enviado"');
        } catch (ex) {
            setErroAPI(ex);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export function getDadosRelatorioSafra(id_cliente) {
    return async (dispatch, getState) => {
        try {
            const filtro = getState().faturaRelatorio.filtro;
            const idClienteValidado = id_cliente || 0;

            dispatch(setAguardando(true));

            const competencia_inicial = `${filtro.ano_safra.split("/")[0]}06`;
            const competencia_final = `${filtro.ano_safra.split("/")[1]}05`;
            if (!competencia_inicial || !competencia_final) {
                setError("Selecione o ano safra");
                return {};
            }
            const resp = await axios.get(
                `${consts.API_URL}/fatura/gerarRelatorioSafra?id_cliente=${idClienteValidado}&competencia_inicial=${competencia_inicial}&competencia_final=${competencia_final}`,
            );

            dispatch({
                type: "FATURA_RELATORIO_DADOS_SAFRA",
                payload: resp,
            });
        } catch (ex) {
            setError("Erro ao carregar registros");
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export function getLista() {
    return async (dispatch, getState) => {
        try {
            const filtro = getState().faturaRelatorio.filtro;
            const usuarioLogado = getState().auth.usuarioLogado || {};
            const idClienteValidado = filtro.id_cliente || (usuarioLogado.administrador ? null : 0);
            let resp = { data: [] };

            dispatch(setAguardando(true));

            //MODO MENSAL
            if (filtro.id_tipo_relatorio == 1) {
                const competencia = `${filtro.ano}${filtro.mes}`;
                if (!competencia) {
                    setError("Selecione o ano e o mês");
                    return {};
                }
                resp = await axios.get(
                    `${consts.API_URL}/fatura/listaRelatorioMensal?id_cliente=${idClienteValidado}&competencia=${competencia}`,
                );
            }

            //MODO SAFRA
            if (filtro.id_tipo_relatorio == 2) {
                const competencia_inicial = `${filtro.ano_safra.split("/")[0]}06`;
                const competencia_final = `${filtro.ano_safra.split("/")[1]}05`;
                if (!competencia_inicial || !competencia_final) {
                    setError("Selecione o ano safra");
                    return {};
                }
                resp = await axios.get(
                    `${consts.API_URL}/fatura/listaRelatorioSafra?id_cliente=${idClienteValidado}&competencia_inicial=${competencia_inicial}&competencia_final=${competencia_final}`,
                );
            }

            //MODO ANUAL (falta implementar)

            dispatch({
                type: "FATURA_RELATORIO_LISTADO",
                payload: resp,
            });
        } catch (ex) {
            setError("Erro ao carregar registros");
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export function getListaCliente() {
    const request = axios.get(`${consts.API_URL}/cliente/listarSelect`);
    return {
        type: "FATURA_RELATORIO_CLIENTE_SELECT_LISTADO",
        payload: request,
    };
}

export function getListaUnidadeConsumidora() {
    const request = axios.get(`${consts.API_URL}/unidadeConsumidora/listarSelect`);
    return {
        type: "FATURA_RELATORIO_UNIDADE_CONSUMIDORA_SELECT_LISTADO",
        payload: request,
    };
}

// export function getDemanda() {
//     return async (dispatch, getState) => {
//         try {
//             dispatch({
//                 type: "FATURA_RELATORIO_DEMANDA_LISTADO",
//                 payload: null,
//             });

//             const resp = await axios.get(
//                 `${consts.API_URL}/demandaContrato?
// 			id_cliente=${getState().faturaRelatorio.registro.id_cliente || 0}
// 			&id_tipo_contrato_demanda=${getState().faturaRelatorio.filtro.id_tipo_contrato_demanda || 0}
// 			&id_unidade_consumidora=${getState().faturaRelatorio.registro.id_unidade_consumidora || 0}`,
//             );

//             dispatch({
//                 type: "FATURA_RELATORIO_DEMANDA_LISTADO",
//                 payload: {
//                     demandas: resp.data,
//                     vigente: resp.data[0],
//                 },
//             });
//         } catch (ex) {
//             setErroAPI(ex);
//         }
//     };
// }

// export function getDemandaContagem() {
//     return async (dispatch, getState) => {
//         try {
//             dispatch({
//                 type: "FATURA_RELATORIO_DEMANDA_CONTAGEM_LISTADO",
//                 payload: { data: [] },
//             });

//             const resp = await axios.get(
//                 `${consts.API_URL}/demandaContrato/listarDemandaContagem?
// 			id_cliente=${getState().faturaRelatorio.registro.id_cliente || null}
// 			&id_tipo_contrato_demanda=${getState().faturaRelatorio.filtro.id_tipo_contrato_demanda || 0}`,
//             );

//             dispatch({
//                 type: "FATURA_RELATORIO_DEMANDA_CONTAGEM_LISTADO",
//                 payload: resp,
//             });
//         } catch (ex) {
//             setErroAPI(ex);
//         }
//     };
// }

// export function getListaGraficoCustoAnual(callback) {
//     return (dispatch, getState) => {
//         dispatch({
//             type: "FATURA_RELATORIO_GRAFICO_CUSTO_ANUAL_LISTADO",
//             payload: { data: [] },
//         });

//         axios
//             .get(
//                 `${consts.API_URL}/fatura/listarGraficoCusto?
// 			id_cliente=${getState().faturaRelatorio.registro.id_cliente || null}`,
//             )
//             .then((resp) => {
//                 dispatch({
//                     type: "FATURA_RELATORIO_GRAFICO_CUSTO_ANUAL_LISTADO",
//                     payload: resp,
//                 });
//                 callback();
//             })
//             .catch((ex) => {
//                 callback();
//             });
//     };
// }

// export function getListaGraficoGeracaoQuantidadeAnual(callback) {
//     return (dispatch, getState) => {
//         dispatch({
//             type: "FATURA_RELATORIO_GRAFICO_GERACAO_QUANTIDADE_ANUAL_LISTADO",
//             payload: { data: [] },
//         });

//         axios
//             .get(
//                 `${consts.API_URL}/fatura/listarGraficoGeracaoQuantidade?
// 			id_cliente=${getState().faturaRelatorio.registro.id_cliente || null}`,
//             )
//             .then((resp) => {
//                 dispatch({
//                     type: "FATURA_RELATORIO_GRAFICO_GERACAO_QUANTIDADE_ANUAL_LISTADO",
//                     payload: resp,
//                 });
//                 callback();
//             })
//             .catch((ex) => {
//                 callback();
//             });
//     };
// }

// export function getListaGraficoCustoPorUnidadeUltimoMes(callback) {
//     return (dispatch, getState) => {
//         dispatch({
//             type: "FATURA_RELATORIO_GRAFICO_CUSTO_UNIDADE_ULTIMO_MES_LISTADO",
//             payload: { data: [] },
//         });

//         axios
//             .get(
//                 `${consts.API_URL}/fatura/listarGraficoCustoPorUnidadeUltimoMes?
// 			id_cliente=${getState().faturaRelatorio.registro.id_cliente || 0}`,
//             )
//             .then((resp) => {
//                 dispatch({
//                     type: "FATURA_RELATORIO_GRAFICO_CUSTO_UNIDADE_ULTIMO_MES_LISTADO",
//                     payload: resp,
//                 });
//                 callback();
//             })
//             .catch((ex) => {
//                 //console.log(ex);
//                 callback();
//             });
//     };
// }

// export function getListaGraficoCustoPorUnidadeAcumuladoAno(callback) {
//     return (dispatch, getState) => {
//         dispatch({
//             type: "FATURA_RELATORIO_GRAFICO_CUSTO_UNIDADE_ACUMULADO_ANO_LISTADO",
//             payload: { data: [] },
//         });

//         axios
//             .get(
//                 `${consts.API_URL}/fatura/listarGraficoCustoPorUnidadeAcumuladoAno?
// 			id_cliente=${getState().faturaRelatorio.registro.id_cliente || 0}`,
//             )
//             .then((resp) => {
//                 dispatch({
//                     type: "FATURA_RELATORIO_GRAFICO_CUSTO_UNIDADE_ACUMULADO_ANO_LISTADO",
//                     payload: resp,
//                 });
//                 callback();
//             })
//             .catch((ex) => {
//                 callback();
//             });
//     };
// }

// export function getListaConsumoPercentualPorHorarioUltimoMes(callback) {
//     return (dispatch, getState) => {
//         dispatch({
//             type: "FATURA_RELATORIO_GRAFICO_CONSUMO_PERCENTUAL_POR_HORARIO_MES_LISTADO",
//             payload: { data: [] },
//         });

//         axios
//             .get(
//                 `${consts.API_URL}/fatura/listarConsumoPercentualPorHorarioUltimoMes?
// 			id_cliente=${getState().faturaRelatorio.registro.id_cliente || 0}`,
//             )
//             .then((resp) => {
//                 dispatch({
//                     type: "FATURA_RELATORIO_GRAFICO_CONSUMO_PERCENTUAL_POR_HORARIO_MES_LISTADO",
//                     payload: resp,
//                 });
//                 callback();
//             })
//             .catch((ex) => {
//                 callback();
//             });
//     };
// }

// export function getListaGraficoGeracaoAvaliacaoProducao(callback) {
//     return (dispatch, getState) => {
//         dispatch({
//             type: "FATURA_RELATORIO_GRAFICO_GERACAO_AVALIACAO_PRODUCAO_LISTADO",
//             payload: { data: [] },
//         });

//         axios
//             .get(
//                 `${consts.API_URL}/fatura/listarGraficoGeracaoAvaliacaoProducao?
// 			id_cliente=${getState().faturaRelatorio.registro.id_cliente || null}`,
//             )
//             .then((resp) => {
//                 dispatch({
//                     type: "FATURA_RELATORIO_GRAFICO_GERACAO_AVALIACAO_PRODUCAO_LISTADO",
//                     payload: resp,
//                 });
//                 callback();
//             })
//             .catch((ex) => {
//                 callback();
//             });
//     };
// }

// export function getListaGraficoCustoPorUnidadeMensal(callback) {
//     return (dispatch, getState) => {
//         dispatch({
//             type: "FATURA_RELATORIO_GRAFICO_CUSTO_UNIDADE_MENSAL_LISTADO",
//             payload: { data: [] },
//         });

//         axios
//             .get(
//                 `${consts.API_URL}/fatura/listarGraficoCustoPorUnidade?
// 			id_cliente=${getState().faturaRelatorio.registro.id_cliente || null}
// 			&ano=${String(getState().faturaRelatorio.registro.competencia).substring(0, 4)}`,
//             )
//             .then((resp) => {
//                 dispatch({
//                     type: "FATURA_RELATORIO_GRAFICO_CUSTO_UNIDADE_MENSAL_LISTADO",
//                     payload: resp,
//                 });
//                 callback();
//             })
//             .catch((ex) => {
//                 callback();
//             });
//     };
// }
